import { pdf } from "@react-pdf/renderer";
import { ReactElement } from "react";


export const downloadPdf = async ( filename: string, docComponent: ReactElement ) => {
  const link = document.createElement("a");
  const blob = await pdf( docComponent ).toBlob();

  link.href = window.URL.createObjectURL(blob);
  link.download = `${filename}.pdf`;
  link.click();
};

export const computeFontSize = ( str: string, maxStringLength: number, maxFontSize: number ) => {
  if ( str.length <= maxStringLength ) {
    return maxFontSize;
  }

  return Math.round( maxStringLength * maxFontSize / str.length );
};

export const computeNumTripsInInfoPage = ( trips: Record<string, any>[], max: number ) => {
    const maxCharsPerLine  = 35;
    const minAvgLinesCount = ( max < 4 ? 1 : 2 );
    const maxAvgLinesCount = ( max < 4 ? 4 : ( minAvgLinesCount * max ) );
  
    if ( !trips.length ) {
      return 0;
    }
    
    const end = Math.min( trips.length, max );
    let totLinesCount = 0;
  
    for ( let i = 0; i < end; i++ ) {
      let maxNumChars = 0;
      const trip = trips[i];
  
      for ( const key in trip ) {
        if ( typeof trip[key] === 'string' && trip[key].length > maxNumChars ) {
          maxNumChars = trip[key].length;
        }
      }
  
      totLinesCount += Math.ceil( maxNumChars / maxCharsPerLine );
    }
  
    const avgLinesCount = Math.ceil( totLinesCount / end );
  
    if ( avgLinesCount > maxAvgLinesCount ) {
      return 1;
    }
  
    if ( avgLinesCount <= minAvgLinesCount ) {
      return end;
    }
  
    return ( Math.floor( minAvgLinesCount * max / avgLinesCount ) + 1 );
  }; 
