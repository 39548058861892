import moment from "moment";
import { getAppConfig } from "../config";
import { PeriodState } from "../store/slices/periodSlice";


export const getClaimDetailPeriod = ( claimDate: string ): PeriodState => {
  const periodDeltaInDays  = getAppConfig( 'claimDetailPeriodDelta', 15 );
  const todayUtc           = moment.utc();
	const claimDateUtc       = moment.utc( claimDate );
	const claimDatePlusDelta = claimDateUtc.add(periodDeltaInDays, "days");
	const periodEnd          = claimDatePlusDelta.isSameOrBefore( todayUtc ) ? claimDatePlusDelta : todayUtc;
		
	return {
    start: moment.utc( claimDate ).subtract(periodDeltaInDays, "days").format("YYYY-MM-DD"),
    end: periodEnd.format("YYYY-MM-DD"),
  };
};

export const getProfileDetailPeriod = ( closeDate?: string | null ): PeriodState => {
  const periodDeltaInDays  = getAppConfig( 'profileDetailPeriodDelta', 15 );
  const dateObj = ( closeDate ? moment.utc( closeDate ) : moment.utc() );
		
	return {
    start: dateObj.clone().subtract( periodDeltaInDays, 'days' ).format( 'YYYY-MM-DD' ),
    end: dateObj.format( 'YYYY-MM-DD' ),
  };
};