import styled from "styled-components";
import { useAppTheme } from "../../hooks";


const WrapperStyled = styled.span`
  position: relative;

  &:hover .tooltip {
    display: flex;
  }
`;

const HelpPointStyled = styled.span`
  background: ${props => props.theme.palettes.tooltip};
  border-radius: 50%;
  color: ${props => props.theme.palettes.onTooltip};
  cursor: default;
  font-size: 0.65rem;
  font-weight: bold;
  height: 1rem;
  width: 1rem;
`;

const TooltipStyled = styled.span`
  background: ${props => props.theme.palettes.tooltip};
  border-radius: 4px;
  bottom: calc( 100% + 0.6em + 2px );
  box-shadow: 0px 2px 4px #07172258;
  color: ${props => props.theme.palettes.onTooltip};
  display: none;
  font-size: 0.68rem;
  line-height: 1.35em;
  padding: 0.5em 0.7em;
  position: absolute;
  text-align: center;
  width: 10rem;
  z-index: 1;

  &::before {
    content: "";
    display: block;
    border-width: 0.6em 0.8em 0;
    border-style: solid;
    border-color: transparent;
    border-top-color: ${props => props.theme.palettes.tooltip};
    top: 100%;
    position: absolute;
  }
`;

const HelpTooltip = ({
  helpText
}: {
  helpText: string;
}) => {
  const theme = useAppTheme();
  
  return (
    <WrapperStyled
      className='flex flex-hcenter'
    >
      <HelpPointStyled
        theme={theme}
        className='flex flex-center'
      >
        {'?'}
      </HelpPointStyled>
      <TooltipStyled
        className='tooltip flex flex-hcenter'
        theme={theme}
      >
        {helpText}
      </TooltipStyled>
    </WrapperStyled>
  );
};

export default HelpTooltip;