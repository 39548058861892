import { useEffect, createRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import classNames from "classnames";
import { getAppConfig, getAppDateFormat } from "../../../config";
import { useAppTheme } from "../../../hooks";
import { formatNumber } from "../../../utils";
import { secondsToDuration } from "../../../utils/time";
import { queryClient } from "../../../App";
import { valueNotFoundSymbol } from "../../../utils/constants";
import { isHeartbeat } from "../../../utils/trips-utils";

import { GroundCheckbox } from "@greenvulcano/ground-react";
import MoodyText from "../../MoodyText/MoodyText";

import { ListOfTripsInterface } from "./ListOfTripsInterface";
import "./ListOfTrips.scss";


const ListStyled = styled.ul`
  border-top: 2px solid ${( props: any ) => props.theme.palettes.borderQuaternaryAlpha20};
`;

const ItemStyled = styled.li`
  border-bottom: 1px solid ${( props: any ) => props.theme.palettes.borderQuaternaryAlpha50};
  color: ${( props: any ) => props.theme.palettes.textSecondaryDark};

  .id-column.highlighted {
    background: ${( props: any ) => props.theme.palettes.secondary};
    color: ${( props: any ) => props.theme.palettes.textWhite};
  }

  .trip-id {
    &::after {
      background: ${( props: any ) => props.color};
    }
  }
`;

const handleEmptyScore = ( score: string ) => {
  if ( undefined === score || null === score ) {
    return valueNotFoundSymbol;
  }

  return String( score );
};

const ListOfTrips = ({
  trips,
  showEmptyList,
  onChangeCheckedTrips = () => null
}: ListOfTripsInterface ) => {
  const theme = useAppTheme();
  const { t } = useTranslation( 'main' );

  const refsMap: any         = {};
  const noTripsToShow        = !( Array.isArray( trips ) && trips.length );
  const showScoreOnTripsList = getAppConfig('showScoreOnTripList');
  
  if ( !noTripsToShow ) {
    trips.forEach( item => {
      refsMap[item.trip_id] = createRef<HTMLLIElement>();
    });
  }

  const onChangeCheckbox = ( checked: boolean, tripID: string ) => {
    const trip = trips.find( item => item.trip_id === tripID );

    onChangeCheckedTrips({
      ...trip,
      checked: Boolean( checked )
    });
  };

  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey : [ 'trips' ] });
    }
  },[])

  useEffect(() => {
    if ( noTripsToShow ) {
      return;
    }

    for ( const item of trips ) {
      const tripID  = item.trip_id;
      const element = refsMap[tripID].current;

      if ( element && element.querySelector( 'input' ).checked ) {
        element.parentNode.scrollTop = (
          element.offsetTop - element.parentNode.offsetTop
        );

        break;
      }
    }  
  }, [ ( trips || [] ).map( item => item.trip_id ).join() ] );

  return (( noTripsToShow && !showEmptyList ) ? (
    <p>{t( 'common.noTripsFound' )}</p>
  ): (
    <ListStyled
      theme={theme}
      className='list-of-trips'
    >
      {(noTripsToShow && showEmptyList ) && (
        <li className='text-xsmall'>
          {t( 'common.noTripsToShow' )}
        </li>
      )}
      {trips.map( item => {
        const duration  = secondsToDuration( item.tot_duration, true ) as Record<string, string>;
        const score     = handleEmptyScore( item.score );
        const isHB      = isHeartbeat( item );

        return (
					<ItemStyled
						key={item.trip_id}
						ref={refsMap[item.trip_id]}
						theme={theme}
						color={item.color}
						className="flex gap-1"
					>
						<GroundCheckbox
							color="secondary"
							checked={item.checked}
							onChange={(event: any) =>
								onChangeCheckbox(event.target.checked, item.trip_id)
							}
						/>
						<div
							className={classNames(
                "id-column flex flex-columns", 
              {
								highlighted: Boolean(item.nearest_point_in_time),
                'flex-center': !showScoreOnTripsList
							})}
						>
							<strong className="trip-id">{"#" + item.trip_id}</strong>
							{isHB ? (
                <strong 
                  className='cursor-help score'
                  title={t( 'common.heartbeat' ) as string}
                >HB</strong>
              ) : ( showScoreOnTripsList && (
                <>
                  <span className="score-label">{t("common.score")}</span>
                  <strong className="score">{score}</strong>
                </>
              ))}
						</div>
						<div className="details-column flex flex-columns">
							<strong>
								{moment.utc(item.date_start).format(getAppDateFormat())}
							</strong>
							<strong>
								{t("common.timeToTime", {
									start: moment.utc(item.date_start).format("HH:mm"),
									end: item.date_end
										? moment.utc(item.date_end).format("HH:mm")
										: valueNotFoundSymbol,
								})}
							</strong>
							<span>
								{item.meters ? formatNumber(( Number( item.meters ) || 0 ) / 1000) : '0'}
								<MoodyText color="textSecondaryLight">km</MoodyText>
							</span>
							<span>
								{formatNumber(duration.hours) || '0'}
								<MoodyText color="textSecondaryLight">HH</MoodyText>
								{" - "}
								{formatNumber(duration.mins) || '0'}
								<MoodyText color="textSecondaryLight">MM</MoodyText>
							</span>
						</div>
					</ItemStyled>
				);
      })}
    </ListStyled>
  ));
};

export default ListOfTrips;
