import { StyleSheet, View, Text, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { formatNumber, capitalize } from "../../../utils";
import { secondsToDuration } from "../../../utils/time";
import { getMarkerIcon } from "../../../utils/colorcoded-markers";
import { valueNotFoundSymbol } from "../../../utils/constants";
import { tripModels } from "../../../models/tripModels";

import { PdfTripsTableInterface } from "./PdfTripsTableInterface";


const getTableColumns = ({ t }: Record<string, any> ) => {
	return [
		{
			id: 'trip_id',
			label: t( 'common.tripId' ),
			renderCellContent: ( value: string, dataItem: tripModels.TripProcessedData ) => {
				if ( !value ) {
					return <Text>{valueNotFoundSymbol}</Text>;
				}
	
				return (
					<View style={{ alignItems: 'center', flexDirection: 'row', gap: 4 }}>
						<Image
							src={getMarkerIcon( dataItem.color, 'end', 'png' )}
							style={{ height: 14, width: 14 }}
						/>
						<Text>{value}</Text>
					</View>
				);
			},
		},
		{
			id: 'date_start',
			label: t( 'common.date' ),
			renderCellContent: ( value: string ) => {
				return (
          <Text>
            {value ? capitalize( moment.utc( value ).format( 'MMMM DD, YYYY' ) ) : valueNotFoundSymbol}
          </Text>
        );
			}
		},
		{
			id: 'date_start',
			label: t( 'common.HHMM' ),
			formatHeadText: ( value: string ) => value.toUpperCase(),
			renderCellContent: ( value: string, dataItem: tripModels.TripProcessedData ) => {
				if ( !value ) {
					return <Text>{valueNotFoundSymbol}</Text>;
				}
	
				return (
					<Text>
						{t( 'common.timeToTime', {
								start: moment.utc( dataItem.date_start ).format( 'HH:mm' ), 
								end: moment.utc( dataItem.date_end ).format( 'HH:mm' )
						})}
					</Text>
				);
			},	
		},
		{
			id: 'meters',
			label: t( 'common.kmTravelled' ),
			renderCellContent: ( value: any ) => {
				return <Text>{value ? formatNumber( value / 1000 ) : valueNotFoundSymbol}</Text>;
			},	
		},
		{
			id: 'tot_duration',
			label: t( 'common.duration' ),
			renderCellContent: ( value: any ) => {
				const duration = secondsToDuration( value, true ) as Record<string, string>;

				return (
					<Text>
						{formatNumber( duration.hours ) || '0'}
						{'HH - '}
						{formatNumber( duration.mins ) || '0'}
						{'MM'}
					</Text>
				);
			},	
		},
		{
			id: 'address_start',
			label: t( 'common.startLocation' )
		},
		{
			id: 'address_end',
			label: t( 'common.endLocation' )
		},
	];
};

const getStyles = ({ theme }: Record<string, any>): Record<string, any> =>
	StyleSheet.create({
		bold: {
			fontFamily: 'Helvetica-Bold',
		},
		table: {
			backgroundColor: theme.palettes.textWhite,
			border: `0.5px solid ${theme.palettes.backgroundDarker}`,
			borderTopLeftRadius: 3,
			borderTopRightRadius: 3,
			overflow: 'hidden',
			width: '100%',
		},
		header: {
			backgroundColor: theme.palettes.backgroundDarker,
			color: theme.palettes.textWhite,
			width: '100%',
			height: 22,
			paddingLeft: 10,
			paddingRight: 10,
			flexWrap: 'nowrap',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		itemHeader: {
			fontSize: 7,
		},
		contentTable: {
			color: theme.palettes.textDarkest,
			width: '100%',
			paddingHorizontal: 10,
			flexWrap: 'wrap',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		row: {
			fontSize: 6,
			paddingTop: 7,
			paddingBottom: 7,
			flexDirection: 'row',
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'center',
			borderBottom: `0.5px solid ${theme.palettes.borderTertiaryDarker}`,
		},
		item1: { width: 50 },
		item2: { width: 50 },
		item3: { width: 50 },
		item4: { width: 50 },
		item5: { width: 50 },
		item6: { width: 100 },
		item7: { width: 100 },
	});

const PdfTripsTable = ({ theme, trips }: PdfTripsTableInterface) => {
	const styles  = getStyles({ theme });
	const { t }   = useTranslation( 'main' );
	const columns = getTableColumns({ t });

	return (
		<View style={styles.table}>
      <View style={[styles.bold, styles.header]}>
        {columns.map((item: any, itemIdx: number) => {
          return (
            <View
              key={'column' + itemIdx}
              style={styles.itemHeader}
            >
              <View style={styles[`item${itemIdx + 1}`]}>
                <Text>
                  {typeof item.formatHeadText === 'function' ? 
                  item.formatHeadText( item.label ) : 
                  item.label}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
      <View style={styles.contentTable}>
        {trips.map((item: any, itemIdx: number) => {
          return (
            <View
              key={'row' + itemIdx}
              style={[ styles.row, ( itemIdx < trips.length - 1 ? {} : { borderBottom: 'none' } )]}
            >
              {columns.map((column: any, columnIdx: number) => {
                const stylesID = `item${columnIdx + 1}`;

                return (
                  <View
                    key={stylesID}
                    style={styles[stylesID]}
                  >
                    {column.id in item ? (
                      typeof column.renderCellContent === 'function' ? (
                        column.renderCellContent( item[column.id], item )
                      ) : ( 
                        <Text>{item[column.id] || valueNotFoundSymbol}</Text>
                      )
                    ) : (
                      <Text>{valueNotFoundSymbol}</Text>
                    )}
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    </View>
	);
};

export default PdfTripsTable;
