import { useTranslation } from "react-i18next";
import { useAppTheme } from "../../../../hooks";
import Chart from "../../../../shared-components/Chart/Chart";
import { capitalize } from "../../../../utils";

import { getChartOption, getHeatmapChartOption } from "./options";
import { TrendsChartInterface } from "./TrendsChartInterface";


const TrendsChart = ({
  uid,
  data,
  period,
}: TrendsChartInterface ) => {
  const { t }  = useTranslation( 'main' );
  const theme  = useAppTheme();

  let name      = '';
  let mainColor = ''
  
  switch ( uid ) {
    case 'speeding':
      name = t( 'common.speeding' );
      break;

    default:
      name = t( 'common.events' );
      break;
  }

  switch ( data.type ) {
    case 'hard_breaking':
      mainColor = 'danger';
      break;

    default:
      mainColor = 'secondary';
      break;
  }

  let chartOption = null;
  const dataName  = capitalize( name );

  if ( 'stats_by_day' === uid ) {
    chartOption = getHeatmapChartOption({
      theme,
      dataName,
      data
    });
  }
  else {
    chartOption = getChartOption({
      theme,
      mainColor,
      dataName,
      period,
      data
    });
  }

  return (
    <Chart
      isResizable
      option={chartOption}
    />
  );
};

export default TrendsChart;