import { claimPageModels } from "../../models/claimPageModels";

export const claimTableColumns = [
    claimPageModels.ClaimTableColumns.claimId,
    claimPageModels.ClaimTableColumns.policyHolder,
    claimPageModels.ClaimTableColumns.policyId,
    claimPageModels.ClaimTableColumns.plate,
	claimPageModels.ClaimTableColumns.vin,
	claimPageModels.ClaimTableColumns.location,
	claimPageModels.ClaimTableColumns.claimDate,
	claimPageModels.ClaimTableColumns.idClaimStatus,
	claimPageModels.ClaimTableColumns.claimType,
	//claimPageModels.ClaimTableColumns.severity,
	claimPageModels.ClaimTableColumns.fraudProbabilityId,	
];

export const getClaimTableColumns = () => {
	return claimTableColumns;
};