import { appConfig } from "../config/app-config/app-config";


export const isPolicyTypePersonal = ( id: string | number ) => {
  return ( appConfig.policyTypeIDs.personal === String( id ) );
};

export const isPolicyTypeCommercial = ( id: string | number ) => {
  return ( appConfig.policyTypeIDs.commercial === String( id ) );
};

export const canClaimAmountBoxInProfileDetailsBeShown = ( policyTypeID: string | number ) => {
  if (isPolicyTypePersonal(policyTypeID)) {
    return appConfig.showClaimAmountBoxInProfileDetailsForPersonalPolicy;
  }

  if (isPolicyTypeCommercial(policyTypeID)) {
    return appConfig.showClaimAmountBoxInProfileDetailsForCommercialPolicy;
  }
 
  return appConfig.showClaimAmountBoxInProfileDetails;
};

export const canStatisticSectionForOutsideTripsInProfileDetailsBeShown = ( policyTypeID: string | number ) => {
  if ( isPolicyTypePersonal( policyTypeID ) ) {
    return appConfig.showStatisticSectionForOutsideTripsInProfileDetailsForPersonalPolicy;
  }

  if ( isPolicyTypeCommercial( policyTypeID ) ) {
    return appConfig.showStatisticSectionForOutsideTripsInProfileDetailsForCommercialPolicy;
  }

  return appConfig.showStatisticSectionForOutsideTripsInProfileDetails;
};

export const canResidenceAreaInProfileDetailsBeShown = ( policyTypeID: string | number ) => {
  if (isPolicyTypePersonal(policyTypeID)) {
    return appConfig.showResidenceAreaInProfileDetailsForPersonalPolicy;
  }

  if (isPolicyTypeCommercial(policyTypeID)) {
    return appConfig.showResidenceAreaInProfileDetailsForCommercialPolicy;
  }
 
  return appConfig.showResidenceAreaInProfileDetails;
};