import { useTranslation } from "react-i18next";
import { getDropdownOptions } from "../../../../config/dropdowns";

import { GroundIcon } from "@greenvulcano/ground-react";
import DrawerSection from "../../../../shared-components/FiltersDrawer/DrawerSection/DrawerSection";
import TextInput from "../../../../shared-components/RHF-input-wrappers/TextInput/TextInput";
import Select from "../../../../shared-components/RHF-input-wrappers/Select/Select";
import Toggle from "../../../../shared-components/RHF-input-wrappers/Toggle/Toggle";
import DateRangePicker from "../../../../shared-components/RHF-input-wrappers/DateRangePicker/DateRangePicker";
import RangeSlider from "../../../../shared-components/RHF-input-wrappers/RangeSlider/RangeSlider";
import { settingsDefaults } from "../../../../config/settings-defaults";
import { useState } from "react";
import { claimFilters } from "../../../../config/app-config/claim-filters-config";
import { claimPageModels } from "../../../../models/claimPageModels";


const DrawerContent = ({
  control,
  errors,
  watch,
  vehicleTypesOptions
}: Record<string, any> ) => {
  const { t } = useTranslation( 'main' );
  const [maxDate] = useState(settingsDefaults.maxDate);

  const claimDateRange     = watch( 'claim_date' );
  const sliderDisabled     = claimDateRange && !( claimDateRange.start && claimDateRange.end );
  const claimStatusOptions = getDropdownOptions( 'claimStatus', false, ( item: any ) => t( item.label ) );

  return (
    <>
      <DrawerSection title={t( 'common.searchYourClient' )}>
       {claimFilters.includes(claimPageModels.ClaimFilters.claimId) && <TextInput
          fluid
          name='claim_id'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.claimId' ) as string}
        />}
       {claimFilters.includes(claimPageModels.ClaimFilters.vin) && <TextInput
          fluid
          name='vin'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.vin' ) as string}
        />}
        {claimFilters.includes(claimPageModels.ClaimFilters.plate) && <TextInput
          fluid
          name='plate'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.plate' ) as string}
        />}
        {claimFilters.includes(claimPageModels.ClaimFilters.policyHolder) && <TextInput
          fluid
          name='policyholder'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.policyholderName' ) as string}
        />}
        {claimFilters.includes(claimPageModels.ClaimFilters.policyId) && <TextInput
          fluid
          name='policy_id'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.policyNumber' ) as string}
        />}
       {claimFilters.includes(claimPageModels.ClaimFilters.agency) && <TextInput
          fluid
          name='agency'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.agency' ) as string}
        />}
       {claimFilters.includes(claimPageModels.ClaimFilters.agent) && <TextInput
          fluid
          name='agent'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.agent' ) as string}
        />}
        {claimFilters.includes(claimPageModels.ClaimFilters.idClaimStatus) && <Select
          fluid
          name='id_claim_status'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.claimStatus' ) as string}
          defaultValue='__all'
          options={[
            { value: '__all', label: t( 'common.allClaimStatus' )},
            ...claimStatusOptions
          ]}
        />}
        {claimFilters.includes(claimPageModels.ClaimFilters.severity) && <Select
          fluid
          name='severity'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.severity' ) as string}
          defaultValue='__all'
          options={[
            { value: '__all', label: t( 'common.allSeverity' )},
            { value: 'high', label: t( 'common.high' )},
            { value: 'medium', label: t( 'common.medium' )},
            { value: 'low', label: t( 'common.low' )},
          ]}
        />}
        {claimFilters.includes(claimPageModels.ClaimFilters.vehicleType) && <Select
          fluid
          name='vehicle_type_id'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.vehicleType' ) as string}
          defaultValue='__all'
          options={[
            { value: '__all', label: t( 'common.allVehicleType' )},
            ...vehicleTypesOptions
          ]}
        />}
      </DrawerSection>
      {claimFilters.includes(claimPageModels.ClaimFilters.fraudProbabilityId) && <DrawerSection title={t( 'common.filterByFraudProbability' )}>
        <div className='flex gap-7'>
          <div className='flex flex-columns gap-1'>
            <Toggle
              animated
              color='successLight'
              name='fraud_prob_low'
              control={control}
              errors={errors}
              label={t( 'common.low' ) as string}
              scale={0.8}
              defaultValue={true}
            />
            <Toggle
              animated
              color='danger'
              name='fraud_prob_high'
              control={control}
              errors={errors}
              label={t( 'common.high' ) as string}
              scale={0.8}
              defaultValue={true}
            />
          </div>
          <div className='flex flex-columns gap-1'>
            <Toggle
              animated
              color='warning'
              name='fraud_prob_medium'
              control={control}
              errors={errors}
              label={t( 'common.medium' ) as string}
              scale={0.8}
              defaultValue={true}
            />
            <Toggle
              animated
              color='textSecondaryLight'
              name='fraud_prob_unknown'
              control={control}
              errors={errors}
              label={t( 'common.unknown' ) as string}
              scale={0.8}
              defaultValue={true}
            />
          </div>
        </div>
      </DrawerSection>}
      <DrawerSection title={t( 'common.filterByTimeOfClaim' )}>
       { claimFilters.includes(claimPageModels.ClaimFilters.claimDate) && <DateRangePicker
          fluid
          showOnTop
          name='claim_date'
          height='40'
          control={control}
          maxDate={maxDate}
          errors={errors}
          onChange={() => null}
          icon={<GroundIcon color='textLight' svgIcon='calendar' width='22' />}
        />}
        {claimFilters.includes(claimPageModels.ClaimFilters.claimTime) && <RangeSlider
          name='claim_time'
          className='pb-2'
          valueType='time'
          max={(24*60*60)-1} // 23h 59m 59s
          step={5*60} // 5min
          minRange={30*60} // 30min
          control={control}
          errors={errors}
          disabled={sliderDisabled}
        />}
        {claimFilters.includes(claimPageModels.ClaimFilters.postalCode) && <TextInput
          fluid
          name='postal_code'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.statePostalCode' ) as string}
          icon={<GroundIcon color='textLight' svgIcon='cap' width='22' />}
        />}
        {claimFilters.includes(claimPageModels.ClaimFilters.location) && <TextInput
          fluid
          name='location'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.claimLocation' ) as string}
          icon={<GroundIcon color='textLight' svgIcon='pin' width='22' />}
        />}
      </DrawerSection>
    </>
  );
};

export default DrawerContent
