import { InputInterface } from "./InputInterface";
import "./Input.scss";
import {  GroundTextInput } from "@greenvulcano/ground-react";

const Input = ({ value, onChange,width,placeHolder,icon,className }: InputInterface) => {

	return (
			<GroundTextInput
				type="text"
				width={width}
				value={value}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
					onChange(event.target.value)
				}
				placeholder={placeHolder}
			icon={icon}
			className={className}
			/>
	);
};

export default Input;
