import { 
  GoogleMap, 
  MarkerF,
  PolylineF,
  CircleF, 
  Polygon, 
  MarkerClustererF
} from "@react-google-maps/api";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import { useAppTheme } from "../../hooks";
import { getMarkerIcon } from "../../utils/colorcoded-markers";
import { getAppDateFormatWithAproximateTime } from "../../config";
import { appConfig } from "../../config/app-config/app-config";

import nearestMarkerIcon from "../../images/nearest-marker.svg";
import homeMarkerIcon from "../../images/home-marker.svg";
import clusterMarkerIcon from "../../images/cluster-marker.svg";
import RequestIndicator from "../RequestIndicator/RequestIndicator";

import { mapStyles } from "../../config/google-map-styles";
import { MapInterface } from "./MapInterface";
import {
	selectGoogleIsLoaded,
	selectAllMarkerObjects,
	selectShowOriginalClaimMarker,
	selectAllShapeObjects,
	selectPolygonShapeObjects,
} from "../../store/slices/mapSlice";
import { useAppSelector } from "../../store/hooks";
import { claimModels } from '../../models/claimModels';
import { isHeartbeat } from "../../utils/trips-utils";
import { tripModels } from "../../models/tripModels";


const getPolylineOptions = (strokeColor: string) => {
	return {
		strokeColor,
		strokeOpacity: 1,
		strokeWeight: 2,
		clickable: false,
		draggable: false,
		editable: false,
		visible: true,
		zIndex: 1,
	};
};

const getMarkerUID = (markerID: string | number, tripID?: string | number) => {
	return (tripID ? tripID + "_" : "") + markerID;
};

const Map = ({
	claim,
	trips,
	residenceCoords,
	isAwaiting,
	className,
}: MapInterface) => {
	const { t } = useTranslation("main");
	const theme = useAppTheme();
	const googleMapRef = useRef<any>();
	const [mapInstance, setMapInstance] = useState<any>(null);
  const [mapChildren, setMapChildren] = useState<any[]>([]);
  const [tempMapChildren, setTempMapChildren] = useState<any[]>([]);
	const [tripsList, setTripsList] = useState(trips);
	const [hasAreaOrPositionBeenAddedToMap, setHasAreaOrPositionBeenAddedToMap] =
		useState(false);
	const [showAreaMarker, setShowAreaMarker] = useState(true);
	const [showInBetweenPoints, setShowInBeetweenPoints] = useState(false);
	const [clickableMarkersMap, setClickableMarkersMap] = useState(
		{} as Record<string, boolean>
	);
	const mapMarkers = useAppSelector(selectAllMarkerObjects);
	const mapShapes = useAppSelector(selectAllShapeObjects);
	const mapPolygonShape = useAppSelector(selectPolygonShapeObjects);
	const showOriginalClaimMarker = useAppSelector(selectShowOriginalClaimMarker);
	const isLoaded = useAppSelector(selectGoogleIsLoaded);
	const thereAreTrips = trips && trips.length;
	const area = claim ? claim.claim_area : null;
	const position = claim ? claim.claim_position : null;

	const openStreetView = (position: any) => {
		const options = {
			position,
			enableCloseButton: true,
			pov: {
				heading: 34,
				pitch: 10,
			},
		};
		const panorama = new window.google.maps.StreetViewPanorama(
			googleMapRef.current.mapRef,
			options
		);

		mapInstance.setStreetView(panorama);
	};

	const maybeSetMarkerAsClickable = (
		markerUID: string,
		{ lat, lng }: { lat: number | string; lng: number | string }
	) => {
		if (typeof clickableMarkersMap[markerUID] === "boolean") {
			return;
		}

		const streetViewService = new window.google.maps.StreetViewService();
		const location = new window.google.maps.LatLng(
			parseFloat(String(lat)),
			parseFloat(String(lng))
		);

		streetViewService.getPanorama(
			{ location, source: window.google.maps.StreetViewSource.OUTDOOR },
			(data, status) => {
				setClickableMarkersMap((_map) => ({
					..._map,
					[markerUID]: status === window.google.maps.StreetViewStatus.OK,
				}));
			}
		);
	};

	const onZoomChanged = () => {
		if (!mapInstance) {
			return;
		}

		if (mapInstance.getZoom() <= 10) {
			setShowInBeetweenPoints(false);
		} else {
			setShowInBeetweenPoints(true);
		}

		if (mapInstance.getZoom() <= 10 || !!position) {
			setShowAreaMarker(true);
		} else {
			setShowAreaMarker(false);
		}
	};

	const canMarkerBeDisplayed = (markerUID: string) => {
		return typeof clickableMarkersMap[markerUID] === "boolean";
	};

  const getTripsListMarkers = ( heartbeatsClusterer?: any ) => {
    let heartbeatIdx = 1;
    const tripMarkers: any[]         = [];
    const heartbeatMarkers: any[]    = [];
    const returnOnlyHeartbeatMarkers = Boolean( heartbeatsClusterer );

    tripsList.forEach( item => {
      const coords      = item.coords as tripModels.TripCoordsItem[];
      const isHB        = isHeartbeat( item );
      const npMarkerUID = getMarkerUID("nearestPoint", item.trip_id);

      if (
        !returnOnlyHeartbeatMarkers &&
        item.processed_nearest_point_in_time && 
        canMarkerBeDisplayed(npMarkerUID)
      ) {
        tripMarkers.push(
          <MarkerF
            key={"nearestMarker_" + item.trip_id}
            position={item.processed_nearest_point_in_time}
            icon={nearestMarkerIcon}
            zIndex={3}
            onClick={() => openStreetView(item.processed_nearest_point_in_time)}
            clickable={clickableMarkersMap[npMarkerUID]}
          />
        );
      }

      if ( !( isHB || returnOnlyHeartbeatMarkers ) ) {
        tripMarkers.push(
          <PolylineF
            key={"polyline_" + item.trip_id}
            path={coords}
            options={getPolylineOptions(item.color)}
          />
        );
      }

      const end = ( Number( coords?.length ) || 0 );

      for ( let coordsItemIdx = 0; coordsItemIdx < end; coordsItemIdx++ ) {
        let zIndex       = 1;
        let iconPrefix   = 'point';
        const coordsItem = coords[coordsItemIdx];
        const markerUID  = getMarkerUID(coordsItemIdx, item.trip_id);

        if (0 === coordsItemIdx) {
          zIndex     = 2 + ( isHB ? heartbeatIdx++ : 0 );
          iconPrefix = ( isHB ? 'empty' : 'start' );
        } 
        else if (coords.length === coordsItemIdx + 1) {
          zIndex     = 2;
          iconPrefix = 'end';
        }

        if ( 'point' === iconPrefix && !showInBetweenPoints ) {
          continue;
        }

        if ( !canMarkerBeDisplayed( markerUID ) ) {
          continue;
        }

        const markerKey = iconPrefix + '_marker_' + item.trip_id + '_' + item.color + '_' + coordsItemIdx;
        const title     = ( 
          (coordsItem.date || '') && 
          moment.utc(coordsItem.date).format(getAppDateFormatWithAproximateTime()) 
        );

        const theMarkerProps = {
          title,
          zIndex,
          key: markerKey,
          position: coordsItem,
          icon: getMarkerIcon(item.color, iconPrefix),
          onClick: () => openStreetView( coordsItem ),
          clickable: clickableMarkersMap[markerUID],
        };

        if ( isHB ) {
          heartbeatMarkers.push(
            <MarkerF
              {...theMarkerProps}
              clusterer={heartbeatsClusterer}
              label={{
                text: 'HB',
                color: theme.palettes.textWhite
              }}
            />
          );
        } 
        else {
          tripMarkers.push( <MarkerF {...theMarkerProps} /> );
        }
      }
    });

    if ( returnOnlyHeartbeatMarkers ) {
      return heartbeatMarkers;
    }

    return tripMarkers;
  };

	useEffect(() => {
		if (!mapInstance) {
			return;
		}

		const newTripsList: tripModels.TripProcessedData[] = [];
		const bounds = new window.google.maps.LatLngBounds();

		trips.forEach( item => {
			if (item.coords) {
				item.coords.forEach(( coordsItem, idx ) => {
					bounds.extend(
						new window.google.maps.LatLng(
							parseFloat(String(coordsItem.lat)),
							parseFloat(String(coordsItem.lng))
						)
					);

					maybeSetMarkerAsClickable(
						getMarkerUID(idx, item.trip_id),
						coordsItem
					);
				});
			}

			if ( appConfig.showNearestPointInTimeMarkerOnMap && item.nearest_point_in_time ) {
        const nPointCoords = item.nearest_point_in_time.coordinates;
				const processed_nearest_point_in_time = {
					lng: parseFloat(String(nPointCoords[0])),
					lat: parseFloat(String(nPointCoords[1])),
				};

        newTripsList.push({ ...item, processed_nearest_point_in_time });
				maybeSetMarkerAsClickable(
					getMarkerUID("nearestPoint", item.trip_id),
					processed_nearest_point_in_time
				);
			} 
      else if ( item.coords ) {
				newTripsList.push(item);
			}
		});

		if (residenceCoords) {
			bounds.extend(
				new google.maps.LatLng(
					parseFloat(String(residenceCoords.lat)),
					parseFloat(String(residenceCoords.lng))
				)
			);
		}

		Object.values(mapMarkers).forEach((marker) => {
			if (marker.position?.lat && marker.position?.lng) {
				bounds.extend(
					new google.maps.LatLng(
						parseFloat(String(marker.position.lat)),
						parseFloat(String(marker.position.lng))
					)
				);
			}
		});

		if (area || position) {
			if (position && showOriginalClaimMarker) {
				const [lng, lat] = position.coordinates;

				if (lng && lat) {
					bounds.extend(
						new google.maps.LatLng(
							parseFloat(String(lat)),
							parseFloat(String(lng))
						)
					);

					maybeSetMarkerAsClickable(getMarkerUID("claimPosition"), {
						lat,
						lng,
					});
				}
			}

			if (!hasAreaOrPositionBeenAddedToMap) {
				if (area && !position) {
					mapInstance.data.addGeoJson({
						type: "Feature",
						geometry: {
							type: "MultiPolygon",
							coordinates: area.coordinates,
						},
					});
					mapInstance.data.setStyle(() => {
						return {
							strokeWeight: 1,
							strokeColor: theme.palettes.secondary,
							fillColor: theme.palettes.secondary,
							fillOpacity: 0.2,
							clickable: false,
						};
					});
				}

				setHasAreaOrPositionBeenAddedToMap(true);
			}

			mapInstance.data.forEach((feature: any) => {
				feature.getGeometry().forEachLatLng((LatLng: any) => {
					bounds.extend(LatLng);
				});
			});
		}

		mapInstance.fitBounds(bounds);
		setTripsList(newTripsList);
	}, [
		mapMarkers,
		mapInstance,
		JSON.stringify(residenceCoords),
		(trips || [])
			.map(item => item.trip_id + "-" + Number(Boolean(item.coords)))
			.join(),
	]);



  useEffect(() => {
    const tempArray: any[] = getTripsListMarkers();

    tempArray.push(
      <MarkerClustererF
        key='hb-clusterer'
			  maxZoom={17}
        gridSize={44}
        styles={[
          { 
            height: 40, 
            width: 40,
            textColor: theme.palettes.textWhite, 
            url: clusterMarkerIcon
          }
        ]}
        calculator={( markers, num ) => {
          return {
            text: ( markers.length + ' HB' ),
            index: num
          };
        }}
      >
        {( clusterer ) => (
          <>
            {getTripsListMarkers( clusterer )}
          </>
        )}
      </MarkerClustererF>
    );

    if (residenceCoords) {
      tempArray.push(
        <MarkerF
          key={"residenceMarker"}
          position={residenceCoords as any}
          icon={homeMarkerIcon}
          zIndex={2}
          clickable={false}
        />
      );
    }

    if (showAreaMarker && claim) {
      let lat: number | null = null;
      let lng: number | null = null;

      if (position && position.coordinates) {
        [lng, lat] = position.coordinates;
      } else if (area && area.coordinates) {
        const coordsIdx = Math.floor(area.coordinates[0][0].length / 2);

        [lng, lat] = area.coordinates[0][0][coordsIdx];
		}

      if (
        lat &&
        lng &&
        ((area && !position) ||
          (position && canMarkerBeDisplayed("claimPosition")))
	  ) {
			const color =
				claimModels.fraudProbabilityColorsMap[
					claim.fraud_probability_id as keyof typeof claimModels.fraudProbabilityColorsMap
				];
			const type =
				claimModels.claimTypeMap[
					claim.claim_type_id as keyof typeof claimModels.claimTypeMap
				];
			tempArray.push(
				<MarkerF
					key={"claimPositionMarker"}
					position={{ lat, lng }}
					zIndex={10}
					icon={getMarkerIcon(color, type)}
					onClick={() => openStreetView({ lat, lng })}
					clickable={area ? false : clickableMarkersMap["claimPosition"]}
					visible={showOriginalClaimMarker}
				/>
			);
		}
    }
    const mapMarkersArray = Object.values(mapMarkers);
	  if (mapMarkersArray.length > 0) {
      mapMarkersArray.forEach((marker) => {
        tempArray.push(
          <MarkerF
            key={marker.key}
            position={marker.position}
            zIndex={marker.zIndex}
            icon={marker.icon}
            onClick={
              marker.onClick !== undefined
                ? marker.onClick
                : () => openStreetView(marker.position)
            }
            clickable={marker.clickable === false ? false : true}
          />
        );
      });
    }
	  const mapShapesArray = Object.values(mapShapes);
	if (mapShapesArray.length > 0) {
		mapShapesArray.forEach((shape,idx) => {
		  tempArray.push(
			  <CircleF
				  key={'circle_'+idx}
					options={{
						strokeOpacity: shape.strokeOpacity,
						strokeWeight: shape.strokeWeight,
						fillColor: shape.fillColor,
						fillOpacity: shape.fillOpacity,
						strokeColor: shape.strokeColor,
					}}
					center={shape.center}
					radius={shape.radius}
				/>
			);
		});
	  }

	  const mapPolygonArray = Object.values(mapPolygonShape);
	  if (mapPolygonArray.length > 0) {
		mapPolygonArray.forEach((shape, idx) => {
		  const paths = shape.paths.map((coord) => ({
			lat: typeof coord.lat === 'function' ? coord.lat() : coord.lat,
			lng: typeof coord.lng === 'function' ? coord.lng() : coord.lng,
		  }));

		  tempArray.push(
			<Polygon
			  key={'polygon_' + idx}
			  paths={paths}
			  options={{
				strokeOpacity: shape.strokeOpacity,
				strokeWeight: shape.strokeWeight,
				fillColor: shape.fillColor,
				fillOpacity: shape.fillOpacity,
				strokeColor: shape.strokeColor,
			  }}
			/>
		  );
		});
	  }

	  const mapMarkerArray = Object.values(mapMarkers);
	  if (mapMarkerArray.length > 0) {
      mapMarkerArray.forEach((marker) => {
        tempArray.push(
          <MarkerF
            key={'marker'+marker.key}
            position={marker.position}
            zIndex={marker.zIndex}
            icon={marker.icon}
            onClick={
              marker.onClick !== undefined
                ? marker.onClick
                : () => openStreetView(marker.position)
            }
            clickable={marker.clickable === false ? false : true}
          />
        );
      });
    }

    // !important this is a hack to force rerender of the map.
    // Unfortunately there seems to be a bug in google maps.
    // if only the children of mapChildren update the icon of the marker won't change.
    // it is necessary to set mapChildren to an empty array and then set everything once again.
    setMapChildren([]);
    setTempMapChildren(tempArray);
  }, [
    area,
    claim,
    clickableMarkersMap,
    mapMarkers,
    position,
    residenceCoords,
    showAreaMarker,
    showInBetweenPoints,
    showOriginalClaimMarker,
	  tripsList,
	mapShapes
  ]);

  useEffect(() => {
    setMapChildren(tempMapChildren);
  }, [tempMapChildren]);

	return (position ||
    area ||
    thereAreTrips ||
    Object.values(mapMarkers).length) &&
    isLoaded ? (
    <div
      className={classNames(
        "map-wrapper width-100 height-100 pos-relative",
        className
      )}
    >
      {isAwaiting ? (
        <RequestIndicator
          isLoading
          delay={1000}
          spinnerOptions={{ scale: 0.5 }}
          backgroundColor="backgroundAlpha50"
          className="pos-absolute zindex-10"
        />
      ) : null}
      <GoogleMap
        ref={googleMapRef}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        zoom={13}
        center={undefined}
        options={{
          styles: mapStyles,
          mapTypeControl: false,
          scaleControl: true,
          controlSize: 32,
          gestureHandling: "greedy",
          minZoom: 3,
          maxZoom : 17
        }}
        onLoad={(map: any) => setMapInstance(map)}
        onZoomChanged={onZoomChanged}
      >
        {mapChildren}
      </GoogleMap>
    </div>
  ) : (
    <div className="height-100 flex flex-center">
      {t("common.noDataToShow")}
    </div>
  );
};

export default Map;
