import { useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { applyErrorBoundary } from "../../../utils/error-boundary";
import { useUserQuery } from "../../../hooks/queries";
import { setDocumentTitle } from "../../../config";
import { useConfirmationModal } from "../../../hooks";
import { FunctionalitiesService } from "../../../services/FunctionalitiesService";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { loadDropdownsData } from "../../../store/slices/dropdownsSlice";
import { selectBrowsingLocked } from "../../../store/slices/browsingLockedSlice";

import { headerNavItems, HeaderNavItem } from "../../../config/header-nav-items";
import Header from "../../../shared-components/Header/Header";
import RequestIndicator from "../../../shared-components/RequestIndicator/RequestIndicator";
import BrowsingLockedModal from "../../../shared-components/confirmation-modals/BrowsingLockedModal/BrowsingLockedModal";

const Root = () => {
  const { t }             = useTranslation( 'main' );
  const location          = useLocation();
  const appDispatch       = useAppDispatch()
  const userQuery         = useUserQuery();
  const isBrowsingLocked  = useAppSelector( selectBrowsingLocked );
  const confirmationModal = useConfirmationModal();
  
  useQuery({
    queryFn: FunctionalitiesService.getDropdownOptions,
    enabled: userQuery.isSuccess,
    onSuccess: ( data: any ) => {
      appDispatch( loadDropdownsData( data ) );
    }
  });

  useLayoutEffect(() => {
    const foundItem = headerNavItems.find(( item: HeaderNavItem ) => (
      item.routerTo === location.pathname
    ))

    setDocumentTitle( foundItem && t( foundItem.i18nKey ) as string );
  }, [ location.pathname ] );

  useEffect(() => {
    if ( isBrowsingLocked ) {
      confirmationModal.open( <BrowsingLockedModal />, { canClose: false } );
    }
    else {
      confirmationModal.close();
    }
  }, [ isBrowsingLocked ] );

  if ( userQuery.isIdle || userQuery.isError ) {
    return <Navigate to='/login' />;
  }

  if ( userQuery.isSuccess && ( /^\/+admin\/*$/i.test( location.pathname ) ) && headerNavItems.length ) {
    return <Navigate to={headerNavItems[0].routerTo} />;
  }

  return ( userQuery.isSuccess ? (
    <>
      {location.pathname.includes( '/pdf/' ) ? null : <Header />}
      <main className='flex-grow flex flex-columns'>
        <Outlet />
      </main>
    </>
  ) : (
    <RequestIndicator
      isLoading
      delay={500}
      className='flex-grow'
    />
  ));
};

export default applyErrorBoundary( Root );