import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const HotjarStateChange = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.hj) {
      window.hj("stateChange", location.pathname);
    }
  }, [location]);

  return <></>;
};
