import { useConfirmationModal } from "../../../hooks";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import { ClaimDetailsConfirmationModalInterface } from "./ClaimDetailsConfirmationModalInterface";
import { useTranslation } from "react-i18next";
import "./ClaimDetailsConfirmationModal.scss";

const ClaimDetailsConfirmationModal = ({ onAction }: ClaimDetailsConfirmationModalInterface) => {
    const { t } = useTranslation("main");
    const confirmationModal = useConfirmationModal();

    const onCloseModalHandler = () => {
		confirmationModal.close();
	};


    const content = (
		<div className="detail-claim-modal">
            <p>{t("modals.cancelButtonMessage")}</p>
		</div>
	);

    return (
        <ConfirmationModal
          title={t('common.confirmCancellation') as string}
          content={content}
          onClose={onCloseModalHandler}
          onAction ={onAction}
      />
    )
}


export default ClaimDetailsConfirmationModal;

