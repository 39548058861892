import { AppLocales } from "../constants";


export interface LocaleItem {
	value: string;
	label: string;
}

export const locales: LocaleItem[] = [
	{ value: AppLocales.esMX, label: 'ESP' },
	{ value: AppLocales.enUS, label: 'ENG' },
];
