import { claimPageModels } from "../../models/claimPageModels";


export const showTelematicData = false;

const claimTelematicFilters: claimPageModels.ClaimFilters[] = [];

export const claimFilters = [
  claimPageModels.ClaimFilters.claimId,
  claimPageModels.ClaimFilters.vin,
  claimPageModels.ClaimFilters.plate,
	claimPageModels.ClaimFilters.policyHolder,
  claimPageModels.ClaimFilters.policyId,
	//claimPageModels.ClaimFilters.agency,
	//claimPageModels.ClaimFilters.agent,
	claimPageModels.ClaimFilters.idClaimStatus,
	//claimPageModels.ClaimFilters.severity,
	//claimPageModels.ClaimFilters.vehicleType,
	//claimPageModels.ClaimFilters.claimDate,
	claimPageModels.ClaimFilters.claimTime,
	// claimPageModels.ClaimFilters.postalCode,
	claimPageModels.ClaimFilters.location,
	claimPageModels.ClaimFilters.fraudProbabilityId,
];

if (showTelematicData) {
	claimFilters.push(...claimTelematicFilters);
}