declare global {
  interface Window {
    env: any
  }
}

// change with your own variables
interface EnvType extends NodeJS.ProcessEnv {
	REACT_APP_GMAPS_API_KEY: string;
	REACT_APP_API_DOMAIN: string;
  REACT_APP_SENTRY_ENABLED: string;
  REACT_APP_SENTRY_ENVIRONMENT: string;
  REACT_APP_SENTRY_DNS: string;
  REACT_APP_SENTRY_TRACED_DOMAINS: string;
}
export const env: EnvType = { ...process.env, ...window.env }