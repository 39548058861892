import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../../utils';
import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { valueNotFoundSymbol } from '../../../../utils/constants';
import { useMemo } from 'react';
import { secondsToDuration } from '../../../../utils/time';
import { getTripsData } from '../../../../utils/trips-utils';

import PdfFraudLevelBudge from '../../PdfFraudLevelBudge/PdfFraudLevelBudge';
import PdfTripsTable from '../../PdfTripsTable/PdfTripsTable';
import PdfStatisticComponent from '../../PdfStatisticComponent/PdfStatisticComponent';
import PdfGoogleMap from '../../PdfGoogleMap/PdfGoogleMap';
import { PolicyAndResidenceFraudPdfInterface } from '../PolicyAndResidenceFraudPdfInterface';
import PdfInfoFields from '../../PdfInfoFields/PdfInfoFields';
import { PdfInfoFieldDataItem } from '../../PdfInfoFields/PdfInfoFieldsInterface';
import { getPdfTempalteStyles } from '../../PdfTemplate/PdfTemplate';
import { policyModels } from '../../../../models/policyModels';
import { i18nObjectToString } from '../../../../utils/i18n';


const getStyles = ({ theme }: Record<string, any>) =>
  StyleSheet.create({
    infoItem: {
      gap: 2,
    },
    infoItemKey: {
      fontSize: 8,
      color: theme.palettes.textLight,
      lineHeight: 1.3,
    },
    infoItemValue: {
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
      lineHeight: 1.5
    },
    infoItemValueLong: {
      fontSize: 10,
      fontFamily: "Helvetica",
      lineHeight: 1
    },
    claimAction: {
      borderBottom: `0.5px solid ${theme.palettes.borderTertiaryDarker}`,
      borderTop: `0.5px solid ${theme.palettes.borderTertiaryDarker}`,
      marginBottom: 20,
      marginTop: 10,
      paddingTop: 30,
      paddingBottom: 30,
    },
    fraudProbabilityContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 3
    }
  });
  
const getInfoFields = ({ tripsData, theme, styles, t }: Record<string, any> ): PdfInfoFieldDataItem[] => {
  const duration = secondsToDuration( tripsData.tot_duration, true ) as Record<string, string>;

  return [
    {
      id: "policyholder",
      i18nTitleKey: "common.policyholderName",
    },
    {
      id: "vin",
      i18nTitleKey: "common.vin",
    },
    {
      id: "policy_id",
      i18nTitleKey: "common.policyNumber",
    },
    {
      id: "policy_type",
      i18nTitleKey: "common.policyType",
      valueFormatter: (value: any) => {
        return <Text>{i18nObjectToString( value ) || valueNotFoundSymbol}</Text>;
      },
    },
    {
      id: "address",
      i18nTitleKey: "common.residence",
      valueFormatter: (value: string ) => {
        return <Text>{value ? capitalize(value) : valueNotFoundSymbol}</Text>;
      },
    },
    {
      id: "make",
      i18nTitleKey: "common.brand",
      valueFormatter: (value: string) => {
        return <Text>{value ? capitalize(value) : valueNotFoundSymbol}</Text>;
      },
    },
    {
      id: "model",
      i18nTitleKey: "common.model",
      valueFormatter: (value: string ) => {
        return <Text>{value ? capitalize(value) : valueNotFoundSymbol}</Text>;
      },
    },
    {
      id: "year",
      i18nTitleKey: "common.year",
      valueFormatter: (value: string ) => {
        return <Text>{value ? capitalize(value) : valueNotFoundSymbol}</Text>;
      },
    },
    {
      id: 'trip_out_residence',
      i18nTitleKey: "common.statistics",
      valueFormatter: (value: string, data: Record<string, any> ) => {
        return (
          <PdfStatisticComponent
            value={value}
            data={data}
            tripsData={tripsData}
            duration={duration}
            theme={theme}
          />
        );
      },
    }, 
    {
      id: "policy_type_fraud",
      i18nTitleKey: "common.fraudProbability",
      valueFormatter: (value: string, data: Record<string, any> ) => {
        const policyTypeFraudComponent = (
          <View style={styles.fraudProbabilityContainer}>
            <Text style={{fontSize: 8}} >{t("common.policyType")}</Text>
            <PdfFraudLevelBudge value={value} theme={theme} />
          </View>
        );
        const residenceFraudValue = data.residence_fraud; 
        const residenceFraudComponent = (
          <View style={styles.fraudProbabilityContainer}>
            <Text style={{fontSize: 8}} >{t("common.residence")}</Text>
              <PdfFraudLevelBudge value={residenceFraudValue} theme={theme} />
            </View>
        );
        return (
          <View style={{ gap: 4 }}>
            {policyTypeFraudComponent}
            {residenceFraudComponent}
          </View>
        );
      },
    }, 
  ]
};

const InfoPageContent = ({
  theme,
  policy,
  trips
}: PolicyAndResidenceFraudPdfInterface) => {
  const { t }          = useTranslation("main");
  const styles         = getStyles({ theme });
  const templateStyles = getPdfTempalteStyles({ theme });
  const tripsData      = useMemo(() => getTripsData(trips), [ trips ]);
  
  return (
    <>
      <Text style={[templateStyles.title, { marginTop: 0 }]}>
        {t("common.policyholderInfo")}
      </Text>
      <PdfInfoFields
        numOfColumns={4}
        fields={getInfoFields({
          t,
          theme,
          styles,
          tripsData
        })}
        data={policy}
        theme={theme}
      />
      <View style={{ marginTop: 20 }}>
        <PdfGoogleMap
          policy={policy}
          trips={trips}
          residenceColor={theme.palettes.residenceArea}
        />
      </View>
      {trips.length ? (
        <>
          <View style={templateStyles.title}>
            <Text>{t("common.lastTripMade")}</Text>
          </View> 
          <PdfTripsTable theme={theme} trips={trips} />
        </>
      ) : null}
    </>
  );
}

export default InfoPageContent;