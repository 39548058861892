import { ReactElement } from "react";
import { AppLocales, AppDateFormats } from "../constants";
import { dateModels } from "./dateModels";
import { policyPageModels } from "./policyPageModels";
import { viewMoreDetailsModels } from "./viewMoreDetailsModels";
import { claimPageModels } from "./claimPageModels";

export namespace appConfigModels  {
export type appConfigInterface = {
	appTitle: string;
	defaultLocale: AppLocales;
	currency: string;
	dateFormat: AppDateFormats;
	themeName: string;
	loginLogo:
		| React.FunctionComponent<
				React.SVGProps<SVGSVGElement> & {
					title?: string | undefined;
				}
		  >
		| string;
	loginLogoHeight: number; // px. Applied only to non SVG images.
	logo:
		| React.FunctionComponent<
				React.SVGProps<SVGSVGElement> & {
					title?: string | undefined;
				}
		  >
		| string;
	logoHeight: number; // px. Applied only to non SVG images.
	pdfLogo: string;
	pdfLogoHeight: number; // pt (points)
	claimDetailPeriodDelta: number; // Number of days
	profileDetailPeriodDelta: number; // Number of days
	maxEndDate: dateModels.dateNoTimeISO;
	showFooter: true;
	copyrightsNotice: ReactElement<any, any>;
	pdfFooterNotes: string;
	showTelematicData: boolean;
	showScoreOnTripList: boolean;
	showPolicyRiskEvents: boolean;
	showDetailsProfile: boolean;
	showExportButtonInClaimPage: boolean;
	showExportButtonInClaimDetailsPage: boolean;
	showExportButtonInPolicyPage: boolean;
	showExportButtonInPolicyDetailsPage: boolean;
	policyTableColumns: policyPageModels.PolicyTableColumns[];
	claimTableColumns: claimPageModels.ClaimTableColumns[];
	showPolicyTableSearchBar: boolean;
	filterButtonPosition: appConfigModels.filterButtonPosition;
	viewMoreClaimDetails: viewMoreDetailsModels.viewMoreClaimDetails[];
	policyTypeIDs: {
		personal: string;
		commercial: string;
	};
  numOfDaysAnalyzedForPdfPoliciesSummary: {
    policy_type: number;
    residence: number;
  };
  
	maxTripsOnTopWithCoords: number;
	maxTripsOnTopWithCoordsForPersonalPolicy: number;
	viewMorePolicyDetails: viewMoreDetailsModels.viewMorePolicyDetails[];
	showClaimAmountBoxInProfileDetails: boolean;
	showClaimAmountBoxInProfileDetailsForPersonalPolicy: boolean;
	showClaimAmountBoxInProfileDetailsForCommercialPolicy: boolean;
  showResidenceAreaInProfileDetails: boolean,
	showResidenceAreaInProfileDetailsForPersonalPolicy: boolean,
	showResidenceAreaInProfileDetailsForCommercialPolicy: boolean,	showStatisticSectionForOutsideTripsInProfileDetails: boolean;
	showStatisticSectionForOutsideTripsInProfileDetailsForPersonalPolicy: boolean;
	showStatisticSectionForOutsideTripsInProfileDetailsForCommercialPolicy: boolean;
  statusNoteFieldMaxCharLength: number;
  pdfPieChartsMaxSlices: number;
  fraudProbabilityLevels: string[];
  showNearestPointInTimeMarkerOnMap: boolean;

	// React-query config.
	staleTime: number;
	cacheTime: number;
	showOnlyResidenceForPolicy: boolean;
};
    export enum filterButtonPosition {
        aboveTable = "above_table",
        filtersDrawer = "filters_drawer",
    }
}
