import { Controller } from "react-hook-form";

import { DateRangePickerInterface } from "./DateRangePickerInterface";
import { GroundDateRangePicker } from "@greenvulcano/ground-react";


const DateRangePicker = ({
  defaultStartDate = '',
  defaultEndDate = '',
  maxDate,
  minDate,
  name = '',
  control,
  errors = {},
  rules = {},
  onChange = () => null,
  ...rest
} : DateRangePickerInterface ) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={{ start: defaultStartDate, end: defaultEndDate }}
      render={({ field: { value, onChange: onChangeHandler } }) => {
        return (
          <GroundDateRangePicker
            {...rest}
            erratum={Boolean( errors[name] )}
            defaultStartDate={value.start}
            defaultEndDate={value.end}
            endOfTimes={maxDate}
            startOfTimes={maxDate}
            onChange={( startIsoDate: string, endIsoDate: string ) => {
              onChangeHandler( { start: startIsoDate, end: endIsoDate } );
              onChange( startIsoDate, endIsoDate );
            }}
          />
        );
      }}
    />
  );
};

export default DateRangePicker;