import { useTranslation } from "react-i18next";

import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";


const CookiesDisabledModal = () => {
  const { t } = useTranslation( 'main' );

  return (
    <ConfirmationModal
      readOnly
      title={t( 'common.enableCookies' ) as string}
      content={<p>{t( 'modals.cookiesDisabledMessage' )}</p>}
    />
  );
};

export default CookiesDisabledModal;