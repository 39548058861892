import { normalizeData } from "../utils";

const dropdowns: Record<string, any>  = {
  claimStatus: [
    { value: '1', label: 'common.claimIsTrue' },
    { value: '2', label: 'common.claimIsFalse' },
    { value: '3', label: 'common.toInvestigateMore' },
  ] 
};


export const getDropdownOptions = ( dropdownID: string, normalize = false, labelCallback = ( item: any ) => item.label  ) => {
  if ( !( dropdownID in dropdowns ) ) {
    return [];
  }

  const options = dropdowns[dropdownID].map(( item: any ) => {
    return {
      ...item,
      label: labelCallback( item )
    };
  });

  if ( normalize ) {
    return normalizeData( options, 'value' );
  }

  return options;
};