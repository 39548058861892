import { isObject } from ".";


export interface ResponseErrorObj {
  status: string;
  code: string | number;
  message: string;
}

export interface ResponseError {
  error: ResponseErrorObj
}

export const parseResponseError = ( error: any ) => {
  if ( isObject( error ) ) {
    return Object.assign(
      { 
        status: 'error',
        code: 0,
        message: 'An error occurred',
      },
      ( error as ResponseError )
    );
  }
  
  return {} as ResponseErrorObj;
};