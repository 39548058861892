import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import { LastTripConfirmationModalInterface } from './LastTripConfirmationModalinterface';
import { useConfirmationModal } from '../../../hooks';
import './LastTripConfirmationModal.scss';


const LastTripConfirmationModal = ({ onAction }: LastTripConfirmationModalInterface) => {
    const { t } = useTranslation("main");
    const confirmationModal = useConfirmationModal();


    const onCloseModalHandler = () => {
        confirmationModal.close();
	};
    const content = (
		<div className="last-trip-modal">
            <p>{ t('modals.lastTripMessage')}</p>
		</div>
	);
    return (
        <ConfirmationModal
          title={t("common.viewLastTrips") as string}
          content={content}
          onClose={onCloseModalHandler}
          onAction={onAction} 
      />
    )
}
export default LastTripConfirmationModal;