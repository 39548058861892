import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { applyErrorBoundary } from "../../../utils/error-boundary";
import { AuthService } from "../../../services/AuthService";

import {
  GroundButton,
  GroundButtonTypes,
  GroundToastType,
  GroundToastVariant,
  useGroundToast
} from "@greenvulcano/ground-react";
import TextInput from "../../../shared-components/RHF-input-wrappers/TextInput/TextInput";
import LoginTemplate from "../../../shared-components/LoginTemplate/LoginTemplate";
import RouterLink from "../../../shared-components/RouterLink/RouterLink";


interface FormValues {
  email: string;
};

const PasswordRecovery = () => {
  const { t }       = useTranslation( 'main' );
  const groundToast = useGroundToast();

  const {
    control, 
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>();

  const onSubmitHandler: SubmitHandler<FormValues> = async ( data ) => {
    try {
      await AuthService.restorePassword( data.email );
    }
    catch ( error: any ) {
      // Left empty on purpose.
    }
    finally {
      reset();
      groundToast.show({
        variant: GroundToastVariant.featured,
        type: GroundToastType.success,
        timeout: 10000,
        message: t( 'toastMessages.restorePasswordSuccessMessage' )
      });
    }
  };

  return (
    <LoginTemplate>
      <form onSubmit={handleSubmit( onSubmitHandler )}>
        <h3>{t( 'passwordRecovery.title' )}</h3>
        <p>{t( 'passwordRecovery.description' )}</p>
        <div className='form-controls'>
          <TextInput
            fluid
            autoValidate
            timeoutAutoValidate
            type='email'
            name='email'
            control={control}
            errors={errors}
            rules={{ required: t( 'common.emailRequired' ) as string }}
            readOnly={isSubmitting}
            placeholder={t( 'common.email' ) as string}
          />
          <div className='buttons'>
            <div className='flex flex-vcenter flex-space-between width-100'>
              <RouterLink to='/login'>{t( 'common.back' )}</RouterLink>
              <GroundButton
                type={GroundButtonTypes.submit}
                isLoading={isSubmitting}
                height='medium'
                color='secondary'
                text={t( 'passwordRecovery.submitText' )}
              />
            </div>
          </div>
        </div>
      </form>
    </LoginTemplate>
  );
};

export default applyErrorBoundary( PasswordRecovery );