import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import { env } from "../env";
export const sentryOptions: Sentry.BrowserOptions = {
  dsn: ( env.REACT_APP_SENTRY_DSN || '' ),
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ( env.REACT_APP_SENTRY_TRACED_DOMAINS ? env.REACT_APP_SENTRY_TRACED_DOMAINS.split( ',' ) : undefined ),
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: ( 'true' === env.REACT_APP_SENTRY_ENABLED ),
  environment: ( env.REACT_APP_SENTRY_ENVIRONMENT || 'development' )
};
