import { useEffect, useRef, forwardRef, ForwardedRef } from "react";
import classNames from "classnames";
import * as echarts from 'echarts';

import { ChartInterface } from "./ChartInterface";
import RequestIndicator from "../RequestIndicator/RequestIndicator";


const Chart = forwardRef(({
  id,
  option,
  isResizable,
  isAwaiting,
  style,
  className,
  onFinished = () => undefined
}: ChartInterface, ref: ForwardedRef<HTMLDivElement | null> ) => {
  const chartRef = useRef<HTMLDivElement | null>( null );

  useEffect(() => {
    if ( !chartRef.current ) {
      return;
    }

    const chart = (
      echarts.getInstanceByDom( chartRef.current ) || 
      echarts.init( chartRef.current )
    );

    chart.on( 'finished', onFinished );
    
    if ( isResizable ) {
      const onResizeWindow = () => chart.resize();

      window.addEventListener( 'resize', onResizeWindow );

      return () => {
        window.removeEventListener( 'resize', onResizeWindow );
      };
    }
  }, [ chartRef ]);

  useEffect(() => {
    if ( chartRef.current ) {
      const chart = echarts.getInstanceByDom( chartRef.current );

      if ( chart ) {
        chart.setOption( option );
      }
    }
  }, [ JSON.stringify( option ) ] )

  return (
    <div
      style={style}
      className={classNames(
        'chart-wrapper width-100 pos-relative',
        className
      )}
    >
      {isAwaiting ? (
        <RequestIndicator
          isLoading
          delay={1000}
          spinnerOptions={{ scale: 0.5 }}
          backgroundColor='backgroundAlpha50'
          className='pos-absolute zindex-10'
        />
      ): null }
      <div
        id={id}
        className='width-100 height-100'
        ref={element => {
          chartRef.current = element;

          if ( typeof ref === 'function' ) {
            ref( element );
          }
          else if ( ref ) {
            ref.current = element;
          }
        }}
      />
    </div>
  );
});

export default Chart;
