import { capitalize } from ".";
import { getAppLocale } from "../config";


export const minsToDuration = ( minutes: number | string, returnObj = false ) => {
  const mins     = Number( minutes );
  const duration = { hours: '', mins: '' };

  if ( Number.isNaN( mins ) ) {
    return ( returnObj ? duration : '–' );
  }

  duration.hours = String( Math.trunc( mins / 60 ) ).padStart( 2, '0' );
  duration.mins  = String( mins % 60 ).padStart( 2, '0' );

  if ( returnObj ) {
    return duration;
  }

  return Object.values( duration ).join( ':' );
};


export const secondsToDuration = ( seconds: number | string, returnObj = false ) => {
  const mins = Number.isNaN( Number( seconds ) ) ? NaN : Math.ceil( Number( seconds ) / 60 );

  return minsToDuration( mins, returnObj );
};


export const getMonthsInitials = () => {
  const initials = [];
  const date     = new Date( 2023, 0, 1 );  // 2023-01-01
  const locale   = getAppLocale();

  for ( let i = 0; i < 12; i++ ) {
    initials.push(
      capitalize( date.toLocaleString( locale, { month: 'short' } ) )
    );

    date.setMonth( i + 1 );
  }
  
  return initials;
};


export const getWeekdaysInitials = () => {
  const initials = [];
  const date     = new Date( 2023, 1, 20 );  // 2023-02-20 is a Monday
  const locale   = getAppLocale();

  for ( let i = 0; i < 7; i++ ) {
    initials.push(
      capitalize( date.toLocaleString( locale, { weekday: 'short'  } ) )
    );

    date.setDate( date.getDate() + 1 );
  }
  
  return initials;
};

export const getHoursOfDay = () => {
  return [ 'am', 'pm' ].reduce( ( output: Array<string>, daytime: string ) => {
    Array.from( Array( 12 ).keys() ).map( ( num: number ) => {
      output.push( `${num + 1} ${daytime}` );
    });

    return output;
  }, [] );
};