import { Controller } from "react-hook-form";

import { TextareaInterface } from "./TextareaInterface";
import { GroundTextarea } from "@greenvulcano/ground-react";


const Textarea = ({
  defaultValue = '',
  name = '',
  control,
  errors = {},
  rules = {},
  ...rest
} : TextareaInterface ) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue || ''}
      render={({ field: { value, onChange } }) => (
        <GroundTextarea
          erratum={Boolean( errors[name] )}
          value={value}
          onChange={onChange}
          {...rest}
        />
      )}
    />
  );
};

export default Textarea;