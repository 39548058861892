import { palettes, palettesSharedWithGround } from "./palettes";
import { svgIconsData } from "./svg-icons-data";
import { tripColors } from "./trip-colors";
import { heartbeatColors } from "./heartbeat-colors";
import { pdfBarChartColors } from "./pdf-bar-chart-colors";

import { groundDefaults } from "./ground-overrides/defaults";
import { groundPresetSizes } from "./ground-overrides/preset-sizes";


export const DefaultTheme: Record<string, any> = {
  name: 'DefaultTheme',
  fontFamilies: {
    primary: 'Roboto'
  },
  palettes: { ...palettes, ...palettesSharedWithGround },
  defaults: groundDefaults,
  presetSizes: groundPresetSizes,
  svgIconsData,
  tripColors,
  heartbeatColors,
  pdfBarChartColors
};