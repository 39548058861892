const basePath = '/admin';

export interface HeaderNavItem {
  i18nKey: string;
  routerTo: string;
}

export const headerNavItems: HeaderNavItem[] = [
  // // Dashboard hidden as per project requirements.
  // {
  //   i18nKey: 'common.dashboard',
  //   routerTo: '/dashboard'
  // },
  {
    i18nKey: 'common.profiles',
    routerTo: `${basePath}/profiles`
  },
  {
    i18nKey: 'common.claims',
    routerTo: `${basePath}/claims`
  },
  // {
  //   i18nKey: 'common.analytics',
  //   routerTo: `${basePath}/analytics`
  // }
];
