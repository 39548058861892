import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  numberToCurrency,
  truncateString,
  getProccessedFraudProbabilityNotes,
} from "../../../../utils";
import { getAppDateFormat, getAppConfig } from "../../../../config";
import { getLevelPalette } from "../../../../utils/color-utils";
import { getDropdownOptions } from "../../../../config/dropdowns";
import { attemptTranslation, i18nObjectToString } from "../../../../utils/i18n";
import { appConfig } from "../../../../config/app-config/app-config";
import { valueNotFoundSymbol } from "../../../../utils/constants";

import { GroundButton, GroundButtonTypes } from "@greenvulcano/ground-react";
import { viewMoreDetailsModels } from "../../../../models/viewMoreDetailsModels";
import { ViewMoreButtonDictionaryItem } from "../../../../shared-components/ViewMoreButton/ViewMoreButtonInterface";
import MoodyText from "../../../../shared-components/MoodyText/MoodyText";
import ViewMoreButton from "../../../../shared-components/ViewMoreButton/ViewMoreButton";
import LevelBudge from "../../../../shared-components/LevelBudge/LevelBudge";
import Select from "../../../../shared-components/RHF-input-wrappers/Select/Select";
import Textarea from "../../../../shared-components/RHF-input-wrappers/Textarea/Textarea";
import ManualClaimDetails from "./ManualClaimDetails/ManualClaimDetails";
import LongTextTooltip from "../../../../shared-components/LongTextTooltip/LongTextTooltip";

import "./MainSidebarContent.scss";


const viewMoreDataDictionary : ViewMoreButtonDictionaryItem[] = [
	{
		key: viewMoreDetailsModels.viewMoreClaimDetails.vin,
		i18nLabelKey: "common.vin",
	},
	{
		key: viewMoreDetailsModels.viewMoreClaimDetails.policyHolder,
		i18nLabelKey: "common.policyHolder",
	},
	{
		key:  viewMoreDetailsModels.viewMoreClaimDetails.policyId,
		i18nLabelKey: "common.policyNumber",
	},
	{
		key:  viewMoreDetailsModels.viewMoreClaimDetails.claimAmont,
		i18nLabelKey: "common.claimAmount",
		valueFormatter: (value: any) => {
			return numberToCurrency(value);
		},
	},
	{
		key:  viewMoreDetailsModels.viewMoreClaimDetails.agencyName,
		i18nLabelKey: "common.agency",
	},
	{
		key:  viewMoreDetailsModels.viewMoreClaimDetails.agentName,
		i18nLabelKey: "common.agent",
  },
  {
		key:  viewMoreDetailsModels.viewMoreClaimDetails.policyType,
    i18nLabelKey: "common.policyType",
    valueFormatter: ( value: any ) => i18nObjectToString( value )
  },
  {
		key:  viewMoreDetailsModels.viewMoreClaimDetails.address,
		i18nLabelKey: "common.residence",
  },
  {
		key: viewMoreDetailsModels.viewMoreClaimDetails.make,
		i18nLabelKey: "common.brand",
  },
  {
		key:  viewMoreDetailsModels.viewMoreClaimDetails.model,
		i18nLabelKey: "common.model",
  },
];


const getDataToShowDictionary = (): ViewMoreButtonDictionaryItem[] => {
  const dataToShowCandidates: (ViewMoreButtonDictionaryItem | undefined)[] = appConfig.viewMoreClaimDetails.map((item: viewMoreDetailsModels.viewMoreClaimDetails) => {
    const key = item as keyof typeof viewMoreDetailsModels.viewMoreClaimDetails;
    return viewMoreDataDictionary.find(entry => entry.key === key);
  });

  const filteredDataToShowDictionary: ViewMoreButtonDictionaryItem[] = dataToShowCandidates.filter((item): item is ViewMoreButtonDictionaryItem => item !== undefined);

  return filteredDataToShowDictionary;
};

const MainSidebarContent = ({
  query,
  statusChangeFormOptions: {
    control,
    formState: { errors },
    mutation: { isLoading: mutationIsLoading },
    disableSubmit,
    onSubmit,
  }
}: Record<string, any>) => {
  const { t } = useTranslation("main");
  const statusNoteFieldMaxCharLength = getAppConfig( 'statusNoteFieldMaxCharLength' );
  const claimStatusOptions = getDropdownOptions(
    "claimStatus",
    false,
    (item: any) => t(item.label)
  );

  return (
    <div className="cdetails-main-sidebar-content flex-grow flex flex-columns">
      <div className="claim-detail-section">
        <h3>{t("common.claimDetail")}</h3>
        <p>
          <MoodyText color="textSecondaryDark" className="flex gap-1/2">
            <span className="uppercase">
              {t("common.policyHolder")}
              {": "}
            </span>
            <strong>
              <LongTextTooltip
                breakWords
                showAtLength={25}
                text={query.data.policyholder || valueNotFoundSymbol}
              />
            </strong>
          </MoodyText>
        </p>
        <p>
          <MoodyText color="textSecondaryDark" className="flex gap-1/2">
            <span className="uppercase">
              {t("common.policy")}
              {": "}
            </span>
            <strong>{query.data.policy_id || valueNotFoundSymbol}</strong>
          </MoodyText>
        </p>
        <ViewMoreButton
          modalOptions={{
            titleMain: t("common.claimDetails"),
            dataArray: [
              {
                data: query.data,
                title: t("common.generalInfo"),
                dataToShowDictionary:getDataToShowDictionary(),
              },
              {
                data: query.data,
                title: t("common.claimNotes"),
                extraDataItems: getProccessedFraudProbabilityNotes(
                  query.data.fraud_probability_notes
                ),
              },
            ],
          }}
        />
      </div>
      <div className="claim-info-section-wrapper flex flex-bottom">
        <div className="claim-info-section flex flex-columns">
          <h3>{t("common.claimInformation")}</h3>
          {query.data.created_manually === "1" ? (
            <ManualClaimDetails claimDetailsQuery={query}></ManualClaimDetails>
          ) : (
            <>
              <p className="location">
                <MoodyText color="textSecondaryDark">
                  {truncateString(query.data.location, 140) ||
                    t("errors.locationNotAvailable")}
                </MoodyText>
              </p>
              <p>
                <MoodyText color="textSecondaryDark">
                  {query.data.claim_date
                    ? `${moment
                        .utc(query.data.claim_date)
                        .format(getAppDateFormat())} ${
                        query.data.sinister_hour
                          ? t("common.atTime", {
                              time: query.data.sinister_hour,
                            })
                          : query.data.sinister_hour_from &&
                            query.data.sinister_hour_to
                          ? t("common.fromTimeToTime", {
                              fromTime: query.data.sinister_hour_from,
                              toTime: query.data.sinister_hour_to,
                            })
                          : ""
                      }`
                    : valueNotFoundSymbol}
                </MoodyText>
              </p>
              <p>
                <MoodyText
                  color={getLevelPalette(query.data.severity)}
                  className="text-small"
                >
                  <span>{t("common.severity")}</span>
                  {": "}
                  <strong>
                    {attemptTranslation(query.data.severity, t) ||
                      t("common.unknown")}
                  </strong>
                </MoodyText>
              </p>
        		{query.data.created_manually === "0" && (
          		<p>
            		<MoodyText color="textSecondaryDark">
              		{t("common.anomalyType")}
              		{": "}
              		<strong>{query.data.anomaly_type || valueNotFoundSymbol}</strong>
            		</MoodyText>
          		</p>
        		)}
            </>
          )}
        </div>
      </div>
      <div className="fraud-anomaly-section">
        <div className="mb-1 flex flex-vcenter gap-1">
          <span>{t("common.fraudProbability")}</span>{" "}
          <LevelBudge
            value={query.data.fraud_probability_id}
            className="fraud-prob-budge"
          />
        </div>
      </div>
      <div className="flex-grow flex flex-bottom">
        <div className="flex-grow flex flex-columns">
          <h3>{t("common.actions")}</h3>
          <form onSubmit={onSubmit} className="actions-form flex flex-columns">
            <Select
              fluid
              name="id_claim_status"
              defaultValue={query.data.id_claim_status}
              control={control}
              errors={errors}
              rules={{ required: true }}
              height="34"
              placeholder={t("common.claimStatus") + "*"}
              options={claimStatusOptions}
            />
            <Textarea
              fluid
              name="note"
              resizeable={false}
              height="74"
              defaultValue={query.data.note}
              control={control}
              errors={errors}
              rules={{ required: true }}
              maxLength={statusNoteFieldMaxCharLength}
              placeholder={t(
                "common.descriptionMaxChars", 
                { maxLength: statusNoteFieldMaxCharLength }
              ) + "*"}
            />
            <GroundButton
              color="secondary"
              height="medium"
              className="uppercase"
              type={GroundButtonTypes.submit}
              text={t("common.changeStatus")}
              isLoading={mutationIsLoading}
              disabled={disableSubmit()}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default MainSidebarContent;
