import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";
import en from "date-fns/locale/en-GB"; //used en-GB instead of en-US to start the week on Monday
import es from "date-fns/locale/es";
import { Controller } from "react-hook-form";
import { DatePickerInterface } from "./DatePickerInterface";
import { getAppLocale } from "../../config";
import { useTranslation } from "react-i18next";
import { isSameDay } from "date-fns";

registerLocale("es", es);
registerLocale("en-GB", en);

const DatePicker = ({ control, name, defaultValue }: DatePickerInterface) => {
	const { t } = useTranslation("main");
  const now = new Date();
	const midnight = new Date();
	midnight.setHours(0, 0, 0, 0);

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { value, onChange } }) => {
				return (
					<ReactDatePicker
						dateFormat="dd/MM/yyyy HH:mm"
						locale={getAppLocale() === "es-MX" ? "es" : "en-GB"}
						onChange={(date) => {
							onChange(date);
						}}
						selected={value}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						maxDate={now}
						maxTime={isSameDay(now, value) ? now : undefined}
						minTime={isSameDay(now, value) ? midnight : undefined}
						onInputClick={() => {
							const timeHeader = document.querySelector(
								".react-datepicker-time__header"
							) as HTMLDivElement;
							if (timeHeader) {
								timeHeader.innerText = t("common.time");
							}
						}}
					/>
				);
			}}
		/>
	);
};

export default DatePicker;
