import moment from "moment";
import { AppDateFormats, AppLocales } from "../../constants";
import { dateModels } from "../../models/dateModels";

import logo from "../../images/logo.png";
import { appConfigModels } from "../../models/appConfigModels";
import { getPolicyTableColumns } from "./policy-table-config";
import { getViewMoreClaimDetails } from "./view-more-claim-details-config";
import { getViewMorePolicyDetails } from "./view-more-policy-details-config";
import { getClaimTableColumns } from "./claim-table-config";

const TODAY_UTC_ISO = moment()
	.utc()
	.format(dateModels.dateFormatNoTimeISO) as dateModels.dateNoTimeISO;

const showTelematicData = false;
const showCreateClaimButton = true;

export const appConfig: appConfigModels.appConfigInterface = {
	appTitle: "Encontrack Cosmos",
	defaultLocale: AppLocales.esMX,
	claimDetailPeriodDelta: 1, // Number of days
	copyrightsNotice: <>&copy; 2023 Encontrack</>,
	currency: "MXN",
	dateFormat: AppDateFormats.dmy,
	filterButtonPosition: appConfigModels.filterButtonPosition.aboveTable,
	themeName: "DefaultTheme",
	loginLogo: logo, // Can be a URL too.
	loginLogoHeight: 58, // px. Applied only to non SVG images.
	logo: logo, // Can be a URL too.
	logoHeight: 48, // px. Applied only to non SVG images.
	pdfLogo: '', // Must be a NON-SVG image path.
	pdfLogoHeight: 64, // pt (points)
	profileDetailPeriodDelta: 2, // Number of days
	maxEndDate: TODAY_UTC_ISO,
	showFooter: true,
	pdfFooterNotes: `Del Carmen 1, Chimalistac, Álvaro Obregón, 01070 Ciudad de México, CDMX, Mexico Phone: +52 5553370900 | info@encontrack.com | www.encontrack.com`,
	policyTableColumns: getPolicyTableColumns({
		showTelematicData,
		showCreateClaimButton,
	}),
	claimTableColumns: getClaimTableColumns(),
	viewMoreClaimDetails: getViewMoreClaimDetails(),
	viewMorePolicyDetails : getViewMorePolicyDetails(),
	showTelematicData: showTelematicData,
	showScoreOnTripList: false,
	showPolicyTableSearchBar: true,
	showPolicyRiskEvents: false,
	showDetailsProfile: true,
	showExportButtonInClaimPage: true,
	showExportButtonInClaimDetailsPage: true,
	showExportButtonInPolicyPage: true,
	showExportButtonInPolicyDetailsPage : true,
  showClaimAmountBoxInProfileDetails: false,
  showClaimAmountBoxInProfileDetailsForPersonalPolicy: false,
	showClaimAmountBoxInProfileDetailsForCommercialPolicy: false,
	showResidenceAreaInProfileDetails: true,
	showResidenceAreaInProfileDetailsForPersonalPolicy: true,
	showResidenceAreaInProfileDetailsForCommercialPolicy: false,
	showStatisticSectionForOutsideTripsInProfileDetails: true,
	showStatisticSectionForOutsideTripsInProfileDetailsForPersonalPolicy: true,
	showStatisticSectionForOutsideTripsInProfileDetailsForCommercialPolicy: false,
  policyTypeIDs: {
    personal: '1',
    commercial: '2'
  },
  numOfDaysAnalyzedForPdfPoliciesSummary: {
    policy_type: 30,
    residence: 60
  },
  maxTripsOnTopWithCoords: 1,
  maxTripsOnTopWithCoordsForPersonalPolicy: 10,
  showOnlyResidenceForPolicy: true,
  statusNoteFieldMaxCharLength: 500,
  pdfPieChartsMaxSlices: 10,
  fraudProbabilityLevels: [ 'inapplicable', 'low', 'medium', 'high', 'analyzing' ],
  showNearestPointInTimeMarkerOnMap: false,
  
	// React-query config.
	staleTime: Infinity,
	cacheTime: Infinity,
};
