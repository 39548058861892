import styled from "styled-components";
import classNames from "classnames";
import { useAppTheme } from "../../hooks";

import { 
  DisclosingArrowInterface,
  DisclosingArrowStyled
} from "./DisclosingArrowInterface";
import "./DisclosingArrow.scss";


const Arrow = styled.span<DisclosingArrowStyled>`
  width:  calc( 0.82rem * ${( props: any ) => props.scale} );
  height: calc( 0.7rem * ${( props: any ) => props.scale} );
  transform: ${( props: any ) => props.rotation};

  &::before,
  &::after {
    background-color: ${( props: any ) => props.theme.palettes[props.color]};
    bottom: calc( 0.2rem * ${( props: any ) => props.scale} );
    width: calc( 0.5rem * ${( props: any ) => props.scale} );
  }
`;

const DisclosingArrow = ({
  scale = 1,
  rotation,
  arrowColor,
  className
}: DisclosingArrowInterface ) => {
  const theme = useAppTheme();

  return (
    <Arrow
      theme={theme}
      color={arrowColor}
      scale={scale}
      rotation={rotation ? `rotate(${rotation})` : 'none'}
      className={classNames(
        'disclosing-arrow',
        className
      )}
    />
  );
};

export default DisclosingArrow;