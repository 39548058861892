import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { attemptTranslation } from "../../utils/i18n";

export interface DropdownsState {
  [key: string]: {
    id: string;
    options: [ { value: string | number, label: string } ]
  }
}

export const dropdownsSlice = createSlice({
  name: 'dropdowns',
  initialState: {} as DropdownsState,
  reducers: {
    loadData: ( state, action: PayloadAction<DropdownsState> ) => {
      return action.payload;
    }
  }
});

export const {
  loadData: loadDropdownsData
} = dropdownsSlice.actions;

export const selectDropdownOptions = ( dropdownID: string, t_fn?: any ) => ( state: RootState ) => {
  const data = state.dropdowns[dropdownID];

  if ( !( data && data.options ) ) {
    return [];
  }

  if ( typeof t_fn === 'function' ) {
    return data.options.map(( item: any ) => {
      return {
        ...item,
        label: attemptTranslation( item.label, t_fn )
      };
    })
  }

  return data.options;
};

export default dropdownsSlice.reducer;