import { useMemo } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { useAppTheme } from "../../hooks";
import { LongTextTooltipInterface } from "./LongTextTooltipInterface";
import { getAppLanguage } from "../../config";

import "./LongTextTooltip.scss";


const TooltipStyled = styled.span`
  background-color: ${props => props.theme.palettes.background};
  border: 1px solid ${props => props.theme.palettes.borderTertiaryDarker};
`;

const LongTextTooltip = ({
  text,
  showAtLength,
  breakWords,
}: LongTextTooltipInterface ) => {
  const theme          = useAppTheme();
  const returnTextAsIs = ( text.length <= showAtLength );

  const textToDisplay = useMemo(() => {
    if ( returnTextAsIs ) {
      return text;
    }

    let charCount = 0;
    const words   = text.split( ' ' );
    const wordsToDisplay: string[] = [];
    
    for ( let word of words ) {
      const wordPlusSpaceLength = word.length + 1;
      
      charCount += wordPlusSpaceLength;

      if ( charCount > showAtLength ) {
        break;
      }

      wordsToDisplay.push( word );
    }

    return wordsToDisplay.join( ' ' ) + '...';
  }, [ text, showAtLength ] );
  
  if ( returnTextAsIs ) {
    return <>{text}</>;
  }

  return (
    <span
      className={classNames(
        'long-text-tooltip-wrapper',
        { 'break-words': breakWords }
      )}
      lang={getAppLanguage()}
    >
      {textToDisplay}
      <TooltipStyled
        theme={theme}
        className='tooltip'
      >
        {text}
      </TooltipStyled>
    </span>
  );
};

export default LongTextTooltip;