import React, { FunctionComponent } from "react";

export const applyErrorBoundary = ( ChildComponent: FunctionComponent ) => {
  return (
    class ErrorBoundary extends React.Component<any, { hasError: boolean, error: any }> {
      constructor( props: any ) {
        super( props );
    
        this.state = { hasError: false, error: null };
      }
    
      static getDerivedStateFromError( error: any ) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
      }
    
      componentDidCatch( error: any, errorInfo: any ) {
        console.error( '[APP error]: ', error );
        console.error( '[APP error details]: ', errorInfo );
      }
    
      render() {
        if ( this.state.hasError ) {
          return <></>;
        }
    
        return <ChildComponent {...this.props} />; 
      }
    }
  );
};