import { env } from "../env";
import { useJsApiLoader } from "@react-google-maps/api";


const libraries: Array<'geometry' | 'drawing' | 'localContext' | 'places' | 'visualization'> = [ 'places' ];

export const useGmapsApiLoader = () => {
  return useJsApiLoader({
    id: 'google-map',
    googleMapsApiKey: ( env.REACT_APP_GMAPS_API_KEY || '' ),
    libraries
  });
};