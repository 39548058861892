/**
 * Whenever the values below are changed, the following must also be updated:
 * - icons in 'src/images/colorcoded-markers'
 * - icons in 'src/images/colorcoded-markers-png'
 * - src/utils/colorcoded-markes.ts
 */

// Commented colors are very near to red,
// so they have been disabled as per requirements.

export const tripColors: string[] = [
  // Red. First color is reserved and must be red.
  // If changed, even the first color of './heartbeat-colors.ts' must me updated.
  '#D51D22', 

  '#117170',
  '#9E9391',
  // '#C11875',
  // '#A94D13',
  '#2804AF',
  '#0DA4ED',
  // '#EB24B1',
  '#6C67DF',
  '#0B5F9E',
  '#6EE736',
  // '#B83B35',
  '#C7AA90',
  '#8AD2BE',
  // '#DF7770',
  '#0E2636',
  '#EBFF00',
  '#A6AAC5',
  '#9DAF39',
  '#6B7783',
];