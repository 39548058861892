import { 
  combineReducers, 
  createAction,
  AnyAction
} from "@reduxjs/toolkit";

import userSlice from "./slices/userSlice";
import periodReducer from "./slices/periodSlice";
import settingsSlice from "./slices/settingsSlice";
import filtersSlice from "./slices/filtersSlice";
import dropdownsSlice from "./slices/dropdownsSlice";
import mapSlice from "./slices/mapSlice";
import browsingLockedSlice from "./slices/browsingLockedSlice";


const appReducer = combineReducers({
  user: userSlice,
  period: periodReducer,
  settings: settingsSlice,
  filters: filtersSlice,
  dropdowns: dropdownsSlice,
  map : mapSlice,
  browsingLocked: browsingLockedSlice
});

export const logout = createAction( 'logout' );

export const rootReducer = ( state: any, action: AnyAction ) => {
  if ( action.type === logout.toString() ) {
    return appReducer( undefined, { type: undefined } );
  }

  return appReducer( state, action );
};