export const groundDefaults: Record<string, any> = {
  // 'lowerCamelCaseComponentName: { object of defaults }
  groundTextInput: {
    height: 54,
    borderRadius: 4,
    svgIconPasswordWidth: 22
  },
  groundSelect: {
    height: 54,
    borderRadius: 4,
  },
  groundTextarea: {
    borderRadius: 4
  },
  groundButton: {
    height: 'large',
    borderRadius: 4
  },
  groundAvatar: {
    backgroundColor: 'primary'
  },
  groundTable: {
    headBgColor: 'backgroundSecondary',
    sortIconColor: 'primary'
  },
  groundPaginationMenu: {
    borderColor: 'borderSecondary'
  },
  groundDateRangePicker: {
    borderRadius: 4
  }
};