import axios from "axios";
import { getApiEndpoint } from "../config";
import { AuthService } from "./AuthService";


export const ScoreService = {
  getDecodedScore: async ( value: number ): Promise<any> => {
    try {
      const { data: responseData } = await axios.get( 
        getApiEndpoint( 'scoreDecode', { value } ), 
        AuthService.getAuthHeaders()
      );
      
      return Promise.resolve( responseData.data );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  }
};