import moment from "moment";
import { attemptTranslation, i18nObjectToString } from "../../../utils/i18n";
import { CsvColumsItem } from "../../../utils/csv";
import { getAppDateFormat } from "../../../config";
import { getFraudProbI18nKey } from "../../../utils/fraud-probability";
import { claimPageModels } from "../../../models/claimPageModels";
import { stripNulls } from "../../../utils/sanitization";
import { valueNotFoundSymbol } from "../../../utils/constants";
import { getDropdownOptions } from "../../../config/dropdowns";
import { languageModels } from "../../../models/languageModels";


export const getCsvColumns = ({ t }: { t: languageModels.Tfn; }): CsvColumsItem[] => {
  const claimStatusOptions = getDropdownOptions( 'claimStatus', true );

  return [
    {
      id: 'insurance_company',
      label: t( 'common.insuranceCompany' )
    },
    {
      id: 'id_client',
      label: t( 'common.clientId' )
    },
    {
      id: claimPageModels.ClaimTableColumns.policyHolder,
      label: t( 'common.policyholderName' )
    },
    {
      id: claimPageModels.ClaimTableColumns.policyId,
      label: t( 'common.policyNumber' )
    },
    {
      id: 'policy_type',
      label: t( 'common.policyType' ),
      formatValue: ( value: any ) => {
        return i18nObjectToString( value );
      }
    },
    {
      id: claimPageModels.ClaimTableColumns.plate,
      label: t( 'common.plate' )
    },
    {
      id: 'make',
      label: t( 'common.brand' )
    },
    {
      id: 'model',
      label: t( 'common.model' )
    },
    {
      id: 'year',
      label: t( 'common.year' )
    },
    {
      id: claimPageModels.ClaimTableColumns.vin,
      label: t( 'common.vin' )
    },
    {
      id: 'address',
      label: t( 'common.residence' ),
      formatValue: stripNulls,
    },
    {
      id: 'state',
      label: t( 'common.state' )
    },
    {
      id: 'municipality',
      label: t( 'common.municipality' )
    },
    {
      id: claimPageModels.ClaimTableColumns.claimDate,
      label: t( 'common.dateOfClaim' ),
      formatValue: ( value: string ) => {
        return ( value ? moment.utc( value ).format( getAppDateFormat() ) : valueNotFoundSymbol );
      }
    },
    {
      id: 'sinister_hour',
      label: t( 'common.timeOfClaim' ),
      formatValue: ( value: string, dataItem: Record<string, any> ) => {
        return ( value || ( dataItem.sinister_hour_from && dataItem.sinister_hour_to ? (
          t( 'common.timeToTime', { start: dataItem.sinister_hour_from, end: dataItem.sinister_hour_to } )
        ) : valueNotFoundSymbol ));
      }
    },
    {
      id: claimPageModels.ClaimTableColumns.location,
      label: t( 'common.claimLocation' ),
      formatValue: stripNulls
    },
    {
      id: claimPageModels.ClaimTableColumns.idClaimStatus,
      label: t( 'common.claimStatus' ),
      formatValue: ( value: string ) => {
        const key = String( value );

        if ( key in claimStatusOptions ) {
          return t( claimStatusOptions[key].label );
        }

        return valueNotFoundSymbol;
      }
    },
    {
      id: claimPageModels.ClaimTableColumns.claimType,
      label: t( 'common.claimType' ),
      formatValue: ( value: string ) => {
        return attemptTranslation( value, t );
      }
    },
    {
      id: claimPageModels.ClaimTableColumns.fraudProbabilityId,
      label: t( 'common.claimFraudProbability' ),
      formatValue: ( value: string ) => {
        return t( getFraudProbI18nKey( value ) );
      }
    }
  ];
};