import { Key } from "react";

export namespace mapModels{
    type position = google.maps.LatLng | google.maps.LatLngLiteral;
    export type MarkerObj = {
        key: Key | null | undefined;
        position:  position;
        title: string;
        icon: string | google.maps.Icon | google.maps.Symbol | undefined;
        zIndex: number | undefined;
        onClick?:  ((e: google.maps.MapMouseEvent) => void);
        clickable?: boolean;
  }
  
  export enum MarkerNames { 
    manualClaimMarker = "manualClaimMarker",
    residenceMarker = "residenceMarker"
    }
  
  export enum ShapeNames { 
        fraudProbabilityRadius = "fraudProbabilityRadius",
        residenceArea = "residenceArea"
    }

   export  interface ShapeInterface {
			strokeColor: string;
			strokeOpacity: number;
			strokeWeight: number;
			fillColor: string;
			fillOpacity: number;
		}

    export interface CircleShapeInterface extends ShapeInterface {
            center: position,
            radius: number,
    }
    export interface PolygonShapeInterface extends ShapeInterface {
            paths: position[],  
    }
      
}
