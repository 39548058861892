import * as Colors from "./colors";

export const palettes: Record<string, string> = {
  primaryDarker: Colors.deepBlueDarker,
  textLight: Colors.deepBlueWashedOut,
  textDarkest: Colors.black,
  textSecondaryLight: Colors.greyLight,
  textSecondaryLighter: Colors.greyLighter,
  textSecondaryDark: Colors.greyDark,
  textSecondaryDarker: Colors.greyDarker,
  textTertiaryDark: Colors.monochromeGreyDark,
  textWhite: Colors.white,
  neutral: Colors.monochromeGrey,
  neutralLight: Colors.monochromeGreyLight,
  neutralLighter: Colors.monochromeGreyLighter,
  neutralLightest: Colors.monochromeGreyLightest,
  neutralDark: Colors.monochromeGreyDark,
  neutralDarker: Colors.monochromeGreyDarker,
  border: Colors.grey,
  borderWhite: Colors.white,
  borderDarkest: Colors.black,
  borderSecondary: Colors.blueGreyLighter,
  borderTertiary: Colors.monochromeGrey,
  borderTertiaryLight: Colors.monochromeGreyLight,
  borderTertiaryLighter: Colors.monochromeGreyLighter,
  borderTertiaryDarker: Colors.monochromeGreyDarker,
  borderQuaternary: Colors.deepBlueWashedOut,
  borderQuaternaryDark: Colors.deepBlueWashedOutDark,
  borderQuaternaryDarker: Colors.deepBlueWashedOutDarker,
  backgroundDarker: Colors.deepBlueDarker,
  backgroundDarkest: Colors.black,
  backgroundSecondary: Colors.blueGreyLightest,
  backgroundTertiary: Colors.monochromeGreyLightest,
  backgroundTertiaryDark: Colors.monochromeGreyLighter,
  backgroundQuaternary: Colors.nightBlueWashedOut,
  successLight: Colors.greenLight,
  onSuccessLight: Colors.white,
  onWarning: Colors.white,
  chartCrash: Colors.mustard,
  chartTheft: Colors.cloudyBlue,
  chartOtherClaim: Colors.mud,
  chartTrips: Colors.grass,
  chartKmTravelled: Colors.brightBlue,
  residenceArea: Colors.orange,
  tooltip: Colors.blackLight,
  onTooltip: Colors.white
};

export const palettesSharedWithGround: Record<string, string> = {
  primary: Colors.deepBlue,
  primaryLight: Colors.deepBlueLight,
  primaryDark: Colors.deepBlueDark,
  primaryDisabled: Colors.blueGrey,
  onPrimary: Colors.white,
  onPrimaryDisabled: Colors.blueGreyDark,
  secondary: Colors.turquoise,
  secondaryLight: Colors.turquoiseLight,
  secondaryDark: Colors.turquoiseDark,
  onSecondary: Colors.white,
  disabled: Colors.monochromeGreyLighter,
  onDisabled: Colors.monochromeGreyDark,
  success: Colors.green,
  light: Colors.blueGreyLighter,
  warning: Colors.orange,
  danger: Colors.red,
  darker: Colors.deepBlueWashedOut,
  text: Colors.deepBlueDarker,
  background: Colors.white
};