import styled from "styled-components";
import { useAppTheme } from "../../hooks";

import { MoodyTextInterface } from "./MoodyTextInterface";


const Text = styled.span`
  color: ${( props: any ) => props.theme.palettes[props.color]};
`;

const MoodyText = ({
  color = 'danger',
  title,
  className,
  children,
}: MoodyTextInterface) => {
  const theme = useAppTheme();

  return (
    <Text
      theme={theme} 
      color={color}
      title={title}
      className={className}
    >
      {children}
    </Text>
  );
};

export default MoodyText;