import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { isObjectEmpty } from "../../../utils";
import { applyErrorBoundary } from "../../../utils/error-boundary";
import { getAppConfig, setDocumentTitle } from "../../../config";
import { policyModels } from "../../../models/policyModels";
import { mapModels } from "../../../models/mapModels";
import { getMarkerIcon } from "../../../utils/colorcoded-markers";
import { canResidenceAreaInProfileDetailsBeShown } from "../../../utils/profiles";
import { tripsQueryAtom } from "../../../atoms/queryAtoms";
import { useAtom } from "jotai";
import { useAppTheme } from "../../../hooks";
import { tripModels } from "../../../models/tripModels";
import { ProfilesService } from "../../../services/ProfilesService";
import { getProfileDetailPeriod } from "../../../utils/period";
import { downloadPdf } from "../../../utils/pdf";

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { selectPeriod, updatePeriod } from "../../../store/slices/periodSlice";
import { PeriodState } from "../../../store/slices/periodSlice";
import { addMarkersWithId, addPolygonShapesWithId, removeMarkersById, removePolygonShapesById, selectMarkerIdsByName, selectShapeIdsByName } from "../../../store/slices/mapSlice";

import { GroundToastType, GroundToastVariant, useGroundToast } from "@greenvulcano/ground-react";
import DetailsPage from "../../../shared-components/DetailsPage/DetailsPage";
import MainSidebarContent from "./MainSidebarContent/MainSidebarContent";
import PolicyAndResidenceFraudPdf from '../../../shared-components/pdf-documents/PolicyAndResidenceFraudPdf/PolicyAndResidenceFraudPdf';

import "./ProfileDetails.scss";


const ProfileDetails = () => {
  const { t }           = useTranslation( 'main' );
  const appDispatch     = useAppDispatch();
  const thePeriod       = useAppSelector( selectPeriod( 'profileDetail' ) );
  const { id_voucher: voucherID = 0 } = useParams();
  const showExportButtonInPolicyDetailsPage = getAppConfig('showExportButtonInPolicyDetailsPage');
  const shapeIdsByName = useAppSelector(selectShapeIdsByName);
  const markerIdsByName = useAppSelector(selectMarkerIdsByName);
  const [tripsQueryAtomValue, setTripsQueryAtom] = useAtom(tripsQueryAtom);
  const [checkedTrips, setCheckedTrips] = useState<tripModels.TripProcessedData[]>( [] );
  const theme       = useAppTheme();
  const groundToast = useGroundToast();


  const updateProfileDetailPeriod = ( period: PeriodState ) => {
		appDispatch( updatePeriod( { id: 'profileDetail', period } ));
	};
  const onSuccessHandler = () => {
    if ( isObjectEmpty( thePeriod ) ) {
      updateProfileDetailPeriod( getProfileDetailPeriod() );
    }
	};
  const query = useQuery({
    queryKey: [ 'profileDetails', voucherID ],
    queryFn: () => ProfilesService.getProfileDetails( voucherID ),
    onSuccess: onSuccessHandler
  });

  const onChangeDateRange = (period: PeriodState) => {
		updateProfileDetailPeriod(period);
  };

  const getHomeMarkerIcon = (color: string) => {
    return getMarkerIcon(color, "residence");
  };
  
  
    const fraudProbabilityColor = (
      query.data?.residence_fraud &&
      policyModels.fraudProbabilityColorsMap[
        query.data.residence_fraud as keyof typeof policyModels.fraudProbabilityColorsMap
      ]
    ) || "#0E2636";
    


  useEffect(() => {
    setDocumentTitle( t( 'common.profileDetail' ) as string );
    onSuccessHandler();
  }, []);

  useEffect(() => {
    return () => {
			appDispatch(removeMarkersById([markerIdsByName.residenceMarker]));
      appDispatch(removePolygonShapesById([shapeIdsByName.residenceArea]));
		};
	}, []);


  useEffect(() => {
    if ( !query.data ) {
      return;
    }

    const policyTypeID = ( query.data.policy_type_id || '' );

    if ( !canResidenceAreaInProfileDetailsBeShown( policyTypeID ) ) {
      return;
    }

    if (
      query.data.residence_area?.coordinates && 
      query.data.residence_area.coordinates.length > 0 && 
      query.data.residence_area_center?.coordinates[1] && 
      query.data.residence_area_center?.coordinates[0]
    ) {
      const flattenedCoordinates = query.data.residence_area.coordinates.flatMap( outer => outer.flatMap( inner => inner ) );

      createResidenceAreaShape({
        color: fraudProbabilityColor, 
        coordinates: flattenedCoordinates,
      });
      createResidenceAreaMarker({
        lat: query.data.residence_area_center.coordinates[1],
        lng: query.data.residence_area_center.coordinates[0], 

      })
    } 
  }, [query.data]);
  
  
  
   const createResidenceAreaShape = ({ color, coordinates }: {
    color: string;
    coordinates: Array<[number, number]>;
  }) => {
    const paths = coordinates.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }));
    const shapeObj = {
      paths: paths,
      strokeColor: color,
      strokeOpacity: 0.3,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.05      
    };

    appDispatch(
      addPolygonShapesWithId([{
        shapeObj: shapeObj,
        id : shapeIdsByName.residenceArea
      }])
     );
     
  };

  const createResidenceAreaMarker = ({
    lat,
    lng,
  }: {
    lat: number;
    lng: number;
  }) => {
    const marker: mapModels.MarkerObj = {
      key: "",
      position: { lat, lng },
      title: "address",
      icon: getHomeMarkerIcon( fraudProbabilityColor ),
      zIndex: 10,
      clickable: false,
    };
    appDispatch(
      addMarkersWithId([
        {
          markerObj: marker,
          id: markerIdsByName.residenceMarker,
        },
      ])
    );
  };

  const onChangeCheckedTrips = ( checkedTrips: tripModels.TripProcessedData[] ) => {
		setCheckedTrips( checkedTrips );
	};

  const onClickExport = () => {
    if ( !query.data ) {
      return;
    }

    (async () => {
      try {
				const filename = t( 'functionalities.policyReportFilename', { policyID: query.data.profile_id } );

        await downloadPdf( filename, (
          <PolicyAndResidenceFraudPdf
						theme={theme}
						policy={query.data}
            trips={checkedTrips}
					/>
        ));
			} catch (error) {
				groundToast.show({
					variant: GroundToastVariant.featured,
					type: GroundToastType.alert,
					message: t("toastMessages.documentGenFailed"),
				});
			}
		})();
  };

  return (
    <DetailsPage
      pageTag='profile'
      mainQuery={query}
      utilityHeaderOptions={{
        relaxed: true,
        exportButton: {
          show: showExportButtonInPolicyDetailsPage,
          disabled: !( query.isSuccess  && tripsQueryAtomValue?.isSuccess ),
          onClick: onClickExport
        },
        title: t( 'common.profiles' ),
        description: t('pagesDescriptions.profileDetails'),
        onChangeDateRange
      }}
      renderMainSidebar={({ statusChangeFormOptions, tripsQuery}) => (
        <MainSidebarContent
          mainQuery={query}
          tripsQuery={tripsQuery}
          statusChangeFormOptions={statusChangeFormOptions}
        />
      )}
      onChangeCheckedTrips= {onChangeCheckedTrips}
    />
  );
};

export default applyErrorBoundary( ProfileDetails );
