
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";

import { DetailsPageModalInterface } from "./DetailsPageModalInterface";
import "./DetailsPageModal.scss";

const DetailsPageModal = ({
  title,
  content
}: DetailsPageModalInterface) => {
  return (
    <ConfirmationModal
      readOnly
      className='details-page-modal'
      title={title}
      content={content}
    />
  );
};

export default DetailsPageModal;