import { useTranslation } from "react-i18next";
import { View, Text } from "@react-pdf/renderer";
import { capitalize } from "../../../utils";
import { getFraudProbI18nKey } from "../../../utils/fraud-probability";
import { getLevelBudgePalette } from "../../../utils/color-utils";
import { computeFontSize } from "../../../utils/pdf";

import { PdfFraudLevelBudgeInterface } from "./PdfFraudLevelBudgeInterface";


const PdfFraudLevelBudge = ({ value, theme }: PdfFraudLevelBudgeInterface ) => {
  const { t }   = useTranslation( 'main' );
  const label   = t( getFraudProbI18nKey( value ) );
  const palette = getLevelBudgePalette( value );

  return (
    <View
      style={{
        alignItems: 'center',
        backgroundColor: theme.palettes[palette],
        borderRadius: 9,
        color: theme.palettes[`on${capitalize( palette )}`],
        height: 16,
        justifyContent: 'center',
        maxWidth: 62,
        paddingBottom: 1,
        paddingHorizontal: 8,
      }}
    >
      <Text style={{
        lineHeight: 1,
        fontSize: computeFontSize( label, 8, 9 )
      }}>
        {label}
      </Text>
    </View>
  );
};

export default PdfFraudLevelBudge;