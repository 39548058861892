import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useAppTheme } from "../../hooks";
import { getAppConfig } from "../../config";
import { AppDateFormats } from "../../constants";

import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { selectPeriod, updatePeriod } from "../../store/slices/periodSlice";
import { selectFilters, updateFilters } from "../../store/slices/filtersSlice";

import { GroundButton, GroundToggle, GroundDateRangePicker } from "@greenvulcano/ground-react";
import ButtonsSeparator from "../ButtonsSeparator/ButtonsSeparator";
import DisclosingArrow from "../DisclosingArrow/DisclosingArrow";
import RouterLink from "../RouterLink/RouterLink";
import MoodyText from "../MoodyText/MoodyText";

import { UtilityHeaderInterface } from "./UtilityHeaderInterface";
import "./UtilityHeader.scss";
import ModalMenu from "../ModalMenu/ModalMenu";


const Header = styled.div`
  color: ${( props: any ) => props.theme.palettes.textWhite};
  background: ${( props: any ) => props.theme.palettes.backgroundDarkest};

  .utility-box {
    border-color: ${( props: any ) => props.theme.palettes.borderQuaternary};
  }

  .utility-label {
    color: ${( props: any ) => props.theme.palettes.textLight};
  }
`;

const PickerWrapper = styled.div`
  .ground-date-range-picker {
    &.filled-up {
      .arrow {
        &::before {
          border-color: ${( props: any ) => props.theme.palettes.textWhite};
        }
      }
    }
  }

  .ground-date-range-picker-input {
    color: ${( props: any ) => props.theme.palettes.textWhite};

    &::placeholder {
      color: ${( props: any ) => props.theme.palettes.textLight};
    }
  }

  .arrow {
    &::before {
      border-color: ${( props: any ) => props.theme.palettes.textLight};
    }
  }
`;

const UtilityHeader = ({
  title,
  description,
  relaxed,
  showToggles,
  exportButton = {
    show: true, // Set to true for the components not involved in the configuration.
  },
  showPicker = true,
  breadcrumbBackTo,
  period,
  startOfTimes = '',
  className,
  onChangeDateRange,
}: UtilityHeaderInterface) => {
  const { t }           = useTranslation( 'main' );
  const theme           = useAppTheme();
  const appDispatch     = useAppDispatch();
  const appDateFormat   = getAppConfig( 'dateFormat' );
  const periodFromStore = useAppSelector( selectPeriod() );
  const toggleValues    = useAppSelector( selectFilters( 'claimTypes' ) );
  const thePeriod       = period || periodFromStore;
  
  let datePickerPlaceholder = '';

  const onChangeToggleHandler = ( event: ChangeEvent<HTMLInputElement> ) => {
    if ( !showToggles ) {
      return;
    }

    const filters = {
      ...toggleValues,
      [event.target.name]: event.target.checked
    };

    appDispatch( updateFilters( { key: 'claimTypes', filters }) );
  };

  const onChangeDateRangeHandler = ( startIsoDate: string, endIsoDate: string ) => {
    if ( !( startIsoDate && endIsoDate ) ) {
      return;
    }

    const period = { start: startIsoDate, end: endIsoDate };

    if ( typeof onChangeDateRange  === 'function' ) {
      onChangeDateRange( period );
    }
    else {
      appDispatch( updatePeriod( { period } ) );
    }
  };

  switch ( appDateFormat ) {
    case AppDateFormats.ymd:
      datePickerPlaceholder = 'yyyy/mm/dd';
      break;

    case AppDateFormats.mdy:
      datePickerPlaceholder = 'mm/dd/yyyy';
      break;

    default:
      datePickerPlaceholder = 'dd/mm/yyyy';
      break;
  }

  return (
    <Header
      theme={theme}
      className={classNames(
        'utility-header flex flex-vcenter flex-space-between',
        className,
        { relaxed }
      )}
    >
      <div className='flex flex-columns gap-1'>
        <h2 className='breadcrumbs flex flex-vcenter'>
          {breadcrumbBackTo ? (
            <>
              <RouterLink
                className='back-link'
                to={breadcrumbBackTo}
              >
                {title}
              </RouterLink>
              <DisclosingArrow
                arrowColor='textWhite'
                rotation='90deg'
                className='arrow'
              />
              <MoodyText
                color='textWhite'
                className='last-bc'
              >
                {t( 'common.detail' )}
              </MoodyText>
            </>
          ) : title}
        </h2>
        <p>{description}</p>
      </div>
      <div className='toolbar flex gap-3'>
        {showToggles ? (
          <div className='toggles-wrapper utility-box flex flex-vcenter gap-3'>
            <span className='utility-label'>
              {t( 'common.claimType' )}
            </span>
            {Object.keys( toggleValues ).map( ( name: string, idx: number ) => (
              <GroundToggle
                animated
                key={name + idx}
                scale={0.7}
                color='successLight'
                offColor='borderQuaternary'
                cursorOffColor='borderQuaternaryDark'
                className='toggle'
                name={name}
                checked={toggleValues[name as keyof typeof toggleValues]}
                label={t( `functionalities.${name}` )}
                onChange={onChangeToggleHandler}
              />
            ))}
          </div>
        ) : null}
        {showPicker ? (
          <PickerWrapper
            theme={theme}
            className='date-range-picker-wrapper utility-box flex flex-vcenter'
          >
            <label
              className='utility-label'
              htmlFor='period-picker-start-input'
            >
              {t( 'common.period' )}
            </label>
            <GroundDateRangePicker
              alignRight
              showUtility
              id='period-picker'
              height='medium'
              width='210'
              dateFormat={appDateFormat}
              defaultStartDate={thePeriod.start}
              defaultEndDate={thePeriod.end}
              startPlaceholder={datePickerPlaceholder}
              endPlaceholder={datePickerPlaceholder}
              startOfTimes={startOfTimes}
              endOfTimes={getAppConfig( 'maxEndDate' )}
              icon={<DisclosingArrow
                className='toggle-arrow'
                arrowColor='textWhite'
                scale={0.7}
              />}
              onChange={onChangeDateRangeHandler}
            />
          </PickerWrapper>
        ) : null}
        {showPicker && exportButton.show ? (
          <ButtonsSeparator />
        ) : null}
        {exportButton.show && ( exportButton.modalActions ? (
          <ModalMenu
            className='utility-export-menu'
            actions={exportButton.modalActions}
            renderTrigger={onClick => {
              return (
                <GroundButton
                  color='secondary'
                  height='medium'
                  className='export-button'
                  text={t( 'common.export' )}
                  disabled={exportButton.disabled}
                  isLoading={exportButton.isLoading}
                  onClick={onClick}
                />
              );
            }}
          />
        ): (
          <GroundButton
            color='secondary'
            height='medium'
            className='export-button'
            text={t( 'common.export' )}
            disabled={exportButton.disabled}
            isLoading={exportButton.isLoading}
            onClick={exportButton.onClick}
          />
        ))}
      </div>
    </Header>
  );
};

export default UtilityHeader;