import { viewMoreDetailsModels } from '../../models/viewMoreDetailsModels';

export const viewMoreClaimDetails = [
    viewMoreDetailsModels.viewMoreClaimDetails.vin,
    viewMoreDetailsModels.viewMoreClaimDetails.policyHolder,
   // viewMoreDetailsModels.viewMoreClaimDetails.claimAmont,
    viewMoreDetailsModels.viewMoreClaimDetails.policyId,
   /*  viewMoreDetailsModels.viewMoreClaimDetails.agentName,
    viewMoreDetailsModels.viewMoreClaimDetails.agencyName, */
    viewMoreDetailsModels.viewMoreClaimDetails.policyType,
    viewMoreDetailsModels.viewMoreClaimDetails.address,
    viewMoreDetailsModels.viewMoreClaimDetails.make,
    viewMoreDetailsModels.viewMoreClaimDetails.model,
   
];


export const getViewMoreClaimDetails = () => {
	return viewMoreClaimDetails;
};
