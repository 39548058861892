import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";


export type FiltersStateItem = Record<string, any>;

export interface FiltersState {
  claims: FiltersStateItem;
  profiles: FiltersStateItem;
  claimTypes: FiltersStateItem;
  claimsTable: FiltersStateItem;
  profilesTable: FiltersStateItem;
}

const initialState = {
  claims: {},
  profiles: {},
  claimTypes: {
    is_crash: true,
    is_theft: true
  },
  claimsTable: {
    currentPage: 1,
    activeColumn: { id: 'policyholder', isAscOrder: true }
  },
  profilesTable: {
    currentPage: 1,
    activeColumn: { id: 'policyholder', isAscOrder: true }
  }
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    update: ( state, action: PayloadAction<{ key: string, filters: FiltersStateItem}> ) => {
      const { key, filters } = action.payload;

      return {
        ...state,
        [key]: filters 
      };
    },
    reset: ( state, action: PayloadAction<{ key: string }> ) => {
      const key = action.payload.key;

      return {
        ...state,
        [key]: initialState[key as keyof FiltersState] 
      };
    }
  }
});

export const {
  update: updateFilters,
  reset: resetFilters
} = filtersSlice.actions;

export const selectFilters = ( key: string ) => ( state: RootState ) => state.filters[key as keyof FiltersState];

export default filtersSlice.reducer;