import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { valueNotFoundSymbol } from "../../../utils/constants";

import { PdfInfoFieldsInterface } from "./PdfInfoFieldsInterface";


const getStyles = ({ theme }: Record<string, any>) =>
  StyleSheet.create({
    bold: {
      fontFamily: "Helvetica-Bold",
    },
    infoItem: {
      gap: 2,
    },
    infoItemKey: {
      fontSize: 8,
      color: theme.palettes.textLight,
      lineHeight: 1.5,
    },
    infoItemValue: {
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
      lineHeight: 1.3
    }
  });

const PdfInfoFields = ({
  numOfColumns= 5,
  fields,
  data,
  theme
}: PdfInfoFieldsInterface) => {
  const { t }  = useTranslation( 'main' );
  const styles = getStyles({ theme });

  // Leading white-spaces clean-up.
  for ( const key in data ) {
    if ( data[key] && ( typeof data[key] === 'string' ) ) {
      data[key] = ( data[key] as string ).trim();
    }
  }

  return (
    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
      {Array.from(Array( numOfColumns ).keys()).map((colIdx: number) => {
        return (
          <View key={"infoCol_" + colIdx} >
            {fields.map((item: any, itemIdx: number) => {
              if (colIdx !== itemIdx % numOfColumns ) {
                return null;
              }

              return (
                <View
                  key={item.id}
                  style={[styles.infoItem, { minHeight: 50 }]}
                >
                  <Text style={styles.infoItemKey}>
                    {t(item.i18nTitleKey)}
                  </Text>
                  <View style={[ styles.infoItemValue, { maxWidth: 120 }]}>
                    {item.id in data ? ( 
                      typeof item.valueFormatter === 'function' ?
                      item.valueFormatter( data[item.id], data ) : 
                      <Text>{data[item.id] || valueNotFoundSymbol}</Text>
                    ) : (
                      <Text>{valueNotFoundSymbol}</Text>
                    )}
                  </View>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

export default PdfInfoFields;