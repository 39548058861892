export const pdfBarChartColors = [ 
  '#84190C', 
  '#D12F1D', 
  '#FAC140', 
  '#E7B322', 
  '#9DCC62', 
  '#43AC5B', 
  '#3AAFEB',
  '#1D70BB',
  '#693A9B',
  '#F24E97',
  '#55C3EC' 
];