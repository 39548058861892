import { StyleSheet, View, Text, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getAppDateFormat, getAppLanguage } from "../../../../config";
import { attemptTranslation } from "../../../../utils/i18n";
import { claimTypeIdToKey } from "../../../../utils/claim";
import { capitalize } from "../../../../utils";
import { claimModels } from "../../../../models/claimModels";
import { stripNulls } from "../../../../utils/sanitization";
import { valueNotFoundSymbol } from "../../../../utils/constants";

import endPointIcon from "../../../../images/pdf-icons/end-point-icon.png";
import theftIcon from "../../../../images/pdf-icons/theft-icon.png";
import crashIcon from "../../../../images/pdf-icons/crash-icon.png";

import PdfTripsTable from "../../PdfTripsTable/PdfTripsTable";
import PdfFraudLevelBudge from "../../PdfFraudLevelBudge/PdfFraudLevelBudge";
import PdfGoogleMap from "../../PdfGoogleMap/PdfGoogleMap";
import { ClaimInfoPdfInterface } from "../ClaimInfoPdfInterface";
import PdfInfoFields from "../../PdfInfoFields/PdfInfoFields";
import { PdfInfoFieldDataItem } from "../../PdfInfoFields/PdfInfoFieldsInterface";
import { getPdfTempalteStyles } from "../../PdfTemplate/PdfTemplate";


const getInfoFields = ({ theme, t }: Record<string, any> ): PdfInfoFieldDataItem[] => {
  return (
    [
      {
        id: "policyholder",
        i18nTitleKey: "common.policyholderName",
      },
      {
        id: "vin",
        i18nTitleKey: "common.vin",
      },
      {
        id: "policy_id",
        i18nTitleKey: "common.policyNumber",
      },
      {
        id: "policy_type",
        i18nTitleKey: "common.policyType",
        valueFormatter: (value: any) => {
          return <Text>{value ? value[getAppLanguage()] : valueNotFoundSymbol}</Text>;
        },
      },
      {
        id: "claim_type_id",
        i18nTitleKey: "common.anomalyType",
        valueFormatter: ( value: string ) => {
          return (
            <Text>
              {value ? attemptTranslation(claimModels.claimTypeMap[value as keyof typeof claimModels.claimTypeMap], t) : valueNotFoundSymbol}
            </Text>
          );
        },
      },
      {
        id: "make",
        i18nTitleKey: "common.brand",
        valueFormatter: (value: string) => {
          return <Text>{value ? capitalize(value) : valueNotFoundSymbol}</Text>;
        },
      },
      {
        id: "model",
        i18nTitleKey: "common.model",
        valueFormatter: (value: string ) => {
          return <Text>{value ? capitalize(value) : valueNotFoundSymbol}</Text>;
        },
      },
      {
        id: "year",
        i18nTitleKey: "common.year",
        valueFormatter: (value: string ) => {
          return <Text>{value ? capitalize(value) : valueNotFoundSymbol}</Text>;
        },
      },
      {
        id: "id_claim_status",
        i18nTitleKey: "common.status",
        valueFormatter: ( value: string ) => {
          return (
            <Text>
              {value ? attemptTranslation(claimModels.claimStatusMap[value as keyof typeof claimModels.claimStatusMap], t) : valueNotFoundSymbol}
            </Text>
          );
        },
      },
      {
        id: "fraud_probability_id",
        i18nTitleKey: "common.fraudProbability",
        valueFormatter: ( value: string ) => {
          return <PdfFraudLevelBudge value={value} theme={theme} />;
        },
      },
    ]
  );
};

const getStyles = ({ theme }: Record<string, any>) =>
  StyleSheet.create({
    infoItem: {
      gap: 2,
    },
    infoItemKey: {
      fontSize: 8,
      color: theme.palettes.textLight,
      lineHeight: 1.5,
    },
    infoItemValue: {
      fontSize: 10,
      fontFamily: "Helvetica-Bold",
      lineHeight: 1.3
    },
    description: {
      fontSize: 8,
      fontFamily: "Helvetica",
      lineHeight: 1.3
    },
    claimAction: {
      borderBottom: `0.5px solid ${theme.palettes.borderTertiaryDarker}`,
      borderTop: `0.5px solid ${theme.palettes.borderTertiaryDarker}`,
      marginBottom: 20,
      marginTop: 10,
      paddingTop: 30,
      paddingBottom: 30,
    }
  });

const getClaimDate = ( claim: claimModels.SingleProcessed, t: any ) => {
  if ( !claim.claim_date ) {
    return valueNotFoundSymbol;
  }

  let claimDate = moment.utc( claim.claim_date ).format( getAppDateFormat() );

  if ( claim.sinister_hour ) {
    claimDate += ' - ' + claim.sinister_hour;
  }
  else if ( claim.sinister_hour_from && claim.sinister_hour_to ) {
    claimDate += ' ' + t( 'common.timeToTime', { start: claim.sinister_hour_from, end: claim.sinister_hour_to } );
  }

  return claimDate;
};

const InfoPageContent = ({
  theme,
  claim,
  claimStatus,
  trips,
}: ClaimInfoPdfInterface) => {
  claim = { ...claim, ...( claimStatus || {} ) };

  const { t }          = useTranslation("main");
  const styles         = getStyles({ theme });
  const templateStyles = getPdfTempalteStyles({ theme });

  const description = ( claim.note ? ( 
    String( claim.note ).replaceAll( /\s+/g, ' ' ) 
  ) : valueNotFoundSymbol );

  return (
    <>
      <Text style={[templateStyles.title, { marginTop: 0 }]}>
        {t("common.claimInformation")}
      </Text>
      <PdfInfoFields
        fields={getInfoFields({ t, theme })}
        data={claim}
        theme={theme}
      />
      <PdfGoogleMap
        claim={claim}
        trips={trips}
      />
      <View style={templateStyles.title}>
        <Image 
          src={'theft' === claimTypeIdToKey( claim.claim_type_id ) ? theftIcon : crashIcon}
          style={{ height: 18, width: 14.5 }}
        />
        <Text>{t("common.reportedInfoAboutClaim")}</Text>
      </View>
      <View style={{ flexDirection: 'row', gap: 30 }}>
        <View style={{ gap: 8 }}>
          <View style={[ styles.infoItem, { width: '35%' }]}>
            <Text style={styles.infoItemKey}>{t("common.location")}</Text>
            <Text style={styles.infoItemValue}>{stripNulls( claim.location ) || valueNotFoundSymbol}</Text>
          </View>
          <View style={styles.infoItem}>
            <Text style={styles.infoItemKey}>{t("common.dateAndTime")}</Text>
            <Text style={styles.infoItemValue}>
              {getClaimDate( claim, t )}
            </Text>
          </View>   
        </View>
        <View>
          <View style={[ styles.infoItem, { width: '60%' } ]}>
            <Text style={styles.infoItemKey}>{t("common.description")}</Text>
            <Text style={styles.description}>{description}</Text>
          </View>
        </View>
      </View>
      {trips.length ? (
        <>
          <View style={templateStyles.title}>
            <Image
              src={endPointIcon}
              style={{ height: 18, width: 18 }}
            />
            <Text>{t("common.placeAndTimeReportedByDevice")}</Text>
          </View> 
          <PdfTripsTable theme={theme} trips={trips} />
      </>
      ) : null}
    </>
  );
};

export default InfoPageContent;
