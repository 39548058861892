// Start Icons
import start_D51D22 from "../images/colorcoded-markers/start_D51D22.svg";
import start_117170 from "../images/colorcoded-markers/start_117170.svg";
import start_9E9391 from "../images/colorcoded-markers/start_9E9391.svg";
import start_C11875 from "../images/colorcoded-markers/start_C11875.svg";
import start_A94D13 from "../images/colorcoded-markers/start_A94D13.svg";
import start_2804AF from "../images/colorcoded-markers/start_2804AF.svg";
import start_0DA4ED from "../images/colorcoded-markers/start_0DA4ED.svg";
import start_EB24B1 from "../images/colorcoded-markers/start_EB24B1.svg";
import start_6C67DF from "../images/colorcoded-markers/start_6C67DF.svg";
import start_0B5F9E from "../images/colorcoded-markers/start_0B5F9E.svg";
import start_6EE736 from "../images/colorcoded-markers/start_6EE736.svg";
import start_B83B35 from "../images/colorcoded-markers/start_B83B35.svg";
import start_C7AA90 from "../images/colorcoded-markers/start_C7AA90.svg";
import start_8AD2BE from "../images/colorcoded-markers/start_8AD2BE.svg";
import start_DF7770 from "../images/colorcoded-markers/start_DF7770.svg";
import start_0E2636 from "../images/colorcoded-markers/start_0E2636.svg";
import start_EBFF00 from "../images/colorcoded-markers/start_EBFF00.svg";
import start_A6AAC5 from "../images/colorcoded-markers/start_A6AAC5.svg";
import start_9DAF39 from "../images/colorcoded-markers/start_9DAF39.svg";
import start_6B7783 from "../images/colorcoded-markers/start_6B7783.svg";

// Start Icons [heartbeats]
import start_18C16F from "../images/colorcoded-markers/start_18C16F.svg";
import start_DB6DD4 from "../images/colorcoded-markers/start_DB6DD4.svg";
import start_90EB24 from "../images/colorcoded-markers/start_90EB24.svg";
import start_B8B035 from "../images/colorcoded-markers/start_B8B035.svg";
import start_9C13A9 from "../images/colorcoded-markers/start_9C13A9.svg";

// End Icons
import end_D51D22 from "../images/colorcoded-markers/end_D51D22.svg";
import end_117170 from "../images/colorcoded-markers/end_117170.svg";
import end_9E9391 from "../images/colorcoded-markers/end_9E9391.svg";
import end_C11875 from "../images/colorcoded-markers/end_C11875.svg";
import end_A94D13 from "../images/colorcoded-markers/end_A94D13.svg";
import end_2804AF from "../images/colorcoded-markers/end_2804AF.svg";
import end_0DA4ED from "../images/colorcoded-markers/end_0DA4ED.svg";
import end_EB24B1 from "../images/colorcoded-markers/end_EB24B1.svg";
import end_6C67DF from "../images/colorcoded-markers/end_6C67DF.svg";
import end_0B5F9E from "../images/colorcoded-markers/end_0B5F9E.svg";
import end_6EE736 from "../images/colorcoded-markers/end_6EE736.svg";
import end_B83B35 from "../images/colorcoded-markers/end_B83B35.svg";
import end_C7AA90 from "../images/colorcoded-markers/end_C7AA90.svg";
import end_8AD2BE from "../images/colorcoded-markers/end_8AD2BE.svg";
import end_DF7770 from "../images/colorcoded-markers/end_DF7770.svg";
import end_0E2636 from "../images/colorcoded-markers/end_0E2636.svg";
import end_EBFF00 from "../images/colorcoded-markers/end_EBFF00.svg";
import end_A6AAC5 from "../images/colorcoded-markers/end_A6AAC5.svg";
import end_9DAF39 from "../images/colorcoded-markers/end_9DAF39.svg";
import end_6B7783 from "../images/colorcoded-markers/end_6B7783.svg";

// End Icons [heartbeats]
import end_18C16F from "../images/colorcoded-markers/end_18C16F.svg";
import end_DB6DD4 from "../images/colorcoded-markers/end_DB6DD4.svg";
import end_90EB24 from "../images/colorcoded-markers/end_90EB24.svg";
import end_B8B035 from "../images/colorcoded-markers/end_B8B035.svg";
import end_9C13A9 from "../images/colorcoded-markers/end_9C13A9.svg";

// Empty Disk Icons [heartbeats]
import empty_D51D22 from "../images/colorcoded-markers/empty_D51D22.svg";
import empty_18C16F from "../images/colorcoded-markers/empty_18C16F.svg";
import empty_DB6DD4 from "../images/colorcoded-markers/empty_DB6DD4.svg";
import empty_90EB24 from "../images/colorcoded-markers/empty_90EB24.svg";
import empty_B8B035 from "../images/colorcoded-markers/empty_B8B035.svg";
import empty_9C13A9 from "../images/colorcoded-markers/empty_9C13A9.svg";

// Point Icons
import point_D51D22 from "../images/colorcoded-markers/point_D51D22.svg";
import point_117170 from "../images/colorcoded-markers/point_117170.svg";
import point_9E9391 from "../images/colorcoded-markers/point_9E9391.svg";
import point_C11875 from "../images/colorcoded-markers/point_C11875.svg";
import point_A94D13 from "../images/colorcoded-markers/point_A94D13.svg";
import point_2804AF from "../images/colorcoded-markers/point_2804AF.svg";
import point_0DA4ED from "../images/colorcoded-markers/point_0DA4ED.svg";
import point_EB24B1 from "../images/colorcoded-markers/point_EB24B1.svg";
import point_6C67DF from "../images/colorcoded-markers/point_6C67DF.svg";
import point_0B5F9E from "../images/colorcoded-markers/point_0B5F9E.svg";
import point_6EE736 from "../images/colorcoded-markers/point_6EE736.svg";
import point_B83B35 from "../images/colorcoded-markers/point_B83B35.svg";
import point_C7AA90 from "../images/colorcoded-markers/point_C7AA90.svg";
import point_8AD2BE from "../images/colorcoded-markers/point_8AD2BE.svg";
import point_DF7770 from "../images/colorcoded-markers/point_DF7770.svg";
import point_0E2636 from "../images/colorcoded-markers/point_0E2636.svg";
import point_EBFF00 from "../images/colorcoded-markers/point_EBFF00.svg";
import point_A6AAC5 from "../images/colorcoded-markers/point_A6AAC5.svg";
import point_9DAF39 from "../images/colorcoded-markers/point_9DAF39.svg";
import point_6B7783 from "../images/colorcoded-markers/point_6B7783.svg";

// Crash, Theft, Residence
import crash_0E2636 from "../images/crash-marker.svg";
import crash_F46A00 from "../images/colorcoded-markers/crash_F46A00.svg";
import crash_488C21 from "../images/colorcoded-markers/crash_488C21.svg";
import crash_DD0546 from "../images/colorcoded-markers/crash_DD0546.svg";
import theft_0E2636 from "../images/theft-marker.svg";
import theft_F46A00 from "../images/colorcoded-markers/theft_F46A00.svg";
import theft_488C21 from "../images/colorcoded-markers/theft_488C21.svg";
import theft_DD0546 from "../images/colorcoded-markers/theft_DD0546.svg";
import residence_F46A00 from "../images/colorcoded-markers/residence_F46A00.svg";
import residence_488C21 from "../images/colorcoded-markers/residence_488C21.svg";
import residence_DD0546 from "../images/colorcoded-markers/residence_DD0546.svg";
import residence_0E2636 from "../images/colorcoded-markers/residence_0E2636.svg";

// PNG End Icons
import png_end_D51D22 from "../images/colorcoded-markers-png/end_D51D22.png";
import png_end_117170 from "../images/colorcoded-markers-png/end_117170.png";
import png_end_9E9391 from "../images/colorcoded-markers-png/end_9E9391.png";
import png_end_C11875 from "../images/colorcoded-markers-png/end_C11875.png";
import png_end_A94D13 from "../images/colorcoded-markers-png/end_A94D13.png";
import png_end_2804AF from "../images/colorcoded-markers-png/end_2804AF.png";
import png_end_0DA4ED from "../images/colorcoded-markers-png/end_0DA4ED.png";
import png_end_EB24B1 from "../images/colorcoded-markers-png/end_EB24B1.png";
import png_end_6C67DF from "../images/colorcoded-markers-png/end_6C67DF.png";
import png_end_0B5F9E from "../images/colorcoded-markers-png/end_0B5F9E.png";
import png_end_6EE736 from "../images/colorcoded-markers-png/end_6EE736.png";
import png_end_B83B35 from "../images/colorcoded-markers-png/end_B83B35.png";
import png_end_C7AA90 from "../images/colorcoded-markers-png/end_C7AA90.png";
import png_end_8AD2BE from "../images/colorcoded-markers-png/end_8AD2BE.png";
import png_end_DF7770 from "../images/colorcoded-markers-png/end_DF7770.png";
import png_end_0E2636 from "../images/colorcoded-markers-png/end_0E2636.png";
import png_end_EBFF00 from "../images/colorcoded-markers-png/end_EBFF00.png";
import png_end_A6AAC5 from "../images/colorcoded-markers-png/end_A6AAC5.png";
import png_end_9DAF39 from "../images/colorcoded-markers-png/end_9DAF39.png";
import png_end_6B7783 from "../images/colorcoded-markers-png/end_6B7783.png";

// PNG End Icons [heartbeats]
import png_end_18C16F from "../images/colorcoded-markers-png/end_18C16F.png";
import png_end_DB6DD4 from "../images/colorcoded-markers-png/end_DB6DD4.png";
import png_end_90EB24 from "../images/colorcoded-markers-png/end_90EB24.png";
import png_end_B8B035 from "../images/colorcoded-markers-png/end_B8B035.png";
import png_end_9C13A9 from "../images/colorcoded-markers-png/end_9C13A9.png";


const markerIcons: Record<string, any> = {
  start_D51D22,
  start_117170,
  start_9E9391,
  start_C11875,
  start_A94D13,
  start_2804AF,
  start_0DA4ED,
  start_EB24B1,
  start_6C67DF,
  start_0B5F9E,
  start_6EE736,
  start_B83B35,
  start_C7AA90,
  start_8AD2BE,
  start_DF7770,
  start_0E2636,
  start_EBFF00,
  start_A6AAC5,
  start_9DAF39,
  start_6B7783,
  start_18C16F,
  start_DB6DD4,
  start_90EB24,
  start_B8B035,
  start_9C13A9,
  end_D51D22,
  end_117170,
  end_9E9391,
  end_C11875,
  end_A94D13,
  end_2804AF,
  end_0DA4ED,
  end_EB24B1,
  end_6C67DF,
  end_0B5F9E,
  end_6EE736,
  end_B83B35,
  end_C7AA90,
  end_8AD2BE,
  end_DF7770,
  end_0E2636,
  end_EBFF00,
  end_A6AAC5,
  end_9DAF39,
  end_6B7783,
  end_18C16F,
  end_DB6DD4,
  end_90EB24,
  end_B8B035,
  end_9C13A9,
  point_D51D22,
  point_117170,
  point_9E9391,
  point_C11875,
  point_A94D13,
  point_2804AF,
  point_0DA4ED,
  point_EB24B1,
  point_6C67DF,
  point_0B5F9E,
  point_6EE736,
  point_B83B35,
  point_C7AA90,
  point_8AD2BE,
  point_DF7770,
  point_0E2636,
  point_EBFF00,
  point_A6AAC5,
  point_9DAF39,
  point_6B7783,
  crash_0E2636,
	crash_F46A00,
	crash_488C21,
	crash_DD0546,
	theft_0E2636,
	theft_F46A00,
	theft_488C21,
	theft_DD0546,
	residence_488C21,
	residence_DD0546,
	residence_F46A00,
	residence_0E2636,
  empty_D51D22,
  empty_18C16F,
  empty_DB6DD4,
  empty_90EB24,
  empty_B8B035,
  empty_9C13A9
};

const pngMarkerIcons: Record<string, any> = {
  png_end_D51D22,
  png_end_117170,
  png_end_9E9391,
  png_end_C11875,
  png_end_A94D13,
  png_end_2804AF,
  png_end_0DA4ED,
  png_end_EB24B1,
  png_end_6C67DF,
  png_end_0B5F9E,
  png_end_6EE736,
  png_end_B83B35,
  png_end_C7AA90,
  png_end_8AD2BE,
  png_end_DF7770,
  png_end_0E2636,
  png_end_EBFF00,
  png_end_A6AAC5,
  png_end_9DAF39,
  png_end_6B7783,
  png_end_18C16F,
  png_end_DB6DD4,
  png_end_90EB24,
  png_end_B8B035,
  png_end_9C13A9,
};

export const getMarkerIcon = ( color: string, tag: string, type?: 'png' ) => {
  const colorKey     = String( color ).toUpperCase().replace( '#', '' );
  const iconKey      = ( type ? `${type}_` : '' ) + `${tag}_${colorKey}`;
  const sourceObject = ( 'png' === type ? pngMarkerIcons : markerIcons );

  if ( !( iconKey in sourceObject ) ) {
    return sourceObject[Object.keys( sourceObject )[0]];
  }

  return sourceObject[iconKey];
};

export const getDefaultResidenceMarkerIcon = () => getMarkerIcon( '0E2636', 'residence' );