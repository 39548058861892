import { unitModels } from "../models/unitModels";
import { formatNumber } from ".";

export const handleNumbersWithAnyType = (num: any) => {
  if (typeof num === "number") return num;
  if (isNaN(num)) return undefined;
  if (typeof num === "string") {
    const parsedNum = parseFloat(num);
    if (isNaN(parsedNum)) return undefined;
    return parsedNum;
  }
};

export const numberToDistance = (distanceInMeters: number) => {
  if (distanceInMeters < unitModels.oneKilometerInMeters) {
    return `${formatNumber( distanceInMeters, 1 )} ${unitModels.unitsEnum.METERS}`;
  } 
  
  const distanceInKilometers = distanceInMeters / unitModels.oneKilometerInMeters;

  return `${formatNumber( distanceInKilometers, 1 )} ${unitModels.unitsEnum.KILOMETERS}`;
};

export const percentToString = ( percent: number | string ) => {
  percent = ( Number( percent ) || 0 );

  return formatNumber( ( percent * 100 ), 2, { alwaysShowDecimals: true } ) + '%';
};

export const calcPercentage = ( amount: number | string, total: number | string ) => {
  const percent = ( Number( amount ) || 0 ) / ( Number( total ) || 1 );

  if ( percent > 1 ) {
    return 0;
  }

  return percent;
};