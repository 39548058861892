import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getAppLanguage } from "../config";

import main_en from "./translations/en/main.json";
import main_es from "./translations/es/main.json";

const initI18n = () => {
	i18n
		.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			resources: {
				en: { main: main_en },
				es: { main: main_es },
			},
			lng: getAppLanguage(), // if you're using a language detector, do not define the lng option
			fallbackLng: "en",

			interpolation: {
				escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
			},
		});
};

export default initI18n;
