import { PeriodState } from "../store/slices/periodSlice";
import { isObject } from "../utils";

export const periodToQueryParams = (period: PeriodState) => {
  return {
    start_date: period.start,
    end_date: period.end,
  };
};

export const filtersToQueryParams = ( filters: Record<string, any> ) => {
  const queryParams: Record<string, any> = {};

  for ( const key in filters ) {
    // Handles the objects produced by the GroundDateRangePicker and GroundRangeSlider.
    if ( isObject( filters[key] ) ) {
      for ( const property in filters[key] ) {
        queryParams[key + '_' + property] = filters[key][property];
      }
    }
    else {
      queryParams[key] = filters[key];
    }
  }

  return queryParams;
};

export const getFallbackErrorMessage = ( error: any ) => {
  let message    = 'Request failed with error: ';
  const errorObj = ( error.response ? error.response.data : error );

  if ( typeof errorObj === 'string' ) {
    message += `"${errorObj}"`;
  }
  else if ( errorObj.message ) {
    message += `"${errorObj.message}"`;
  }
  else {
    return 'An unexpected error occured';
  }

  return message;
};
