import { formatNumber } from ".";
import i18n from "i18next";


export interface PdfChartOptionDataItem {
  value: number;
  name: string;
  color?: string;
}

const maybeForceTitleWrap = ( 
  title: string, 
  maxCharsPerLine = 70, 
  minWordsLastLine = 2 
): { wrappedTitle: string; numOfLines: number } => {
  if ( title.length <= maxCharsPerLine ) {
    return {
      wrappedTitle: title,
      numOfLines: 1
    };
  }

  const words = title.split( ' ' );
  const lines: Array<string[]> = [];
  let currentLine: string[]    = [];
  let currentCharCount         = 0;
  
  words.map(( word ) => {
    const wordPlusSpaceLength = word.length + 1;
    const partialCount        = currentCharCount + wordPlusSpaceLength;

    if ( partialCount <= maxCharsPerLine ) {
      currentCharCount += wordPlusSpaceLength;

      currentLine.push( word );
    }
    else {
      lines.push( currentLine );

      currentLine      = [ word ];
      currentCharCount = 0;
    }
  });

  lines.push( currentLine );

  const lastLine = lines[lines.length - 1];

  if ( lastLine.length < minWordsLastLine ) {
    const numWordsToPop         = minWordsLastLine - lastLine.length;
    const currentSecondLastLine = lines[lines.length - 2];
    const newSecondLastLine     = currentSecondLastLine.slice( 0, currentSecondLastLine.length - numWordsToPop );
    const newLastLine           = currentSecondLastLine.slice( -1 * numWordsToPop ).concat( lastLine );

    lines[lines.length - 1] = newLastLine;
    lines[lines.length - 2] = newSecondLastLine;
  }

  const wrappedTitle = lines.map( line => {
    return line.join( ' ' );
  }).join( '\n' );

  return {
    wrappedTitle,
    numOfLines: lines.length
  }
};

export const getPdfPieChartOption = ( title: string, data: PdfChartOptionDataItem[] ) => {
  const processedData = data.map( item => {
    const { color, ...rest } = item;

    if ( color ) {
      return {
        ...rest,
        itemStyle: { color }
      };
    }

    return rest;
  });

  let formatter = ( params: any ) => {
    let name = params.data.name;

    if ( name.length > 20 ) {
      name = name.substring( 0, 20 ) + '...';
    }

    return [
      name,
      `{value|${formatNumber( params.data.value )} ( ${formatNumber( params.percent )}% )}`
    ].join( '\n' );
  };

  if ( !processedData.length ) {
    formatter = ( params: any ) => {
      return params.data.name;
    };

    processedData.push({
      value: 0, 
      name: i18n.t( 'errors.noData', { ns: 'main' } ), 
      itemStyle:{
        color: '#ccc'
      }
    });
  }

  const wrappedTitleData = maybeForceTitleWrap( title );

  return {
    title: {
      text: wrappedTitleData.wrappedTitle,
      left: '0%',
      top: '5%',
      textStyle: {
        fontSize: 30,
        fontWeight: 'normal',
        lineHeight: 36
      }
    },
    legend: {
      orient: 'vertical',
      top: '25%',
      left: '78%',
      textStyle: {
        fontSize: 18,
        lineHeight: 22
      },
      formatter: ( name: string ) => {
        const nameData = maybeForceTitleWrap( name, 20, 1 );

        return nameData.wrappedTitle;
      }
    },
    series: [
      {
        type: 'pie',
        radius: ( wrappedTitleData.numOfLines > 1 ? '60%' : '55%' ),
        right: '25%',
        top: ( wrappedTitleData.numOfLines > 1 ? '10%' : undefined ),
        itemStyle: {
          shadowBlur: 50,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        },
        label: {
          fontSize: 16,
          lineHeight: 26,
          formatter,
          rich: {
            value: {
              fontWeight: 'bold',
              fontSize: 20
            }
          }
        },
        data: processedData,
      }
    ]
  };
};

export const getPdfBarChartOption = ( 
  title: string, 
  data: PdfChartOptionDataItem[], 
  options: {
    colors: string[];
  }
) => {
  const labels: string[] = [];

  const formatter = ( params: any ) => {
    return formatNumber( params.data.value );
  };

  const series = data.map(( item, idx ) => {
    const seriesData: Array<any> = [];

    for ( let i = 0; i < data.length; i++ ) {
      if ( i === idx ) {
        seriesData.push({
          value: item.value,
          itemStyle: {
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 10
          }
        })
      }
      else {
        seriesData.push( undefined );
      }
    }

    labels.push( item.name );

    return {
      name: item.name,
      type: 'bar',
      stack: 'stack',
      barMaxWidth: 50,
      label: {
        show: true,
        position: 'top',
        fontSize: 16,
        formatter
      },
      data: seriesData
    };
  });

  const wrappedTitleData = maybeForceTitleWrap( title );

  return {
    grid: {
      top: '20%',
      left: '5%',
      right: '25%'
    },
    title: {
      text: wrappedTitleData.wrappedTitle,
      left: '0%',
      top: '5%',
      textStyle: {
        fontSize: 30,
        fontWeight: 'normal'
      }
    },
    legend: {
      show: true,
      data: labels,
      orient: 'vertical',
      top: 'center',
      left: '80%',
      textStyle: {
        fontSize: 16
      },
      formatter: ( name: string ) => {
        return name.replaceAll( '\n', ' ' );
      }
    },
    color: options.colors,
    xAxis: {
      type: 'category',
      data: labels
    },
    yAxis: {
      type: 'value'
    },
    series
  }
};