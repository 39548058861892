import { camelize, isObject } from ".";
import { getAppLanguage } from "../config";
import { languageModels } from "../models/languageModels";


export const attemptTranslation = ( str: string, t_fn: languageModels.Tfn, context?: string ) => {
  context = ( context || 'common' );
  
  let i18nKey        = '';
  let translation    = '';
  const camelizedStr = camelize( str );

  for ( let i = 1; i <= 2; i++ ) {
    i18nKey     = context + '.' + camelizedStr;
    translation = t_fn( i18nKey );

    if ( i18nKey !== translation ) {
      return translation;
    }

    context = 'common';
  }
  
  return str;
};

/**
 * Parses an i18n object received from back-end.
 */
export const i18nObjectToString = ( i18nObject: Record<string, string> ) => {
  const lang = getAppLanguage();

  if ( isObject( i18nObject ) && i18nObject[lang] ) {
    return i18nObject[lang];
  }
  
  return '';
};
