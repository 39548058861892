import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAppTheme } from "../../../hooks";

import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";


const AnimatedBar = styled.div`
  @keyframes bounce {
    0%, 100% {
      margin-left: 0;
    }
    50% {
      margin-left: 80%;
    }
  }

  border-radius: 3px;
  border: 1px solid  ${props => props.theme.palettes.borderTertiary};
  height: 1rem;
  overflow: hidden;
  position: relative;

  &::before {
    animation: bounce 2s linear infinite;
    background-color: ${props => props.theme.palettes.secondaryAlpha90}; 
    content: "";
    display: block;
    height: 1rem;
    position: absolute;
    width: 20%;
  }
`;


const BrowsingLockedModal = () => {
  const { t } = useTranslation( 'main' );
  const theme = useAppTheme();

  return (
    <ConfirmationModal
      isBlockingModal
      content={(
        <>
          <p>{t( 'modals.browsingLockedMessage' )}</p>
          <AnimatedBar theme={theme} />
        </>  
      )}
    />
  );
};

export default BrowsingLockedModal;