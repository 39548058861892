import {
  Svg,
  Path
} from "@react-pdf/renderer";

import { PdfSvgIconInterface } from "./PdfSvgIconInterface";


const PdfSvgIcon = ({
  name,
  color,
  style,
  theme
}: PdfSvgIconInterface) => {
  const icon = ( name in theme.svgIconsData ? theme.svgIconsData[name] : null );

  return ( icon && (
    <Svg viewBox={icon.viewbox} style={style}>
      <Path
        fill={theme.palettes[color]}
        transform={icon.transform}
        d={icon.path}
      />
    </Svg>
  ));
};

export default PdfSvgIcon;