import { Link } from "react-router-dom";
import styled from "styled-components";
import classNames from "classnames";
import { useAppTheme } from "../../hooks";

import { RouterLinkInterface } from "./RouterLinkInterface";
import "./RouterLink.scss";

const LinkWrapper = styled.span`
  a {
    color: ${( props: any ) => props.theme.palettes.secondary};

    &:active {
      color: ${( props: any ) => props.theme.palettes.secondaryLight};
    }
  }
`;

const RouterLink = ({ to, className, children, ...rest }: RouterLinkInterface) => {
  const theme = useAppTheme();

  return (
    <LinkWrapper
      theme={theme}
      className={classNames(
        'router-link flex-inline flex-vcenter',
        className
      )}
    >
      <Link
        to={to}
        {...rest}
      >
        {children}
      </Link>
    </LinkWrapper>
  );
};

export default RouterLink;