// import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppTheme } from "../../hooks";
import { getAppConfig } from "../../config";

import "./Footer.scss";


const MainFooter = styled.footer`
  border-top-color: ${( props: any ) => props.theme.palettes.borderSecondary};
`;

const Footer = () => {
  const theme = useAppTheme();
  // const { t }    = useTranslation( 'main' );
  // const navigate = useNavigate();

  if ( !getAppConfig( 'showFooter', true ) ) {
    return null;
  }

  return (
    <MainFooter
      theme={theme}
      className='main-footer flex flex-vcenter'
    >
      <nav>
      </nav>
      <p className='copyrights'>
        {getAppConfig( 'copyrightsNotice' )}
      </p>
    </MainFooter>
  );
};

export default Footer;