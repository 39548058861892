import { Controller } from "react-hook-form";
import { SelectInterface } from "./SelectInterface";

import { GroundSelect } from "@greenvulcano/ground-react";


const Select = ({
  name = '',
  defaultValue = '',
  control,
  errors = {},
  rules = {},
  ...rest
}: SelectInterface ) => {

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => {
        return (
          <GroundSelect
            erratum={Boolean( errors[name] )}
            value={value}
            onChange={onChange}
            {...rest}
          />
        );
      }}
    />
  );
};

export default Select;