import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { applyErrorBoundary } from "../../../utils/error-boundary";
import { areCookiesEnabled } from "../../../utils";
import { useConfirmationModal } from "../../../hooks";
import { AuthService } from "../../../services/AuthService";

import {
  GroundButton,
  GroundButtonTypes
} from "@greenvulcano/ground-react";
import TextInput from "../../../shared-components/RHF-input-wrappers/TextInput/TextInput";
import LoginTemplate from "../../../shared-components/LoginTemplate/LoginTemplate";
import RouterLink from "../../../shared-components/RouterLink/RouterLink";
import MoodyText from "../../../shared-components/MoodyText/MoodyText";
import CookiesDisabledModal from "../../../shared-components/confirmation-modals/CookiesDisabledModal/CookiesDisabledModal";

import "./Login.scss";
import { setGoogleIsLoaded } from "../../../store/slices/mapSlice";
import { useAppDispatch } from "../../../store/hooks";


interface FormValues {
  username: string;
  password: string;
};

const Login = () => {
  const { t }             = useTranslation( 'main' );
  const navigate          = useNavigate();
  const confirmationModal = useConfirmationModal();
  const [ serverError, setServerError ] = useState( '' );
  const {
    control, 
    handleSubmit,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>();
  const appDispatch = useAppDispatch();
  const username = watch( 'username' );
  const password = watch('password');
  

  
  useEffect(() => {
    const googleIsLoaded = !!window.google?.maps
    if (googleIsLoaded) {
      appDispatch(setGoogleIsLoaded(googleIsLoaded))
    }
  },[appDispatch])

  const onSubmitHandler: SubmitHandler<FormValues> = async ( data ) => {
    if ( !areCookiesEnabled() ) {
      return confirmationModal.open( <CookiesDisabledModal /> );
    }

    try {
      await AuthService.login( data );

      navigate( '/' );
    }
    catch ( error: any ) {
      setServerError( t( error ) as string );
    }
  };

  useEffect(() => {
    if ( username || password ) {
      setServerError( '' );
    }
  }, [ username, password ] );

  return (
    <LoginTemplate>
      <form
        className='login-form'
        onSubmit={handleSubmit( onSubmitHandler )}
      >
        <h3>{t( 'login.title' )}</h3>
        <p>{t( 'login.description' )}</p>
        <div className='form-controls'>
          <TextInput
            fluid
            autoValidate
            timeoutAutoValidate
            key='username'
            name='username'
            control={control}
            errors={errors}
            rules={{ required: t( 'common.usernameRequired' ) as string }}
            readOnly={isSubmitting}
            placeholder={t( 'common.username' ) as string}
          />
          <TextInput
            fluid
            key='password'
            name='password'
            type='password'
            control={control}
            errors={errors}
            rules={{ required: t( 'common.passwordRequired' ) as string }}
            readOnly={isSubmitting}
            placeholder={t( 'common.password' ) as string}
          />
          <div className='submit-wrapper'>
            <MoodyText className='error-message'>{serverError}</MoodyText>
            <GroundButton
              fluid
              type={GroundButtonTypes.submit}
              color='secondary'
              height='medium'
              text={t( 'login.submitText' )}
              isLoading={isSubmitting}
            />
          </div>
          <RouterLink 
            to='/recovery-password'
            className='link-to-recovery flex flex-hcenter'
          >
            {t( 'login.linkText' )}
          </RouterLink>
        </div>
      </form>
    </LoginTemplate>
  );
};

export default applyErrorBoundary( Login );