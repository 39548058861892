import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils";
import { calcPercentage, percentToString } from "../../../utils/numbers";
import { appConfig } from "../../../config/app-config/app-config";
import { policyModels } from "../../../models/policyModels";

import { Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import PdfTemplate from "../PdfTemplate/PdfTemplate";
import { getPdfTempalteStyles } from "../PdfTemplate/PdfTemplate";
import { PoliciesSummaryChartIDs, PoliciesSummaryPdfInterface } from "./PoliciesSummaryPdfInterface";
import PdfChart from "../PdfChart/PdfChart";


const styles = StyleSheet.create({
  secondaryTitle: {
    fontSize: 12,
    opacity: 0.5,
    textTransform: 'capitalize'
  }
});

const getTotalAnalized = ( data: policyModels.SummaryReportData, fraudProbID: 'policy_type' | 'residence' ) => {
  const totLowProb = ( Number( data[`tot_low_prob_${fraudProbID}`] ) || 0 );
  const totMediumProb = ( Number( data[`tot_medium_prob_${fraudProbID}`] ) || 0 );
  const totHighProb = ( Number( data[`tot_high_prob_${fraudProbID}`] ) || 0 );

  return ( totLowProb + totMediumProb + totHighProb );
};

const PoliciesSummaryPdf = ({
  theme,
  data,
  chartRefs
}: PoliciesSummaryPdfInterface ) => {
  const { t }            = useTranslation( 'main' );
  const templateStyles   = getPdfTempalteStyles({ theme });
  const pdfTemplateProps = {
    theme,
    docTitle: t( 'pdf.policiesSummary.headerText' ),
  };

  const totAnalyzedPolicyType     = getTotalAnalized( data, 'policy_type' );
  const totAnalyzedPolicyTypePerc = calcPercentage( data.tot_high_prob_policy_type, totAnalyzedPolicyType );
  const totAnalyzedResidence      = getTotalAnalized( data, 'residence' );
  const totAnalyzedResidencePerc  = calcPercentage( data.tot_high_prob_residence, totAnalyzedResidence );

  const charts = {} as Record<PoliciesSummaryChartIDs, ReactNode>;
  
  for ( const refID in chartRefs ) {
    const element = chartRefs[refID as PoliciesSummaryChartIDs].current;

    if ( !element ) {
      continue;
    }

    charts[refID as PoliciesSummaryChartIDs] = <PdfChart element={element} />;
  }

  const renderSectionTitle = ( title: string, secondaryTitle?: string, moreStyles?: Style ) => {
    return (
      <View style={[
        templateStyles.title,
        {
          flexDirection: 'column',
          alignItems: 'flex-start'
        },
        ( moreStyles || {} )
      ]}>
        {secondaryTitle ? (
          <Text style={styles.secondaryTitle}>
            {secondaryTitle}
          </Text>
        ) : null }
        <Text>
          {title}
        </Text>
      </View>
    );
  };

  return (
    <Document>
      <Page size='A4'>
        <PdfTemplate 
          pageNum={1}
          {...pdfTemplateProps}
        >
          <View>
            {renderSectionTitle(
              t( 'pdf.executiveSummary' ),
              t( 'common.policyTypeFraud' ) as string
            )}
            <Text>
              {t( 'pdf.policiesSummary.policyTypeIntroText.pt1' )}
              {' '}
              <Text style={templateStyles.bold}>
                {formatNumber( totAnalyzedPolicyType )}
                {' '}
                {t( 'pdf.policiesSummary.policyTypeIntroText.pt2' )}
              </Text>
              {' '}
              {t( 'pdf.policiesSummary.policyTypeIntroText.pt3' )}
              {' '}
              <Text style={templateStyles.bold}>
                {formatNumber( data.tot_high_prob_policy_type )}
                {' ('}
                {percentToString( totAnalyzedPolicyTypePerc )}
                {')'}
              </Text>
              {' '}
              {t( 'pdf.policiesSummary.policyTypeIntroText.pt4' )}
              {' '}
              <Text style={templateStyles.bold}>
                {formatNumber( data.km_policy_type_high_prob )}
                {' km '}
                {t( 'pdf.policiesSummary.policyTypeIntroText.pt5', { 
                  numDays: appConfig.numOfDaysAnalyzedForPdfPoliciesSummary.policy_type
                })}
              </Text>
            </Text>
          </View>
          <View>
            {renderSectionTitle(
              t( 'pdf.policiesSummary.statisticsHeaderText' ),
              undefined,
              templateStyles.titleBigMargin
            )}
            {charts.fraudProbPolicyType}
          </View>
        </PdfTemplate>
      </Page>
      <Page size='A4'>
        <PdfTemplate 
          pageNum={2}
          {...pdfTemplateProps}
        >
          {charts.mxStatesPolicyType}
          {charts.vehicleModelsPolicyType}
        </PdfTemplate>
      </Page>
      <Page size='A4'>
        <PdfTemplate 
          pageNum={3}
          {...pdfTemplateProps}
        >
          {charts.kmTraveled}
        </PdfTemplate>
      </Page>
      <Page size='A4'>
        <PdfTemplate 
          pageNum={4}
          {...pdfTemplateProps}
        >
          <View>
            {renderSectionTitle(
              t( 'pdf.executiveSummary' ),
              t( 'common.residenceFraud' ) as string
            )}
            <Text>
              {t( 'pdf.policiesSummary.residenceIntroText.pt1' )}
              {' '}
              <Text style={templateStyles.bold}>
                {formatNumber( totAnalyzedResidence )}
                {' '}
                {t( 'pdf.policiesSummary.residenceIntroText.pt2' )}
              </Text>
              {' '}
              {t( 'pdf.policiesSummary.residenceIntroText.pt3' )}
              {' '}
              <Text style={templateStyles.bold}>
                {formatNumber( data.tot_high_prob_residence )}
                {' ('}
                {percentToString( totAnalyzedResidencePerc )}
                {')'}
              </Text>
              {' '}
              {t( 'pdf.policiesSummary.residenceIntroText.pt4' )}
              {' '}
              <Text style={templateStyles.bold}>
                {t( 'pdf.policiesSummary.residenceIntroText.pt5' )}
                {' '}
                {percentToString( data.out_residence_high_prob_perc )}
              </Text>
              {' '}
              {t( 'pdf.policiesSummary.residenceIntroText.pt6' )}
            </Text>
          </View>
          <View>
            {renderSectionTitle(
              t( 'pdf.policiesSummary.statisticsHeaderText' ),
              undefined,
              templateStyles.titleBigMargin
            )}
            {charts.fraudProbResidence}
          </View>
        </PdfTemplate>
      </Page>
      <Page size='A4'>
        <PdfTemplate 
          pageNum={5}
          {...pdfTemplateProps}
        >
          {charts.mxStatesResidence}
          {charts.vehicleModelsResidence}
        </PdfTemplate>
      </Page>
      <Page size='A4'>
        <PdfTemplate 
          pageNum={6}
          {...pdfTemplateProps}
        >
          {charts.tripsOutResidencePerc}
        </PdfTemplate>
      </Page>
    </Document>
  );
};

export default PoliciesSummaryPdf;
