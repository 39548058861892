import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import ErrorPage from "./ErrorPage/ErrorPage";
import Login from "./routes/Login/Login";
import PasswordRecovery from "./routes/PasswordRecovery/PasswordRecovery";
import Root from "./routes/Root/Root";
// import Dashboard from "./routes/Dashboard/Dashboard";
import Claims from "./routes/Claims/Claims";
import ClaimDetails from "./routes/ClaimDetails/ClaimDetails";
import Trends from "./routes/Trends/Trends";
import Profiles from "./routes/Profiles/Profiles";
import ProfileDetails from "./routes/ProfileDetails/ProfileDetails";
import PdfDocument from "./routes/PdfDocument/PdfDocument";
import GoogleAnalyticsReporter from "../utils/GoogleAnalyticsReporter";
import { HotjarStateChange } from "../utils/HotjarStateChange";

export const AppRouter = () => {

  return(
  <BrowserRouter>
    <GoogleAnalyticsReporter />
    <HotjarStateChange />
    <Routes>
      <Route path="/" element={<Navigate to="/admin" />} />
      <Route path="/admin" element={<Root />}>
        {/* <Route path="dashboard" element={<Dashboard />} /> */}
        <Route path="claims" element={<Claims />} />
        <Route path="claims/:id" element={<ClaimDetails />} />
        <Route path="profiles" element={<Profiles />} />
        <Route path="profiles/:id_voucher" element={<ProfileDetails />} />
        <Route path="analytics" element={<Trends />} />
        <Route path="pdf/:id" element={<PdfDocument />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/recovery-password" element={<PasswordRecovery />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  </BrowserRouter>
)};
