import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { RootState } from "..";

export interface PeriodState {
  start: string;
  end: string;
}

const today = moment.utc().format('YYYY-MM-DD');
export const immutablePeriods = {
  global: {
    start: moment.utc().subtract(1, "months").format("YYYY-MM-DD"),
    end: today,
  },
  profiles: {
    start: moment.utc().subtract(1, "days").format("YYYY-MM-DD"),
    end: today,
  },
  trends: {
    start: moment.utc().subtract(15, "days").format("YYYY-MM-DD"),
    end: today,
  },
  newClaim: {
    start: moment.utc().subtract(0, "days").format("YYYY-MM-DD"),
    end: today,
  },
};

const initialState = {
  immutablePeriods,
  global: structuredClone(immutablePeriods.global),
  profiles: structuredClone(immutablePeriods.profiles),
  trends: structuredClone(immutablePeriods.trends),
  claimDetail: {},
  profileDetail: {},
};

export const periodSlice = createSlice({
  name: "period",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<{ id?: string, period: PeriodState }>) => {
      const { id = 'global', period } = action.payload;
      return {
        ...state,
        [id]: period
      };
    },
  },
});

export const { update: updatePeriod } = periodSlice.actions;

export const selectPeriod =
  (id = "global") =>
  (state: RootState) =>
    state.period[id as keyof typeof initialState] as PeriodState;

export const selectImmutablePeriod =
  (id = "global") =>
  (state: RootState) =>
    state.period.immutablePeriods[
      id as keyof typeof immutablePeriods
    ] as PeriodState;

export default periodSlice.reducer;
