import axios from "axios";
import { getApiEndpoint } from "../config";
import { AuthService } from "./AuthService";

import { PeriodState } from "../store/slices/periodSlice";
import { periodToQueryParams } from "./utils";
import { tripModels } from "../models/tripModels";


export const TripsService = {
  getTrips: async ({
    id_voucher,
    period,
    claim_id,
    max_on_top_with_coords = 1,
  }: {
    id_voucher: string | number;
    period: PeriodState;
    claim_id?: string | number;
    max_on_top_with_coords?: number;
  }) => {
    try {
      const { data: responseData } = await axios.get( 
        getApiEndpoint( 'trips' ),
        { 
          params: {
            id_voucher,
            order: 'desc',
            claim_id: ( claim_id || undefined ),
            max_on_top_with_coords: ( max_on_top_with_coords || undefined ),
            ...periodToQueryParams( period )
          },
          ...AuthService.getAuthHeaders()
        }
      );

      return Promise.resolve( responseData.data as tripModels.TripData[] );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  },
  getTripCoords: async ( tripID: string | number, period: PeriodState ) => {
    try {
      const { data: responseData } = await axios.get( 
        getApiEndpoint( 'tripCoords', { id: tripID } ),
        { 
          params: periodToQueryParams( period ),
          ...AuthService.getAuthHeaders()
        }
      );
      
      return Promise.resolve( responseData.data as tripModels.CoordsQueryResponseData );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  },
  getLastTripDate: async ( id_voucher: string | number ): Promise<any> => {
    try {
      const { data: responseData } = await axios.get( 
        getApiEndpoint( 'lastTripDate', { id: id_voucher } ),
        { 
          ...AuthService.getAuthHeaders()
        }
      );
      
      return Promise.resolve( responseData.data );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  },
};
