import { languageModels } from "./languageModels";
import { unitModels } from "./unitModels";

export namespace claimModels {
  export enum noteTypeEnum {
    NUMBER = "number",
    STRING = "string",
    DATE = "date",
  }

  type noteLabel = {
    en: string;
    es: string;
  };

  export function isNoteLabelKey(
    key: string
  ): key is languageModels.language {
    return key === "en" || key === "es";
  }
  export type fraudProbabilityNote = {
    label: noteLabel;
    value: number | string | Date;
    type: noteTypeEnum;
    key: string;
    unit?: unitModels.unitsEnum;
  };

  export const fraudProbabilityColorsMap ={
    "1": "#488c21",
    "2": "#f46A00",
    "3": "#dd0546",
  }

  export const claimTypeMap = {
    "1": "crash",
    "2" : "theft"
  }

  export const claimStatusMap = {
    "1": "claim_is_true",
    "2": "claim_is_false",
    "3": "to_investigate_more",
  };

  export type fraudProbabilityNoteArray = fraudProbabilityNote[];

  export interface ClaimPosition {
    type: string;
    coordinates: [ number, number ];
  }

  export interface ClaimArea {
    type: string;
    crs: Record<string, any>;
    coordinates: [ number, number ][][][];
  }

  export interface Single {
    claim_id: string;
    claim_date: string;
    policyholder: string;
    insurance_id: string;
    agent_id: string | null;
    agent_name: string | null;
    agency_id: string | null;
    agency_name: string | null;
    anomaly_type: string | null;
    claim_cost_range_id: string | null;
    fraud_probability_id: string;
    claim_amount: string | null;
    claim_type_id: string;
    policy_id: string;
    profile_id: string;
    policy_type: string | null;
    vehicle_type_id: string;
    severity: string | null;
    claim_position: ClaimPosition | null;
    zip_code: string;
    claim_area: ClaimArea | null;
    id_operator: string;
    created_manually: string;
    area_of_the_entity: string | null;
    entity_of_country: string | null;
    id_voucher: string;
    id_claim_status: string | null;
    vehicle_description: string | null;
    zipcode: string;
    fraud_probability_notes: string | null;
    sinister_hour: string;
    sinister_hour_from: string | null;
    sinister_hour_to: string | null;
    make: string;
    year: string;
    model: string;
    plate: string;
    vin: string;
    note: string | null;
    location: string;
    fraud_probability_radius: string;
    address: string;
  }

  export interface SingleProcessed extends Omit<Single, 'policy_type' | 'fraud_probability_notes'> {
    policy_type: Record<string, any> | null;
    fraud_probability_notes: fraudProbabilityNoteArray | null;
  }

  export interface ListDataItem {
    claim_id: string;
    policyholder: string;
    claim_date: string;
    insurance_id: string;
    agent_id: string | null;
    agency_id: string | null;
    claim_cost_range_id: string | null;
    fraud_probability_id: string;
    id_voucher: string;
    policy_id: string;
    vehicle_type_id: string;
    claim_type: string;
    severity: string | null;
    plate: string;
    vin: string;
    sinister_hour: string | null;
    sinister_hour_from: string | null;
    sinister_hour_to: string | null;
    id_claim_status: string | null;
    area_of_the_entity: string | null;
    entity_of_country: string | null;
    location: string;
  }

  export interface ListResponse {
    data: ListDataItem[];
    totalItems: number;
  }

  export interface SummaryReportData {
    first_claim_date: string;
    last_claim_date: string;
    total: string;
    tot_low_prob: string;
    tot_medium_prob: string;
    tot_high_prob: string;
    tot_not_calc_prob: string;
    km_high_prob: string;
    states_high_prob: Array<{
      state: string;
      claims_count: string;
    }>;
    policy_types_high_prob: Array<{
      policy_type: string;
      claims_count: string;
    }>;
  }

  export interface CsvReportDataItem {
    claim_id: string;
    policyholder: string;
    claim_date: string;
    insurance_id: string;
    policy_id: string;
    policy_type: string | null;
    claim_type: string;
    fraud_probability_id: string;
    insurance_company: string;
    municipality: string;
    state: string;
    sinister_hour: string | null;
    sinister_hour_from: string | null;
    sinister_hour_to: string | null;
    id_claim_status: string | null;
    id_client: string;
    vin: string;
    model: string;
    make: string;
    plate: string;
    year: string;
    location: string;
    address: string;
  }

  export interface CsvReportParsedDataItem extends Omit<CsvReportDataItem, 'policy_type'> {
    policy_type: Record<string, string> | null;
  }
}
