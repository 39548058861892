/**
 * Whenever the values below are changed, the following must also be updated:
 * - icons in 'src/images/colorcoded-markers'
 * - icons in 'src/images/colorcoded-markers-png'
 * - src/utils/colorcoded-markes.ts
 */


export const heartbeatColors: string[] = [
  // Red. First color is reserved and must be red.
  // If changed, even the first color of './trip-colors.ts' must me updated.
  '#D51D22',

  '#18C16F',
  '#DB6DD4',
  '#90EB24',
  '#B8B035',
  '#9C13A9',
];