import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAppTheme, useConfirmationModal } from "../../../hooks";
import { applyErrorBoundary } from "../../../utils/error-boundary";
import { secondsToDuration } from "../../../utils/time";
import { i18nObjectToString } from "../../../utils/i18n";
import { ProfilesService } from "../../../services/ProfilesService";
import { getProfileDetailPeriod } from "../../../utils/period";
import { ClaimsService } from "../../../services/ClaimsService";
import { AxiosError } from "axios";
import { getAppConfig } from "../../../config";
import { policyPageModels } from "../../../models/policyPageModels";
import { appConfig } from "../../../config/app-config/app-config";
import { stripNulls } from "../../../utils/sanitization";
import { languageModels } from "../../../models/languageModels";
import { downloadCsv } from "../../../utils/csv";
import { downloadPdf } from "../../../utils/pdf";
import { PoliciesSummaryChartRefs } from "../../../shared-components/pdf-documents/PoliciesSummaryPdf/PoliciesSummaryPdfInterface";
import { policyModels } from "../../../models/policyModels";
import { 
  getFraudProbPolicyTypePieChartOption,
  getFraudProbResidencePieChartOption,
  getMxStatesPolicyTypePieChartOption,
  getMxStatesResidencePieChartOption,
  getVehicleModelsPolicyTypePieChartOption,
  getVehicleModelsResidencePieChartOption,
  getKmTraveledBarChartOption,
  getTripsOutResidencePercBarChartOption
} from "./chart-option";

import { getCsvColumns } from "./csv-columns";
import { PeriodState } from "../../../store/slices/periodSlice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { selectPeriod, updatePeriod } from "../../../store/slices/periodSlice";
import { selectSetting } from '../../../store/slices/settingsSlice';
import { selectFilters, updateFilters } from "../../../store/slices/filtersSlice";
import { selectDropdownOptions } from "../../../store/slices/dropdownsSlice";
import { lockBrowsing, unlockBrowsing } from "../../../store/slices/browsingLockedSlice";

import { 
  useGroundToast, 
  GroundToastVariant,
  GroundToastType,
  GroundButton
} from "@greenvulcano/ground-react";
import UtilityHeader from "../../../shared-components/UtilityHeader/UtilityHeader";
import MainContentWrapper from "../../../shared-components/MainContentWrapper/MainContentWrapper";
import TableArea from "../../../shared-components/TableArea/TableArea";
import DrawerContent from "./DrawerContent/DrawerContent";
import LevelBudge from "../../../shared-components/LevelBudge/LevelBudge";
import PoliciesSummaryPdf from "../../../shared-components/pdf-documents/PoliciesSummaryPdf/PoliciesSummaryPdf";
import ChartLoaderForPdf from "../../../shared-components/ChartLoaderForPdf/ChartLoaderForPdfLoader";
import DownloadPoliciesModal from "../../../shared-components/confirmation-modals/DownloadPoliciesModal/DownloadPoliciesModal";

import "./Profiles.scss";


const getTableColumns = ({
  t,
  eventsQueryIsLoading,
  createClaimHandler
}: {
  t: languageModels.Tfn;
  eventsQueryIsLoading: boolean;
  createClaimHandler: ( id_voucher: string ) => Function;
}): policyPageModels.PolicyTableColumn[] => {
  const maybeShowSkeleton = ( value: string ) => {
    if ( eventsQueryIsLoading  ) {
      return ( <div className='cell-skeleton' />);
    }

    return ( value || '–' );
  };

  const possibleColumns: { [key in policyPageModels.PolicyTableColumns]: policyPageModels.PolicyTableColumn } = {
    [policyPageModels.PolicyTableColumns.profileId] : {
      id: policyPageModels.PolicyTableColumns.profileId,
      isSortable: true,
      headContent: t( 'common.id' )
    },
    [policyPageModels.PolicyTableColumns.policyHolder] : {
      id: policyPageModels.PolicyTableColumns.policyHolder,
      isSortable: true,
      headContent: t( 'common.policyholderName' )
    },
    [policyPageModels.PolicyTableColumns.policyId]:{
      id:policyPageModels.PolicyTableColumns.policyId,
      isSortable: true,
      headContent: t( 'common.policyNumber' )
    },
    [policyPageModels.PolicyTableColumns.policyType] :{
      id: policyPageModels.PolicyTableColumns.policyType,
      headContent: t( 'common.policyType' ),
      renderCellContent: ( value: any ) => {
        return i18nObjectToString( value );
      }
    },
    [policyPageModels.PolicyTableColumns.plate] : {
      id: policyPageModels.PolicyTableColumns.plate,
      isSortable: true,
      headContent: t( 'common.plate' ),
    },
    [policyPageModels.PolicyTableColumns.model] : {
      id: policyPageModels.PolicyTableColumns.model,
      isSortable: true,
      headContent: t( 'common.model' ),
    },
    [policyPageModels.PolicyTableColumns.year] : {
      id: policyPageModels.PolicyTableColumns.year,
      isSortable: true,
      headContent: t( 'common.year' ),
    },
    [policyPageModels.PolicyTableColumns.make] : {
      id: policyPageModels.PolicyTableColumns.make,
      isSortable: true,
      headContent: t( 'common.brand' ),
    },
    [policyPageModels.PolicyTableColumns.vin] : {
      id: policyPageModels.PolicyTableColumns.vin,
      isSortable: true,
      headContent: t( 'common.vin' ),
    },
   [ policyPageModels.PolicyTableColumns.address] :{
      id: policyPageModels.PolicyTableColumns.address,
      headContent: t( 'common.residence' ),
      renderCellContent: ( value: string ) => {
        return stripNulls( value );
      }
    },
   [ policyPageModels.PolicyTableColumns.residenceFraud]: {
      id: policyPageModels.PolicyTableColumns.residenceFraud,
       headContent: t('common.residenceFraud'),
       renderCellContent: (value: string) => {
         return (<LevelBudge value={value} />);
       }
     },
     [ policyPageModels.PolicyTableColumns.policyTypeFraud]:  {
       id: policyPageModels.PolicyTableColumns.policyTypeFraud,
       headContent: t('common.policyTypeFraud'),
       renderCellContent: (value: string) => {
         return (<LevelBudge value={value} />);
       }
     },
    //  [policyPageModels.PolicyTableColumns.score]: {
    //    id: policyPageModels.PolicyTableColumns.score,
    //    headContent: t('common.drivingScore'),
    //    renderCellContent: ( _value: string, dataItem: policyModels.ListProcessedDataItem ) => {
    //      return maybeShowSkeleton(
    //        attemptTranslation(dataItem.score_description, t)
    //      );
    //    }
    //  },
     [policyPageModels.PolicyTableColumns.tripsCount]: {
       id:policyPageModels.PolicyTableColumns.tripsCount,
       headContent: t('common.nTrips'),
       renderCellContent: (value: string) => {
         return maybeShowSkeleton(value);
       }
     },
     [policyPageModels.PolicyTableColumns.kmCount] :{
       id: policyPageModels.PolicyTableColumns.kmCount,
       headContent: t('common.nKM'),
       renderCellContent: (value: string) => {
         return maybeShowSkeleton(value);
       }
     },
     [policyPageModels.PolicyTableColumns.totDuration] :{
       id: policyPageModels.PolicyTableColumns.totDuration,
       headContent: t('common.nHHMM'),
       renderCellContent: (value: string) => {
         return maybeShowSkeleton(secondsToDuration(value) as string);
       }
     },
    //  [policyPageModels.PolicyTableColumns.riskEvents] : {
    //    id: policyPageModels.PolicyTableColumns.riskEvents,
    //    headContent: t('common.riskEvents'),
    //    renderCellContent: ( _value: string, dataItem: policyModels.ListProcessedDataItem ) => {
    //      if ( eventsQueryIsLoading ) {
    //        return (
    //          <div className='flex gap-2'>
    //            <div className='flex flex-columns gap-1'>
    //              <div className='cell-skeleton' />
    //              <div className='cell-skeleton' />
    //            </div>
    //            <div className='flex flex-columns gap-1'>
    //              <div className='cell-skeleton' />
    //              <div className='cell-skeleton' />
    //            </div>
    //          </div>
    //        );
    //      }
   
    //      return (
    //        <div className='flex gap-2'>
    //          <div className='risk-events-data-column flex-grow'>
    //            <strong>
    //              {dataItem.speeding ?? '–'}
    //            </strong>
    //            <span>
    //              {t('common.speeding')}
    //            </span>
    //            <strong>
    //              {dataItem.idling_time ?? '–'}
    //            </strong>
    //            <span>
    //              {t('common.idlingTime')}
    //            </span>
    //            <strong>
    //              {dataItem.long_distance ?? '–'}
    //            </strong>
    //            <span>
    //              {t('common.longDistance')}
    //            </span>
    //          </div>
    //          <div className='risk-events-data-column flex-grow'>
    //            <strong>
    //              {dataItem.night_time ?? '–'}
    //            </strong>
    //            <span>
    //              {t('common.nightTime')}
    //            </span>
    //            <strong>
    //              {dataItem.long_duration ?? '–'}
    //            </strong>
    //            <span>
    //              {t('common.longDuration')}
    //            </span>
    //          </div>
    //        </div>
    //      );
    //    }
    // },
   [policyPageModels.PolicyTableColumns.createClaimButton] : {
     id: policyPageModels.PolicyTableColumns.createClaimButton,
     headContent : '',
      renderCellContent: ( _value: string, dataItem: policyModels.ListProcessedDataItem ) => {
        const onClickAddClaim = createClaimHandler(dataItem.id_voucher);

        return (
          <GroundButton
            color='secondary'
            height='small'
            text={t('common.createClaim').toUpperCase()}
            onClick={onClickAddClaim}
          />
        );
      }
    }, 
  };

  return appConfig.policyTableColumns.map((item: policyPageModels.PolicyTableColumns) => {
    return possibleColumns[item];
  });
};

const makeVoucherIDsList = ( data: policyModels.ListProcessedDataItem[] ) => {
  return data.map( item => String( item.id_voucher ) ).join();
};

const Profiles = () => {
  const { t }             = useTranslation( 'main' );
  const theme             = useAppTheme();
  const appDispatch       = useAppDispatch();
  const period            = useAppSelector( selectPeriod( 'profiles' ) );
  const groundToast       = useGroundToast();
  const navigate          = useNavigate();
  const queryClient       = useQueryClient();
  const filters           = useAppSelector( selectFilters( 'profiles' ) );
  const scoreOptions      = useAppSelector(selectDropdownOptions('score', t));
  const confirmationModal = useConfirmationModal();
  const showTelematicData = getAppConfig('showTelematicData');
  const currentResultsPerPage = useAppSelector( selectSetting( 'resultsPerPage' ) );
  const profilesTableState    = useAppSelector( selectFilters( 'profilesTable' ) );
  const showExportButtonInPolicyPage = getAppConfig('showExportButtonInPolicyPage');

  const chartRefFraudProbPolicyType     = useRef<HTMLDivElement | null>( null );
  const chartRefFraudProbResidence      = useRef<HTMLDivElement | null>( null );
  const chartRefMxStatesPolicyType      = useRef<HTMLDivElement | null>( null );
  const chartRefMxStatesResidence       = useRef<HTMLDivElement | null>( null );
  const chartRefVehicleModelsPolicyType = useRef<HTMLDivElement | null>( null );
  const chartRefVehicleModelsResidence  = useRef<HTMLDivElement | null>( null );
  const chartRefKmTraveled              = useRef<HTMLDivElement | null>( null );
  const chartRefTripsOutResidencePerc   = useRef<HTMLDivElement | null>( null );
  
  const [ tableData, setTableData ] = useState<policyModels.ListProcessedDataItem[] | null>( null );
  const [ csvQueryDependencies, setCsvQueryDependencies ] = useState<any>( null );
  const [searchbarInputValueDebounced, setSearchbarInputValueDebounced] = useState('');
  const [ canDownloadSummary, setCanDownloadSummary ] = useState( false );
  const [ chartLoadedCounter, setChartLoadedCounter ] = useState( 0 );

  const { currentPage, activeColumn } = profilesTableState as Record<string, any>;
  const apiQueryParams = {
    ...filters,
    order_by: activeColumn.id,
    order: activeColumn.isAscOrder ? 'asc' : 'desc'
  };
  const chartRefs: PoliciesSummaryChartRefs = {
    fraudProbPolicyType: chartRefFraudProbPolicyType,
    fraudProbResidence: chartRefFraudProbResidence,
    mxStatesPolicyType: chartRefMxStatesPolicyType,
    mxStatesResidence: chartRefMxStatesResidence,
    vehicleModelsPolicyType: chartRefVehicleModelsPolicyType,
    vehicleModelsResidence: chartRefVehicleModelsResidence,
    tripsOutResidencePerc: chartRefTripsOutResidencePerc,
    kmTraveled: chartRefKmTraveled
  };
  
  const mainQuery = useQuery({
    keepPreviousData : true,
    queryKey: [
      'profilesList', 
      currentPage, 
      { 
        currentResultsPerPage, 
        activeColumn, 
        filters,
        searchbarInputValue: searchbarInputValueDebounced 
      } 
    ],
    queryFn: () => {
      queryClient.cancelQueries({ queryKey: [ 'profilesListEvents' ] });

      return ProfilesService.getProfiles({
        ...apiQueryParams,
        search_string: searchbarInputValueDebounced,
        page: currentPage,
        limit: currentResultsPerPage
      }, period );
    }
  });

  const voucherIDsList = ( mainQuery.isSuccess ? makeVoucherIDsList( mainQuery.data.data ) : '' );
  const eventsQuery    = useQuery({
    enabled: Boolean( voucherIDsList ),
    queryKey: [ 'profilesListEvents', { voucherIDsList, period } ],
    queryFn: () => {  if (showTelematicData) return ProfilesService.getEventsDetail(voucherIDsList, period) },
  });

  const csvQuery = useQuery({
    enabled: Boolean( csvQueryDependencies ),
    queryKey: [ 'csvProfiles', csvQueryDependencies ],
    queryFn: () => ProfilesService.getProfilesForReport({
      ...apiQueryParams,
      ...csvQueryDependencies.filters
    }),
    onSuccess: data => {
      produceCsv( data );
      setCsvQueryDependencies( null )
    },
    onError: ( error: AxiosError ) => {
      appDispatch( unlockBrowsing() );
      setCsvQueryDependencies( null )
      
      if ( '404' === String( error.code ) ) {
        groundToast.show({
          variant: GroundToastVariant.featured,
          type: GroundToastType.info,
          message: t( 'toastMessages.noDataFoundToGenDocument' )
        });
      }
      else {
        groundToast.show({
          variant: GroundToastVariant.featured,
          type: GroundToastType.alert,
          message: t( 'toastMessages.documentGenFailed' )
        });
      }
    }
  });

  const summaryQuery = useQuery({
    enabled: canDownloadSummary,
    queryKey: [ 'summaryProfiles' ],
    queryFn: () => ProfilesService.getProfilesSummaryForReport(),
    onError: ( error: AxiosError ) => {
      appDispatch( unlockBrowsing() );
      setCanDownloadSummary( false );

      if ( '404' === String( error.code ) ) {
        groundToast.show({
          variant: GroundToastVariant.featured,
          type: GroundToastType.info,
          message: t( 'toastMessages.noDataFoundToGenDocument' )
        });
      }
      else {
        groundToast.show({
          variant: GroundToastVariant.featured,
          type: GroundToastType.alert,
          message: t( 'toastMessages.documentGenFailed' )
        });
      }
    }
  });

  const addClaimMutation = useMutation({
		mutationFn: ClaimsService.addClaim,
		onSuccess: (claimId: string, variables: { id_voucher: string }) => {
			const queryParams = `id_voucher=${variables.id_voucher}`;
      navigate(`/admin/claims/${claimId}?${queryParams}`);
		},
		onError: (error: AxiosError) => {
			groundToast.show({
				type: GroundToastType.alert,
				variant: GroundToastVariant.featured,
				message: String(error),
			});
		},
	});

  const createClaimHandler = ( id_voucher: string) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      addClaimMutation.mutate({id_voucher});
    };
  };

  const incrementChartLoadedCounter = () => setChartLoadedCounter( value => value + 1 );

  const canLoadChart = ( chartNum: number ) => {
    return ( canDownloadSummary && ( chartNum - 1 <= chartLoadedCounter ) );
  };

  const produceSummary = ( data: policyModels.SummaryReportData ) => {
    (async () => {
			try {
        const filename = t( 'functionalities.policiesSummaryFilename' );

        await downloadPdf( filename, (
          <PoliciesSummaryPdf
						theme={theme}
            chartRefs={chartRefs}
            data={data}
					/>
        ));
			} 
      catch (error) {
				groundToast.show({
					variant: GroundToastVariant.featured,
					type: GroundToastType.alert,
					message: t( 'toastMessages.documentGenFailed' ),
				});
			}
      finally {
        setChartLoadedCounter( 0 );
        setCanDownloadSummary( false );
        appDispatch( unlockBrowsing() );
      }
		})();
  };

  const produceCsv = ( data: policyModels.CsvReportParsedDataItem[] ) => {
    const filename = String( t( 'common.policies' ) ).toLowerCase();

    downloadCsv( data, getCsvColumns({ t }), filename );
    appDispatch( unlockBrowsing() );
  };

  const onClickExportSummary = () => {
    appDispatch( lockBrowsing() );
    setCanDownloadSummary( true );
  };
  
  const onClickExportAsCsv = () => {
    if ( !( mainQuery.data && mainQuery.data.data && mainQuery.data.data.length ) ) {
      groundToast.show({
        variant: GroundToastVariant.featured,
        type: GroundToastType.info,
        message: t( 'toastMessages.noDataFoundToGenDocument' )
      });

      return;
    }

    const onAction = ( downloadCompleteReport: boolean ) => {
      const levels       = appConfig.fraudProbabilityLevels;
      let requestFilters = filters;
      const notInapplicableFilters: Record<string, boolean> = {};
      const inapplicableFilters: Record<string, boolean>    = {};

      if ( !downloadCompleteReport ) {
        [ 'policy_type', 'residence' ].forEach( fraudProbID => {
          levels.forEach(( level, idx ) => {
            const key = `${fraudProbID}_fraud_${level}`;
    
            if ( 0 === idx ) {
              inapplicableFilters[key] = false;
            }
            else {
              notInapplicableFilters[key]  = true;
            }
          });
        });

        requestFilters = { 
          ...notInapplicableFilters, 
          ...filters, 
          ...inapplicableFilters 
        };
      }

      confirmationModal.close();
      launchDownloadCsvReport( requestFilters );
    };

    confirmationModal.open( <DownloadPoliciesModal onAction={onAction} /> );
  };

  const launchDownloadCsvReport = ( filters: Record<string, any> ) => {
    const dependencies = { filters };
    const data: any = queryClient.getQueryData( [ 'csvProfiles', dependencies ] );

    appDispatch( lockBrowsing() );

    if ( data ) {
      produceCsv( data );
    }
    else {
      setCsvQueryDependencies( dependencies )
    }
  };

  const onActivateRow = ( _rowUID: string, dataItem: policyModels.ListProcessedDataItem ) => {
    if (!window.getSelection()?.toString().length) {
      appDispatch( updatePeriod({
        id: 'profileDetail', 
        period: getProfileDetailPeriod( dataItem.close_date )
      }));
      navigate( `./${dataItem.id_voucher}` );
    }
  };

  const onChangeDateRange = ( newPeriod: PeriodState ) => {
    appDispatch( updatePeriod( { id: 'profiles', period: newPeriod } ));
  };

  const updateActiveColumn = ( activeColumn: any ) => {
    appDispatch(updateFilters({
      key: 'profilesTable', 
      filters: { ...profilesTableState, activeColumn }
    }));
  };

  useEffect(() => {
    if ( !eventsQuery.data ) {
      if ( mainQuery.data ) {
        setTableData( mainQuery.data.data );
      }

      return;
    }

    setTableData( mainQuery.data!.data.map( item => {
      const key = String( item.id_voucher );

      if ( eventsQuery.data[key] ) {
        return {
          ...item,
          ...eventsQuery.data[key]
        };
      }

      return item;
    }));
  }, [ mainQuery.status, eventsQuery.status, voucherIDsList ]);

  useEffect(() => {
    if ( 
      summaryQuery.data && 
      ( chartLoadedCounter === Object.keys( chartRefs ).length  ) 
    ) {
      produceSummary( summaryQuery.data );
    }
  }, [ chartLoadedCounter ] );

  return (
    <div className='claims-page flex-grow flex flex-columns'>
      <UtilityHeader
        relaxed
        showPicker={false}
        period={period}
        exportButton={{
          show: showExportButtonInPolicyPage,
          isLoading: ( csvQuery.isLoading || canDownloadSummary ),
          modalActions: [
            {
              label: t( 'common.downloadSummary' ) as string,
              callback: onClickExportSummary
            },
            {
              label: t( 'common.downloadCsv' ) as string,
              callback: onClickExportAsCsv
            }
          ]
        }}
        title={t( 'common.profiles' )}
        description={t( 'pagesDescriptions.profiles' )}
        onChangeDateRange={onChangeDateRange}
      />
      <MainContentWrapper overlapped>
        <TableArea
          searchbarInputValueDebounced={searchbarInputValueDebounced}
          setSearchbarInputValueDebounced={setSearchbarInputValueDebounced}
          query={mainQuery}
          data={tableData}
          storeSliceKey='profiles'
          className='tarea-profiles'
          i18nTitleKey='common.profiles'
          tableOptions={{
            uidProperty: 'profile_id',
            activeColumn,
            columns: getTableColumns({
              t,
              createClaimHandler,
              eventsQueryIsLoading: eventsQuery.isLoading
            }),
            onActivateRow,
            onClickSortButton: ( columnState: any ) => updateActiveColumn( columnState )
          }}
          renderDrawerContent={( params ) => (
            <DrawerContent {...params} scoreOptions={scoreOptions} />
          )}
        />
      </MainContentWrapper>
      {summaryQuery.isSuccess && (
        <>
          <ChartLoaderForPdf
            ref={chartRefFraudProbPolicyType}
            canLoad={canLoadChart( 1 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getFraudProbPolicyTypePieChartOption({ t, theme, data: summaryQuery.data })}
          />
          <ChartLoaderForPdf
            ref={chartRefFraudProbResidence}
            canLoad={canLoadChart( 1 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getFraudProbResidencePieChartOption({ t, theme, data: summaryQuery.data })}
          />
          <ChartLoaderForPdf
            ref={chartRefMxStatesPolicyType}
            canLoad={canLoadChart( 2 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getMxStatesPolicyTypePieChartOption({ t, theme, data: summaryQuery.data })}
          />
          <ChartLoaderForPdf
            ref={chartRefMxStatesResidence}
            canLoad={canLoadChart( 2 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getMxStatesResidencePieChartOption({ t, theme, data: summaryQuery.data })}
          />
          <ChartLoaderForPdf
            ref={chartRefVehicleModelsPolicyType}
            canLoad={canLoadChart( 3 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getVehicleModelsPolicyTypePieChartOption({ t, theme, data: summaryQuery.data })}
          />
          <ChartLoaderForPdf
            ref={chartRefVehicleModelsResidence}
            canLoad={canLoadChart( 3 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getVehicleModelsResidencePieChartOption({ t, theme, data: summaryQuery.data })}
          />
          <ChartLoaderForPdf
            ref={chartRefKmTraveled}
            canLoad={canLoadChart( 4 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getKmTraveledBarChartOption({ t, theme, data: summaryQuery.data })}
          />
          <ChartLoaderForPdf
            ref={chartRefTripsOutResidencePerc}
            canLoad={canLoadChart( 4 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getTripsOutResidencePercBarChartOption({ t, theme, data: summaryQuery.data })}
          />
        </>
      )}
    </div>
  );
};

export default applyErrorBoundary( Profiles );

