import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { sentryOptions } from './config/sentry';

import moment from 'moment';
import 'moment/locale/es';
import { getAppLanguage } from './config';

import { Provider as ReduxProvider } from "react-redux";
import { store } from "../src/store";

import reportWebVitals from './reportWebVitals';
import initI18n from './i18n';
import App from './App';
import './styles/index.scss';


Sentry.init( sentryOptions );
initI18n();
moment.locale( getAppLanguage() );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
