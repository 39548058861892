import { mapStyles } from "../config/google-map-styles";
import { env } from "../env";
import { claimModels } from "../models/claimModels";
import { policyModels } from "../models/policyModels";
import { tripModels } from "../models/tripModels";
import { canResidenceAreaInProfileDetailsBeShown } from "./profiles";


export interface GMapStaticUrlFunctionArg {
  trips: tripModels.TripProcessedData[],
  claim?: claimModels.SingleProcessed,
  policy?: policyModels.SingleProcessed,
  size: string;
  scale?: number;
  residenceColor?: string;
  startInitial: string;
  endInitial: string;
}

export const getGoogleMapStaticUrl = ({
  trips,
  claim,
  policy,
  residenceColor,
  size,
  scale,
  startInitial,
  endInitial
}: GMapStaticUrlFunctionArg ) => {
  let styles: string[] = [];
  let paths: string[]  = [];

  if ( !( trips && Array.isArray( trips ) ) ) {
    trips = [] as tripModels.TripProcessedData[];
  }

  if ( residenceColor ) {
    residenceColor = residenceColor.replace( '#', '0x' );
  }

  for ( const style of mapStyles ) {
    const parts = [];

    if ( !( 'elementType' in style && 'stylers' in style ) ) {
      continue;
    }

    if ( 'featureType' in style ) {
      parts.push( 'feature:' + style.featureType );
    }

    parts.push( 'element:' + style.elementType );

    const stylerKey   = Object.keys( style.stylers[0] )[0];
    const stylerValue = String( Object.values( style.stylers[0] )[0] ).replace( '#', '0x' );

    parts.push( stylerKey + ':' + stylerValue );
    styles.push( parts.join( '|' ) );
  }

  for ( const item of trips ) {
    if ( !( item.coords && Array.isArray( item.coords ) ) ) {
      continue;
    }

    const color = item.color.replace( '#', '0x' );

    const pathLocation = item.coords.map( coords => {
      const { lat, lng } = coords;

      return ( lat && lng ) ? ( lat + ',' + lng ) : '';
    }).filter(( latLng: string ) => Boolean( latLng )).join( '|' );

    const endIdx  = item.coords.length - 1;
    const markers = [ 0, endIdx ].map(( coordIdx: number ) => {
      const { lat, lng } = item.coords![coordIdx];

      if ( !( lat && lng ) ) {
        return '';
      }

      return 'markers=color:' + color 
            + '|label:' + ( 0 === coordIdx ? startInitial : endInitial ) 
            + '|size:mid|' + lat + ',' + lng;
    });
    
    paths.push(
        'path=color:' + color + '|weight:3|' + pathLocation 
      + '&' + markers.filter(( marker: string ) => Boolean( marker )).join( '&' )
    );
  }
  
  if (
    claim &&
    claim.claim_position &&
    claim.claim_position.coordinates &&
    claim.claim_position.coordinates[0]
  ) {
    const [lng, lat] = claim.claim_position.coordinates;
    const fraudProbabilityColor =
      claimModels.fraudProbabilityColorsMap[
        claim.fraud_probability_id as keyof typeof claimModels.fraudProbabilityColorsMap
      ] ?? "#000000";

    const color = fraudProbabilityColor.replace("#", "0x")

    const fraudProbabilityRadius = generateCirclePoints(
      { lat, lng },
      Number( claim.fraud_probability_radius ) || 0
    );

    paths.push(`markers=color:${color}|` + lat + "," + lng);
    paths.push(
      "path=color:" +
      color +
      "|fillcolor:" +
      color +
      "30|weight:1|" +
      fraudProbabilityRadius +
      `&markers=color:${color}|` +
      lat +
      "," +
      lng
    );
  }

  if (
    policy &&
    canResidenceAreaInProfileDetailsBeShown( policy.policy_type_id || '' ) &&
    policy.residence_area_center &&
    Array.isArray( policy.residence_area_center.coordinates )
  ) {
    const [ lng, lat ] = policy.residence_area_center.coordinates; 
    
    if ( lng && lat ) {
      paths.push( `markers=color:${residenceColor}|label:R|size:mid|` + lat + ',' + lng );
    }
  }
  
  const queryParams  = [
    ...paths,
    'format=png',
    'maptype=roadmap',
    'size=' + size,
    'scale=' + ( scale || 1 ),
    'style=' + styles.join( '&style=' ),
    'key=' + env.REACT_APP_GMAPS_API_KEY,
  ];
  return 'https://maps.googleapis.com/maps/api/staticmap?' + queryParams.join( '&' );
};

function generateCirclePoints(center: {lat: number, lng: number}, radius: number, numPoints = 100): string {
    let points: string[] = [];
    let d2r = (Math.PI / 180);   // degrees to radians
    let r2d = (180 / Math.PI);   // radians to degrees
    let earthRadius = 6378137;  // Earth's radius in meters

    for (let i = 0; i <= numPoints; ++i) {
          let theta = (i / numPoints) * 2 * Math.PI;
          let dx = radius * Math.cos(theta);
          let dy = radius * Math.sin(theta);

          let lat = center.lat + (dy / earthRadius) * r2d;
          let lng = center.lng + (dx / (earthRadius * Math.cos(center.lat * d2r))) * r2d;

          points.push(lat.toFixed(6) + ',' + lng.toFixed(6));
      }

    return points.join('|');
}
