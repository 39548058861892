import { valueNotFoundSymbol } from "./constants";


export interface CsvColumsItem {
  id: string;
  label: string;
  formatValue?: ( value: any, dataItem?: any ) => string;
}

export const downloadCsv = ( data: Record<string, any>[], columns: CsvColumsItem[], filename: string ) => {
  const rows = data.map( dataItem => {
      return columns.map( column => {
        let value = ( dataItem[column.id] || valueNotFoundSymbol );

        if ( column.id in dataItem ) {
          if ( typeof column.formatValue === 'function' ) {
            value = column.formatValue( dataItem[column.id], dataItem );
          }
        }

        return JSON.stringify( value );
      }).join( ',' );
  });

  const csvData = [
    columns.map( column => column.label ).join( ',' ),  
    ...rows
  ].join( '\r\n' );

  const universalBOM = '\uFEFF';
  const link = document.createElement( 'a' );
  const file = new Blob( [ universalBOM + csvData ], { type: 'text/csv;charset=UTF-8' } );

  link.href     = window.URL.createObjectURL( file );
  link.download = `${filename}.csv`;
  link.click();
};