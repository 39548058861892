import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { getFraudProbI18nKey } from "../../utils/fraud-probability";
import { appConfig } from "../../config/app-config/app-config";

import { GroundLabel } from "@greenvulcano/ground-react";
import { LevelBudgeInterface } from "./LevelBudgeInterface";

import "./LevelBudge.scss";


const LevelBudge = ({
  value,
  width = '100',
  className,
  style,
  ...otherProps
}: LevelBudgeInterface) => {
  const valueAsString = String( value ).toLowerCase();
  const valueAsNumber = ( Number( value ) || 0 );

  const { t }  = useTranslation( 'main' );
  const text   = t( getFraudProbI18nKey( valueAsString ));
  let color    = '';
  const colors = [ 'disabled', 'successLight', 'warning', 'danger', 'secondary' ];
  const levels = appConfig.fraudProbabilityLevels;

  const idx = levels.indexOf( valueAsString );

  if ( idx > -1 ) {
    color = colors[idx];
  }
  else if ( valueAsNumber >= 0 && valueAsNumber < levels.length ) {
    color = colors[valueAsNumber];
  }
  else {
    color = colors[0];
  }

  return (
    <GroundLabel
      {...otherProps}
      className={classNames(
        'level-budge',
        className
      )}
      text={text}
      color={color}
      style={{
        ...style,
        minWidth: parseInt( String( width ) ) + 'px'
      }}
    />
  );
};

export default LevelBudge;
