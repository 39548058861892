export namespace policyModels {
  export const fraudProbabilityColorsMap = {
    "1": "#488c21",
    "2": "#f46A00",
    "3": "#dd0546",
  }

  export interface ResidenceArea {
    type: string;
    coordinates: [ number, number ][][][];
  }

  export interface ResidenceAreaCenter {
    type: string;
    coordinates: [ number, number ];
  }

  export interface Single {
    profile_id: string;
    policyholder: string;
    id_voucher: string;
    policy_id: string;
    policy_type_id: string | null;
    policy_type: string | null;
    claims_count: string;
    claims_amount: string;
    agency_name: string | null;
    agent_name: string | null;
    vin: string;
    model: string;
    make: string;
    year: string;
    residence_area: string | null;
    residence_area_center: string | null;
    tot_trips: string | null;
    trip_out_residence: string | null;
    trip_out_residence_perc: string | null;
    policy_type_fraud: string;
    residence_fraud: string;
    address: string;
  }

  export interface SingleProcessed extends Omit<Single, 'residence_area' | 'residence_area_center' | 'policy_type'> {
    policy_type: Record<string, string> | null;
    residence_area: ResidenceArea | null;
    residence_area_center: ResidenceAreaCenter | null;
  }

  export interface ListDataItem {
    profile_id: string;
    policyholder: string;
    policy_id: string;
    policy_type_id: string | null;
    policy_type: string | null;
    id_voucher: string;
    close_date: string | null;
    vin: string;
    model: string;
    plate: string;
    make: string;
    year: string;
    policy_type_fraud: string;
    residence_fraud: string;
    address: string;
  }

  export interface ListProcessedDataItem extends Omit<ListDataItem, 'policy_type'> {
    policy_type: Record<string, string> | null;
  }

  export interface ListResponse {
    data: ListProcessedDataItem[];
    totalItems: number;
  }

  export interface SummaryReportData {
    tot_analyzed: string;
    tot_low_prob_policy_type: string;
    tot_medium_prob_policy_type: string;
    tot_high_prob_policy_type: string;
    tot_analyzing_prob_policy_type: string;
    tot_low_prob_residence: string;
    tot_medium_prob_residence: string;
    tot_high_prob_residence: string;
    tot_analyzing_prob_residence: string;
    km_policy_type_high_prob: string;
    out_residence_high_prob_perc: string;
    states_high_prob: Array<{
      state: string;
      tot_high_prob_policy_type: string;
      tot_high_prob_residence: string;
    }>;
    vehicle_models_high_prob: Array<{
      model: string;
      tot_high_prob_policy_type: string;
      tot_high_prob_residence: string;
    }>;
    km_traveled_high_prob: Array<{
      range: { min: number, max: number | null };
      count: string;
    }>;
    trips_out_residence_high_prob_perc: Array<{
      percent: number;
      count: string;
    }>;
  }

  export type FraudProbabilityCounts = Record<'policy_type' | 'residence', Array<{
    fraud_id: number;
    count: number;
  }>>;

  export interface CsvReportDataItem {
    profile_id: string;
    policyholder: string;
    policy_id: string;
    policy_type: string | null;
    insurance_company: string;
    municipality: string;
    state: string;
    tot_trips: string;
    tot_hours: string;
    tot_km: string;
    vin: string;
    model: string;
    plate: string;
    make: string;
    year: string;
    trip_out_residence: string;
    trip_out_residence_perc: string;
    policy_type_fraud: string;
    residence_fraud: string;
    address: string;
  }

  export interface CsvReportParsedDataItem extends Omit<CsvReportDataItem, 'policy_type'> {
    policy_type: Record<string, string> | null;
  }
}