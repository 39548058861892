import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import styled from "styled-components";
import classNames from "classnames";
import moment from "moment";
import { useAppTheme } from "../../../hooks";
import { applyErrorBoundary } from "../../../utils/error-boundary";
import { AnalyticsService } from "../../../services/AnalyticsService";

import { PeriodState } from "../../../store/slices/periodSlice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { selectPeriod, updatePeriod } from "../../../store/slices/periodSlice";

import TrendsWidget from "./TrendsWidget/TrendsWidget";
import TrendsChart from "./TrendsChart/TrendsChart";
import UtilityHeader from "../../../shared-components/UtilityHeader/UtilityHeader";
import MainContentWrapper from "../../../shared-components/MainContentWrapper/MainContentWrapper";
import RequestIndicator from "../../../shared-components/RequestIndicator/RequestIndicator";

import "./Trends.scss";
import { attemptTranslation } from "../../../utils/i18n";


const startOfTimes = moment.utc().subtract( 3, 'month' ).format( 'YYYY-MM-DD' );
const widgets = [
  {
    id: 'speeding',
    i18nTitleKey: 'common.speeding',
    unit: 'number'
  },
  {
    id: 'nighttime',
    i18nTitleKey: 'common.nighTime',
    unit: 'number'
  },
  {
    id: 'idle_time',
    i18nTitleKey: 'common.idlingTime',
    unit: 'number'
  },
  {
    id: 'long_trip',
    i18nTitleKey: 'common.longDistanceTrip',
    unit: 'number'
  },
  {
    id: 'theft',
    i18nTitleKey: 'common.numberOfThefts',
    unit: 'number'
  },
  {
    id: 'claims_count',
    i18nTitleKey: 'common.numberOfClaims',
    unit: 'number'
  },
  {
    id: 'score',
    i18nTitleKey: 'common.score',
    unit: 'score',
    formatter: ( dataItem: any, { className, t }: any ) => {
      return (
        <span className='flex flex-vcenter gap-1/2'>
          <span className={className}>{dataItem.score_value}</span>
          <span style={{fontSize: '0.6em', textTransform: 'uppercase'}}>
            {attemptTranslation( dataItem.value, t )}
          </span>
        </span>
      );
    }
  },
  {
    id: 'fraud_probability',
    i18nTitleKey: 'common.claimFraud',
  },
  {
    id: 'policy_type_fraud',
    i18nTitleKey: 'common.policyTypeFraud',
  },
  {
    id: 'residence_fraud',
    i18nTitleKey: 'common.residenceFraud',
  }
];

const triggerButtons = [
  {
    id: 'all_events',
    i18nTitleKey: 'common.allEvents',
  },
  {
    id: 'speeding',
    i18nTitleKey: 'common.speeding'
  },
  {
    id: 'nighttime',
    i18nTitleKey: 'common.nighTime',
  },
  {
    id: 'idle_time',
    i18nTitleKey: 'common.idlingTime',
  },
  {
    id: 'long_trip',
    i18nTitleKey: 'common.longDistanceTrip',
  },
  {
    id: 'crash',
    i18nTitleKey: 'common.crash',
  },
  {
    id: 'stats_by_day',
    i18nTitleKey: 'common.statsByDay',
  }
];

const TriggerButton = styled.button<{ width: string }>`
  color: ${( props: any) => props.theme.palettes.textWhite};
  border-bottom-color: ${( props: any) => props.theme.palettes.borderQuaternary};
  width: ${( props: any) => props.width};

  &.selected {
    border-bottom-color: ${( props: any) => props.theme.palettes.secondary};
  }
`; 

const Trends = () => {
  const { t }       = useTranslation( 'main' );
  const theme       = useAppTheme();
  const appDispatch = useAppDispatch();
  const [ activeButton, setActiveButton ] = useState( triggerButtons[0].id );
  const period = useAppSelector( selectPeriod( 'trends' ) );

  const mainQuery = useQuery({
    queryKey: [ 'analitics', { period } ],
    queryFn: () => AnalyticsService.getSummary( period )
  });
  const statsByDayChartQuery = useQuery({
    queryKey: ["statsByDayChart", { period }],
    queryFn: () => AnalyticsService.getChartData("stats_by_day", period),
    enabled: mainQuery.isSuccess,
  });

  const onClickTriggerButton = ( buttonID: string ) => {
    setActiveButton( buttonID );
  };

  const onChangeDateRange = ( newPeriod: PeriodState ) => {
    appDispatch( updatePeriod( { id: 'trends', period: newPeriod } ));
  };

  const canShowChart = () => {
    return (
      ( mainQuery.isSuccess && 'stats_by_day' !== activeButton ) || 
      statsByDayChartQuery.isSuccess
    );
  };

  const getChartData = () => {
    if ( 'stats_by_day' === activeButton ) {
      return ( statsByDayChartQuery.data || [] );
    }

    return ( 
      mainQuery.data && mainQuery.data[activeButton] ?
      mainQuery.data[activeButton].detail :
      []
    );
  };

  return (
    <div className='trends-page flex-grow flex flex-columns'>
      <UtilityHeader
        title={t( 'common.analytics' )}
        description={t( 'pagesDescriptions.trends' )}
        period={period}
        startOfTimes={startOfTimes}
        exportButton={{
          show: false,
        }}
        onChangeDateRange={onChangeDateRange}
      />
      <MainContentWrapper darkMode>
        <div className='flex gap-1 '>
          {widgets.map(( item: any ) => {
            let data = (
              mainQuery.isSuccess && ( item.id in mainQuery.data ) ?
              mainQuery.data[item.id] :
              {}
            );

            return( 
              <TrendsWidget
                {...item}
                key={item.id}
                id={item.id}
                data={data}
                isAwaiting={mainQuery.isLoading}
                width={( 100 / widgets.length ) + '%'}
              />
            );
          })}
        </div>
        <div className='trigger-buttons-wrapper flex'>
          {triggerButtons.map(( item: any ) => {
            return (
              <TriggerButton
                key={item.id}
                theme={theme}
                width={( 100 / triggerButtons.length ) + '%'}
                onClick={() => onClickTriggerButton( item.id ) }
                className={classNames(
                  'flex flex-hcenter',
                  { selected: ( activeButton === item.id ) }
                )}
              >
                <span className='text'>
                  {t( item.i18nTitleKey )}
                </span>
              </TriggerButton>
            );
          })}
        </div>
        <div className='chart-wrapper mt-2'>
          {canShowChart() ? (
            <TrendsChart
              key={activeButton}
              uid={activeButton}
              data={getChartData()}
              period={period}
            />
          ) : (
            <RequestIndicator
              isLoading={!( mainQuery.isError && statsByDayChartQuery.isError )}
              spinnerOptions={{
                color: 'secondary',
                pathColor: 'borderQuaternary'
              }}
            />
          )}
        </div>
      </MainContentWrapper>
    </div>
  );
};

export default applyErrorBoundary( Trends );
