import { FC, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppTheme } from "../../hooks";
import { applyErrorBoundary } from "../../utils/error-boundary";

import { ConfirmationModalContext } from "../../contexts/ConfirmationModalContext";
import { ConfirmationModalContextInterface } from "../../contexts/ConfirmationModalContext";
import { ConfirmationModalProviderInterface, ConfirmationModalProviderOptions } from "./ConfirmationModalProviderInterface";

import "./ConfirmationModalProvider.scss";


const ModalWrapper = styled.div`
  background: ${( props: any ) => `${props.theme.palettes.text}80`};
`;

const ConfirmationModalProvider = ({
  children
}: ConfirmationModalProviderInterface ) => {
  const theme = useAppTheme();
  const [ modal, setModal ] = useState<ReactElement | null>( null );
  const [ modalOptions, setModalOptions ] = useState<ConfirmationModalProviderOptions>({
    canClose: true
  });

  const contextValue: ConfirmationModalContextInterface = {
    open: ( modalComponent: ReactElement, options: ConfirmationModalProviderOptions = {} ) => {
      if ( modal ) {
        return;
      }
      
      setModal( modalComponent );
      setModalOptions({ ...modalOptions, ...options });
    },
    close: () => {
      setModal( null );
    }
  };

  const onClickWrapper = () => {
    if ( modalOptions.canClose ) {
      contextValue.close();
    }
  };

  useEffect(() => {
    window.addEventListener( 'popstate', contextValue.close );

    return () => {
      window.removeEventListener( 'popstate', contextValue.close );
    }
  }, [] );

  return (
    <ConfirmationModalContext.Provider value={contextValue}>
      {children}
      {modal && (
        <ModalWrapper
          theme={theme}
          className='modal-window-wrapper flex flex-center'
          onClick={onClickWrapper}
        >
          {modal}
        </ModalWrapper>
      )}
    </ConfirmationModalContext.Provider>
  );
};

export default applyErrorBoundary( ConfirmationModalProvider );