import styled from "styled-components";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useAppTheme } from "../../hooks";
import { parseResponseError } from "../../utils/response-error";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectSetting, updateSetting } from "../../store/slices/settingsSlice";
import { selectFilters, updateFilters } from "../../store/slices/filtersSlice";

import { 
  GroundTable, 
  GroundPaginationMenu,
  GroundPaginationMenuVariants,
  GroundIcon
} from "@greenvulcano/ground-react";
import Card from "../Card/Card";
import FiltersDrawer from "../FiltersDrawer/FiltersDrawer";
import ResultsPerPageMenu from "./ResultsPerPageMenu/ResultsPerPageMenu";
import RequestIndicator from "../RequestIndicator/RequestIndicator";
import DisclosingArrow from "../DisclosingArrow/DisclosingArrow";

import { TableAreaInterface } from "./TableAreaInterface";
import "./TableArea.scss";
import {  useRef, useState } from "react";
import Input from "../Input/Input";
import { appConfig } from "../../config/app-config/app-config";
import { appConfigModels } from "../../models/appConfigModels";


const TableWrapper = styled.div`
  border-color: ${( props: any ) => props.theme.palettes.borderSecondary};

  th {
    border-bottom-color: ${( props: any ) => props.theme.palettes.borderSecondary};
  }

  th,
  .sort-button {
    color: ${( props: any ) => props.theme.palettes.text};
  }

  td {
    color: ${( props: any ) => props.theme.palettes.textSecondaryDark};

    &::before {
      border-color: ${( props: any ) => props.theme.palettes.borderQuaternaryAlpha30};
    }
  }

  tr.selected {
    background-color: ${( props: any ) => props.theme.palettes.primaryAlpha10};
  }

  .sort-button {
    &.ascending,
    &.descending {
      .sort-icon::before {
        border-color: ${( props: any ) => props.theme.palettes.primary};
      }

      .sort-icon::after {
        background: ${( props: any ) => props.theme.palettes.primary};
      }
    }
  }
`;

const PaginationWrapper = styled.div`
  .ground-pagination-menu {
    .item:not(.active) {
      color: ${( props: any ) => props.theme.palettes.primary};
    }
  }
`;

const TableArea = ({
  data,
  query,
  i18nTitleKey,
  storeSliceKey,
  searchbarInputValueDebounced,
  setSearchbarInputValueDebounced,
  tableOptions: {
    columns,
    ...otherTableProps
  },
  className,
  renderDrawerContent = () => null
}: TableAreaInterface) => {
  const { t }       = useTranslation( 'main' );
  const theme       = useAppTheme();
  const appDispatch = useAppDispatch();
  const currentResultsPerPage = useAppSelector( selectSetting( 'resultsPerPage' ) );
  const tableStateKey = storeSliceKey + 'Table';
  const tableState    = useAppSelector<any>( selectFilters( tableStateKey ) );
  const [ opened, setOpened ] = useState( false ); 
  const currentPage  = tableState.currentPage;
  const isAwaiting   = !query.isSuccess;
  const theData      = ( data || ( query.isSuccess && query.data.data ) );
  const thereAreData = ( theData && Array.isArray( theData ) && theData.length );
  const totalNumOfResults = (query.isSuccess ? query.data.totalItems : 0);
  const [searchbarInputValue, setSearchbarInputValue] = useState('');
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  
  
  const handleSearchbarInputChange = (value: string) => {
    const lowerCaseInputValue = value.toLowerCase().trim();
    if (setSearchbarInputValue) {
      setSearchbarInputValue(value);
    }
  
    if (debounceTimer.current !== null) {
      clearTimeout(debounceTimer.current);
    }
  
    debounceTimer.current = setTimeout(() => {
      if (setSearchbarInputValueDebounced) {
        setSearchbarInputValueDebounced(lowerCaseInputValue);
      }
    }, 300);
  };

  const updateCurrentPage = ( newPageNumber: number ) => {
    appDispatch(updateFilters({
      key: tableStateKey, 
      filters: { ...tableState, currentPage: newPageNumber }
    }));
  };

  const onClickResultsPerPageHandler = ( value: number ) => {
    let newPageNum = currentPage;
    
    if ( currentPage > 1 ) {
      newPageNum = Math.ceil( ( currentPage * currentResultsPerPage ) / value );
    }
    
    updateCurrentPage( newPageNum );
    appDispatch( updateSetting({ resultsPerPage: value }) );
  };

  const onClick = () => {
    setOpened(value => !value)
  }

  if ( columns[columns.length - 1].id !== 'utility' ) {
    // Adds "fixed width" class to ID column.
    columns[0].className = classNames(
      'id-column-width',
      columns[0].className
    );

    columns.push({
      id: 'utility',
      className: 'collapse-width',
      renderCellContent: () => {
        return (
          <DisclosingArrow
            arrowColor='textLight'
            rotation='90deg'
            className='arrow'
          />
        );
      }
    })
  }

  return (
		<Card className={classNames("table-area flex-grow", className)}>
			{isAwaiting ? (
				<RequestIndicator
					isLoading={!query.isError}
					errorMessage={parseResponseError(query.error).message || ""}
				/>
			) : (
				<>
					<div className="tarea-header">
						<h3 >
                {t(i18nTitleKey)} {`(${thereAreData ? totalNumOfResults : 0})`}
              </h3>
              <div className="input-container">
              {
                appConfig.showPolicyTableSearchBar && searchbarInputValueDebounced !== undefined &&
                <Input value={searchbarInputValue} className="input-searchbar-table" onChange={handleSearchbarInputChange} width={300} placeHolder={t("common.search") as string} icon={<GroundIcon color="textLight" svgIcon="search" width="22" />} />
                }
                </div>
              { appConfig.filterButtonPosition === appConfigModels.filterButtonPosition.aboveTable && <button type="button"  className='filter-button' onClick={onClick}>
								<GroundIcon svgIcon={"filters"} width="18" />
							</button>}
					</div>
					<TableWrapper
						theme={theme}
						className={classNames("table-wrapper", {
							updating: query.isFetching,
						})}
					>
						<GroundTable
							fluid
							data={thereAreData ? theData : []}
							columns={columns || []}
							{...otherTableProps}
						/>
						{thereAreData ? (
							query.isFetching ? (
								<RequestIndicator
									isLoading
									className="table-update-indicator"
									spinnerOptions={{ scale: 0.7 }}
								/>
							) : null
						) : (
							<div className="no-content flex flex-center">
								{query.isFetching ? (
									<RequestIndicator isLoading spinnerOptions={{ scale: 0.3 }} />
								) : (
									<p>{t("errors.noResults")}</p>
								)}
							</div>
						)}
					</TableWrapper>
					{thereAreData ? (
						<PaginationWrapper
							theme={theme}
							className="pagination-wrapper flex flex-space-between"
						>
							<ResultsPerPageMenu
								totalNumOfResults={totalNumOfResults}
								currentValue={currentResultsPerPage}
								onClickItem={onClickResultsPerPageHandler}
							/>
							<GroundPaginationMenu
								variant={GroundPaginationMenuVariants.compact}
								numberOfPages={Math.ceil(
									totalNumOfResults / (currentResultsPerPage || 10)
								)}
								currentPage={currentPage}
								onClickItem={updateCurrentPage}
							/>
						</PaginationWrapper>
					) : null}
					<FiltersDrawer
						storeSliceKey={storeSliceKey}
						showFilter={opened}
						setShowFilter={setOpened}
						renderContent={renderDrawerContent}
					/>
				</>
			)}
		</Card>
	);
};

export default TableArea;