import { Controller } from "react-hook-form";
import { ToggleInterface } from "./ToggleInterface";

import { GroundToggle } from "@greenvulcano/ground-react";


const Toggle = ({
  name = '',
  defaultValue = false,
  control,
  errors = {},
  rules = {},
  ...rest
}: ToggleInterface ) => {

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
      render={({ field: { value, onChange } }) => {
        return (
          <GroundToggle
            erratum={Boolean( errors[name] )}
            checked={value}
            onChange={onChange}
            {...rest}
          />
        );
      }}
    />
  );
};

export default Toggle;