import axios from "axios";
import { getApiEndpoint } from "../config";
import { isObjectEmpty } from "../utils";
import {
  getStorageItem,
  setStorageItem,
  removeStorageItem
} from "../utils/local-storage";
import { getFallbackErrorMessage } from "./utils";


export const AuthService = {
  getToken: () => {
    const authObj = getStorageItem( 'auth' );

    if ( isObjectEmpty( authObj ) || !authObj.token ) {
      return '';
    }

    return authObj.token;
  },
  getAuthHeaders: () => {
    const token = AuthService.getToken();
    
    if ( !token ) {
      return { headers: {} };
    }

    return { headers: { Authorization: `Bearer ${token}` } };
  },
  login: async ( { username, password }: any ): Promise<any> => {
    try {
      const { data: responseData } = await axios.post( 
        getApiEndpoint( 'login' ), 
        { username, password }
      );

      setStorageItem( 'auth', responseData );

      return Promise.resolve( responseData );
    }
    catch ( error: any ) {
      const respStatusCode = ( error.response ? ( Number( error.response.status ) || 0 ) : 0 );

      if ( 403 === respStatusCode ) {
        return Promise.reject( 'errors.usernameOrPasswordInvalid' );
      }

      return Promise.reject( getFallbackErrorMessage( error ) );
    }
  },
  logout: async (): Promise<any> => {
		try {
      const { data: responseData } = await axios.post(
				getApiEndpoint( 'logout' ),
        {},
				AuthService.getAuthHeaders()
			);

			return Promise.resolve( responseData );
		}
    catch ( error: any ) {
      const errorMessage = error.response ? error.response.data : error;

			return Promise.reject( errorMessage );
		}
    finally {
			removeStorageItem( 'auth' );
		}
	},

  // To be called in the catch block of
  // the other services' methods.
  maybeForceLogout: ( error: any ) => {
    const respStatusCode = ( error.response ? Number( error.response.status ) : 0 );

    if ( !Number.isNaN( respStatusCode ) && ( 401 === respStatusCode || 403 === respStatusCode ) ) {
      removeStorageItem( 'auth' );

      window.location.reload();
    }

    return Promise.reject( error.response ? error.response.data : error );
  },
  getUserInfo: async () => {
    try {
      if ( !AuthService.getToken() ) {
        throw new Error( 'Missing token' );
      }

      const { data: responseData } = await axios.get(
        getApiEndpoint( 'userInfo' ), 
        AuthService.getAuthHeaders()
      );

      return Promise.resolve({
        ...responseData.data,
        fullname: responseData.data.firstName + ' ' + responseData.data.lastName,
        role: responseData.data.profile.description
      });
    }
    catch ( error: any ) {
      removeStorageItem( 'auth' );

      return Promise.reject( error.message || 'Please provide your login credentials' );
    }
  },
  restorePassword: async ( username: string ): Promise<string> => {
		try {
      await axios.post(
        getApiEndpoint( 'restorePassword' ),
        { username }
      );

      return Promise.resolve( 'ok' );
		} 
    catch ( error: any ) {
      const respStatusCode = ( error.response ? ( Number( error.response.status ) || 0 ) : 0 );

      if ( 403 === respStatusCode ) {
        // We don't want to inform an anonymous user about
        // the correctness of the username submitted.
        return Promise.resolve( 'ok' );
      }

      return Promise.reject( getFallbackErrorMessage( error ) );
		}
	},
};