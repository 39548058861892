// Numbers are values in pixels.

export const groundPresetSizes: Record<string, any> = {
  groundButton: {
    large: 40,
    medium: 34
  },
  groundAvatar: {
    small: 36
  }
};