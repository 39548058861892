import { Text,StyleSheet, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { PdfStatisticComponentInterface } from "./PdfStatisticComponentInterface";
import { formatNumber } from "../../../utils";
import { valueNotFoundSymbol } from "../../../utils/constants";

const getStyles = ({ theme }: Record<string, any>) =>
	StyleSheet.create({
		bold: {
			fontFamily: "Helvetica-Bold"
		},
		container: {
      fontFamily: "Helvetica",
			gap: 2,
		},
		statisticContainer: {
			flexDirection: "row",
			alignItems: "center",
			fontSize: 8,
			gap: 2,
		},
		item: { 
      fontSize: 8, 
      paddingRight : 3
    },
		infoItem: {
			gap: 2,
		},
		infoItemKey: {
			fontSize: 8,
			color: theme.palettes.textLight,
			lineHeight: 1.3,
		},
	});

const PdfStatisticComponent = ({value,data,tripsData,duration,theme} : PdfStatisticComponentInterface) => {
  const { t }          = useTranslation( 'main' );
  const tripsCount     = tripsData.trips_count;
  const percTotTripout = data.trip_out_residence_perc;
  const km             = formatNumber( tripsData.km_count );
  const hours          = formatNumber(duration.hours);
  const mins           = formatNumber(duration.mins);
  const styles         = getStyles({ theme });

  return (
    <View style={styles.container}>
      <View style={styles.statisticContainer}>
        <Text>
          <Text style={styles.bold}>
            {tripsCount}
          </Text>
          <Text>
            {' '}
            {t("common.trips")}
          </Text>
        </Text>
        <Text style={{paddingLeft : 4}} >|</Text>
        <Text>
          <Text style={styles.bold}>
            {km}
          </Text>
          <Text>{" km"}</Text>
        </Text>
        <Text  style={{paddingLeft : 4}}>|</Text>
        <Text>
          <Text style={styles.bold}>
            {hours}
          </Text>
          <Text>{t("common.HH") + " - "}</Text>
        </Text>
        <Text>
          <Text style={styles.bold}>
            {mins}
          </Text>
          <Text>{t("common.MM")}</Text>
        </Text>
      </View>
      <Text style={styles.item} >
        <Text style={styles.bold}>
          {value ? value : valueNotFoundSymbol}
        </Text>
        <Text>
          {' '}
          {t("common.tripsOutsideResidence")}
        </Text>
      </Text>
      <Text style={styles.item}>
        <Text style={styles.bold}>
          {percTotTripout ?? valueNotFoundSymbol}
          {'%'}
        </Text>
        <Text>
          {' '}
          {t("common.tripsOutsideResidence")}
        </Text>
      </Text>
    </View>
  );
};

export default PdfStatisticComponent;