import { useTranslation } from "react-i18next";
import { View, Image } from "@react-pdf/renderer"
import { getGoogleMapStaticUrl } from "../../../utils/google-maps";

import { PdfGoogleMapInterface } from "./PdfGoogleMapInterfacce";


const PdfGoogleMap = ( props: PdfGoogleMapInterface) => {
  const { t }  = useTranslation( 'main' );
  const mapURL = getGoogleMapStaticUrl({
    size: '720x360',
		scale: 2,
    startInitial: t( 'functionalities.startInitial' ),
    endInitial: t( 'functionalities.endInitial' ),
    ...props
  });
  
  return (
    <View style={{
			alignItems: 'center',
			justifyContent: 'center',
		}}>
      <Image
        src={mapURL}
        style={{
          height: 260,
          border: '1px dotted black'
        }}
      />
    </View>
  );
};

export default PdfGoogleMap;