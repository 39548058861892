export const getLevelPalette = ( level: string | number, reversePalettes = false ) => {
  const _value = Number( level );
  let palette  = 'onDisabled';
  let palettes = [ 'success', 'warning', 'danger' ];
  const levels = [ 'low', 'medium', 'high' ];

  if ( reversePalettes ) {
    palettes = palettes.reverse();
  }

  // Level can be an integer
  if ( !Number.isNaN( _value ) && _value >= 1 ) {
    return palettes[_value - 1];
  }

  level = String( level ).toLowerCase();

  for ( const levelIdx in levels ) {
    if ( levels[levelIdx] === level ) {
      return palettes[levelIdx];
    }
  }

  return palette;
};

export const getScoreLevelPalette = ( value: string | number, palettes?: any, fallbackPalette?: string ) => {
  const _value = Number( value );
  let palette  = fallbackPalette || 'onDisabled';
  let _palettes = palettes || [ 'danger', 'warning', 'success' ];

  // Percentages
  const levels = [ 33, 66, 100 ];

  if ( !Number.isNaN( _value ) ) {
    for ( let i = 0; i < levels.length; i++ ) {
      if ( _value <= levels[i] ) {
        palette = _palettes[i];

        break;
      }
    }
  }

  return palette;
};

export const getLevelBudgePalette = ( value: string ) => {
  value = String( value ).toLowerCase();

  let palette = 'disabled';

  if ( 'low' === value || '1' === value ) {
    palette = 'successLight';
  }
  else if ( 'medium' === value || '2' === value ) {
    palette = 'warning';
  }
  else if ( 'high' === value || '3' === value ) {
    palette = 'danger';
  }

  return palette;
};