import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { mapModels } from "../../models/mapModels";
import { RootState } from "..";
import { uniqueId } from "../../utils";

export interface MapState {
	markerObjectsById: { [key: string]: mapModels.MarkerObj };
	markerIdsByName: {
		[key in mapModels.MarkerNames]: string;
	};
	showOriginalClaimMarker: boolean;
	googleIsLoaded: boolean;
	shapeObjectsById: { [key: string]: mapModels.CircleShapeInterface };
	shapePolygonObjectsById: { [key: string]: mapModels.PolygonShapeInterface };
	shapeIdsByName: {
		[key in mapModels.ShapeNames]: string;
	};
}

const initialState: MapState = {
	markerObjectsById: {},
	markerIdsByName: {
		[mapModels.MarkerNames.manualClaimMarker]: uniqueId(),
		[mapModels.MarkerNames.residenceMarker]: uniqueId(),
	},
	showOriginalClaimMarker: true,
	googleIsLoaded: false,
	shapeObjectsById: {},
	shapePolygonObjectsById : {},
	shapeIdsByName: {
		[mapModels.ShapeNames.fraudProbabilityRadius]: uniqueId(),
		[mapModels.ShapeNames.residenceArea]: uniqueId(),
	},
};

export const mapSlice = createSlice({
	name: "map",
	initialState,
	reducers: {
		removeMarkersById: (state, action: PayloadAction<string[]>) => {
			const markerObjectsClone = { ...state.markerObjectsById };
			action.payload.forEach((id) => {
				delete markerObjectsClone[id];
			});
			return {
				...state,
				markerObjectsById: markerObjectsClone,
				showOriginalClaimMarker:
					markerObjectsClone[
						state.markerIdsByName[mapModels.MarkerNames.manualClaimMarker]
					] === undefined,
			};
		},
		addMarkersWithId: (
			state,
			action: PayloadAction<{ markerObj: mapModels.MarkerObj; id: string }[]>
		) => {
			const markerObjectsClone = { ...state.markerObjectsById };
			action.payload.forEach((obj) => {
				markerObjectsClone[obj.id] = {...obj.markerObj, key: obj.id};
			});
			return {
				...state,
				markerObjectsById: markerObjectsClone,
				showOriginalClaimMarker:
					markerObjectsClone[
						state.markerIdsByName[mapModels.MarkerNames.manualClaimMarker]
					] === undefined,
			};
		},
		changeMarkerIconById: (
			state,
			action: PayloadAction<{ icon: string; id: string }>
		) => {
			if (state.markerObjectsById[action.payload.id]) {
				return {
					...state,
					markerObjectsById: {
						...state.markerObjectsById,
						[action.payload.id]: {
							...state.markerObjectsById[action.payload.id],
							icon: action.payload.icon,
						},
					},
				};
			}
		},
		removeShapesById: (state, action: PayloadAction<string[]>) => {
			const shapeObjectsClone = { ...state.shapeObjectsById };
			action.payload.forEach((id) => {
				delete shapeObjectsClone[id];
			});
			return {
				...state,
				shapeObjectsById: shapeObjectsClone,
			};
		},
		removePolygonShapesById: (state, action: PayloadAction<string[]>) => {
			const shapeObjectsClone = { ...state.shapePolygonObjectsById };
			action.payload.forEach((id) => {
				delete shapeObjectsClone[id];
			});
			return {
				...state,
				shapePolygonObjectsById: shapeObjectsClone,
			};
		},
		addShapesWithId: (
			state,
			action: PayloadAction<
				{ shapeObj: mapModels.CircleShapeInterface; id: string }[]
			>
		) => {
			const shapeObjectsClone = { ...state.shapeObjectsById };
			action.payload.forEach((obj) => {
				shapeObjectsClone[obj.id] = obj.shapeObj;
			});
			return {
				...state,
				shapeObjectsById: shapeObjectsClone,
			};
		},
		addPolygonShapesWithId: (
			state,
			action: PayloadAction<
				{ shapeObj: mapModels.PolygonShapeInterface; id: string }[]
			>
		) => {
			const shapeObjectsClone = { ...state.shapePolygonObjectsById };
			action.payload.forEach((obj) => {
				shapeObjectsClone[obj.id] = obj.shapeObj;
			});
			return {
				...state,
				shapePolygonObjectsById: shapeObjectsClone,
			};
		},
		setGoogleIsLoaded: (state, action: PayloadAction<boolean>) => {
			return {
				...state,
				googleIsLoaded: action.payload,
			};
		},
	},
});
export const selectMarkerIdsByName = (state: RootState) =>
	state.map.markerIdsByName;
export const selectShapeIdsByName = (state: RootState) =>
	state.map.shapeIdsByName;
export const selectAllMarkerObjects = (state: RootState) =>
	state.map.markerObjectsById;
export const selectAllShapeObjects = (state: RootState) =>
	state.map.shapeObjectsById;
	export const selectPolygonShapeObjects = (state: RootState) =>
	state.map.shapePolygonObjectsById;
export const selectShowOriginalClaimMarker = (state: RootState) =>
	state.map.showOriginalClaimMarker;
export const selectGoogleIsLoaded = (state: RootState) =>
	state.map.googleIsLoaded;

export const {
	changeMarkerIconById,
	removeMarkersById,
	addMarkersWithId,
	setGoogleIsLoaded,
	addShapesWithId,
	addPolygonShapesWithId,
	removeShapesById,
	removePolygonShapesById
} = mapSlice.actions;
export default mapSlice.reducer;
