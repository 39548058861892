import styled from "styled-components";
import classNames from "classnames";
import { useAppTheme } from "../../../hooks";

import { ResultsPerPageMenuInterface } from "./ResultsPerPageMenuInterface";
import "./ResultsPerPageMenu.scss"


const Item = styled.button`
  border-color: ${( props: any ) => props.theme.palettes.borderSecondary};
  color: ${( props: any ) => props.theme.palettes.primary};

  &:not(.active):not(:disabled):hover {
    background-color: ${( props: any ) => props.theme.palettes.primaryAlpha10};
  }

  &.active {
    background-color: ${( props: any ) => props.theme.palettes.primary};
    color: ${( props: any ) => props.theme.palettes.onPrimary};
  }

  &:disabled {
    color: ${( props: any ) => props.theme.palettes.primaryAlpha50};
  }
`;

const ResultsPerPageMenu = ({
  totalNumOfResults = 1,
  currentValue,
  className,
  onClickItem = () => null
}: ResultsPerPageMenuInterface ) => {
  totalNumOfResults = Number( totalNumOfResults );
  currentValue      = Number( currentValue );

  if ( Number.isNaN( totalNumOfResults ) || totalNumOfResults < 1 ) {
    totalNumOfResults = 1;
  }

  if ( Number.isNaN( currentValue ) || currentValue < 1 ) {
    currentValue = 1;
  }

  if ( currentValue > totalNumOfResults ) {
    currentValue = totalNumOfResults;
  }

  const theme   = useAppTheme();
  const targets = [ 10, 25, 50, 100];

  return (
    <div
      className={classNames(
        'number-of-results-menu',
        className
      )}
    >
      {targets.map(( targetValue: number, idx: number ) => {
        const isActive   = ( currentValue > ( targets[idx - 1] || 0 ) && currentValue <= targetValue );
        const isDisabled = ( idx > 0 && totalNumOfResults <= targets[idx - 1] );

        return (
          <Item
            key={`resultsMenuBtn_${targetValue}`}
            type='button'
            theme={theme}
            disabled={isDisabled}
            className={classNames(
              'item',
              { active: isActive }
            )}
            onClick={() => onClickItem( targetValue )}
          >
            {targetValue}
          </Item>
        );
      })}
    </div>
  );
};

export default ResultsPerPageMenu;