import { Page } from "@react-pdf/renderer";
import { computeNumTripsInInfoPage } from "../../../utils/pdf";

import PdfTemplate from "../PdfTemplate/PdfTemplate";
import PdfTripsTable from "../PdfTripsTable/PdfTripsTable";
import { PdfTripsPagesInterface } from "./PdfTripsPagesInterface";


const PdfTripsPages = ({
  trips,
  pointerInitValue = 0,
  theme,
  ...templatePropsLeft
}: PdfTripsPagesInterface) => {
  const pages       = [];
  const rowsPerPage = 22;
  let idx           = 1;
  let slicePointer = pointerInitValue;

  while ( slicePointer < trips.length ) {
    let currentPageData  = trips.slice( slicePointer, slicePointer + rowsPerPage );
    const numTripsInPage = computeNumTripsInInfoPage( currentPageData, rowsPerPage );

    if ( numTripsInPage < rowsPerPage ) {
      currentPageData  = trips.slice( slicePointer, slicePointer + numTripsInPage );
    }

    slicePointer += numTripsInPage;

    pages.push(
      <Page key={'pdfPage' + idx++} size='A4'>
        <PdfTemplate 
          theme={theme}
          {...templatePropsLeft}
        >
          <PdfTripsTable theme={theme} trips={currentPageData} />
        </PdfTemplate>
      </Page>
    );
  }

  return <>{pages}</>;
};

export default PdfTripsPages;