export namespace policyPageModels {
	export enum PolicyTableColumns {
		vin = "vin",
		plate = "plate",
		residenceFraud = "residence_fraud",
		policyTypeFraud = "policy_type_fraud",
		// score = "score",
		tripsCount = "trips_count",
		kmCount = "km_count",
		totDuration = "tot_duration",
		// riskEvents = "risk_events",
		profileId = "profile_id",
		policyHolder = "policyholder",
		policyId = "policy_id",
		policyType = "policy_type",
		address = "address",
		createClaimButton = "create_claim_button",
		model = "model",
		make = "make",
		year = "year"
	}
	export type PolicyTableColumn = {
		id: PolicyTableColumns;
		headContent: string;
		isSortable?: boolean;
		renderCellContent?: (...args: any[]) => string | JSX.Element;
	};

	export enum PolicyFilters {
		vin = "vin",
		plate = "plate",
		residenceFraud = "residence_fraud",
		policyTypeFraud = "policy_type_fraud",
		profileId = "profile_id",
		policyHolder = "policyholder",
		policyId = "policy_id",
		policyTypeId = "policy_type_id",
		address = "address",
		agency = "agency",
		agent = "agent",
		model = "model",
		make = "make",
	}
}
