import { getAppConfig } from "../../config";
import { appConfigModels } from "../../models/appConfigModels";
import RouterLink from "../RouterLink/RouterLink";
import { LogoInterface } from "./LogoInterface";

const Logo = ({ configId = 'logo', height }: LogoInterface) => {
  const LogoComponent = getAppConfig( configId  as keyof appConfigModels.appConfigInterface  );
  const defaultHeight = height || getAppConfig( `${configId}Height` as keyof appConfigModels.appConfigInterface , null );

  return (
    <h1>
      <RouterLink to='/'>
        {typeof LogoComponent === 'string' ? (
          <img src={LogoComponent} height={defaultHeight} alt=''/>
        ) : (
          <LogoComponent />
        )}
      </RouterLink>
    </h1>
  );
};

export default Logo;