import { env } from "../env";

const apiBase = env.REACT_APP_API_DOMAIN + '/api/v1';

export type ApiEndpoint = ( 
  'login' | 'logout' | 'userInfo' | 'restorePassword' | 'claims' | 'addClaim' |
  'singleClaim' | 'updateClaim' | 'calculateFraud' | 'profiles' | 'profilesCountFraudProb' |
  'singleProfile' | 'profileEventsDetail' | 'trips' | 'tripCoords' | 'lastTripDate' |
  'scoreDecode' | 'analyticsSummary' | 'analyticsChart' | 'drivingScores' |
  'vehicleTypes' | 'claimsForReport' | 'claimsSummaryForReport' | 'profilesForReport' |
  'profilesSummaryForReport'
);

/**
 * This utility object is supposed to be accessed through the function getApiEndpoint()
 * defined in '/src/config/index.ts'
 * 
 * You can use path params too, namely you can write something like this:
 * {
 *    userAction: '/users/:userId/some-action'
 * }
 * Then access it like so:
 * getApiEndpoint( 'userAction', { userId: '...' } )
 */
export const apiEndpoints: Record<ApiEndpoint, string> = {
  // Authentication
  login: `${apiBase}/login`,
  logout: `${apiBase}/logout`,
  userInfo: `${apiBase}/users/me/info`,
  restorePassword: `${apiBase}/account/restore-password`,

  // Claims
  claims: `${apiBase}/claims`,
  addClaim: `${apiBase}/claims`,
  singleClaim: `${apiBase}/claims/:id`,
  updateClaim: `${apiBase}/claims/:id`,
  calculateFraud: `${apiBase}/claims/calculate-fraud-by-id/:id`,
  claimsForReport: `${apiBase}/claims/report`,
  claimsSummaryForReport: `${apiBase}/claims/report/summary`,
  
  // Profiles
  profiles: `${apiBase}/profiles`,
  singleProfile: `${apiBase}/profiles/:id_voucher`,
  profileEventsDetail: `${apiBase}/profiles/events-detail`,
  profilesForReport: `${apiBase}/profiles/report`,
  profilesSummaryForReport: `${apiBase}/profiles/report/summary`,
  profilesCountFraudProb: `${apiBase}/profiles/count_fraud_prob`,

  // Trips
  trips: `${apiBase}/trips`,
  tripCoords: `${apiBase}/trips/:id/coords`,
  lastTripDate : `${apiBase}/trips/last-trip-date/:id`,

  // Score
  scoreDecode: `${apiBase}/score/decode/:value`,

  // Analytics
  analyticsSummary: `${apiBase}/analytics`,
  analyticsChart: `${apiBase}/analytics/chart`,

  // Functionalities
  drivingScores: `${apiBase}/driving-scores`,
  vehicleTypes: `${apiBase}/vehicles/types`,
};