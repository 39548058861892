import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils";
import { calcPercentage, percentToString } from "../../../utils/numbers";
import { claimModels } from "../../../models/claimModels";

import { Document, Page, View, Text } from "@react-pdf/renderer";
import PdfTemplate from "../PdfTemplate/PdfTemplate";
import { getPdfTempalteStyles } from "../PdfTemplate/PdfTemplate";
import { ClaimsSummaryChartIDs, ClaimsSummaryPdfInterface } from "./ClaimsSummaryPdfInterface";
import PdfChart from "../PdfChart/PdfChart";


const getTotalAnalized = ( data: claimModels.SummaryReportData ) => {
  const totLowProb = ( Number( data.tot_high_prob ) || 0 );
  const totMediumProb = ( Number( data.tot_medium_prob ) || 0 );
  const totHighProb = ( Number( data.tot_low_prob ) || 0 );

  return ( totLowProb + totMediumProb + totHighProb );
};

const ClaimsSummaryPdf = ({
  theme,
  data,
  chartRefs
}: ClaimsSummaryPdfInterface ) => {
  const { t }            = useTranslation( 'main' );
  const templateStyles   = getPdfTempalteStyles({ theme });
  const pdfTemplateProps = {
    theme,
    docTitle: t( 'pdf.claimsSummary.headerText' ),
    period: { 
      start: data.first_claim_date, 
      end: data.last_claim_date
    }
  };

  const totAnalyzed     = getTotalAnalized( data );
  const totAnalyzedPerc = calcPercentage( data.tot_high_prob, totAnalyzed );

  const charts = {} as Record<ClaimsSummaryChartIDs, ReactNode>;
  
  for ( const refID in chartRefs ) {
    const element = chartRefs[refID as ClaimsSummaryChartIDs].current;

    if ( !element ) {
      continue;
    }

    charts[refID as ClaimsSummaryChartIDs] = <PdfChart element={element} />;
  }

  return (
    <Document>
      <Page size='A4'>
        <PdfTemplate
          pageNum={1}
          {...pdfTemplateProps}
        >
          <View>
            <Text style={[ templateStyles.title ]}>
              {t( 'pdf.executiveSummary' )}
            </Text>
            <Text>
              {t( 'pdf.claimsSummary.introText.pt1' )}
              {' '}
              <Text style={templateStyles.bold}>{formatNumber( totAnalyzed )}</Text>
              {' '}
              {t( 'pdf.claimsSummary.introText.pt2' )}
              {' '}
              <Text style={templateStyles.bold}>
                {formatNumber( data.tot_high_prob )}
                {' ('}
                {percentToString( totAnalyzedPerc )}
                {')'}
              </Text>
              {' '}
              {t( 'pdf.claimsSummary.introText.pt3' )}
              {' '}
              <Text style={templateStyles.bold}>
                {formatNumber( data.km_high_prob )}
                {' km'}
              </Text>
              {' '}
              {t( 'pdf.claimsSummary.introText.pt4' )}
            </Text>
          </View>
          <View>
            <Text style={[ templateStyles.title, templateStyles.titleBigMargin]}>
              {t( 'pdf.claimsSummary.statisticsHeaderText' )}
            </Text>
          </View>
          {charts.fraudProb}
        </PdfTemplate>
      </Page>
      <Page size='A4'>
        <PdfTemplate
          pageNum={2}
          {...pdfTemplateProps}
        >
          {charts.mxStates}
          {charts.policyTypes}
        </PdfTemplate>
      </Page>
    </Document>
  );
};

export default ClaimsSummaryPdf;
