import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { rootReducer } from "./rootReducer";


export const store = configureStore({
  reducer: rootReducer,
  enhancers: [ Sentry.createReduxEnhancer() ]
});

export type RootState   = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;