import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useAppTheme } from "../../../../hooks";
import { parseResponseError } from "../../../../utils/response-error";
import { numberToCurrency, formatNumber, isObjectEmpty } from "../../../../utils";
import { secondsToDuration } from "../../../../utils/time";
import { getScoreLevelPalette } from "../../../../utils/color-utils";
import { attemptTranslation, i18nObjectToString } from "../../../../utils/i18n";
import { ScoreService } from "../../../../services/ScoreService";
import { getAppConfig } from "../../../../config";
import { canClaimAmountBoxInProfileDetailsBeShown, canStatisticSectionForOutsideTripsInProfileDetailsBeShown } from "../../../../utils/profiles";
import { appConfig } from "../../../../config/app-config/app-config";
import { getTripsData } from "../../../../utils/trips-utils";
import { valueNotFoundSymbol } from "../../../../utils/constants";

import { GroundButton, GroundButtonTypes } from "@greenvulcano/ground-react";
import { ViewMoreButtonDictionaryItem } from "../../../../shared-components/ViewMoreButton/ViewMoreButtonInterface";
import { viewMoreDetailsModels } from '../../../../models/viewMoreDetailsModels';
import ViewMoreButton from "../../../../shared-components/ViewMoreButton/ViewMoreButton";
import MainSidebarCard from "../../../../shared-components/MainSidebarCard/MainSidebarCard";
import MoodyText from "../../../../shared-components/MoodyText/MoodyText";
import ButtonsSeparator from "../../../../shared-components/ButtonsSeparator/ButtonsSeparator";
import Chart from "../../../../shared-components/Chart/Chart";
import Select from "../../../../shared-components/RHF-input-wrappers/Select/Select";
import Textarea from "../../../../shared-components/RHF-input-wrappers/Textarea/Textarea";
import RequestIndicator from "../../../../shared-components/RequestIndicator/RequestIndicator";
import LevelBudge from "../../../../shared-components/LevelBudge/LevelBudge";
import LongTextTooltip from "../../../../shared-components/LongTextTooltip/LongTextTooltip";

import "./MainSidebarContent.scss";


const viewMoreDataDictionary: ViewMoreButtonDictionaryItem[] = [
  {
    key:  viewMoreDetailsModels.viewMorePolicyDetails.vin,
    i18nLabelKey: 'common.vin'
  },
  {
    key:  viewMoreDetailsModels.viewMorePolicyDetails.policyHolder,
    i18nLabelKey: 'common.policyHolder'
  },
  {
    key: viewMoreDetailsModels.viewMorePolicyDetails.policyNumber,
    i18nLabelKey: 'common.policyNumber'
  },
  {
    key: viewMoreDetailsModels.viewMorePolicyDetails.agencyName,
    i18nLabelKey: 'common.agency'
  },
  {
    key: viewMoreDetailsModels.viewMorePolicyDetails.agentName,
    i18nLabelKey: 'common.agent'
  },
  {
		key:  viewMoreDetailsModels.viewMorePolicyDetails.policyType,
    i18nLabelKey: "common.policyType",
    valueFormatter: ( value: any ) => i18nObjectToString( value )
  },
  {
		key:  viewMoreDetailsModels.viewMorePolicyDetails.address,
		i18nLabelKey: "common.residence",
  },
  {
		key: viewMoreDetailsModels.viewMorePolicyDetails.make,
		i18nLabelKey: "common.brand",
  },
  {
		key:  viewMoreDetailsModels.viewMorePolicyDetails.model,
		i18nLabelKey: "common.model",
  },
];



const getDataToShowDictionary = (): ViewMoreButtonDictionaryItem[] => {
  const dataToShowCandidates: (ViewMoreButtonDictionaryItem | undefined)[] = appConfig.viewMorePolicyDetails.map((item: viewMoreDetailsModels.viewMorePolicyDetails) => {
    const key = item as keyof typeof viewMoreDetailsModels.viewMorePolicyDetails;
    return viewMoreDataDictionary.find(entry => entry.key === key);
  });

  const filteredDataToShowDictionary: ViewMoreButtonDictionaryItem[] = dataToShowCandidates.filter((item): item is ViewMoreButtonDictionaryItem => item !== undefined);

  return filteredDataToShowDictionary;
};

const getChartOption = ({
  t,
  data,
  theme
}: Record<string, any> ) => {
  const scoreValue = ( Number( data.value ) || 0 );
  const scoreLabel = (
    data.label ?
    attemptTranslation( data.label, t ).toUpperCase() :
    t( 'common.unknown' )
  );

  let mainColor     = '';
  const mainPalette = getScoreLevelPalette( scoreValue );

  if ( 'success' === mainPalette ) {
    mainColor = theme.palettes.successLight;
  }
  else {
    mainColor = theme.palettes[mainPalette];
  }
  return {
    title: {
      text: [ `{a|${scoreValue}}`, `{b|${scoreLabel}}` ].join( '\n' ),
      subtext: String( t( 'common.score' ) ).toLowerCase(),
      left: 'center',
      top: '31%',
      itemGap: 8,
      textStyle: {
        fontFamily: theme.fontFamilies.primary,
        color: mainColor,
        rich: {
          a: {
            fontSize: 18,
            fontWeight: 'bold',
            lineHeight: 22
          },
          b: {
            fontSize: 10,
          },
        }
      },
      subtextStyle: {
        fontSize: 10,
        fontFamily: theme.fontFamilies.primary,
        color: theme.palettes.textLight
      }
    },
    series: [
      {
        name: t( 'common.riskEvents' ),
        type: 'pie',
        radius: ['62%', '102%'],
        cursor: 'default',
        itemStyle: {
          borderWidth: 5,
          borderColor: theme.palettes.background
        },
        emphasis: {
          disabled: true
        },
        label: {
          show: false
        },
        data: [
          {
            value: scoreValue,
            tooltip: {
              valueFormatter: ( value: string | number ) => ( value + '%' )
            },
            itemStyle: {
              color: mainColor
            }
          },
          {
            value: ( 100 - scoreValue ),
            itemStyle: {
              color: theme.palettes.backgroundTertiaryDark
            }
          }
        ]
      }
    ]
  };
};


const RiskEventsInfo = ({ value, label }: any ) => {
  return (
    <MoodyText
      color='textSecondaryDark'
      className='risk-events-info flex flex-vcenter gap-1/2'
    >
      <strong className='value'>
        {value ?? '–'}
      </strong>
      <span
        className='label text-xsmall'
        title={label}
      >
        {label.toLowerCase()}
      </span>
    </MoodyText>
  );
}

const MainSidebarContent = ({
  mainQuery,
  tripsQuery,
  statusChangeFormOptions: {
    control,
    formState:{ errors },
    mutation: {
      isLoading: mutationIsLoading
    },
    disableSubmit,
    onSubmit
  }
}: Record<string, any> ) => {
  const { t } = useTranslation( 'main' );
  const theme = useAppTheme();

  const data         = mainQuery.data || {};
  const tripsData    = useMemo(() => getTripsData( tripsQuery.data ), [ tripsQuery.status ] );
  const errorMessage = parseResponseError( mainQuery.error ).message;
  const duration     = secondsToDuration( tripsData.tot_duration, true ) as Record<string, string>;
  const showPolicyRiskEvents = getAppConfig('showPolicyRiskEvents');
  const showDetailsProfile = getAppConfig('showDetailsProfile');
  const scoreQueryEnabled = ( !isObjectEmpty( tripsData ) && tripsData.avg_score >= 0 );
  const showClaimAmount = canClaimAmountBoxInProfileDetailsBeShown(data.policy_type_id);
  const showStatisticSectionForOutsideTrips = canStatisticSectionForOutsideTripsInProfileDetailsBeShown(data.policy_type_id);
  const scoreQuery = useQuery({
    queryKey: [ 'score', tripsData.avg_score ],
    queryFn: () => ScoreService.getDecodedScore( tripsData.avg_score ),
    enabled: scoreQueryEnabled
  });

  return (
		<div className="flex flex-columns gap-3">
			<MainSidebarCard
				isSuccess={mainQuery.isSuccess}
				isLoading={!mainQuery.isError}
				errorMessage={errorMessage}
				className="pdetails-main-sidebar-section flex-grow"
			>
				{showDetailsProfile ? (
					<div>
						<h3 className="title mb-2">{t("common.profileDetail")}</h3>
						<p>
							<MoodyText color="textSecondaryDark" className="flex gap-1/2">
								<span className="uppercase">
									{t("common.policyHolder")}
									{": "}
								</span>
								<strong>
                  <LongTextTooltip
                    breakWords
                    showAtLength={45}
                    text={data.policyholder || valueNotFoundSymbol}
                  />
                </strong>
							</MoodyText>
						</p>
						<p>
							<MoodyText color="textSecondaryDark" className="flex gap-1/2">
								<span className="uppercase">
									{t("common.policy")}
									{": "}
								</span>
								<strong>{data.policy_id || valueNotFoundSymbol}</strong>
							</MoodyText>
						</p>
					</div>
				) : (
					<div>
						<h3 className="title mb-5/2">
							{t("common.driverId")}
							{": "}
							{data.profile_id || valueNotFoundSymbol}
						</h3>
						<div className="flex flex-columns gap-1/2">
							<strong>
								<MoodyText color="textSecondaryDark">
									{data.policyholder || valueNotFoundSymbol}
								</MoodyText>
							</strong>
							<MoodyText color="textSecondaryDark">
								{data.policy_type
									? t(`common.policyTypeDynamic`, {
											policyType: i18nObjectToString(data.policy_type),
									  })
									: valueNotFoundSymbol}
							</MoodyText>
						</div>
					</div>
				)}
				<ViewMoreButton
					modalOptions={{
						dataArray: [
							{
								data,
								title: undefined,
								dataToShowDictionary: getDataToShowDictionary(),
							},
						],
						titleMain: t("common.profileDetails"),
					}}
				/>
			</MainSidebarCard>
			{showPolicyRiskEvents ? (
				<MainSidebarCard
					isSuccess={tripsQuery.isSuccess}
					isLoading={!tripsQuery.isError}
					className="pdetails-main-sidebar-section flex-grow"
				>
					<h3 className="title">{t("common.riskEvents")}</h3>
					<div className="flex">
						<div className="risk-events-data-column flex flex-columns gap-2">
							<RiskEventsInfo
								value={tripsData.speeding}
								label={t("common.speeding")}
							/>
							<RiskEventsInfo
								value={tripsData.idling_time}
								label={t("common.idlingTime")}
							/>
							<RiskEventsInfo
								value={tripsData.long_distance}
								label={t("common.longDistance")}
							/>
							<RiskEventsInfo
								value={tripsData.night_time}
								label={t("common.nightTime")}
							/>
							<RiskEventsInfo
								value={tripsData.long_duration}
								label={t("common.longDuration")}
							/>
						</div>
						{scoreQuery.isSuccess ? (
							<Chart
								option={getChartOption({ t, theme, data: scoreQuery.data })}
							/>
						) : (
							<RequestIndicator
								heightAuto
								isLoading={!scoreQuery.isError && scoreQueryEnabled}
								spinnerOptions={{ scale: 0.3 }}
								errorTitle={t("errors.noData") as string}
								style={{ fontSize: "1rem" }}
							/>
						)}
					</div>
				</MainSidebarCard>
			) : null}
			<MainSidebarCard
				hideError
				isSuccess={mainQuery.isSuccess}
				isLoading={!mainQuery.isError}
				spinnerOptions={{ scale: 0.3 }}
				className="pdetails-main-sidebar-section flex-grow"
			>
				<h3 className="title">{t("common.fraudProbability")}</h3>
				<div className="mb-2 flex flex-vcenter gap-1">
					<span className="text-small">{t("common.policyType")}</span>{" "}
					<LevelBudge value={data.policy_type_fraud} />
				</div>
				<div className="flex flex-vcenter gap-1">
					<span className="text-small">{t("common.residence")}</span>{" "}
					<LevelBudge value={data.residence_fraud} />
				</div>
			</MainSidebarCard>
			{showClaimAmount && (
				<MainSidebarCard
					hideError
					isSuccess={mainQuery.isSuccess}
					isLoading={!mainQuery.isError}
					spinnerOptions={{ scale: 0.3 }}
					className="pdetails-main-sidebar-section flex-grow"
				>
					<div className="flex flex-vcenter gap-2">
						<h3 className="title no-margin">
							{t("common.claims")}
							{" = "}
							{data.claims_count ?? valueNotFoundSymbol}
						</h3>
						<ButtonsSeparator color="borderQuaternaryAlpha30" />
						<MoodyText
							color="textSecondaryDark"
							className="text-small text-bold"
						>
							{numberToCurrency(data.claims_amount)}
						</MoodyText>
					</div>
				</MainSidebarCard>
			)}
			<MainSidebarCard
				hideError
				isSuccess={tripsQuery.isSuccess}
				isLoading={!tripsQuery.isError}
				spinnerOptions={{ scale: 0.3 }}
				className="pdetails-main-sidebar-section flex-grow"
			>
				<h3 className="title">{t("common.statisticsForListOfTrips")}</h3>
				<div className="text-small flex flex-space-between">
					<MoodyText color="textSecondaryDark">
						<strong>{tripsData.trips_count ?? valueNotFoundSymbol}</strong>{" "}
						<MoodyText color="textSecondaryLight">
							{t("common.trips")}
						</MoodyText>
					</MoodyText>
					<ButtonsSeparator color="borderQuaternaryAlpha30" />
					<MoodyText color="textSecondaryDark">
						<strong>{formatNumber(tripsData.km_count) || valueNotFoundSymbol}</strong>{" "}
						<MoodyText color="textSecondaryLight">km</MoodyText>
					</MoodyText>
					<ButtonsSeparator color="borderQuaternaryAlpha30" />
					<MoodyText color="textSecondaryDark">
						<strong>{formatNumber(duration.hours) || "0"}</strong>
						<MoodyText color="textSecondaryLight">HH</MoodyText>
						{" - "}
						<strong>{formatNumber(duration.mins) || "0"}</strong>
						<MoodyText color="textSecondaryLight">MM</MoodyText>
					</MoodyText>
				</div>
        {data.policy_type && showStatisticSectionForOutsideTrips && (
          <div className="residence-statistics-container">
            <h3 className="title">{t("common.residenceFraudCalculation")}</h3>
            <div className="text-small flex flex-space-between">
              <MoodyText color="textSecondaryDark">
                <strong>{data.tot_trips ?? valueNotFoundSymbol}</strong>{" "}
                <MoodyText color="textSecondaryLight">
                  {t("common.totalTrips")}
                </MoodyText>
              </MoodyText>
              <ButtonsSeparator color="borderQuaternaryAlpha30" />
                <MoodyText color="textSecondaryDark">
                <strong>{data.trip_out_residence ?? valueNotFoundSymbol}</strong>{" "}
                <MoodyText color="textSecondaryLight">
                  {t("common.tripsOutside")}
                </MoodyText>
              </MoodyText>
              <ButtonsSeparator color="borderQuaternaryAlpha30" />
              <MoodyText color="textSecondaryDark">
                <strong>{data.trip_out_residence_perc ?? valueNotFoundSymbol}</strong>{" "}
                <MoodyText color="textSecondaryLight">%</MoodyText>
              </MoodyText>
            </div>
          </div>
				)}
			</MainSidebarCard>
		</div>
	);
};

export default MainSidebarContent;
