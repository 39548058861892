import { Document, Page } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { computeNumTripsInInfoPage } from "../../../utils/pdf";

import PdfTemplate from "../PdfTemplate/PdfTemplate";
import InfoPageContent from "./InfoPageContent/InfoPageContent";
import PdfTripsPages from "../PdfTripsPages/PdfTripsPages";
import { ClaimInfoPdfInterface } from "./ClaimInfoPdfInterface";


const ClaimInfoPdf = ({
  theme,
  claim,
  claimStatus,
  trips
}: ClaimInfoPdfInterface) => {
  const { t } = useTranslation( 'main' );
  const maxNumTripsInInfoPage = 3;
  const numTripsInInfoPage    = computeNumTripsInInfoPage( trips, maxNumTripsInInfoPage );
  const pdfTemplateProps      = {
    theme,
    claimStatus,
    reportIdLabel: t('common.claimId'),
    reportID: claim.claim_id
  };
  
  return (
    <Document>
      <Page size='A4'>
        <PdfTemplate {...pdfTemplateProps}>
          <InfoPageContent
            theme={theme}
            claim={claim}
            trips={trips.slice( 0, numTripsInInfoPage )}
            claimStatus={claimStatus}
          />
        </PdfTemplate>
      </Page>
      <PdfTripsPages
        trips={trips}
        pointerInitValue={numTripsInInfoPage}
        {...pdfTemplateProps}
      />
    </Document>
  );
};

export default ClaimInfoPdf;
