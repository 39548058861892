export const getStorageItem = ( key: string, defaultValue: any = null ) => {
  try {
    const item = localStorage.getItem( key ); 
  
    return ( typeof item === 'string' ? JSON.parse( item ) : (
      null === item ? defaultValue : item
    ));
  }
  catch ( error ) {
    return defaultValue;
  }
};

export const setStorageItem = ( key: string, value: any ) => {
  localStorage.setItem( key, JSON.stringify( value ) );
};

export const removeStorageItem = ( key: string ) => {
  localStorage.removeItem( key );
};