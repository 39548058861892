import { useEffect, useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppTheme } from "../../hooks";
import { isObject, isObjectEmpty } from "../../utils";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateFilters, selectFilters, resetFilters } from "../../store/slices/filtersSlice";

import { 
  GroundButton, 
  GroundButtonTypes,
  GroundIcon
} from "@greenvulcano/ground-react";
import TextButton from "../TextButton/TextButton";

import {
  FiltersDrawerInterface,
  FiltersDrawerFormValues
} from "./FiltersDrawerInterface";
import "./FiltersDrawer.scss";
import { appConfig } from "../../config/app-config/app-config";
import { appConfigModels } from "../../models/appConfigModels";


const Wrapper = styled.div`
  background: ${( props: any ) => props.theme.palettes.textAlpha50};
`;

const Drawer = styled.section`
  background: ${( props: any ) => props.theme.palettes.background};

  .toggle-button {
    background: ${( props: any ) => props.theme.palettes.secondary};
  }

  .header {
    border-bottom-color: ${( props: any ) => props.theme.palettes.borderSecondary}; 
  }
`;

const FiltersDrawer = ({
  storeSliceKey,
  showFilter,
  setShowFilter,
  renderContent = () => null,
}: FiltersDrawerInterface) => {
  const theme           = useAppTheme();
  const { t }           = useTranslation( 'main' );
  const appDispatch     = useAppDispatch();
  const submittedValues = useAppSelector( selectFilters( storeSliceKey ) ) as FiltersDrawerFormValues;
  const [ show, setShow ]     = useState( false );
  const [ defaultValues, setDefaultValues ]     = useState( {} as FiltersDrawerFormValues );
  const {
    control, 
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<FiltersDrawerFormValues>();

  const values = getValues();

  const disableSubmit = () => {
    const currentValues = watch();

    for ( const property in defaultValues ) {
      const currentValue = currentValues[property as keyof FiltersDrawerFormValues];
      const defaultValue = defaultValues[property as keyof FiltersDrawerFormValues];

      // Handles the objects produced by the GroundDateRangePicker and GroundRangeSlider. 
      if ( isObject( defaultValue ) ) {
        const _defaults = defaultValue as Record<string, string | number>;
        const _values   = currentValue as Record<string, string | number>;

        if ( Object.values( _values ).some(( value: string | number ) => '' === value ) ) {
          continue;
        }
        
        if ( Object.keys( _defaults ).find( ( key: string ) => ( _values[key] !== _defaults[key] ) ) ) {
          return false;
        }
      }
      else if ( currentValue !== defaultValue ) {
        return false;
      }
    }
    
    return isObjectEmpty( submittedValues );
  };

  const onClickToggle = () => {
    setShowFilter( value => !value );
  };

  const onSubmitHandler: SubmitHandler<FiltersDrawerFormValues> = ( data ) => {
    const processedData = Object.keys( data ).reduce(( outputObj: any, key: string ) => {
      const value = data[key as keyof FiltersDrawerFormValues];

      outputObj[key] = ( ( '__all' === value ) ? '' : value );

      return outputObj; 
    }, {} );

    setShowFilter( false );
    appDispatch( updateFilters( { key: storeSliceKey, filters: processedData }) );
  }

  const onResetHandler = () => {
    reset( defaultValues );
    appDispatch( resetFilters( { key: storeSliceKey }) );
  };

  useEffect(() => {
    if ( showFilter ) {
      setShow( true );

      Object.keys( submittedValues ).forEach(( key: string ) => {
        setValue( 
          key as keyof FiltersDrawerFormValues, 
          submittedValues[key as keyof FiltersDrawerFormValues]
        );
      })
    }
    else {
      setTimeout(() => setShow( false ), 300 )
    }
  }, [ showFilter ] );

  useEffect(() => {
    if ( !isObjectEmpty( values ) && isObjectEmpty( defaultValues ) ) {
      setDefaultValues( values );
    }
  }, [ values ] );

  return (
		<Wrapper
			theme={theme}
			className={classNames("filters-drawer-wrapper flex flex-right", { show })}
			onClick={() => setShowFilter(false)}
		>
			<Drawer
				theme={theme}
				className={classNames("filters-drawer flex flex-columns", {
					showFilter,
					filterButtonOnDrawer:
						appConfig.filterButtonPosition ===
						appConfigModels.filterButtonPosition.filtersDrawer,
				})}
				onClick={(event) => event.stopPropagation()}
			>
				<button
					type="button"
					className="toggle-button flex flex-vcenter"
					onClick={onClickToggle}
				>
					<GroundIcon svgIcon={show ? 'close' : 'filters'} width="18" />
				</button>
				<div className="header flex flex-vcenter flex-space-between">
					<h3>{t("common.filters")}</h3>
					<TextButton color="secondary" onClick={onResetHandler}>
						{t("common.resetAllFilters")}
					</TextButton>
				</div>
				<form
					className="flex flex-columns flex-grow"
					onSubmit={handleSubmit(onSubmitHandler)}
				>
					<>
						{renderContent({ control, errors, watch })}
						<div className="flex-grow flex flex-bottom">
							<GroundButton
								fluid
								className="uppercase mt-2"
								color="secondary"
								height="medium"
								type={GroundButtonTypes.submit}
								text={t("common.search")}
								disabled={disableSubmit()}
							/>
						</div>
					</>
				</form>
			</Drawer>
		</Wrapper>
	);
};

export default FiltersDrawer;