import { viewMoreDetailsModels } from '../../models/viewMoreDetailsModels';

export const viewMorePolicyDetails = [
    viewMoreDetailsModels.viewMorePolicyDetails.vin,
    viewMoreDetailsModels.viewMorePolicyDetails.policyHolder,
    viewMoreDetailsModels.viewMorePolicyDetails.policyNumber,
   /*  viewMoreDetailsModels.viewMorePolicyDetails.agentName,
    viewMoreDetailsModels.viewMorePolicyDetails.agencyName, */
    viewMoreDetailsModels.viewMorePolicyDetails.policyType,
    viewMoreDetailsModels.viewMorePolicyDetails.address,
    viewMoreDetailsModels.viewMorePolicyDetails.make,
    viewMoreDetailsModels.viewMorePolicyDetails.model,
   
];


export const getViewMorePolicyDetails = () => {
	return viewMorePolicyDetails;
};