import { CsvColumsItem } from "../../../utils/csv";
import { i18nObjectToString } from "../../../utils/i18n";
import { getFraudProbI18nKey } from "../../../utils/fraud-probability";
import { valueNotFoundSymbol } from "../../../utils/constants";
import { languageModels } from "../../../models/languageModels";
import { policyPageModels } from "../../../models/policyPageModels";
import { stripNulls } from "../../../utils/sanitization";


export const getCsvColumns = ({ t }: { t: languageModels.Tfn }): CsvColumsItem[] => {
  const maybeCastNumber = ( value: string ) => {
    return (
      ( value && !isNaN( Number( value ) ) ? 
      String( Math.round( Number( value ) * 100 ) / 100 ) : 
      valueNotFoundSymbol )
    );
  }

  return [
    {
      id: 'insurance_company',
      label: t( 'common.insuranceCompany' )
    },
    {
      id: policyPageModels.PolicyTableColumns.profileId,
      label: t( 'common.clientId' )
    },
    {
      id: policyPageModels.PolicyTableColumns.policyHolder,
      label: t( 'common.policyholderName' )
    },
    {
      id: policyPageModels.PolicyTableColumns.policyId,
      label: t( 'common.policyNumber' )
    },
    {
      id: policyPageModels.PolicyTableColumns.policyType,
      label: t( 'common.policyType' ),
      formatValue: ( value: any ) => {
        return i18nObjectToString( value );
      }
    },
    {
      id: policyPageModels.PolicyTableColumns.plate,
      label: t( 'common.plate' )
    },
    {
      id: policyPageModels.PolicyTableColumns.make,
      label: t( 'common.brand' )
    },
    {
      id: policyPageModels.PolicyTableColumns.model,
      label: t( 'common.model' )
    },
    {
      id: policyPageModels.PolicyTableColumns.year,
      label: t( 'common.year' )
    },
    {
      id: policyPageModels.PolicyTableColumns.vin,
      label: t( 'common.vin' )
    },
    {
      id: policyPageModels.PolicyTableColumns.address,
      label: t( 'common.residence' ),
      formatValue: stripNulls
    },
    {
      id: 'state',
      label: t( 'common.state' )
    },
    {
      id: 'municipality',
      label: t( 'common.municipality' )
    },
    {
      id: 'tot_trips',
      label: t( 'common.totalTrips' ),
      formatValue: maybeCastNumber
    },
    {
      id: 'tot_hours',
      label: t( 'common.travelTime' ) + ' (h)',
      formatValue: maybeCastNumber
    },
    {
      id: 'tot_km',
      label: t( 'common.kmTravelled' ),
      formatValue: maybeCastNumber
    },
    {
      id: 'trip_out_residence',
      label: t( 'common.tripsOutsideResidence' ),
      formatValue: maybeCastNumber
    },
    {
      id: 'trip_out_residence_perc',
      label: t( 'common.percentageOfTripsOutsideResidence' ) + ' (%)',
      formatValue: maybeCastNumber
    },
    {
      id: 'policy_type_fraud',
      label: t( 'common.policyTypeFraudProbability' ),
      formatValue: ( value: string ) => {
        return t( getFraudProbI18nKey( value ) );
      }
    },
    {
      id: 'residence_fraud',
      label: t( 'common.residenceFraudProbability' ),
      formatValue: ( value: string ) => {
        return t( getFraudProbI18nKey( value ) );
      }
    }
  ];
};