import { Controller } from "react-hook-form";
import classNames from "classnames";
import { TextInputInterface } from "./TextInputInterface";

import {
  GroundTextInput,
  GroundTextInputTypes
} from "@greenvulcano/ground-react";
import MoodyText from "../../MoodyText/MoodyText";
import "./TextInput.scss";


const TextInput = ({
  name = '',
  type = '',
  defaultValue = '',
  control,
  errors = {},
  customErrors = {},
  rules = {},
  className,
  ...rest
} : TextInputInterface ) => {
  if ( type === GroundTextInputTypes.email ) {
    rules = {
      pattern: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/i,
      ...rules,
    };
  }

  return (
    <div className='textinput-wrapper'>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => {
          let otherClassNames = '';

          if ( type === GroundTextInputTypes.password ) {
            otherClassNames = classNames(
              { 'space-letters': value }
            );
          }

          return (
            <GroundTextInput
              erratum={Boolean( errors[name] )}
              type={type}
              className={classNames( otherClassNames, className )}
              value={value}
              onChange={onChange}
              {...rest}
            />
          );
        }}
      />
      {errors[name] ? ( 
        <MoodyText className='validation-error'>
          {customErrors[errors[name].type] || errors[name].message}
        </MoodyText>
      ) : null}
    </div>
  );
};

export default TextInput;