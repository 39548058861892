import classNames from "classnames";
import { Controller } from "react-hook-form";
import { formatNumber } from "../../../utils";
import { secondsToDuration } from "../../../utils/time";

import { GroundRangeSlider, GroundRangeSliderValue } from "@greenvulcano/ground-react";
import { RangeSliderInterface } from "./RangeSliderInterface";


const formatLabelValue = ( value: number, valueType: string ) => {
  let formattedValue = '';

  switch ( valueType ) {
    case 'time':
      formattedValue = secondsToDuration( value ) as string;
      break;

    default:
      formattedValue = formatNumber( value );
      break;
  }

  return formattedValue;
};

const RangeSlider = ({
  name = '',
  min = 0,
  max = 100,
  minRange,
  valueType = 'number',
  label,
  control,
  errors = {},
  rules = {},
  className,
  ...rest
} : RangeSliderInterface ) => {
  if ( !minRange ) {
    minRange = Math.ceil( max * 0.05 ); // 5%
  }

  return (
    <div className={classNames(
      'range-slider-wrapper',
      className
    )}>
      {label ? (
        <div className='text-small pt-1 pb-1'>{label}</div>
      ): null}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={{ min, max }}
        render={({ field: { value, onChange } }) => {
          return (
            <GroundRangeSlider
              color='secondaryDark'
              cursorColor='secondary'
              labelColor='textLight'
              min={min}
              max={max}
              value={value}
              minRange={minRange}
              formatLabelValue={( value: number ) => formatLabelValue( value, valueType )}
              onChange={( value: GroundRangeSliderValue ) => onChange( value )}
              {...rest}
            />
          );
        }}
      />
    </div>
  );
};

export default RangeSlider;