import styled from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { useTranslation } from "react-i18next";
import { applyErrorBoundary } from "./utils/error-boundary";
import { getAppConfig, getAppLocale, getThemeToLoad } from "./config";

import { AppRouter } from "./routing/router";
import {
  GroundProvider,
	GroundToastProviderDirections,
} from "@greenvulcano/ground-react";
import ConfirmationModalProvider from "./shared-components/ConfirmationModalProvider/ConfirmationModalProvider";
import { useEffect } from "react";
import { useAppDispatch } from "./store/hooks";
import { setGoogleIsLoaded } from "./store/slices/mapSlice";
import { useGmapsApiLoader } from "./hooks/gmaps";
import ReactGA from "react-ga4";
import { env } from "./env";
import { hotjar } from "react-hotjar";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			staleTime: getAppConfig("staleTime", 10 * 1000),
			cacheTime: getAppConfig("cacheTime", 5 * 60 * 1000),
		},
	},
});
const toastOptions = {
	direction: GroundToastProviderDirections.center,
};

// Necessary hack to overcome the lack of support for
// internationalization of Ground.
const getDateRangePickerAdditionalDefaults = ({ t }: Record<string, any>) => {
	return {
		locale: getAppLocale(),
		utilityButtonsText: {
			today: t("ground.today"),
			currentWeek: t("ground.thisWeek"),
			currentMonth: t("ground.thisMonth"),
			lastMonth: t("ground.lastMonth"),
			lastYear: t("ground.lastYear"),
		},
	};
};

const PageWrapper = styled.div`
	color: ${(props: any) => props.theme.palettes.text};
`;

const App = () => {
	const { t } = useTranslation("main");
	const theme = getThemeToLoad();
	const appDispatch  = useAppDispatch();
  const { isLoaded } = useGmapsApiLoader();

  useEffect(() => {
    if (env.REACT_APP_HOTJAR_ID && env.REACT_APP_HOTJAR_SNIPPET_VERSION) {
      hotjar.initialize(
        parseInt(env.REACT_APP_HOTJAR_ID),
        parseInt(env.REACT_APP_HOTJAR_SNIPPET_VERSION)
      );
    }
    if (env.REACT_APP_GTAG_ID) {
      ReactGA.initialize(env.REACT_APP_GTAG_ID);
    }
  }, []);

	useEffect(() => {
		appDispatch(setGoogleIsLoaded(isLoaded))
	}, [appDispatch, isLoaded])

	theme.defaults.groundDateRangePicker = {
		...theme.defaults.groundDateRangePicker,
		...getDateRangePickerAdditionalDefaults({ t }),
	};

	return (
    <GroundProvider theme={theme} toastOptions={toastOptions}>
      <PageWrapper theme={theme} className="flex-grow flex flex-columns">
        <QueryClientProvider client={queryClient}>
          <ConfirmationModalProvider>
            <AppRouter />
          </ConfirmationModalProvider>
        </QueryClientProvider>
      </PageWrapper>
    </GroundProvider>
  );
};

export default applyErrorBoundary(App);
