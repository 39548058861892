/**
 * Removes all the isolated occurences of the word "null"
 * from a string.
 */
export const stripNulls = ( str: string ) => {
  return String( str ).replace( /^([^\w]*)null([^\w]+)/i, '$1$2' )
                      .replaceAll( /([^\w]+)null([^\w]+)/gi, '$1$2' )
                      .replace( /([^\w]+)null([^\w]*)$/i, '$1$2' )
                      .replaceAll( / +/g, ' ' )
                      .trim();
};