import { useTranslation } from "react-i18next";

import DrawerSection from "../../../../shared-components/FiltersDrawer/DrawerSection/DrawerSection";
import TextInput from "../../../../shared-components/RHF-input-wrappers/TextInput/TextInput";
import Select from "../../../../shared-components/RHF-input-wrappers/Select/Select";
// import RangeSlider from "../../../../shared-components/RHF-input-wrappers/RangeSlider/RangeSlider";
import { policyPageModels } from "../../../../models/policyPageModels";
import { policyFilters } from "../../../../config/app-config/policy-filters-config";
import Toggle from "../../../../shared-components/RHF-input-wrappers/Toggle/Toggle";


const DrawerContent = ({
  control,
  errors,
  scoreOptions
}: Record<string, any> ) => {
  const { t } = useTranslation('main');
  

  return (
    <>
      <DrawerSection title={t( 'common.searchYourClient' )}>
        {policyFilters.includes(policyPageModels.PolicyFilters.policyId) && <TextInput
          fluid
          name={policyPageModels.PolicyFilters.policyId}
          control={control}
          errors={errors}
          height='40'
          placeholder={t('common.policyNumber') as string}
        />}
        {policyFilters.includes(policyPageModels.PolicyFilters.policyHolder) && <TextInput
          fluid
          name={policyPageModels.PolicyFilters.policyHolder}
          control={control}
          errors={errors}
          height='40'
          placeholder={t('common.policyholderName') as string}
        />}
        { policyFilters.includes(policyPageModels.PolicyFilters.policyTypeId) && <Select
          fluid
          name={policyPageModels.PolicyFilters.policyTypeId}
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.policyType' ) as string}
          defaultValue='__all'
          options={[
            { value: '__all', label: t( 'common.allPolicyType' )},
            { value: '1', label: t( 'common.personal' )},
            { value: '2', label: t( 'common.commercial' )}
          ]}
        />}
        {policyFilters.includes(policyPageModels.PolicyFilters.address) && <TextInput
          fluid
          name={policyPageModels.PolicyFilters.address}
          control={control}
          errors={errors}
          height='40'
          placeholder={t('common.residenceAddress') as string}
        />}
        
       { policyFilters.includes(policyPageModels.PolicyFilters.plate) && <TextInput
          fluid
          name={policyPageModels.PolicyFilters.plate}
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.plate' ) as string}
        />}
       { policyFilters.includes(policyPageModels.PolicyFilters.model) && <TextInput
          fluid
          name={policyPageModels.PolicyFilters.model}
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.model' ) as string}
        />}
       { policyFilters.includes(policyPageModels.PolicyFilters.make) && <TextInput
          fluid
          name={policyPageModels.PolicyFilters.make}
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.brand' ) as string}
        />}
        { policyFilters.includes(policyPageModels.PolicyFilters.vin) && <TextInput
          fluid
          name={policyPageModels.PolicyFilters.vin}
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.vin' ) as string}
        />}
        { policyFilters.includes(policyPageModels.PolicyFilters.agency) && <TextInput
          fluid
          name={policyPageModels.PolicyFilters.agency}
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.agency' ) as string}
        />}
       { policyFilters.includes(policyPageModels.PolicyFilters.agent) && <TextInput
          fluid
          name={policyPageModels.PolicyFilters.agent}
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.agent' ) as string}
        />}
         {/* <Select
          fluid
          name='score'
          control={control}
          errors={errors}
          height='40'
          placeholder={t( 'common.score' ) as string}
          defaultValue='__all'
          options={[
            { value: '__all', label: t( 'common.allScores' )},
            ...scoreOptions
          ]}
        />
        <RangeSlider
          name='trips_count'
          max={1000}
          step={5}
          control={control}
          errors={errors}
          label={t( 'common.nTrips' ) as string}
        />
        <RangeSlider
          name='km_count'
          max={1000000}
          step={1000}
          minRange={1000}
          control={control}
          errors={errors}
          label={t( 'common.nKM' ) as string}
        />
        <RangeSlider
          name='tot_duration'
          valueType='time'
          max={5000*60*60}
          step={1*60*60}
          minRange={100*60*60}
          control={control}
          errors={errors}
          label={t( 'common.durationHHMM' ) as string}
        /> */}
      </DrawerSection>
      { policyFilters.includes(policyPageModels.PolicyFilters.policyTypeFraud) && <DrawerSection title={t( 'common.policyTypeFraud' )}>
        <div className='flex gap-7'>
          <div className='flex flex-columns gap-1'>
            <Toggle
              animated
              color='successLight'
              name='policy_type_fraud_low'
              control={control}
              errors={errors}
              label={t( 'common.low' ) as string}
              scale={0.8}
              defaultValue={true}
            />
            <Toggle
              animated
              color='danger'
              name='policy_type_fraud_high'
              control={control}
              errors={errors}
              label={t( 'common.high' ) as string}
              scale={0.8}
              defaultValue={true}
            />
            <Toggle
              animated
              color='textSecondaryLight'
              name='policy_type_fraud_inapplicable'
              control={control}
              errors={errors}
              label={t( 'common.inapplicable' ) as string}
              scale={0.8}
              defaultValue={true}
            />
          </div>
          <div className='flex flex-columns gap-1'>
            <Toggle
              animated
              color='warning'
              name='policy_type_fraud_medium'
              control={control}
              errors={errors}
              label={t( 'common.medium' ) as string}
              scale={0.8}
              defaultValue={true}
            />
            <Toggle
              animated
              color='secondary'
              name='policy_type_fraud_analyzing'
              control={control}
              errors={errors}
              label={t( 'common.analyzing' ) as string}
              scale={0.8}
              defaultValue={true}
            />
          </div>
        </div>
        </DrawerSection>}
        { policyFilters.includes(policyPageModels.PolicyFilters.residenceFraud) && <DrawerSection title={t( 'common.residenceFraud' )}>
        <div className='flex gap-7'>
          <div className='flex flex-columns gap-1'>
            <Toggle
              animated
              color='successLight'
              name='residence_fraud_low'
              control={control}
              errors={errors}
              label={t( 'common.low' ) as string}
              scale={0.8}
              defaultValue={true}
            />
            <Toggle
              animated
              color='danger'
              name='residence_fraud_high'
              control={control}
              errors={errors}
              label={t( 'common.high' ) as string}
              scale={0.8}
              defaultValue={true}
            />
            <Toggle
              animated
              color='textSecondaryLight'
              name='residence_fraud_inapplicable'
              control={control}
              errors={errors}
              label={t( 'common.inapplicable' ) as string}
              scale={0.8}
              defaultValue={true}
            />
          </div>
          <div className='flex flex-columns gap-1'>
            <Toggle
              animated
              color='warning'
              name='residence_fraud_medium'
              control={control}
              errors={errors}
              label={t( 'common.medium' ) as string}
              scale={0.8}
              defaultValue={true}
            />
            <Toggle
              animated
              color='secondary'
              name='residence_fraud_analyzing'
              control={control}
              errors={errors}
              label={t( 'common.analyzing' ) as string}
              scale={0.8}
              defaultValue={true}
            />
          </div>
        </div>
      </DrawerSection>}
    </>
  );
};

export default DrawerContent
