import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useConfirmationModal } from "../../hooks";
import { valueNotFoundSymbol } from "../../utils/constants";
import { stripNulls } from "../../utils/sanitization";

import TextButton from "../TextButton/TextButton";
import { DetailsPageModalDataItem } from "../confirmation-modals/DetailsPageModal/DetailsPageModalInterface";
import DetailsPageModal from "../confirmation-modals/DetailsPageModal/DetailsPageModal";

import {
  ViewMoreButtonInterface,
  ViewMoreButtonDictionaryItem
} from "./ViewMoreButtonInterface";
import "./ViewMoreButton.scss";

type ModalDataArrayItem = {
  label: string;
  value: any;
};

type ModalDataArray = ModalDataArrayItem[]

type ModalDataGroupType = {
  title?: string | null;
  modalDataArray: ModalDataArray;
};

type ModalDataGroupArrayType = ModalDataGroupType[];

const ViewMoreButton = ({
  modalOptions: {
    titleMain,
    dataArray,
  },
  className
}: ViewMoreButtonInterface ) => {
  const { t } = useTranslation( 'main' );
  const confirmationModal = useConfirmationModal();

  const modalDataGroupArray: ModalDataGroupArrayType= dataArray.map((dataObj) => {
    const tempModalDataArray =
      dataObj.dataToShowDictionary?.map(
        (dataItem: ViewMoreButtonDictionaryItem) => {
          return {
            value:
              dataItem.key in dataObj.data
                ? typeof dataItem.valueFormatter === "function"
                  ? dataItem.valueFormatter(dataObj.data[dataItem.key])
                  : dataObj.data[dataItem.key]
                : "–",
            label: t(dataItem.i18nLabelKey),
          };
        }
      ) ?? [];
    if (!!dataObj.extraDataItems) {
      dataObj.extraDataItems?.forEach((item) => tempModalDataArray?.push(item));
    }
    return {
      title: dataObj.title,
      modalDataArray: tempModalDataArray
    }
  });

  const modalContent = (
    <>
      {modalDataGroupArray?.map((modalDataGroup, index) => {
        if (!modalDataGroup.modalDataArray.length) return null;
        return (
          <div className={"modal-data-group"} key={`modal-data-group-${index}`}>
            {modalDataGroup.title && (
              <>
                <div className={"modal-data-group-title-box"} />
                <h2>{modalDataGroup.title}</h2>
              </>
            )}
            {modalDataGroup.modalDataArray.map(
              (item: DetailsPageModalDataItem, idx: number) => {
                return (
                  <p key={"dpageModalInfoItem_" + idx}>
                    <span className="uppercase">{item.label}</span>
                    {": "}
                    <strong>{ stripNulls( item.value ) || valueNotFoundSymbol}</strong>
                  </p>
                );
              }
            )}
          </div>
        );
      })}
    </>
  );
  const onClickHandler = () => {
    confirmationModal.open(
      <DetailsPageModal
        title={titleMain}
        content={modalContent}
      />
    )
  };

  return (
    <TextButton
      color='secondary'
      className={classNames(
        'view-more-button',
        className
      )}
      onClick={onClickHandler}
    >
      {t( 'common.viewMoreDetails' )}
    </TextButton>
  );
};

export default ViewMoreButton;
