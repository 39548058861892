import { policyPageModels } from "../../models/policyPageModels";

export const showTelematicData = false;
const policyTelematicFilters: policyPageModels.PolicyFilters[] = [];
export const policyFilters = [
	policyPageModels.PolicyFilters.profileId,
	policyPageModels.PolicyFilters.policyHolder,
	policyPageModels.PolicyFilters.policyId,
	policyPageModels.PolicyFilters.policyTypeId,
	policyPageModels.PolicyFilters.policyTypeFraud,
	policyPageModels.PolicyFilters.residenceFraud,
	policyPageModels.PolicyFilters.plate,
	policyPageModels.PolicyFilters.address,
	policyPageModels.PolicyFilters.make,
	policyPageModels.PolicyFilters.model,
	policyPageModels.PolicyFilters.vin,
];

if (showTelematicData) {
	policyFilters.push(...policyTelematicFilters);
}
