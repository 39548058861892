import { appConfig } from "../config/app-config/app-config";


export const getFraudProbI18nKey = ( value: string | number ) => {
  const levels        = appConfig.fraudProbabilityLevels;
  const valueAsString = String( value );
  const valueAsNumber = ( Number( value ) || 0 );

  if ( levels.includes( valueAsString ) ) {
    return `common.${valueAsString}`;
  }

  if ( valueAsNumber >= 0 && valueAsNumber < levels.length ) {
    return 'common.' + levels[valueAsNumber];
  }

  return 'common.unknown';
};
