import axios from "axios";
import { getApiEndpoint } from "../config";
import { AuthService } from "./AuthService";

export const FunctionalitiesService = {
  getScoreOptions: async (): Promise<any> => {
    try {
      const { data: responseData } = await axios.get( 
        getApiEndpoint( 'drivingScores' ), 
        { ...AuthService.getAuthHeaders() }
      );

      const options = responseData.data.map(( item: any ) => {
        return {
          ...item,
          value: ( item.value.from + ':' + item.value.to )
        };
      });
      
      return Promise.resolve( options );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  },
  getVehicleTypesOptions: async (): Promise<any> => {
    try {
      const { data: responseData } = await axios.get( 
        getApiEndpoint( 'vehicleTypes' ), 
        { ...AuthService.getAuthHeaders() }
      );

      const options = responseData.data.map(( item: any ) => {
        return {
          ...item,
          value: item.id
        };
      });
      
      return Promise.resolve( options );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  },
  getDropdownOptions: async (): Promise<any> => {
    try {
      const [ scores, vehicleTypes ] = await Promise.all([
        FunctionalitiesService.getScoreOptions(),
        FunctionalitiesService.getVehicleTypesOptions()
      ]);
      
      return Promise.resolve({
        score: {
          id: 'score',
          options: scores 
        },
        vehicleTypes: {
          id: 'vehicleTypes',
          options: vehicleTypes 
        }
      });
    }
    catch ( error: any ) {
      return error;
    }
  }
};