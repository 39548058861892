import axios from "axios";
import { getApiEndpoint } from "../config";
import { AuthService } from "./AuthService";
import { normalizeData } from "../utils";

import { PeriodState } from "../store/slices/periodSlice";
import { periodToQueryParams } from "./utils";


export const AnalyticsService = {
  getSummary: async ( period: PeriodState ): Promise<any> => {
    try {
      const { data: responseData } = await axios.get(
        getApiEndpoint( 'analyticsSummary' ),
        {
          params: { ...periodToQueryParams( period ) },
          ...AuthService.getAuthHeaders()
        }
      );

      const allEventsEntityIDs = [
        'speeding',
        'nighttime',
        'idle_time',
        'long_trip',
        'crash'
      ];

      const allEventsDetail = Object.values( responseData.data.reduce(( _map: any, item: any ) => {
        if ( allEventsEntityIDs.includes( item.entity_id ) ) {
          item.detail.forEach(( detailDataItem: any ) => {
            const key = detailDataItem.date;

            if ( _map[key] ) {
              _map[key].value = Number( _map[key].value ) + Number( detailDataItem.value );
            }
            else {
              _map = {
                ..._map,
                [key]: { ...detailDataItem }
              }
            }
          });
        }
        
        return _map;
      }, {} ) );

      responseData.data.push({
        entity_id: 'all_events',
        detail: allEventsDetail
      });

      return Promise.resolve( normalizeData( responseData.data, 'entity_id' ) );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  },
  getChartData: async ( event_id: string, period: PeriodState ): Promise<any> => {
    try {
      const { data: responseData } = await axios.get(
        getApiEndpoint( 'analyticsChart' ),
        {
          params: { event_id, ...periodToQueryParams( period ) },
          ...AuthService.getAuthHeaders()
        }
      );

      return Promise.resolve( responseData.data );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  }
};