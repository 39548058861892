
import styled from "styled-components";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppTheme, useConfirmationModal } from "../../hooks";

import { GroundButton, GroundButtonVariants } from "@greenvulcano/ground-react";
import CloseButton from "../CloseButton/CloseButton";

import { ConfirmationModalInterface } from "./ConfirmationModalInterface";
import "./ConfirmationModal.scss";


const Modal = styled.div`
  background: ${( props: any ) => props.theme.palettes.background};
  
  h3 {
    color: ${( props: any ) => props.theme.palettes.primaryDarkest};
  }
  
  p {
    color: ${( props: any ) => props.theme.palettes.textSecondaryDark};
  }
`;

const ConfirmationModal = ({ 
  title,
  content,
  readOnly,
  isBlockingModal,
  confirmBtnText,
  className,
  style,
  onClose,
  onAction = () => undefined
}: ConfirmationModalInterface) => {
  const { t }             = useTranslation( 'main' );
  const theme             = useAppTheme();
  const confirmationModal = useConfirmationModal();
  const [ isLoading, setIsLoading ] = useState( false );

  const onCloseModalHandler = () => {
    if ( typeof onClose === 'function' ) {
      onClose();
    }
    
    setIsLoading( false );
    confirmationModal.close();
  };

  return (
    <Modal
      theme={theme}
      style={style}
      className={classNames(
        'confirmation-modal',
        className
      )}
      onClick={( event ) => event.stopPropagation()}
    >
      {!isBlockingModal && (
        <CloseButton
          disabled={isLoading}
          onClick={onCloseModalHandler}
        />
      )}
      {title && (
        <h3>{title}</h3>
      )}
      <div className='content'>{content}</div>
      {!( readOnly || isBlockingModal ) && (
        <div className='action-buttons flex'>
          <GroundButton
            fluid
            disabled={isLoading}
            variant={GroundButtonVariants.ghost}
            text={t( 'common.cancel' )}
            onClick={onCloseModalHandler}
            color="secondary"
          />
          <GroundButton
            fluid
            text={confirmBtnText || t( 'common.confirm' )}
            isLoading={isLoading}
            onClick={onAction}
            color="secondary"
          />
        </div>
      )}
    </Modal>
  );
};

export default ConfirmationModal;