export namespace viewMoreDetailsModels{
    export enum viewMoreClaimDetails {
		vin = "vin",
		policyHolder = "policyholder",
		policyId = "policy_id",
		policyType = "policy_type",
		address = "address",
		model = "model",
        make = "make",
        claimAmont = "claim_amount",
        agencyName = "agency_name",
        agentName = "agent_name"
	}

	export enum viewMorePolicyDetails {
		vin = "vin",
		policyHolder = "policyholder",
		policyNumber = "policy_id",
		policyType = "policy_type",
		address = "address",
		model = "model",
        make = "make",
        agencyName = "agency_name",
        agentName = "agent_name"
	}
}