import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

function GoogleAnalyticsReporter() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: "pageview", page:location.pathname });
  }, [location]);

  return null;
}

export default GoogleAnalyticsReporter;
