import axios, { AxiosError } from "axios";
import { getApiEndpoint } from "../config";
import { AuthService } from "./AuthService";
import { getI18n } from "react-i18next";
import { PeriodState } from "../store/slices/periodSlice";
import { filtersToQueryParams, periodToQueryParams } from "./utils";
import moment from "moment";
import { claimModels } from "../models/claimModels";


type updateClaimInterface = {
	claimId: string;
	claimPosition: { lat: number; lng: number };
	claimAddress: string;
	claimZipCode: string;
	idAnomalyType: string;
	claimDateAndTime: Date;
};

type updateClaimPayloadInterface = {
	claim_position: string;
	sinister_address: string;
	zip_code: string;
	id_claim_type: string;
	date_of_sinister: string;
	sinister_hour: string;
};

export const ClaimsService = {
	getClaims: async (
		queryParams: Record<string, any>,
		period: PeriodState,
    filters: Record<string, any>
	) => {
		try {
			const { data: responseData } = await axios.get(getApiEndpoint("claims"), {
				params: {
					...queryParams,
          ...filtersToQueryParams( filters ),
					...periodToQueryParams( period ),
				},
				...AuthService.getAuthHeaders(),
			});

      const claimListData = responseData.data.data as claimModels.ListDataItem[];

			return Promise.resolve({
				data: claimListData,
				totalItems: (
          Number(responseData.data.pagination.total) ||
					claimListData.length || 1
        )
			} as claimModels.ListResponse );
		} catch (error: any) {
			return AuthService.maybeForceLogout(error);
		}
	},
  getClaimsForReport: async ( 
    period: PeriodState, 
    queryParams: Record<string, any>, 
    filters: Record<string, any>
  ) => {
		try {
			const { data: responseData } = await axios.get(getApiEndpoint( 'claimsForReport' ), {
				params: {
					...queryParams,
          ...filtersToQueryParams( filters ),
					...periodToQueryParams( period ),
				},
				...AuthService.getAuthHeaders(),
			});

      const rawData    = responseData.data as claimModels.CsvReportDataItem[];
      const parsedData = rawData.map( item => {
        if ( item.policy_type ) {
          try {
            item.policy_type = JSON.parse( item.policy_type );
          }
          catch (error) {
            // Left empty on purpose.
          }
        }
        
        return item as claimModels.CsvReportParsedDataItem;
      });

      return Promise.resolve( parsedData );
		}
    catch ( error: any ) {
			return AuthService.maybeForceLogout( error );
		}
	},
  getClaimsSummaryForReport: async ( period: PeriodState ) => {
		try {
			const { data: responseData } = await axios.get(getApiEndpoint( 'claimsSummaryForReport' ), {
				params: periodToQueryParams(period),
				...AuthService.getAuthHeaders(),
			});

      return Promise.resolve( responseData.data as claimModels.SummaryReportData );
		}
    catch ( error: any ) {
			return AuthService.maybeForceLogout( error );
		}
	},
	getClaimDetails: async (id: string | number) => {
		try {
			const { data: responseData } = await axios.get(
				getApiEndpoint("singleClaim", { id }),
				AuthService.getAuthHeaders()
			);

      const rawData = responseData.data as claimModels.Single;

			let fraud_probability_notes = [];
      let policy_type = {};

			try {
        if ( rawData.fraud_probability_notes ) {
          fraud_probability_notes = JSON.parse( rawData.fraud_probability_notes );
        }
				
        if ( rawData.policy_type ) {
          policy_type = JSON.parse( rawData.policy_type );
        }
			} catch (error) {
        // Left empty on purpose.
      }

			return Promise.resolve({
				...rawData,
				fraud_probability_notes,
        policy_type
			} as claimModels.SingleProcessed );
		} catch (error: any) {
			return AuthService.maybeForceLogout(error);
		}
	},
	changeClaimStatus: async (
		claimID: string | number,
		payload: Record<string, any>
	): Promise<any> => {
		try {
			const { data: responseData } = await axios.post(
				getApiEndpoint("singleClaim", { id: claimID }),
				payload,
				{
					params: { action: "change_status" },
					...AuthService.getAuthHeaders(),
				}
			);

			return Promise.resolve(responseData);
		} catch (error: any) {
			return AuthService.maybeForceLogout(error);
		}
	},
	addClaim: async (payload: { id_voucher: string }) => {
		try {
			const { data: responseData } = await axios.post(
				getApiEndpoint("addClaim"),
				payload,
				AuthService.getAuthHeaders()
			);
			return Promise.resolve(String(responseData.data.claim_id));
		} catch (error) {
			const axiosError = error as AxiosError;
			const i18n = getI18n();
			const errorMessage = i18n.t("main:errors.failedToCreateClaim");
			axiosError.message = errorMessage;
			return Promise.reject(axiosError.message);
		}
	},
	updateClaim: async (claimToUpdate: updateClaimInterface) => {
		try {
			const claimPosition = `POINT(${claimToUpdate.claimPosition.lng} ${claimToUpdate.claimPosition.lat})`;
				const claimDate = moment(claimToUpdate.claimDateAndTime).format("YYYY-MM-DD");
				const claimTime = moment(claimToUpdate.claimDateAndTime).format("HH:mm:ss");
			const payload: updateClaimPayloadInterface = {
				claim_position: claimPosition,
				zip_code: claimToUpdate.claimZipCode,
				sinister_address: claimToUpdate.claimAddress,
				id_claim_type: claimToUpdate.idAnomalyType,
				date_of_sinister: claimDate,
				sinister_hour: claimTime,
			};
			
			const { data: responseData } = await axios.put(
				getApiEndpoint("updateClaim", { id: claimToUpdate.claimId }),
				payload,
				AuthService.getAuthHeaders()
			);

			return Promise.resolve(String(responseData.data.claim_id));
		} catch (error) {
			const axiosError = error as AxiosError;
			const i18n = getI18n();
			const errorMessage = i18n.t("main:errors.failedToUpdateClaim");
			axiosError.message = errorMessage;
			return Promise.reject(axiosError.message);
		}
	},
	calculateFraud : async ( claimId: string) => {
		try {
			const { data: responseData } = await axios.put(
				getApiEndpoint( 'calculateFraud',{id: claimId} ),
				{
				},
					AuthService.getAuthHeaders()
			);
	
			return Promise.resolve( responseData.data);
		}
		catch (error) {
			const typeError = error as AxiosError;
			const i18n = getI18n();
			const errorMessage = i18n.t('main:errors.failedToCalculateFraud');
			typeError.message = errorMessage;
			return  Promise.reject(typeError.message);
		}
	}
};
