import { apiEndpoints, ApiEndpoint } from "./api-endpoints";
import { appConfig } from "./app-config/app-config";
import { Themes } from "../themes";
import { AppDateFormats } from "../constants";
import { isObjectEmpty } from "../utils";
import { getStorageItem, setStorageItem } from "../utils/local-storage"
import { appConfigModels } from '../models/appConfigModels';


export const getApiEndpoint = ( key: ApiEndpoint, params?: Record<string, string | number> ): string => {
  if ( !( key in apiEndpoints ) ) {
    return '';
  }

  let endpoint = apiEndpoints[key];

  if ( params && !isObjectEmpty( params ) ) {
    let pattern        = ':(' + Object.keys( params ).join( '|' ) + ')/';
    const tempEndpoint = endpoint.replace( new RegExp( pattern, 'gi' ), ( match, ...args ) => {
      return String( params[args[0]] ) + '/';
    });

    pattern  = ( '/:(' + Object.keys( params ).join( '|' ) + ')/*$' );
    endpoint = tempEndpoint.replace( new RegExp( pattern, 'i' ), ( match, ...args ) => {
      return '/' + String( params[args[0]] );
    });
  }

  return endpoint;
};


export const getAppConfig = ( key: keyof  appConfigModels.appConfigInterface , defaultValue: any = '' ) => {
  return ( key in appConfig ? appConfig[key] : defaultValue );
};


export const setAppLocale = ( locale: string ) => {
  setStorageItem( 'locale', locale );
};


export const getAppLocale = () => {
  return String( getStorageItem( 'locale', getAppConfig( 'defaultLocale', 'en-US' ) ) );
};


export const getAppLanguage = () => {
  return getAppLocale().split( '-' )[0] || 'en';
};


export const getThemeToLoad = () => {
  const themeName = getAppConfig( 'themeName' );

  return ( themeName in Themes ? Themes[themeName] : Themes.DefaultTheme );
};

export const getAppDateFormat = () => {
  const appDateFormat = getAppConfig( 'dateFormat' );

  switch ( appDateFormat ) {
    case AppDateFormats.ymd:
      return 'YYYY/M/D';

    case AppDateFormats.mdy:
      return 'M/D/YYYY';

    default:
      return 'D/M/YYYY';
  }
};

export const getAppDateFormatWithAproximateTime = () => {
  return getAppDateFormat() + ' HH:mm';
};

export const setDocumentTitle = ( afterHyphen = '' ) => {
  let title = getAppConfig( 'appTitle', '' );

  if ( afterHyphen ) {
    title += ` - ${afterHyphen}`;
  }

  document.title = title;
};