import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export const browsingLockedSlice = createSlice({
  name: 'browsingLocked',
  initialState: false,
  reducers: {
    lock: () => {
      return true;
    },
    unlock: () => {
      return false;
    }
  }
});

export const {
  lock: lockBrowsing,
  unlock: unlockBrowsing
} = browsingLockedSlice.actions;

export const selectBrowsingLocked = ( state: RootState ) => state.browsingLocked;

export default browsingLockedSlice.reducer;
