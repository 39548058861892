import { getAppConfig } from "../../../config";
import { claimModels } from "../../../models/claimModels";
import { languageModels } from "../../../models/languageModels";
import { isObject } from "../../../utils";
import { i18nObjectToString } from "../../../utils/i18n";
import { PdfChartOptionDataItem, getPdfPieChartOption } from "../../../utils/pdf-chart-option-utils";


export const getFraudProbPieChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: claimModels.SummaryReportData
}) => {
  if ( !data ) {
    return {};
  }

  const chartData: PdfChartOptionDataItem[] = [
    {
      value: Number( data.tot_low_prob ) || 0,
      name: t( 'common.low' ),
      color: theme.palettes.successLight
    },
    {
      value: Number( data.tot_medium_prob ) || 0,
      name: t( 'common.medium' ),
      color: theme.palettes.warning
    },
    {
      value: Number( data.tot_high_prob ) || 0,
      name: t( 'common.high' ),
      color: theme.palettes.danger
    }
  ];

  return getPdfPieChartOption(
    t( 'pdf.claimsSummary.chartTitle.fraudProb' ),
    chartData
  );
};

export const getMxStatesPieChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: claimModels.SummaryReportData
}) => {
  const dataToProcess = ( data && Array.isArray( data.states_high_prob )  ) ? data.states_high_prob : [];

  const chartData: PdfChartOptionDataItem[] = [];
  const pdfPieChartsMaxSlices = getAppConfig( 'pdfPieChartsMaxSlices' );
  const minEnd  = Math.min( dataToProcess.length, pdfPieChartsMaxSlices );
  let othersSum = 0;

  for ( let i = 0; i < minEnd; i++ ) {
    const dataItem = (
      isObject( dataToProcess[i] ) ? 
      dataToProcess[i] : 
      {} as typeof dataToProcess[0]
    );

    const value = ( Number( dataItem.claims_count ) || 0 );

    if ( value ) {
      if ( !dataItem.state ) {
        othersSum += value;

        continue;
      }

      chartData.push({
        value: ( Number( dataItem.claims_count ) || 0 ),
        name: String( dataItem.state )
      });
    }
  }

  if ( dataToProcess.length > pdfPieChartsMaxSlices ) {
    for ( let i = minEnd; i < dataToProcess.length; i++ ) {
      const dataItem = (
        isObject( dataToProcess[i] ) ? 
        dataToProcess[i] : 
        {} as typeof dataToProcess[0]
      );

      othersSum += ( Number( dataItem.claims_count ) || 0 );
    }
  }

  if ( othersSum ) {
    chartData.push({
      value: othersSum,
      name: t( 'common.others' ),
      color: '#777'
    });
  }

  return getPdfPieChartOption(
    t( 'pdf.claimsSummary.chartTitle.mxStates' ),
    chartData
  );
};

export const getPolicyTypesPieChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: claimModels.SummaryReportData
}) => {
  const dataToProcess = ( data && Array.isArray( data.policy_types_high_prob )  ) ? data.policy_types_high_prob : [];

  const chartData: PdfChartOptionDataItem[] = [];
  let unknownClaimsCountSum = 0;
  const end = dataToProcess.length;

  // Data come already sorted in descending order.
  for ( let i = 0; i < end; i++ ) {
    const dataItem = (
      isObject( dataToProcess[i] ) ? 
      dataToProcess[i] : 
      {} as typeof dataToProcess[0]
    );

    if ( !dataItem.policy_type ) {
      unknownClaimsCountSum += ( Number( dataItem.claims_count ) || 0 );

      continue;
    }

    let i18nObject = {};

    try {
      i18nObject = JSON.parse( dataItem.policy_type );
    }
    catch ( error ) {
      // Left empty on purpose
    }

    chartData.push({
      value: ( Number( dataItem.claims_count ) || 0 ),
      name: i18nObjectToString( i18nObject )
    });
  }

  if ( unknownClaimsCountSum ) {
    chartData.push({
      value: unknownClaimsCountSum,
      name: t( 'common.others' )
    });
  }

  return getPdfPieChartOption(
    t( 'pdf.claimsSummary.chartTitle.policyTypes' ),
    chartData
  );
};