import classNames from "classnames";
import styled from "styled-components";
import { useAppTheme } from "../../hooks";

import "./ButtonsSeparator.scss";


const Separator = styled.div`
  border-left-color: ${( props: any ) => props.theme.palettes[props.color]};
`;

const ButtonsSeparator = ({
  color = 'borderQuaternary',
  className
}: any) => {
  const theme = useAppTheme();

  return (
    <Separator
      theme={theme}
      color={color}
      className={classNames(
        'buttons-separator',
        className
      )}
    />
  );
};

export default ButtonsSeparator;