import { policyPageModels } from "../../models/policyPageModels";

const policyTableTelematicColumns = [
	policyPageModels.PolicyTableColumns.residenceFraud,
	policyPageModels.PolicyTableColumns.policyTypeFraud,
	// policyPageModels.PolicyTableColumns.score,
	policyPageModels.PolicyTableColumns.tripsCount,
	policyPageModels.PolicyTableColumns.kmCount,
	policyPageModels.PolicyTableColumns.totDuration,
	// policyPageModels.PolicyTableColumns.riskEvents,
];
export const policyTableColumns = [
	policyPageModels.PolicyTableColumns.profileId,
	policyPageModels.PolicyTableColumns.policyHolder,
	policyPageModels.PolicyTableColumns.policyId,
	policyPageModels.PolicyTableColumns.vin,
	policyPageModels.PolicyTableColumns.plate,
	policyPageModels.PolicyTableColumns.make,
	policyPageModels.PolicyTableColumns.model,
	policyPageModels.PolicyTableColumns.year,
	policyPageModels.PolicyTableColumns.policyType,
	policyPageModels.PolicyTableColumns.address, 
	policyPageModels.PolicyTableColumns.policyTypeFraud,
	policyPageModels.PolicyTableColumns.residenceFraud,
];

export const getPolicyTableColumns = ({
	showTelematicData,
	showCreateClaimButton,
}: {
	showTelematicData: boolean;
	showCreateClaimButton: boolean;
}) => {
	if (showTelematicData) {
		policyTableColumns.push(...policyTableTelematicColumns);
	}
	if (showCreateClaimButton) {
		policyTableColumns.push(
			policyPageModels.PolicyTableColumns.createClaimButton
		);
	}
	return policyTableColumns;
};
