import { getAppConfig } from "../../../config";
import { languageModels } from "../../../models/languageModels";
import { policyModels } from "../../../models/policyModels";
import { formatNumber, isObject } from "../../../utils";
import {
  PdfChartOptionDataItem, 
  getPdfPieChartOption ,
  getPdfBarChartOption
} from "../../../utils/pdf-chart-option-utils";


export const getFraudProbPieChartOption = ({
  t,
  data,
  theme,
  fraudProbID
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData,
  fraudProbID: 'policy_type' | 'residence'
}) => {
  if ( !data ) {
    return {};
  }

  const chartData: PdfChartOptionDataItem[] = [
    {
      value: ( Number( data[`tot_low_prob_${fraudProbID}`] ) || 0 ),
      name: t( 'common.low' ),
      color: theme.palettes.successLight
    },
    {
      value: ( Number( data[`tot_medium_prob_${fraudProbID}`] ) || 0 ),
      name: t( 'common.medium' ),
      color: theme.palettes.warning
    },
    {
      value: ( Number( data[`tot_high_prob_${fraudProbID}`] ) || 0 ),
      name: t( 'common.high' ),
      color: theme.palettes.danger
    }
  ];

  return getPdfPieChartOption(
    t( `pdf.policiesSummary.chartTitle.fraudProb_${fraudProbID}` ),
    chartData
  );
};

export const getFraudProbPolicyTypePieChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData
}) => {
  return getFraudProbPieChartOption({ t, data, theme, fraudProbID: 'policy_type' });
};

export const getFraudProbResidencePieChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData
}) => {
  return getFraudProbPieChartOption({ t, data, theme, fraudProbID: 'residence' });
};

export const getMxStatesPieChartOption = ({
  t,
  data,
  theme,
  fraudProbID
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData;
  fraudProbID: 'policy_type' | 'residence';
}) => {
  const dataToProcess = ( data && Array.isArray( data.states_high_prob )  ) ? data.states_high_prob : [];

  const chartData: PdfChartOptionDataItem[] = [];
  const pdfPieChartsMaxSlices = getAppConfig( 'pdfPieChartsMaxSlices' );
  const minEnd  = Math.min( dataToProcess.length, pdfPieChartsMaxSlices );
  let othersSum = 0;

  const orderedData = dataToProcess.sort( ( itemA, itemB ) => {
    const valueA = ( Number( itemA[`tot_high_prob_${fraudProbID}`] ) || 0 );
    const valueB = ( Number( itemB[`tot_high_prob_${fraudProbID}`] ) || 0 );

    return ( valueB - valueA );
  });

  for ( let i = 0; i < minEnd; i++ ) {
    const dataItem = (
      isObject( orderedData[i] ) ? 
      orderedData[i] : 
      {} as typeof orderedData[0]
    );

    const value = ( Number( dataItem[`tot_high_prob_${fraudProbID}`] ) || 0 );

    if ( value ) {
      if ( !dataItem.state ) {
        othersSum += value;

        continue;
      }

      chartData.push({
        value: ( Number( dataItem[`tot_high_prob_${fraudProbID}`] ) || 0 ),
        name: String( dataItem.state )
      });
    }
  }

  if ( orderedData.length > pdfPieChartsMaxSlices ) {
    for ( let i = minEnd; i < orderedData.length; i++ ) {
      const dataItem = (
        isObject( orderedData[i] ) ? 
        orderedData[i] : 
        {} as typeof orderedData[0]
      );

      othersSum += ( Number( dataItem[`tot_high_prob_${fraudProbID}`] ) || 0 );
    }
  }

  if ( othersSum ) {
    chartData.push({
      value: othersSum,
      name: t( 'common.others' ),
      color: '#777'
    });
  }

  return getPdfPieChartOption(
    t( `pdf.policiesSummary.chartTitle.mxStates_${fraudProbID}` ),
    chartData
  );
};

export const getMxStatesPolicyTypePieChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData
}) => {
  return getMxStatesPieChartOption({ t, theme, data, fraudProbID: 'policy_type' });
};

export const getMxStatesResidencePieChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData
}) => {
  return getMxStatesPieChartOption({ t, theme, data, fraudProbID: 'residence' });
};

export const getVehicleModelsPieChartOption = ({
  t,
  data,
  theme,
  fraudProbID
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData;
  fraudProbID: 'policy_type' | 'residence';
}) => {
  const dataToProcess = ( data && Array.isArray( data.vehicle_models_high_prob )  ) ? data.vehicle_models_high_prob : [];
  
  const chartData: PdfChartOptionDataItem[] = [];
  const pdfPieChartsMaxSlices = getAppConfig( 'pdfPieChartsMaxSlices' );
  const minEnd  = Math.min( dataToProcess.length, pdfPieChartsMaxSlices );
  let othersSum = 0;

  const orderedData = dataToProcess.sort( ( itemA, itemB ) => {
    const valueA = ( Number( itemA[`tot_high_prob_${fraudProbID}`] ) || 0 );
    const valueB = ( Number( itemB[`tot_high_prob_${fraudProbID}`] ) || 0 );

    return ( valueB - valueA );
  });

  for ( let i = 0; i < minEnd; i++ ) {
    const dataItem = (
      isObject( orderedData[i] ) ? 
      orderedData[i] : 
      {} as typeof orderedData[0]
    );

    const value = ( Number( dataItem[`tot_high_prob_${fraudProbID}`] ) || 0 );

    if ( value ) {
      if ( !dataItem.model ) {
        othersSum += value;

        continue;
      }

      chartData.push({
        value: ( Number( dataItem[`tot_high_prob_${fraudProbID}`] ) || 0 ),
        name: String( dataItem.model )
      });
    }
  }

  if ( orderedData.length > pdfPieChartsMaxSlices ) {
    for ( let i = minEnd; i < orderedData.length; i++ ) {
      const dataItem = (
        isObject( orderedData[i] ) ? 
        orderedData[i] : 
        {} as typeof orderedData[0]
      );

      othersSum += ( Number( dataItem[`tot_high_prob_${fraudProbID}`] ) || 0 );
    }
  }

  if ( othersSum ) {
    chartData.push({
      value: othersSum,
      name: t( 'common.others' ),
      color: '#777'
    });
  }

  return getPdfPieChartOption(
    t( `pdf.policiesSummary.chartTitle.vehicleModels_${fraudProbID}` ),
    chartData
  );
};

export const getVehicleModelsPolicyTypePieChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData
}) => {
  return getVehicleModelsPieChartOption({ t, theme, data, fraudProbID: 'policy_type' });
};

export const getVehicleModelsResidencePieChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData
}) => {
  return getVehicleModelsPieChartOption({ t, theme, data, fraudProbID: 'residence' });
};

export const getKmTraveledBarChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData
}) => {
  const dataToProcess = ( data && Array.isArray( data.km_traveled_high_prob )  ) ? data.km_traveled_high_prob : [];
  
  let minRangeKmTraveled = '0';

  // Data comes already sorted by 'range'.
  const chartData = dataToProcess.map(( dataItem, idx ) => {
    let name = '';

    if ( 0 === idx && !dataItem.range.max ) {
      name = t( 'common.num_orMore', { num: formatNumber( Number( dataItem.range.min ) || 0 ) } );
    }
    else {
      name = t( 'common.minNum_to_maxNum', { 
        minNum: formatNumber( Number( dataItem.range.min ) || 0 ),
        maxNum: formatNumber( Number( dataItem.range.max ) || 0 )
      });
    }

    if ( idx === dataToProcess.length - 1 ) {
      minRangeKmTraveled = formatNumber( Number( dataItem.range.min ) || 0 );
    }

    return {
      name: ( name + '\nkm' ),
      value: ( Number( dataItem.count ) || 0 )
    };
  });

  const title = t( 'pdf.policiesSummary.chartTitle.kmTraveled', {
    numVehiclesHighProb: formatNumber( Number( data.tot_high_prob_policy_type ) || 0 ),
    kmTraveled: minRangeKmTraveled
  });

  return getPdfBarChartOption( title, chartData, { colors: theme.pdfBarChartColors });
};

export const getTripsOutResidencePercBarChartOption = ({
  t,
  data,
  theme
}: {
  t: languageModels.Tfn;
  theme: Record<string, any>;
  data: policyModels.SummaryReportData
}) => {
  const dataToProcess = ( data && Array.isArray( data.trips_out_residence_high_prob_perc )  ) ? data.trips_out_residence_high_prob_perc : [];
  
  let minPercent = '0';

  // Data comes already sorted by 'percent'.
  const chartData = dataToProcess.map(( dataItem, idx ) => {
    if ( idx === dataToProcess.length - 1 ) {
      minPercent = ( dataItem.percent + '%' );
    }

    return {
      name: ( dataItem.percent + '%' ),
      value: ( Number( dataItem.count ) || 0 )
    };
  });

  const title = t( 'pdf.policiesSummary.chartTitle.tripsOutResidencePerc', {
    numVehiclesHighProb: formatNumber( Number( data.tot_high_prob_residence ) || 0 ),
    percent: minPercent
  });

  return getPdfBarChartOption( title, chartData, { colors: theme.pdfBarChartColors } );
};
