import styled from "styled-components";
import { useAppTheme } from "../../hooks";
import classNames from "classnames";


const Button = styled.button`
  color: ${( props: any ) => props.theme.palettes[props.color]};
`;

const TextButton = ({
  type = 'button',
  color = 'primary',
  className,
  children,
  ...rest
}: any ) => {
  const theme = useAppTheme();

  return (
    <Button
      type={type}
      theme={theme}
      color={color}
      className={classNames(
        'text-button',
        className
      )}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default TextButton;