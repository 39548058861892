import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import styled from "styled-components";
import { useAppTheme } from "../../hooks";

import {
  ModalMenuAction,
  ModalMenuInterface,
  ModalMenuStyled
} from "./ModalMenuInterface";
import "./ModalMenu.scss";


const Modal = styled.div<ModalMenuStyled>`
  background: ${( props: any ) => props.theme.palettes.background};
  margin-top: ${( props: any ) => props.marginTop}px;
`;

const ActionButton = styled.button`
  color: ${( props: any ) => props.theme.palettes.primary};
  border-top: 1px solid ${( props: any ) => props.theme.palettes.borderSecondary};

  &:hover {
    color: ${( props: any ) => props.theme.palettes.secondary};
  }

  &:first-of-type {
    border-top: none;
  }
`;

const ModalMenu = ({
  keepInDocumentBody,
  opened = false,
  toggle = true,
  actions = [],
  className,
  children,
  onClickOutsise = () => null,
  renderTrigger,
  renderTriggerContent = () => null
}: ModalMenuInterface) => {
  const theme      = useAppTheme();
  const wrapperRef = useRef<HTMLDivElement>( null );
  const modalRef   = useRef<HTMLDivElement>( null );
  const { t }      = useTranslation( 'main' );
  const [ modalOpened, setModalOpened ] = useState( opened );
  const [ marginTop, setMarginTop ]     = useState( 0 );

  const onClickTrigger = ( event: any ) => {
    event.stopPropagation();
    setModalOpened( value => toggle ? !value : true );

    if ( keepInDocumentBody && toggle && modalOpened ) {
      setMarginTop( 0 );
    }
  }

  useEffect(() => {
    if ( !( keepInDocumentBody && modalOpened && modalRef.current ) ) {
      return;
    }

    const modalTop = modalRef.current.getBoundingClientRect().top;
    const offset = (
      modalRef.current.getBoundingClientRect().bottom -
      document.body.getBoundingClientRect().bottom
    );

    if ( offset > 0 && modalTop - offset > 15 ) {
      setMarginTop( ( offset + 15 ) * -1 );
    }
}, [ modalRef, modalOpened ])

  useEffect(() => {
    const handleClickOutside = ( event: any ) => {
      if ( wrapperRef.current && !wrapperRef.current.contains( event.target ) ) {
        setModalOpened( false );
        onClickOutsise( event );

        if ( keepInDocumentBody ) {
          setMarginTop( 0 );
        }
      }
    };
    
    document.addEventListener( 'mousedown', handleClickOutside );
    
    return () => {
      document.removeEventListener( 'mousedown', handleClickOutside );
    };
  }, [ modalRef, wrapperRef ]);

  return (
    <div
      ref={wrapperRef}
      className={classNames(
        'modal-menu-wrapper pos-relative',
        { opened: modalOpened }
      )}
    >
      {typeof renderTrigger === 'function' ? (
        renderTrigger( onClickTrigger, modalOpened )
      ) : (
        <button 
          type='button'
          className='trigger pos-relative'
          onClick={onClickTrigger}
        >
          {renderTriggerContent( modalOpened )}
        </button>
      )}
      <Modal
        ref={modalRef}
        theme={theme}
        marginTop={marginTop}
        className={classNames(
          'modal-menu',
          { opened: modalOpened },
          className
        )}
      >
        {children}
        {actions.map(( action: ModalMenuAction, idx: number ) => (
          <ActionButton
            key={'ModalMenuActionButton_' + idx}
            theme={theme}
            type='button'
            className='action-button'
            onClick={( event ) => {
              event.stopPropagation();
              setModalOpened( false );
              action.callback();
            }}
          >
            {typeof action.renderContent === 'function' ? (
              action.renderContent()
            ) : action.label}
          </ActionButton>
        ))}
      </Modal>
    </div>
  );
};

export default ModalMenu;