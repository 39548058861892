import classNames from "classnames";
import MoodyText from "../../MoodyText/MoodyText";

import { DrawerSectionInterface } from "./DrawerSectionInterface";
import "./DrawerSection.scss";


const DrawerSection = ({
  title,
  className,
  children
}: DrawerSectionInterface) => {
  
  return (
    <div className={classNames(
      'drawer-section',
      className
    )}
    >
      <h4>
        <MoodyText color='secondary'>
          {title}
        </MoodyText>
      </h4>
      <div className='flex flex-columns gap-2'>
        {children}
      </div>
    </div>
  );
};

export default DrawerSection;