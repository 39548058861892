export const svgIconsData: Record<string, any> = {
  eye: {
    viewbox: '0 0 22 15',
    transform: 'translate(-1 -4.5)',
    path: 'M12,6.5A9.77,9.77,0,0,1,20.82,12,9.822,9.822,0,0,1,3.18,12,9.77,9.77,0,0,1,12,6.5Zm0-2A11.827,11.827,0,0,0,1,12a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4.5Zm0,5A2.5,2.5,0,1,1,9.5,12,2.5,2.5,0,0,1,12,9.5Zm0-2A4.5,4.5,0,1,0,16.5,12,4.507,4.507,0,0,0,12,7.5Z'
  },
  eyeShut: {
    viewbox: '0 0 22 19.15',
    transform: 'translate(-1 -2.5)',
    path: 'M12,6.55a9.77,9.77,0,0,1,8.82,5.5,9.647,9.647,0,0,1-2.41,3.12l1.41,1.41A11.8,11.8,0,0,0,23,12.05,11.834,11.834,0,0,0,8.36,5.12l1.65,1.65A10.108,10.108,0,0,1,12,6.55ZM10.93,7.69,13,9.76a2.5,2.5,0,0,1,1.28,1.28l2.07,2.07a4.679,4.679,0,0,0,.14-1.07A4.483,4.483,0,0,0,12,7.55,4.244,4.244,0,0,0,10.93,7.69ZM2.01,4.42,4.69,7.1A11.738,11.738,0,0,0,1,12.05a11.827,11.827,0,0,0,11,7.5,11.73,11.73,0,0,0,4.32-.82l3.42,3.42,1.41-1.41L3.42,3Zm7.5,7.5,2.61,2.61a.5.5,0,0,1-.12.02,2.5,2.5,0,0,1-2.5-2.5C9.5,12,9.51,11.97,9.51,11.92Zm-3.4-3.4,1.75,1.75a4.6,4.6,0,0,0-.36,1.78,4.505,4.505,0,0,0,6.27,4.14l.98.98a10.432,10.432,0,0,1-2.75.38,9.77,9.77,0,0,1-8.82-5.5A9.9,9.9,0,0,1,6.11,8.52Z'
  },
  filters: {
    viewbox: '0 0 18 18',
    path: 'M.818,3.079H3.406a2.279,2.279,0,0,0,4.24,0h9.536a.818.818,0,1,0,0-1.636H7.646a2.279,2.279,0,0,0-4.24,0H.818a.818.818,0,0,0,0,1.636M5.526,1.636a.624.624,0,1,1-.638.624.632.632,0,0,1,.638-.624M17.182,8.182H14.594a2.279,2.279,0,0,0-4.24,0H.818a.818.818,0,0,0,0,1.636h9.536a2.279,2.279,0,0,0,4.24,0h2.588a.818.818,0,1,0,0-1.636M12.474,9.624A.624.624,0,1,1,13.112,9a.632.632,0,0,1-.638.624m4.708,5.3H7.646a2.279,2.279,0,0,0-4.24,0H.818a.818.818,0,1,0,0,1.636H3.406a2.279,2.279,0,0,0,4.24,0h9.536a.818.818,0,1,0,0-1.636M5.526,16.364a.624.624,0,1,1,.638-.624.632.632,0,0,1-.638.624'
  },
  close: {
    viewbox: '0 0 18 18',
    path: 'M17.62,15.786a1.3,1.3,0,0,1-1.834,1.834L9,10.834,2.214,17.62A1.3,1.3,0,0,1,.38,15.786L7.166,9,.38,2.214A1.3,1.3,0,0,1,2.214.38L9,7.166,15.786.38A1.3,1.3,0,0,1,17.62,2.214L10.834,9Z'
  },
  calendar: {
    viewbox: '0 0 512 512',
    path: 'M369.12 37.06v-19.2c0-9.86-8-17.86-17.86-17.86S333.4 8 333.4 17.86v17.86H178.61V17.86c0-9.86-8-17.86-17.86-17.86s-17.86 8-17.86 17.86v19.2C67.2 43.69 23.82 91.92 23.82 172.65v202.42C23.82 462.09 73.73 512 160.75 512h190.51c87.02 0 136.93-49.91 136.93-136.93V172.65c0-80.73-43.38-128.96-119.07-135.59ZM142.89 72.61v16.7c0 9.86 8 17.86 17.86 17.86s17.86-8 17.86-17.86V71.45H333.4v17.86c0 9.86 8 17.86 17.86 17.86s17.86-8 17.86-17.86v-16.7c54.59 5.5 81.93 37.1 83.1 96.23H59.78c1.17-59.14 28.51-90.73 83.1-96.23Zm208.37 403.67H160.75c-68.09 0-101.21-33.12-101.21-101.21V204.56h392.93v170.51c0 68.09-33.12 101.21-101.21 101.21Zm16.65-179.79c0 13.16-10.65 23.81-23.81 23.81s-23.88-10.65-23.88-23.81 10.6-23.81 23.77-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Zm0 71.44c0 13.16-10.65 23.81-23.81 23.81s-23.88-10.65-23.88-23.81 10.6-23.81 23.77-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Zm-88.07-71.44c0 13.16-10.65 23.81-23.81 23.81s-23.86-10.65-23.86-23.81 10.58-23.81 23.74-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Zm0 71.44c0 13.16-10.65 23.81-23.81 23.81s-23.86-10.65-23.86-23.81 10.58-23.81 23.74-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Zm-88.14-71.44c0 13.16-10.65 23.81-23.81 23.81s-23.86-10.65-23.86-23.81 10.58-23.81 23.74-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Zm0 71.44c0 13.16-10.65 23.81-23.81 23.81s-23.86-10.65-23.86-23.81 10.58-23.81 23.74-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Z'
  },
  cap: {
    viewbox: '0 0 512 512',
    path: 'M327.44 0H184.56C55.19 0 0 55.19 0 184.56v142.88C0 456.81 55.19 512 184.56 512h142.88C456.81 512 512 456.81 512 327.44V184.56C512 55.19 456.81 0 327.44 0ZM170.65 36.05l52.67 212.86-187.6 54.73V184.56c0-105.05 35.78-145.18 134.93-148.51ZM36.03 340.74l195.88-57.15 47.69 192.69h-95.05c-105.27 0-145.34-35.93-148.52-135.54Zm440.24-13.3c0 109.88-38.95 148.84-148.84 148.84h-11.01L207.4 35.72h120.05c109.88 0 148.84 38.95 148.84 148.84v142.88Z'
  },
  pin: {
    viewbox: '0 0 512 512',
    path: 'M256.02 123.56c-50.81 0-92.15 41.34-92.15 92.15s41.34 92.15 92.15 92.15 92.13-41.34 92.13-92.15-41.32-92.15-92.13-92.15Zm0 148.58c-31.11 0-56.43-25.32-56.43-56.43s25.32-56.43 56.43-56.43 56.41 25.32 56.41 56.43-25.3 56.43-56.41 56.43Zm216.95-103.47C446.62 52.23 345.05.05 256.09 0h-.14c-88.9 0-190.42 52.06-216.9 168.42v.02C9.96 296.93 87.51 404.55 157.66 472.17c27.55 26.55 62.9 39.83 98.24 39.83s70.66-13.28 98.2-39.81c70.27-67.5 147.96-174.95 118.87-303.52ZM329.34 446.45c-41.25 39.72-105.73 39.69-146.89 0C118.9 385.2 48.47 288.61 73.88 176.3 97.5 72.53 186.7 35.72 255.95 35.72h.12c69.29.05 158.56 36.95 182.07 140.84 25.42 112.31-45.13 208.74-108.8 269.89Z'
  },
  fraud: {
    viewbox: '0 0 210.3 210.3',
    path: 'M105.2 127.7c-12.4 0-22.6-10.1-22.6-22.6 0-12.4 10.1-22.6 22.6-22.6 6 0 11.7 2.3 15.9 6.6 4.3 4.3 6.6 9.9 6.6 16 0 12.5-10.1 22.6-22.5 22.6zm0-35.1c-6.9 0-12.6 5.6-12.6 12.6 0 6.9 5.6 12.6 12.6 12.6 6.9 0 12.6-5.6 12.6-12.6 0-3.4-1.3-6.5-3.7-8.9-2.4-2.4-5.6-3.7-8.9-3.7zm74 68.2c12-15.9 18.6-35.7 18.6-55.7s-6.5-39.8-18.6-55.7c-1.7-2.2-4.8-2.6-7-1-2.2 1.7-2.6 4.8-1 7 10.7 14.2 16.6 31.9 16.6 49.7 0 17.8-5.8 35.4-16.6 49.7-1.7 2.2-1.2 5.3 1 7 .9.7 2 1 3 1 1.5 0 3-.7 4-2zm-141.1 1c2.2-1.7 2.7-4.8 1-7-10.8-14.4-16.6-31.6-16.6-49.7s5.7-35.2 16.6-49.7c1.7-2.2 1.2-5.3-1-7-2.2-1.7-5.3-1.2-7 1C19 65.7 12.6 84.9 12.6 105.2c0 20.2 6.4 39.5 18.6 55.7 1 1.3 2.5 2 4 2 1-.1 2-.4 2.9-1.1zm113.1-22c7.4-9.9 11.5-22.2 11.5-34.6 0-12.4-4.1-24.7-11.5-34.6-1.7-2.2-4.8-2.7-7-1-2.2 1.7-2.7 4.8-1 7 6.2 8.2 9.5 18.4 9.5 28.6 0 10.2-3.4 20.4-9.5 28.6-1.7 2.2-1.2 5.3 1 7 .9.7 2 1 3 1 1.5 0 3-.7 4-2zm-85.1 1c2.2-1.7 2.7-4.8 1-7-12.6-16.8-12.6-40.4 0-57.2 1.7-2.2 1.2-5.3-1-7-2.2-1.7-5.3-1.2-7 1-15.3 20.4-15.3 48.8 0 69.2 1 1.3 2.5 2 4 2 1.1 0 2.1-.4 3-1z'
  },
  noFraud: {
    viewbox: '0 0 156.7 156.7',
    path: 'm128.2 18.6-36.9-13c-7.3-2.5-18.6-2.5-25.8 0L28.6 18.7c-10.5 3.7-18.8 15-18.8 25.7v51.8c0 9.8 6.5 22.2 14.9 28.1l31.8 22.4c6 4.3 14 6.4 21.9 6.4 7.9 0 15.8-2.1 21.9-6.4l31.8-22.4c8.4-5.9 14.9-18.2 14.9-28V44.4c0-10.8-8.3-22.1-18.8-25.8zm8.8 77.5c0 6.6-5 15.9-10.7 19.9l-31.8 22.4c-8.7 6.2-23.5 6.2-32.2 0L30.5 116c-5.7-4-10.7-13.3-10.7-19.9V44.4c0-6.4 5.7-14 12.1-16.2L68.8 15c5-1.8 14-1.8 19.1 0l36.9 13c6.5 2.3 12.1 9.9 12.1 16.3v51.8zM94.8 60.8c2 2 2 5.1 0 7.1L76.1 86.6c-.9.9-2.2 1.5-3.5 1.5s-2.6-.5-3.5-1.5l-7-7c-2-2-2-5.1 0-7.1s5.1-2 7.1 0l3.5 3.5 15.2-15.2c1.8-2 4.9-2 6.9 0z'
  },
  search: {
    viewbox: '0 0 17.49 17.49',
    transform: 'translate(-3.255 -3.255)',
    path: 'M15.755,14.255h-.79l-.28-.27a6.51,6.51,0,1,0-.7.7l.27.28v.79l5,4.99,1.49-1.49Zm-6,0a4.5,4.5,0,1,1,4.5-4.5A4.494,4.494,0,0,1,9.755,14.255Z'
  },
  clock: {
    viewbox: '0 0 24 24',
    path: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-13V7h-1v5h4V7h-1z'
  },
  magnifyingGlass: {
    viewbox: '0 0 512 512',
    path: 'M483.4 483.4c-3.2 3.2-7.3 4.7-11.5 4.7-4.1 0-8.3-1.6-11.5-4.7l-43.2-43.2c-6.3-6.3-6.3-16.6 0-22.9 6.3-6.3 16.6-6.3 22.9 0l43.2 43.2c6.5 6.4 6.5 16.6.1 22.9zm-16.8-238.2c0 122.1-99.3 221.4-221.4 221.4S23.8 367.3 23.8 245.2 123.1 23.8 245.2 23.8s221.4 99.3 221.4 221.4zm-32.4 0c0-104.2-84.8-189-189-189s-189 84.8-189 189 84.8 189 189 189 189-84.8 189-189z'
  }
};