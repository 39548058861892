import { useEffect, useRef, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";
import { useAppTheme } from "../../../hooks";
import { applyErrorBoundary } from "../../../utils/error-boundary";
import { getAppConfig, getAppDateFormat } from "../../../config";
import { attemptTranslation } from "../../../utils/i18n";
import { valueNotFoundSymbol } from "../../../utils/constants";
import { getClaimDetailPeriod } from "../../../utils/period";
import { ClaimsService } from "../../../services/ClaimsService";
import { appConfig } from "../../../config/app-config/app-config";
import { claimModels } from "../../../models/claimModels";
import { claimPageModels } from "../../../models/claimPageModels";
import { stripNulls } from "../../../utils/sanitization";
import { downloadCsv } from "../../../utils/csv";
import { downloadPdf } from "../../../utils/pdf";
import { languageModels } from "../../../models/languageModels";
import { AxiosError } from "axios";
import { ClaimsSummaryChartRefs } from "../../../shared-components/pdf-documents/ClaimsSummaryPdf/ClaimsSummaryPdfInterface";
import { 
  getFraudProbPieChartOption, 
  getMxStatesPieChartOption,
  getPolicyTypesPieChartOption
} from "./chart-option";

import { getDropdownOptions } from "../../../config/dropdowns";
import { getCsvColumns } from "./csv-columns";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { selectPeriod, updatePeriod } from "../../../store/slices/periodSlice";
import { selectSetting } from "../../../store/slices/settingsSlice";
import { selectFilters, updateFilters } from "../../../store/slices/filtersSlice";
import { selectDropdownOptions } from "../../../store/slices/dropdownsSlice";
import { lockBrowsing, unlockBrowsing } from "../../../store/slices/browsingLockedSlice";

import {
  useGroundToast,
  GroundToastVariant,
  GroundToastType
} from "@greenvulcano/ground-react";
import UtilityHeader from "../../../shared-components/UtilityHeader/UtilityHeader";
import MainContentWrapper from "../../../shared-components/MainContentWrapper/MainContentWrapper";
import TableArea from "../../../shared-components/TableArea/TableArea";
import LevelBudge from "../../../shared-components/LevelBudge/LevelBudge";
import DrawerContent from "./DrawerContent/DrawerContent";
import ClaimsSummaryPdf from "../../../shared-components/pdf-documents/ClaimsSummaryPdf/ClaimsSummaryPdf";
import ChartLoaderForPdf from "../../../shared-components/ChartLoaderForPdf/ChartLoaderForPdfLoader";

import "./Claims.scss";


const getTableColumns = ({ t }: { t: languageModels.Tfn }): claimPageModels.ClaimTableColumn[] => {
  const claimStatusOptions = getDropdownOptions( 'claimStatus', true );
  
  const possibleColumns: {[key in claimPageModels.ClaimTableColumns]: claimPageModels.ClaimTableColumn} = {
    [claimPageModels.ClaimTableColumns.claimId]: {
      id: claimPageModels.ClaimTableColumns.claimId,
      isSortable: true,
      headContent : t('common.id')
    },
    [claimPageModels.ClaimTableColumns.policyHolder]: {
      id: claimPageModels.ClaimTableColumns.policyHolder,
      isSortable: true,
      headContent : t('common.policyholderName')
    },
    [claimPageModels.ClaimTableColumns.policyId]: {
      id: claimPageModels.ClaimTableColumns.policyId,
      isSortable: true,
      headContent : t('common.policy')
    },
    [claimPageModels.ClaimTableColumns.plate]: {
      id: claimPageModels.ClaimTableColumns.plate,
      isSortable: true,
      headContent : t('common.plate')
    },
    [claimPageModels.ClaimTableColumns.vin]: {
      id: claimPageModels.ClaimTableColumns.vin,
      isSortable: true,
      headContent : t('common.vin')
    },
    [claimPageModels.ClaimTableColumns.location]: {
      id: claimPageModels.ClaimTableColumns.location,
      isSortable: true,
      headContent : t('common.claimLocation'),
      renderCellContent: ( value: string ) => {
        return stripNulls( value );
      }
    },
    [claimPageModels.ClaimTableColumns.claimDate]: {
      id: claimPageModels.ClaimTableColumns.claimDate,
      isSortable: true,
      headContent: t('common.dateOfClaim'),
      renderCellContent: ( value: string, dataItem: any ) => {
        const dateFormatted = value ? moment.utc( value ).format(getAppDateFormat()) : null;

        return (
          <>
            {dateFormatted ? renderBoldText(dateFormatted) : valueNotFoundSymbol}
            <div>
              {dataItem.sinister_hour ? (
                t( 'common.atTime', { time: dataItem.sinister_hour } )
              ) : (
                dataItem.sinister_hour_from && dataItem.sinister_hour_to  ? (
                  t( 'common.timeToTime', { start: dataItem.sinister_hour_from, end: dataItem.sinister_hour_to } )
                ) : valueNotFoundSymbol
              )}
            </div>
          </>
        );
      },
    },
    [claimPageModels.ClaimTableColumns.idClaimStatus]: {
      id: claimPageModels.ClaimTableColumns.idClaimStatus,
      isSortable: true,
      headContent: t('common.claimStatus'),
      renderCellContent: ( value: string ) => {
        const key = String( value );

        if ( key in claimStatusOptions ) {
          return t( claimStatusOptions[key].label ) as string;
        }

        return valueNotFoundSymbol;
      }
    },
    [claimPageModels.ClaimTableColumns.claimType]: {
      id: claimPageModels.ClaimTableColumns.claimType,
      isSortable: true,
      headContent: t('common.claimType'),
      renderCellContent: ( value: string ) => {
        return attemptTranslation( value, t );
      }
    },
    [claimPageModels.ClaimTableColumns.severity]: {
      id: claimPageModels.ClaimTableColumns.severity,
      isSortable: true,
      headContent: t('common.severity'),
      renderCellContent: ( value: string ) => {
        return attemptTranslation( value, t );
      }
    },
    [claimPageModels.ClaimTableColumns.fraudProbabilityId]: {
      id: claimPageModels.ClaimTableColumns.fraudProbabilityId,
      isSortable: true,
      headContent: t('common.fraudProbability'),
      renderCellContent: ( value: string ) => {
        return ( <LevelBudge value={value} /> );
      }
    }
  };

  return appConfig.claimTableColumns.map((item: claimPageModels.ClaimTableColumns) => {
    return possibleColumns[item];
  });
};

const renderBoldText = ( text: string ) => {
  return ( <strong>{text || valueNotFoundSymbol}</strong> );
}

const Claims = () => {
  const { t }       = useTranslation( 'main' );
  const theme       = useAppTheme();
  const appDispatch = useAppDispatch();
  const period      = useAppSelector( selectPeriod() );
  const groundToast = useGroundToast();
  const navigate    = useNavigate();
  const queryClient = useQueryClient();
  const filters     = useAppSelector( selectFilters( 'claims' ) );
  const claimTypesFilters     = useAppSelector( selectFilters( 'claimTypes' ) );
  const currentResultsPerPage = useAppSelector( selectSetting( 'resultsPerPage' ) );
  const vehicleTypesOptions   = useAppSelector( selectDropdownOptions( 'vehicleTypes' ) );
  const claimTableState       = useAppSelector( selectFilters( 'claimsTable' ) );
  const showExportButtonInClaimPage = getAppConfig('showExportButtonInClaimPage');

  const chartRefFraudProb   = useRef<HTMLDivElement | null>( null );
  const chartRefMxStates    = useRef<HTMLDivElement | null>( null );
  const chartRefPolicyTypes = useRef<HTMLDivElement | null>( null );

  const [ canDownloadSummary, setCanDownloadSummary ] = useState( false );
  const [ chartLoadedCounter, setChartLoadedCounter ] = useState( 0 );
  const [ csvQueryDependencies, setCsvQueryDependencies ] = useState<any>( null );
  
  const { currentPage, activeColumn } = claimTableState as Record<string, any>;
  const commonApiQueryParams = {
    ...claimTypesFilters,
    order_by: activeColumn.id,
    order: activeColumn.isAscOrder ? 'asc' : 'desc'
  };
  const chartRefs: ClaimsSummaryChartRefs = {
    fraudProb: chartRefFraudProb,
    mxStates: chartRefMxStates,
    policyTypes: chartRefPolicyTypes
  };

  const mainQuery = useQuery({
    keepPreviousData : true,
    queryKey: [
      'claimsList',
      currentPage,
      { currentResultsPerPage, activeColumn, filters, claimTypesFilters, period }
    ],
    queryFn: () => ClaimsService.getClaims( {
      ...commonApiQueryParams,
      page: currentPage,
      limit: currentResultsPerPage
    }, period, filters )
  });

  const summaryQuery = useQuery({
    enabled: canDownloadSummary,
    queryKey: [ 'summaryClaims', { period } ],
    queryFn: () => ClaimsService.getClaimsSummaryForReport( period ),
    onError: ( error: AxiosError ) => {
      appDispatch( unlockBrowsing() );
      setCanDownloadSummary( false );

      if ( '404' === String( error.code ) ) {
        groundToast.show({
          variant: GroundToastVariant.featured,
          type: GroundToastType.info,
          message: t( 'toastMessages.noDataFoundToGenDocument' )
        });
      }
      else {
        groundToast.show({
          variant: GroundToastVariant.featured,
          type: GroundToastType.alert,
          message: t( 'toastMessages.documentGenFailed' )
        });
      }
    }
  });

  const csvQuery = useQuery({
    enabled: Boolean( csvQueryDependencies ),
    queryKey: [ 'csvClaims', csvQueryDependencies ],
    queryFn: () => ClaimsService.getClaimsForReport( period, commonApiQueryParams, filters ),
    onSuccess: data => {
      produceCsv( data );
      setCsvQueryDependencies( null );
    },
    onError: ( error: AxiosError ) => {
      appDispatch( unlockBrowsing() );
      setCsvQueryDependencies( null );
      
      if ( '404' === String( error.code ) ) {
        groundToast.show({
          variant: GroundToastVariant.featured,
          type: GroundToastType.info,
          message: t( 'toastMessages.noDataFoundToGenDocument' )
        });
      }
      else {
        groundToast.show({
          variant: GroundToastVariant.featured,
          type: GroundToastType.alert,
          message: t( 'toastMessages.documentGenFailed' )
        });
      }
    }
  });

  const incrementChartLoadedCounter = () => setChartLoadedCounter( value => value + 1 );

  const canLoadChart = ( chartNum: number ) => {
    return ( canDownloadSummary && ( chartNum - 1 <= chartLoadedCounter ) );
  };

  const produceCsv = ( data: claimModels.CsvReportParsedDataItem[] ) => {
    const filename = t(
      'functionalities.claimsCsvFilename', 
      { fromDate: period.start , toDate: period.end  }
    );
    const columns = getCsvColumns({ t });

    downloadCsv( data, columns, filename );
    appDispatch( unlockBrowsing() );
  };

  const produceSummary = ( data: claimModels.SummaryReportData ) => {
    (async () => {
			try {
        const filename = t( 'functionalities.claimsSummaryFilename', {
          fromDate: period.start,
          toDate: period.end
        });

        await downloadPdf( filename, (
          <ClaimsSummaryPdf
						theme={theme}
            chartRefs={chartRefs}
            data={data}
					/>
        ));
			} 
      catch (error) {
				groundToast.show({
					variant: GroundToastVariant.featured,
					type: GroundToastType.alert,
					message: t( 'toastMessages.documentGenFailed' ),
				});
			}
      finally {
        setChartLoadedCounter( 0 );
        setCanDownloadSummary( false );
        appDispatch( unlockBrowsing() );
      }
		})();
  };

  const onClickExportSummary = () => {
    appDispatch( lockBrowsing() );
    setCanDownloadSummary( true );
  };

  const onClickExportAsCsv = () => {
    if ( !( mainQuery.data && mainQuery.data.data && mainQuery.data.data.length ) ) {
      groundToast.show({
        variant: GroundToastVariant.featured,
        type: GroundToastType.info,
        message: t( 'toastMessages.noDataFoundToGenDocument' )
      });

      return;
    }

    const dependencies = { period, claimTypesFilters, filters };
    const data: claimModels.CsvReportParsedDataItem[] | undefined = queryClient.getQueryData( [ 'csvClaims', dependencies ] );

    appDispatch( lockBrowsing() );

    if ( data ) {
      produceCsv( data );
    }
    else {
      setCsvQueryDependencies( dependencies );
    }
  };

  const onActivateRow = ( rowUID: string, dataItem: claimModels.ListDataItem ) => {
    if (!window.getSelection()?.toString().length) {
      appDispatch( updatePeriod({
        id: 'claimDetail', 
        period: getClaimDetailPeriod( dataItem.claim_date )
      }));
      navigate({
        pathname: `./${rowUID}`,
        search: createSearchParams({ id_voucher: dataItem.id_voucher }).toString()
      });
    }
  };

  const updateActiveColumn = ( activeColumn: any ) => {
    appDispatch(updateFilters({
      key: 'claimsTable', 
      filters: { ...claimTableState, activeColumn }
    }));
  };

  useEffect(() => {
    if ( 
      summaryQuery.data && 
      ( chartLoadedCounter === Object.keys( chartRefs ).length )
    ) {
      produceSummary( summaryQuery.data );
    }
  }, [ chartLoadedCounter ] );

  return (
    <div className='claims-page flex-grow flex flex-columns'>
      <UtilityHeader
        relaxed
        showToggles
        exportButton={{
          show: showExportButtonInClaimPage,
          isLoading: ( csvQuery.isLoading || canDownloadSummary ),
          modalActions: [
            {
              label: t( 'common.downloadSummary' ) as string,
              callback: onClickExportSummary
            },
            {
              label: t( 'common.downloadCsv' ) as string,
              callback: onClickExportAsCsv
            }
          ] 
        }}
        title={t( 'common.claims' )}
        description={t( 'pagesDescriptions.claims' )}
      />
      <MainContentWrapper overlapped>
        <TableArea
          storeSliceKey='claims'
          query={mainQuery}
          className='tarea-claims'
          i18nTitleKey='claims.claimsFound'
          tableOptions={{
            activeColumn,
            uidProperty: 'claim_id',
            columns: getTableColumns({ t }),
            onActivateRow,
            onClickSortButton: ( columnState: any ) => updateActiveColumn( columnState )
          }}
          renderDrawerContent={( params ) => (
            <DrawerContent
              {...params}
              vehicleTypesOptions={vehicleTypesOptions}
            />
          )}
        />
      </MainContentWrapper>
      {summaryQuery.isSuccess && (
        <>
          <ChartLoaderForPdf
            ref={chartRefFraudProb}
            canLoad={canLoadChart( 1 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getFraudProbPieChartOption({ t, theme, data: summaryQuery.data })}
          />
          <ChartLoaderForPdf
            ref={chartRefMxStates}
            canLoad={canLoadChart( 2 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getMxStatesPieChartOption({ t, theme, data: summaryQuery.data })}
          />
          <ChartLoaderForPdf
            ref={chartRefPolicyTypes}
            canLoad={canLoadChart( 3 )}
            onLoaded={incrementChartLoadedCounter}
            chartOption={getPolicyTypesPieChartOption({ t, theme, data: summaryQuery.data })}
          />
        </>
      )}
    </div>
  );
};

export default applyErrorBoundary( Claims );
