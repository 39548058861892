import { useQuery } from "react-query";
import { AuthService } from "../services/AuthService";

import { useAppDispatch } from "../store/hooks";
import { updateUserInfo, UserState } from "../store/slices/userSlice";


export const useUserQuery = () => {
  const token       = AuthService.getToken();
  const appDispatch = useAppDispatch();

  const query = useQuery({
    queryKey: [ 'userQuery', token ],
    retry: false,
    enabled: Boolean( token ),
    queryFn: AuthService.getUserInfo,
    onSuccess: ( data: UserState ) => {
      appDispatch( updateUserInfo( data ) );
    }
  });

  return query;
};
