import {
  getMonthsInitials, 
  getWeekdaysInitials,
  getHoursOfDay
} from "../../../../utils/time";
import { formatNumber } from "../../../../utils";
import moment from "moment";


export const getChartOption = ({
  data,
  theme,
  dataName,
  period,
  mainColor
}: Record<string, any> ) => {
  const monthsInitials = getMonthsInitials();
  const hoursOfDay     = getHoursOfDay();
  const startDateObj   = moment.utc( period.start );
  const endDateObj     = moment.utc( period.end );
  const diff           = endDateObj.diff( startDateObj, 'days' );
  const lastDayOfMonth = Number( endDateObj.endOf( 'month' ).format( 'D' ) );
  const periodRangesBetweenMonths = startDateObj.month() !== endDateObj.month();

  const isHourlyChart = ( diff <= 1 );
  const isDailyChart  = diff <= ( periodRangesBetweenMonths ? 30 : lastDayOfMonth );
  const format        = ( periodRangesBetweenMonths ? 'D-M' : 'D' );

  let xAxisData;
  let yAxisMax = 0;

  if ( isHourlyChart ) {
    xAxisData = Array.from( { length: 24 }, (_, i) => i + 1 );
  }
  else if ( isDailyChart ) {
    xAxisData = Array.from( { length: diff + 1 }, (_, i) => {
      return startDateObj.add(( 0 === i ? 0 : 1 ), 'days' ).format( format );
    });
  }
  else {
    xAxisData = Array.from( { length: 12 }, (_, i) => i + 1 );
  }

  const yAxisData = xAxisData.map(( timeUnit: any ) => {
    let datum;

    if ( isHourlyChart ) {
      datum = data.find(( item: any ) => ( Number( item.date ) || 24 ) === timeUnit );
    }
    else if ( isDailyChart ) {
      datum = data.find(( item: any ) => moment.utc( item.date, 'YYYY-MM-DD' ).format( format ) === timeUnit );
    }
    else {
      datum = data.find(( item: any ) => Number( item.date ) === timeUnit );
    }

    if ( datum ) {
      const value = Number( datum.value );
      
      yAxisMax = Math.max( yAxisMax, value );

      return value;
    }
    
    return 0;
  });

  const leftOffset = Math.max( 0, ( String( yAxisMax ).length - 4 ) * 8 );

  return {
    tooltip: {
      show: true,
      trigger: 'axis',
      formatter: ( data: any ) => {
        return '<strong>' 
              + formatNumber( data[0].value ) 
              + '</strong> ' 
              + data[0].seriesName;
      },
      axisPointer: {
        lineStyle: {
          type: 'solid',
          color: theme.palettes[mainColor]
        }
      }
    },
    grid: {
      left: ( 45 + leftOffset ),
      right: 0,
      bottom: 30,
      top: 20
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisLabel: {
        color: theme.palettes.textWhite,
        formatter: ( value: string | number ) => {
          if ( isHourlyChart ) {
            return hoursOfDay[Number( value ) - 1];
          }

          if ( isDailyChart ) {
            return String( value ).replace( '-', ' / ' );
          }
          
          return monthsInitials[Number( value ) - 1];
      }
      },
      axisLine: {
        lineStyle: {
          color: theme.palettes.borderQuaternaryDarker
        }
      }
    },
    yAxis: {
      type: 'value',
      slitNumber: 4,
      axisLabel: {
        color: theme.palettes.textWhite,
      },
      splitLine: {
        lineStyle: {
          color: theme.palettes.borderQuaternaryDarker
        }
      }
    },
    series: [
      {
        name: dataName,      
        data: yAxisData,
        type: 'line',
        smooth: true,
        symbol: 'circle',
        symbolSize: 10,
        lineStyle: {
          width: 3,
          color: theme.palettes[mainColor]
        },
        itemStyle: {
          color: theme.palettes[mainColor],
          borderColor: theme.palettes.backgroundDarkest,
          borderWidth: 4
        },
        emphasis: {
          scale: 2
        }
      }
    ]
  };
};

export const getHeatmapChartOption = ({
  data,
  dataName,
  theme
}: Record<string, any>) => {
  let max = 0;

  const dataItems = data.map(( item: any ) => {
    if ( item.value > max ) {
      max = item.value;
    }

    // hour = 0 -> 00:00
    // weekdays start from 1 and in the chart are reversed
    return [
      ( item.hour || 24 ) - 1, 
      ( 7 - item.weekday ),
      ( item.value || '-' )
    ];
  });

  return {
    tooltip: {
      position: 'top',
      formatter: ( params: any ) => {
        return `<strong>${params.value[2]}</strong> ${params.seriesName}`;
      }
    },
    grid: {
      left: 40,
      right: 3,
      bottom: 30,
      top: 20
    },
    xAxis: {
      type: 'category',
      data: getHoursOfDay(),
      axisLabel: {
        color: theme.palettes.textWhite
      },
      axisLine: {
        lineStyle: {
          color: theme.palettes.borderQuaternaryDarker
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: theme.palettes.borderQuaternaryDarker
        }
      }
    },
    yAxis: {
      type: 'category',
      data: getWeekdaysInitials().reverse(),
      axisLabel: {
        color: theme.palettes.textWhite
      },
      axisLine: {
        lineStyle: {
          color: theme.palettes.borderQuaternaryDarker
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: theme.palettes.borderQuaternaryDarker
        }
      }
    },
    visualMap: {
      max,
      min: 0,
      show: false,
      inRange: {
        color: [ theme.palettes.secondaryAlpha30, theme.palettes.secondary ],
      }
    },
    series: [
      {
        name: dataName,
        type: 'heatmap',
        data: dataItems,
        itemStyle: {
          borderColor: theme.palettes.borderQuaternaryDarker,
          borderWidth: 1,
        },
        emphasis: {
          itemStyle: {
            borderColor: theme.palettes.borderWhite,
            borderWidth: 1,
            shadowBlur: 10,
            shadowColor: '#00000070'
          }
        }
      }
    ]
  }
};