export namespace claimPageModels {
  export enum ClaimTableColumns {
    claimId = "claim_id",
    policyHolder = "policyholder",
    policyId = "policy_id",
    plate = "plate",
    vin = "vin",
    location = "location",
    claimDate = "claim_date",
    idClaimStatus = "id_claim_status",
    claimType = "claim_type",
    severity = "severity",
    fraudProbabilityId = "fraud_probability_id",
  }

  export type ClaimTableColumn = {
    id: ClaimTableColumns;
    headContent: string;
    isSortable?: boolean;
    renderCellContent?: (...args: any[]) => string | JSX.Element;
  };
  
  export enum ClaimFilters {
    claimId = "claim_id",
    vin = "vin",
    plate = "plate",
    policyHolder = "policyholder",
    policyId = "policy_id",
    agency = "agency",
    agent = "agent",
    idClaimStatus = "id_claim_status",
    severity = "severity",
    vehicleType = "vehicle_type_id",
    claimDate = "claim_date",
    claimTime = "claim_time",
    postalCode = "postal_code",
    location = "location",
    fraudProbabilityId = "fraud_probability_id"
  }
}