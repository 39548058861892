import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { GroundSelect } from "@greenvulcano/ground-react";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import { DownloadPoliciesModalInterface } from "./DownloadPoliciesModalInterface";


const InfoStyled = styled.div`
  margin-bottom: 1rem;

  p {
    font-size: 0.85rem;
    margin: 0;
  }
`;

const DownloadPoliciesModal = ({
  onAction
}: DownloadPoliciesModalInterface ) => {
  const { t } = useTranslation( 'main' );
  const [ versionID, setVersionID ] = useState( 'standard' );

  return (
    <ConfirmationModal
      style={{ width: '500px' }}
      onAction={() => onAction( 'complete' === versionID )}
      title={t( 'common.chooseReportVersion' ) as string}
      confirmBtnText={t('common.download' ) as string}
      content={(
        <>
          <InfoStyled className='flex flex-columns gap-1'>
            <p>
              <strong>{t("common.standard")}:</strong>
              {' '}
              {t("modals.downloadPoliciesStandardMessage")}
            </p>
            <p>
              <strong>{t("common.complete")}:</strong>
              {' '}
              {t("modals.downloadPoliciesCompleteMessage")}
            </p>
          </InfoStyled>
          <GroundSelect
            fluid
            height='40'
            value={versionID}
            options={[
              { value: 'standard', label: t( 'common.standardReport' ) },
              { value: 'complete', label: t( 'common.completeReport' ) },
            ]}
            onChange={( event: any ) => setVersionID( event.target.value )}
          />
        </>
      )}
    />
  );
};

export default DownloadPoliciesModal;
