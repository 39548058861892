import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getAppDateFormat, getAppDateFormatWithAproximateTime  } from "../../../config";
import { useAppTheme } from "../../../hooks";
import { formatNumber, isObjectEmpty } from "../../../utils";
import { tripListHasTrips } from "../../../utils/trips-utils";

import { PeriodState } from "../../../store/slices/periodSlice";

import { TripsChartInterface } from "./TripsChartInterface";
import Chart from "../../Chart/Chart";
import { tripModels } from "../../../models/tripModels";


const getChartOption = ({
  t,
  theme,
  data
}: Record<string,any> ) => {
  const barSeriesName     = t( 'common.trips' );
  const lineSeriesName    = t( 'common.kmTravelled' );
  const scatterSeriesName = t( 'common.claim' );

  return {
    title: {
      text: t( 'claims.tripsKmTravelled' ),
      left: 20,
      itemStyle: {
        color: theme.palettes.text
      }
    },
    tooltip: {
      trigger: 'axis',
      formatter: ( data: any ) => {
        return data[0].marker + ' ' + data[0].seriesName
             + '<br/><strong>' + formatNumber( data[0].value[1] ) + ' Km</strong>'
             + '<br />' + moment.utc( data[0].value[0] ).format( getAppDateFormatWithAproximateTime());
      }
    },
    legend: {
      data: [ lineSeriesName, barSeriesName ],
      align: 'left',
      right: 20,
      icon: 'roundRect',
      itemGap: 30,
      textStyle: {
        color: theme.palettes.textLight
      }
    },
    grid: {
      left: 60,
      right: 24,
      bottom: 30
    },
    xAxis: {
      type: 'time',
      axisLabel: {
        color: theme.palettes.textLight,
        fontSize: 10,
        formatter: ( value: number ) => {
          return moment.utc(value).format(getAppDateFormat());
        }
      },
      axisLine: {
        lineStyle: {
          color: theme.palettes.textLight
        }
      }
    },
    yAxis: {
      type: 'value',
      slitNumber: 4,
      axisLabel: {
        color: theme.palettes.textLight,
        fontSize: 11,
        formatter: ( value: number ) => {
          if ( value < 1000 ) {
            return value;
          }

          return ( Math.round( value / 1000 ) + 'k' );
        },
      }
    },
    series: [
      {
        name: barSeriesName ,
        data: data.trips,
        type: 'bar',
        barWidth: '50%',
        barMaxWidth: 20,
        roundCap: true,
        itemStyle: {
          color: theme.palettes.chartTrips,
          borderRadius: [50, 50, 0, 0]
        }
      },
      {
        name: lineSeriesName,
        data: data.kmTravelled,
        type: 'line',
        smooth: true,
        symbol: 'none',
        markLine: {
          symbol: 'none',
          silent: true,
          data: [{ name: scatterSeriesName, xAxis: data.claim[0] }],
          label: {
            color: theme.palettes.textLight,
            formatter: scatterSeriesName,
          },
          lineStyle: {
            color: theme.palettes.danger,
            type: 'solid',
            width: 2
          }
        },
        itemStyle: {
          color: theme.palettes.chartKmTravelled
        }
      },
      // {
      //   name: scatterSeriesName,
      //   type: 'scatter',
      //   symbolSize: 15,
      //   itemStyle: {
      //     color: theme.palettes.danger,
      //     borderColor: theme.palettes.background,
      //     borderWidth: 3,
      //     opacity: 1
      //   },
      //   data: [ data.claim ]
      // }
    ]
  };
};

const parseTripsData = ( data: tripModels.TripProcessedData[], thePeriod: PeriodState ) => {
  let tripsData: Array<number[]> = [];
  const kmTraveledData: Record<string, number[]> = {};

  data.forEach( item => {
    const datetime    = moment.utc( item.date_start ).valueOf();
    const kmTravelled = ( Number( item.meters ) || 0 ) / 1000;

    tripsData.push([ datetime, kmTravelled ]);
  });

  tripsData.sort(( itemA: number[], itemB: number[] ) => {
    const datetimeA = itemA[0];
    const datetimeB = itemB[0];

    if ( datetimeA < datetimeB ) {
      return -1;
    }

    if ( datetimeA > datetimeB ) {
      return 1;
    }

    return 0;
  });

  if ( !moment.utc( tripsData[0][0] ).isSame( moment.utc( thePeriod.start ), 'day' ) ) {
    tripsData = [
      [ moment.utc( thePeriod.start ).valueOf(), 0 ],
      ...tripsData
    ];
  }

  if ( !moment.utc( tripsData[tripsData.length - 1][0] ).isSame( moment.utc( thePeriod.end ), 'day' ) ) {
    tripsData.push([ moment.utc( thePeriod.end ).valueOf(), 0 ]);
  }

  tripsData.forEach(( item: number[] ) => {
    const datetime    = item[0];
    const kmTravelled = item[1];

    const key = String( datetime );

    if ( key in kmTraveledData ) {
      if ( kmTraveledData[key][1] < kmTravelled ) {
        kmTraveledData[key][1] = kmTravelled;
      }
    }
    else {
      kmTraveledData[key] = [ datetime, kmTravelled ];
    }
  });

  return {
    trips: tripsData,
    kmTravelled: Object.values( kmTraveledData ),
  };
};

interface ChartData {
  trips: Array<number[]>;
  kmTravelled: Array<number[]>;
  claim: Array<number>;
}

const TripsChart = ({
  trips,
  claim,
  isAwaiting,
  thePeriod,
}: TripsChartInterface ) => {
  const { t } = useTranslation( 'main' );
  const theme = useAppTheme();
  const [ data, setData ] = useState( {} as ChartData );

  const noTripsToChart = !tripListHasTrips( trips );

  useEffect(() => {
    if ( noTripsToChart ) {
      return;
    }

    setData({
      claim: [
        ( new Date( claim.claim_date ) ).getTime(),
        ( claim.meters || 0 ) / 1000
      ],
      ...parseTripsData( trips, thePeriod )
    });
  }, [ trips ] );

  return ( noTripsToChart ? (
    <div className='height-100 flex flex-center'>
      {t( 'common.noDataToShow' )}
    </div>
  ) : ( isObjectEmpty( data ) ? null : (
    <Chart
      isResizable
      isAwaiting={isAwaiting}
      className='height-100'
      option={getChartOption({ t, theme, data })}
    />
  )));
};

export default TripsChart;