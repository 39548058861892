import { Document, Page } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { computeNumTripsInInfoPage } from '../../../utils/pdf';

import InfoPageContentFraudProbability from './InfoPageContent/InfoPageContent';
import PdfTemplate from '../PdfTemplate/PdfTemplate';
import PdfTripsPages from '../PdfTripsPages/PdfTripsPages';
import { PolicyAndResidenceFraudPdfInterface } from './PolicyAndResidenceFraudPdfInterface';


const PolicyAndResidenceFraudPdf = ({
	theme,
	policy,
  trips,
}: PolicyAndResidenceFraudPdfInterface) => {
  const { t } = useTranslation( 'main' );
	const maxNumTripsInInfoPage = 4;
  const numTripsInInfoPage    = computeNumTripsInInfoPage( trips, maxNumTripsInInfoPage );
  const pdfTemplateProps      = {
    theme,
    reportID: policy.profile_id,
    reportIdLabel: t( 'common.id' ),
    docTitle: t( 'pdf.policyReport.headerText' )
  };
	
	return (
		<Document>
			<Page size="A4">
				<PdfTemplate {...pdfTemplateProps}>
					<InfoPageContentFraudProbability
						theme={theme}
						policy={policy}
						trips={trips.slice( 0, numTripsInInfoPage )}
					/>
				</PdfTemplate>
			</Page>
			<PdfTripsPages
        trips={trips}
        pointerInitValue={numTripsInInfoPage}
        {...pdfTemplateProps}
      />
		</Document>
	);
};
export default PolicyAndResidenceFraudPdf;