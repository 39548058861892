import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useAppTheme } from "../../../hooks";
import { applyErrorBoundary } from "../../../utils/error-boundary";
import { parseResponseError } from "../../../utils/response-error";
import { getAppConfig, setDocumentTitle } from "../../../config";
import { ClaimsService } from "../../../services/ClaimsService";
import { downloadPdf } from "../../../utils/pdf";
import { useAtom } from "jotai";
import { tripModels } from "../../../models/tripModels";
import { claimModels } from "../../../models/claimModels";

import { PeriodState } from "../../../store/slices/periodSlice";
import { useAppDispatch } from "../../../store/hooks";
import { updatePeriod } from "../../../store/slices/periodSlice";
import { getClaimDetailPeriod } from "../../../utils/period";

import {
	GroundToastType,
	GroundToastVariant,
	useGroundToast,
} from "@greenvulcano/ground-react";
import DetailsPage from "../../../shared-components/DetailsPage/DetailsPage";
import Card from "../../../shared-components/Card/Card";
import RequestIndicator from "../../../shared-components/RequestIndicator/RequestIndicator";
import MainSidebarContent from "./MainSidebarContent/MainSidebarContent";
import ClaimInfoPdf from "../../../shared-components/pdf-documents/ClaimInfoPdf/ClaimInfoPdf";
import { tripsQueryAtom } from "../../../atoms/queryAtoms";

import "./ClaimDetails.scss";


const ClaimDetails = () => {
	const { t }       = useTranslation("main");
	const theme       = useAppTheme();
	const groundToast = useGroundToast();
	const appDispatch = useAppDispatch();
	const { id: claimID = 0 } = useParams();
	const [checkedTrips, setCheckedTrips] = useState<tripModels.TripProcessedData[]>( [] );
  const [tripsQueryAtomValue, setTripsQueryAtom] = useAtom(tripsQueryAtom);
	const [statusFormData, setStatusFormData] = useState(
		{} as Record<string, any>
	);
	const showExportButtonInClaimDetailsPage = getAppConfig('showExportButtonInClaimDetailsPage');

	const updateClaimDetailPeriod = ( period: PeriodState ) => {
		appDispatch( updatePeriod( { id: 'claimDetail', period } ));
	};

	const onSuccessHandler = ( data: claimModels.SingleProcessed ) => {
		if (data.created_manually === "0") {
		updateClaimDetailPeriod(getClaimDetailPeriod(data.claim_date));
    }
	};

	const query = useQuery({
		queryKey: ["claimDetails", claimID],
		queryFn: () => ClaimsService.getClaimDetails(claimID),
		onSuccess: onSuccessHandler
	});
	
	const onChangeCheckedTrips = ( checkedTrips: tripModels.TripProcessedData[] ) => {
		setCheckedTrips( checkedTrips );
	};

	const onChangeDateRange = (period: PeriodState) => {
		updateClaimDetailPeriod(period);
	};

	const onClickExport = () => {
    if ( !query.data ) {
      return;
    }

		(async () => {
			try {
        const filename = t( 'functionalities.claimReportFilename', { claimID } );

        await downloadPdf( filename, (
          <ClaimInfoPdf
						theme={theme}
						claim={query.data}
						trips={checkedTrips}
						claimStatus={statusFormData}
					/>
        ));
			} 
      catch (error) {
				groundToast.show({
					variant: GroundToastVariant.featured,
					type: GroundToastType.alert,
					message: t("toastMessages.documentGenFailed"),
				});
			}
		})();
	};

  const renderMainSidebar = ({
    statusChangeFormOptions,
  }: {
    statusChangeFormOptions: any;
  }) => {
    return (
      <Card className="height-100 flex">
        {query.isSuccess ? (
          <MainSidebarContent
            query={query}
            statusChangeFormOptions={statusChangeFormOptions}
          />
        ) : (
          <RequestIndicator
            isLoading={!query.isError}
            errorMessage={parseResponseError(query.error).message}
            spinnerOptions={{ scale: 0.7 }}
          />
        )}
      </Card>
    );
  };

	useEffect(() => {
		setDocumentTitle(t("common.claimDetail") as string);

		if ( query.data ) {
			onSuccessHandler( query.data );
		}
	}, []);

	return (
		<DetailsPage
			showChart
			pageTag="claim"
			mainQuery={query}
			utilityHeaderOptions={{
				relaxed: true,
        exportButton: {
          show: showExportButtonInClaimDetailsPage,
          disabled: ( !query?.isSuccess || !tripsQueryAtomValue?.isSuccess ),
          onClick: onClickExport 
        },
				title: t("common.claims"),
				description: t("pagesDescriptions.claimDetails"),
				onChangeDateRange,
			}}
			renderMainSidebar={renderMainSidebar}
			onChangeCheckedTrips={onChangeCheckedTrips}
			onChangeStatusForm={(data: any) => setStatusFormData(data)}
		/>
	);
};

export default applyErrorBoundary(ClaimDetails);
