import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { PDFViewer } from "@react-pdf/renderer";
import { useAppTheme } from "../../../hooks";
import { ClaimsService } from "../../../services/ClaimsService";

import ClaimInfoPdf from "../../../shared-components/pdf-documents/ClaimInfoPdf/ClaimInfoPdf";


const PdfDocument = () => {
	const theme              = useAppTheme();
	const [ searchParams ]   = useSearchParams();
  const claimID            = searchParams.get( 'claim_id' ) || 0;
  const query              = useQuery( {
    queryKey: [ 'claimDetails', claimID ],
    queryFn: () => ClaimsService.getClaimDetails( claimID ),
    enabled: Boolean( claimID )
  });

	return ( query.isSuccess ? (
		<PDFViewer style={{ height: "100vh" }}>
			<ClaimInfoPdf 
					theme={theme}
					claim={query.data}
					trips={[]}
					claimStatus={{
						id_claim_status: 1
					}}
				/>
		</PDFViewer>
	) : null );
};

export default PdfDocument;
