import styled from "styled-components";
import classNames from "classnames";
import { useAppTheme } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { formatNumber, normalizeData } from "../../../../utils";
import { getScoreLevelPalette } from "../../../../utils/color-utils";

import { GroundLoadingSpinner } from "@greenvulcano/ground-react";
import MoodyText from '../../../../shared-components/MoodyText/MoodyText';

import { 
  TrendsWidgetInterface, 
  TrendsWidgetStyled
} from './TrendsWidgetInterface';
import "./TrendsWidget.scss"


const Wrapper = styled.div<TrendsWidgetStyled>`
  background: ${( props: any ) => props.theme.palettes.backgroundQuaternary};
  width: ${( props: any ) => props.width};
`; 

const TrendsWidget = ({
    data,
    i18nTitleKey,
    unit = '',
    width,
    isAwaiting,
    formatter
}: TrendsWidgetInterface ) => {
    const { t } = useTranslation( 'main' );
    const theme = useAppTheme();
    const {
      value,
      criticality_level
    } = data;

    let displayedUnit       = '';
    let displayedValue: any = '';
    const fallbackPalette   = 'textWhite';
    let color               = fallbackPalette;
    const palettes          = [ 'successLight', 'warning', 'danger' ];
    const levelsI18nKeys    = [ 'common.low', 'common.medium', 'common.high' ];
    const level_id = Number.isNaN( Number( criticality_level ) ) ? 0 :  Number( criticality_level );

    if ( Array.isArray( value ) ) {
      displayedValue = normalizeData( value, 'id' );
    }
    else if ( value !== undefined ) {
      displayedValue = String( value );
    }

    if ( level_id > 0 && level_id <= palettes.length ) {
      color = palettes[level_id - 1];
    }
   
    switch ( unit ) {
      case 'percentage':
          displayedValue = value + '%';
      break;

      case 'mins':
        displayedUnit = 'min';
      break;

      case 'km':
        displayedUnit  = 'km'
        displayedValue = formatNumber( value );
      break;

      case 'kmh':
        displayedUnit = 'km/h';
      break;

      case 'hours':
        displayedUnit = 'h';
      break;

      case 'number':
        displayedValue = formatNumber( value );
      break;

      case 'score':
         color = getScoreLevelPalette( data.score_value, palettes.reverse(), fallbackPalette );
      break;

      // Empty. Covers non standard cases
      default:
      break;
    }

    return (
      <Wrapper
        theme={theme}
        width={width || 'auto'}
        className='trends-widget flex flex-columns'
      >
        {isAwaiting ? (
          <div className='flex-grow flex flex-center'>
            <GroundLoadingSpinner
              scale={0.5}
              color='secondary'
              pathColor='borderQuaternary'
            />
          </div>
        ) : (
          <>
            <MoodyText className='title flex-grow' color='background'>
              {t( i18nTitleKey )}
            </MoodyText>
            {Array.isArray( value ) ? (
              <div className='flex gap-1'>
                {palettes.map(( paletteName: string, idx: number ) => {
                  const mapKey = String( idx + 1 );

                  return (
                    <MoodyText
                      key={paletteName}
                      color={paletteName}
                      className='value cursor-help flex-grow'
                      title={t( levelsI18nKeys[idx] ) as string}
                    >
                      {mapKey in displayedValue && displayedValue[mapKey].value ? 
                      displayedValue[mapKey].value : 
                      '–'}
                    </MoodyText>
                  );
                })}
              </div>
            ) : (
              <MoodyText color={color}>
                {displayedValue ? (
                  formatter ? formatter( data, { className: 'value', t } ) : (
                    <span className={classNames( 'value', unit )}>{displayedValue}</span>
                  )
                ): (
                  <span className='placeholder'>&mdash;</span>
                )}
              </MoodyText>
            )}
          </>
        )}
      </Wrapper>
    );
};

export default TrendsWidget;