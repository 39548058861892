import { ChangeEvent, useEffect, useState, useMemo } from "react";
import { useQueryClient, useQuery, useQueries, UseQueryResult, useMutation, QueryClient } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import { useAtom } from "jotai";
import { ClaimsService } from "../../services/ClaimsService";
import { TripsService } from "../../services/TripsService";
import { useAppTheme, useConfirmationModal } from "../../hooks";
import { isObjectEmpty } from "../../utils";
import { canClaimAmountBoxInProfileDetailsBeShown, isPolicyTypePersonal } from "../../utils/profiles";
import { getAppConfig } from "../../config";
import { stripNulls } from "../../utils/sanitization";
import { isHeartbeat, tripListHasTrips } from "../../utils/trips-utils";
import { getDefaultResidenceMarkerIcon } from "../../utils/colorcoded-markers";
import { tripModels } from "../../models/tripModels";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectImmutablePeriod,
  selectPeriod,
  updatePeriod,
} from "../../store/slices/periodSlice";
import { tripsQueryAtom } from "../../atoms/queryAtoms";

import { DetailsPageInterface } from "./DetailsPageInterface";
import { GroundToggle, GroundToastType, GroundToastVariant, useGroundToast } from "@greenvulcano/ground-react";
import UtilityHeader from "../UtilityHeader/UtilityHeader";
import MainContentWrapper from "../MainContentWrapper/MainContentWrapper";
import Card from "../Card/Card";
import RequestIndicator from "../RequestIndicator/RequestIndicator";
import ListOfTrips from "./ListOfTrips/ListOfTrips";
import TripsChart from "./TripsChart/TripsChart";
import Map from "../Map/Map";
import MainSidebarCard from "../MainSidebarCard/MainSidebarCard";
import MoodyText from "../MoodyText/MoodyText";
import LastTripConfirmationModal from "../confirmation-modals/LastTripConfirmationModal/LastTripConfirmationModa";
import LongTextTooltip from "../LongTextTooltip/LongTextTooltip";
import HelpTooltip from "../HelpTooltip/HelpTooltip";

import "./DetailsPage.scss";


interface StatusChangeFormValues {
  id_claim_status?: string;
  id_fraud_status?: string;
  note: string;
}

const statusFormInitialValues: Record<string, StatusChangeFormValues> = {
  profile: {
    id_fraud_status: '',
    note: ''
  },
  claim: {
    id_claim_status: '',
    note: ''
  }
}

const getTripFallbackCoords = ( theTrip: tripModels.TripProcessedData ) => {
  const fallbackCoords = [];
  
  if ( theTrip.lng_start && theTrip.lat_start ) {
    fallbackCoords.push( { lat: theTrip.lat_start, lng: theTrip.lng_start });
  }

  if ( theTrip.lng_end && theTrip.lat_end ) {
    fallbackCoords.push( { lat: theTrip.lat_end, lng: theTrip.lng_end });
  }

  return fallbackCoords;
};

const getTripColor = ({ 
  tripIdx, 
  tripColors, 
  isFirstColorReservedToFirstTrip = true
}: {
  tripIdx: number;
  tripColors: string[];
  isFirstColorReservedToFirstTrip?: boolean;
}) => {
  const numOfColors = tripColors.length;

  if ( 0 !== tripIdx ) {
    if ( tripIdx >= numOfColors ) {
      if ( isFirstColorReservedToFirstTrip ) {
        tripIdx = ( tripIdx % ( numOfColors - 1 ) ) || ( numOfColors - 1 );
      }
      else {
        tripIdx = ( tripIdx % numOfColors );
      }
    }
  }

  return tripColors[tripIdx];
};

const autoselectTrips = ({
  trips,
  showHeartbeats,
  maxTripsOnTopWithCoords,
}: {
  trips: tripModels.TripData[] | tripModels.TripProcessedData[];
  showHeartbeats: boolean;
  maxTripsOnTopWithCoords: number;
}): ( tripModels.TripPreprocessedData[] | tripModels.TripProcessedData[] ) => {
  let checkedTripFound = false;
  let checkedTripOrHeartbeatFound = false;

  const updateFlags = ( item: tripModels.TripData | tripModels.TripProcessedData ) => {
    if ( !( 'checked' in item ) ) {
      return;
    }

    if ( item.checked ) {
      checkedTripOrHeartbeatFound = true;
    }

    if ( item.checked && !isHeartbeat( item ) ) {
      checkedTripFound = true;
    }
  };

  trips.forEach( item => updateFlags( item )); 

  let processedTrips = trips.map( item => {
    const isProcessedTripList = ( 'checked' in item );
    let checked = ( isProcessedTripList && Boolean( item.checked ) );
    const isNearestPointItemWithCoords = Boolean( item.nearest_point_in_time && item.coords );

    if ( isProcessedTripList ) {
      if (
        isNearestPointItemWithCoords &&
        (
          ( showHeartbeats && !checkedTripOrHeartbeatFound ) ||
          ( !showHeartbeats && !checkedTripFound )
        )
      ) {
        checked = true; 
      }
    }
    else {
      checked = isNearestPointItemWithCoords;
    }

    return { ...item, checked };
  });

  processedTrips.forEach( item => updateFlags( item ));

  if ( 
    ( showHeartbeats && !checkedTripOrHeartbeatFound ) ||
    ( !showHeartbeats && !checkedTripFound )
  ) {
    let idx = 0;

    processedTrips = processedTrips.map( item => {
      return {
        ...item,
        checked: ( 
          ( !showHeartbeats && isHeartbeat( item ) ) ?
          Boolean( item.checked ) :
          ( idx++ < maxTripsOnTopWithCoords )
        )
      };
    });
  };

  return processedTrips;
};

const processRawTrips = ({
  trips,
  showHeartbeats,
  maxTripsOnTopWithCoords,
  theme,
  queryClient
}: {
  trips: tripModels.TripData[];
  showHeartbeats: boolean;
  maxTripsOnTopWithCoords: number;
  theme: Record<string, any>;
  queryClient: QueryClient;
}): tripModels.TripProcessedData[] => {
  let tripIdx          = 0;
  let heartbeatIdx     = 0;
  let redTripProcessed = false;

  const preprocessedTrips = autoselectTrips({
    trips,
    showHeartbeats,
    maxTripsOnTopWithCoords,
  });

  return preprocessedTrips.map(( item: any ) => {
    let colorFnArgs = null;
    let coords      = item.coords;
    let redColorIdx = -1; // Initialized with an invalid value.
    const isHB      = isHeartbeat( item );
    
    if ( item.checked && !redTripProcessed ) {
      redColorIdx      = 0; // The first checked item's color must be red.
      redTripProcessed = true;
    }

    if ( isHB ) {
      let heartbeatColorIdx = heartbeatIdx + 1; // We skip the first color of the list of 'heartbeatColors'
      
      heartbeatIdx += 1;
      colorFnArgs = { 
        tripIdx: ( 0 === redColorIdx ) ? redColorIdx : heartbeatColorIdx, 
        tripColors: theme.heartbeatColors,
      };
    }
    else {
      let tripColorIdx = tripIdx + 1; // We skip the first color of the list of 'tripColors'

      if ( !coords ) {
        coords = (
          queryClient.getQueryData([ 'tripCoords', item.trip_id ]) ||
          ({} as any)
        ).coords
      }

      tripIdx    += 1;
      colorFnArgs = { 
        tripIdx: ( 0 === redColorIdx ) ? redColorIdx : tripColorIdx, 
        tripColors: theme.tripColors
      };
    }

    return {
      ...item,
      color: getTripColor( colorFnArgs ),
      coords
    };
  });
};

const DetailsPage = ({
  pageTag,
  showChart,
  mainQuery,
  utilityHeaderOptions,
  renderMainSidebar = () => null,
  onChangeCheckedTrips = () => null,
  onChangeStatusForm = () => null
}: DetailsPageInterface ) => {
  const periodID        = pageTag + "Detail";
  const isClaimDetail   = ( "claim" === pageTag );
  const isProfileDetail = ( "profile" === pageTag );

  const { t } = useTranslation("main");
  const location = useLocation();
  const theme = useAppTheme();
  const queryClient = useQueryClient();
  const appDispatch = useAppDispatch();
  const groundToast = useGroundToast();
  const confirmationModal = useConfirmationModal();
  const thePeriod = useAppSelector(selectPeriod( periodID ));
  const newClaimPeriodImmutable = useAppSelector(
    selectImmutablePeriod("newClaim")
  );
  const [searchParams] = useSearchParams();
  let { id: pageID = 0, id_voucher: voucherID = 0 } = useParams();

  const [showResidence, setShowResidence] = useState(false);
  const [ showHeartbeats, setShowHeartbeats ] = useState( true );
  const [ lastTripAlertDisplayed, setLastTripAlertDisplayed ] = useState(false);
  const [statusFormDefaults, setStatusFormDefaults] = useState( {} as StatusChangeFormValues );
  const [tripsQueryAtomValue, setTripsQueryAtom] = useAtom(tripsQueryAtom);

  // 'allTripsAndHeartbeats' becomes an array only when the component has
  // finished processing the data received from the back-end.
  const [ allTripsAndHeartbeats, setAllTripsAndHeartbeats] = useState<tripModels.TripProcessedData[] | null>( null );

  const tripsAndHeartbeatsToDisplay = useMemo(() => {
    const safeAllTripsAndHeartbeats = ( allTripsAndHeartbeats || [] );

    if ( showHeartbeats ) {
      return safeAllTripsAndHeartbeats;
    }

    return safeAllTripsAndHeartbeats.filter( item => !isHeartbeat( item ) );
  }, [ allTripsAndHeartbeats, showHeartbeats ] );

  const checkedTripsAndHeartbeatsToDisplay = useMemo(() => {
    return tripsAndHeartbeatsToDisplay.filter( item => item.checked );
  }, [ tripsAndHeartbeatsToDisplay ] );

  const {
    control,
    handleSubmit,
    formState,
    getValues,
    watch,
  } = useForm<StatusChangeFormValues>();

  const claimID = ( isClaimDetail ? pageID : 0 );
  let maxTripsOnTopWithCoords = getAppConfig( 'maxTripsOnTopWithCoords' );
  let sidebarDoesNotHaveClaimAmountBox = false;
  const showResidencePolicy = getAppConfig('showOnlyResidenceForPolicy');

  if (isClaimDetail) {
    voucherID = (
      searchParams.get('id_voucher') ||
      (mainQuery.isSuccess ? mainQuery.data.id_voucher : 0)
    );
  }

  if ( isProfileDetail && mainQuery.data ) {
    sidebarDoesNotHaveClaimAmountBox = !canClaimAmountBoxInProfileDetailsBeShown( mainQuery.data.policy_type_id );

    if ( isPolicyTypePersonal( mainQuery.data.policy_type_id ) ) {
      maxTripsOnTopWithCoords = getAppConfig( 'maxTripsOnTopWithCoordsForPersonalPolicy' );
    }
  }

  const lastTripDateQuery = useQuery({
    queryKey: [ 'lastTripDate', voucherID ],
    queryFn: () => TripsService.getLastTripDate( voucherID ),
    onError: () => {
      groundToast.show({
        variant: GroundToastVariant.featured,
        type: GroundToastType.alert,
        message: t("errors.failedToFindLatestTrips"),
      });
    },
  });

  const tripsQuery = useQuery({
    enabled: Boolean( voucherID ) && !isObjectEmpty( thePeriod ),
    queryKey: ["trips", voucherID, { thePeriod, claimID, maxTripsOnTopWithCoords }],
    queryFn: () => {
      if (moment(thePeriod.start).isValid() && moment(thePeriod.end).isValid()) {
        return TripsService.getTrips({
          id_voucher: voucherID,
          period: thePeriod,
          claim_id: claimID,
          max_on_top_with_coords: maxTripsOnTopWithCoords,
        });
      }
    },
  });

  const handleCoordsQueryResponse = ( data: tripModels.CoordsQueryResponseData, checkedTripID: string ) => {
    setAllTripsAndHeartbeats(tripsAndHBs => tripsAndHBs!.map( item => {
      if ( String(item.trip_id) === String(checkedTripID) ) {
        return {
          ...item,
          coords: ( data.coords || getTripFallbackCoords( item ) ),
        };
      }

      return item;
    }));
  };

  const coordsQueries = useQueries(
    checkedTripsAndHeartbeatsToDisplay.map( checkedTrip => {
      const tripID = checkedTrip.trip_id;
      const period = {
        start: moment.utc(checkedTrip.date_gmt_start).format("YYYY-MM-DD HH:mm:ss"),
        end: moment.utc(checkedTrip.date_gmt_end).format("YYYY-MM-DD HH:mm:ss"),
      };

      return {
        enabled: !Boolean( checkedTrip.coords ),
        queryKey: [ 'tripCoords', tripID ],
        queryFn: () => TripsService.getTripCoords( tripID, period ),
        onSuccess: ( data: tripModels.CoordsQueryResponseData ) => handleCoordsQueryResponse( data, tripID ),
        onError: () => handleCoordsQueryResponse( {} as tripModels.CoordsQueryResponseData, tripID ),
      };
    })
  );

  const mutation = useMutation({
    mutationFn: (payload: any) => {
        return ClaimsService.changeClaimStatus(pageID, payload);
    },
    onSuccess: () => {
      if (isClaimDetail) {
        queryClient.invalidateQueries({ queryKey: ["claimsList"] });
        queryClient.invalidateQueries({ queryKey: ["claimDetails", pageID] });
      } else {
        queryClient.invalidateQueries({ queryKey: ["profileDetails", pageID] });
      }

      groundToast.show({
        variant: GroundToastVariant.featured,
        message: t("toastMessages.statusChanged"),
      });

      setStatusFormDefaults(getValues());
    },
    onError: () => {
      groundToast.show({
        variant: GroundToastVariant.featured,
        type: GroundToastType.alert,
        message: t("toastMessages.statusChangeFailed"),
      });
    },
  });

  const dependentSpinnerIsLoading = !(tripsQuery.isError || mainQuery.isError);
  const breadcrumbBackTo = location.pathname.replace(/\/+[^\/]+\/*$/, "");

  const areTripsBeingProcessed = () => {
    return ( null === allTripsAndHeartbeats );
  }

  const coordsQueriesIsFetching = () => {
    return coordsQueries.some((query: UseQueryResult) => query.isFetching);
  };

  const onChangeCheckedTripHandler = ( trip: Record<string, any> ) => {
    setAllTripsAndHeartbeats(tripsAndHBs => tripsAndHBs!.map((item: any) => {
      return ( ( String(item.trip_id) === String(trip.trip_id) ) ? trip : item );
    }));
  };
  
  const onChangeShowHeartbeatsToggle = ( event: ChangeEvent<HTMLInputElement> ) => {
    const checked = event.target.checked;

    setAllTripsAndHeartbeats(
      autoselectTrips({
        maxTripsOnTopWithCoords,
        showHeartbeats: checked,
        trips: allTripsAndHeartbeats as tripModels.TripProcessedData[]
      }) as tripModels.TripProcessedData[]
    );
    setShowHeartbeats( checked );
  };

  const onSubmitStatusChange: SubmitHandler<StatusChangeFormValues> = (
    data
  ) => {
    mutation.mutate(data);
    onChangeStatusForm(data);
  };

  const onActionLastTripModal = () => {
    const lastTripDate = lastTripDateQuery.data?.last_trip_date;

    if ( lastTripDate ) {
      appDispatch(
        updatePeriod({ 
          id: periodID, 
          period: { 
            start: moment.utc( lastTripDate ).subtract( 1, 'days' ).format( 'YYYY-MM-DD' ), 
            end: moment.utc( lastTripDate ).add( 1, 'days' ).format( 'YYYY-MM-DD' )
          } 
        })
      );
      queryClient.removeQueries({ queryKey: [ 'trips' ] });

      // This will force the list of trips 
      // to fall in a state of "processing in progress" 
      setAllTripsAndHeartbeats( null );
    }

    confirmationModal.close();
  };

  const disableStatusFormSubmit = () => {
    const currentValues = watch();

    if (JSON.stringify(currentValues) === JSON.stringify(statusFormDefaults)) {
      return true;
    }

    return !formState.isValid;
  };

  useEffect(() => {
    if ( !tripsQuery.isSuccess ) {
      return;
    }

    const trips     = tripsQuery.data!;
    let localShowHB = showHeartbeats;

    if ( !( lastTripAlertDisplayed || ( trips && trips.length ) ) ) {
      setLastTripAlertDisplayed( true );
      
      if ( lastTripDateQuery.data ) {
        confirmationModal.open(
          <LastTripConfirmationModal onAction={onActionLastTripModal} />
        );
      } 
      else {
        groundToast.show({
          variant: GroundToastVariant.featured,
          type: GroundToastType.warning,
          message: t("toastMessages.noTrips"),
        });
      }
    }
    else if ( !tripListHasTrips( trips ) ) {
      localShowHB = true;

      setShowHeartbeats( localShowHB );
    }

    setAllTripsAndHeartbeats(processRawTrips({
      trips,
      theme,
      queryClient,
      maxTripsOnTopWithCoords,
      showHeartbeats: localShowHB
    }));
  }, [ tripsQuery.data ] );

  useEffect(() => {
    if (tripsQueryAtomValue === null || tripsQuery.status !== tripsQueryAtomValue.status) {
      setTripsQueryAtom(tripsQuery);
    }
  }, [tripsQuery]);

  useEffect(() => {
    if (!isObjectEmpty(mainQuery.data) && isObjectEmpty(statusFormDefaults)) {
      const initialValues = statusFormInitialValues[pageTag];
      const defaults = Object.keys(initialValues).reduce(
        (obj: any, key: string) => {
          return {
            ...obj,
            [key]:
              key in mainQuery.data
                ? mainQuery.data[key]
                : initialValues[key as keyof StatusChangeFormValues],
          };
        },
        {}
      );

      setStatusFormDefaults(defaults);
      onChangeStatusForm(defaults);
      if (isClaimDetail && mainQuery.data.claim_date === null) {
        appDispatch(
          updatePeriod({ id: "claimDetail", period: newClaimPeriodImmutable })
        );
      } 
    }
  }, [mainQuery.status]);

  useEffect(() => {
    onChangeCheckedTrips( checkedTripsAndHeartbeatsToDisplay );
  }, [ checkedTripsAndHeartbeatsToDisplay ] );

  return (
		<div className="details-page flex-grow flex flex-columns">
			<UtilityHeader
				{...utilityHeaderOptions}
				period={thePeriod}
				breadcrumbBackTo={breadcrumbBackTo}
			/>
			<MainContentWrapper overlapped>
				<div className="dpage-content flex gap-2">
					<div
						className={classNames("sidebar", `${pageTag}-details-sidebar`, {
							"no-claim-amount-box": sidebarDoesNotHaveClaimAmountBox,
						})}
					>
						{renderMainSidebar({
              statusChangeFormOptions:{
								control,
								formState,
								mutation,
								disableSubmit: disableStatusFormSubmit,
								onSubmit: handleSubmit(onSubmitStatusChange),
							},
              tripsQuery 
						})}
					</div>
					<div className="flex-grow flex flex-columns gap-3">
						<Card className="dpage-map-wrapper dpage-card flex-grow">
							{areTripsBeingProcessed() ? (
								<RequestIndicator
									isLoading={dependentSpinnerIsLoading}
									spinnerOptions={{ scale: 0.7 }}
								/>
							) : (
								isClaimDetail ? (
									<Map
										isAwaiting={
											tripsQuery.isFetching || coordsQueriesIsFetching()
										}
										claim={mainQuery.data}
										trips={checkedTripsAndHeartbeatsToDisplay}
									/>
								) : (
									<Map
										isAwaiting={
											tripsQuery.isFetching || coordsQueriesIsFetching()
										}
										trips={checkedTripsAndHeartbeatsToDisplay}
										residenceCoords={
											showResidence ? mainQuery.data.address_coords : null
										}
									/>
								)
							)}
						</Card>
						{showChart ? (
							<Card className="dpage-chart-wrapper">
								{areTripsBeingProcessed() ? (
                  <RequestIndicator
                    isLoading={dependentSpinnerIsLoading}
                    spinnerOptions={{ scale: 0.7 }}
                  />
								) : (
									<TripsChart
                    isAwaiting={tripsQuery.isFetching}
                    trips={tripsAndHeartbeatsToDisplay}
                    claim={mainQuery.data}
                    thePeriod={thePeriod}
									/>
								)}
							</Card>
						) : null}
					</div>
					<div
						className={classNames(
							"list-of-trips-sidebar sidebar",
							`${pageTag}-details-sidebar`,
							{ "no-claim-amount-box": sidebarDoesNotHaveClaimAmountBox }
						)}
					>
						{isProfileDetail ? (
							<MainSidebarCard
								hideError
								isSuccess={tripsQuery.isSuccess}
								isLoading={dependentSpinnerIsLoading}
								className="dpage-card dpage-residence-box-card"
							>
								<div className="mb-2">
									<h3 className='flex flex-vcenter gap-1'>
                    <img
                      style={{ width: '1.7rem', height: 'auto' }} 
                      src={getDefaultResidenceMarkerIcon()}
                    />
                    {t( 'common.residence' )}
                  </h3>
									{!showResidencePolicy && (
										<p className="small-text">
											<MoodyText color="textLight">
												{t("common.residenceBoxDescr")}
											</MoodyText>
										</p>
                  )}
								</div>
								{mainQuery.isSuccess && mainQuery.data.address ? (
									<div className="flex flex-vcenter gap-1">
										{!showResidencePolicy && mainQuery.data.address_coords ? (
											<GroundToggle
												animated
												checked={showResidence}
												onChange={( event: ChangeEvent<HTMLInputElement> ) =>
													setShowResidence(event.target.checked)
												}
												color="success"
												scale={0.7}
											/>
										) : null}
										<p>
                      <LongTextTooltip
                        showAtLength={60}
                        text={stripNulls( mainQuery.data.address )}
                      />
                    </p>
									</div>
								) : (
									<p>{t("errors.addressNotAvailable")}</p>
								)}
							</MainSidebarCard>
						) : null}
						<MainSidebarCard
							isSuccess={!areTripsBeingProcessed()}
							isLoading={dependentSpinnerIsLoading}
							className={classNames("dpage-card dpage-trips-card", {
								resized: isProfileDetail && !sidebarDoesNotHaveClaimAmountBox,
								"no-claim-amount-box": sidebarDoesNotHaveClaimAmountBox,
							})}
							innerClassName="height-100 flex flex-columns gap-2"
						>
							<div>
								<h3 className="title">{t("common.listOfTripsTitle")}</h3>
								<p className="small-text">
									<MoodyText color="textLight">
										{t("common.listOfTripsDescr")}
									</MoodyText>
								</p>
                {( allTripsAndHeartbeats &&  allTripsAndHeartbeats.length ) ? (
                  <div className='mt-3 flex flex-vcenter gap-1'>
                    <GroundToggle
                      animated
                      checked={showHeartbeats}
                      label={t( 'common.hideHb' )}
                      offLabel={t( 'common.showHb' )}
                      onChange={onChangeShowHeartbeatsToggle}
                      color='secondary'
                      scale={0.7}
                    />
                    <HelpTooltip helpText={t( 'tooltips.heartbeatHint')} />
                  </div>
                ) : null}
							</div>
							<ListOfTrips
								trips={tripsAndHeartbeatsToDisplay}
                showEmptyList={!showHeartbeats}
								onChangeCheckedTrips={onChangeCheckedTripHandler}
							/>
						</MainSidebarCard>
					</div>
				</div>
			</MainContentWrapper>
		</div>
	);
};

export default DetailsPage;
