import moment from "moment";
import { getAppConfig, getAppDateFormatWithAproximateTime, getAppLanguage, getAppLocale } from "../config";
import { claimModels } from "../models/claimModels";
import { unitModels } from "../models/unitModels";
import { DetailsPageModalDataItem } from "../shared-components/confirmation-modals/DetailsPageModal/DetailsPageModalInterface";
import { handleNumbersWithAnyType, numberToDistance } from "./numbers";


/**
 * Checks whether or not cookies are enabled.
 */
export const areCookiesEnabled = (): boolean => {
  let cookieEnabled = Boolean( navigator.cookieEnabled );

  if ( !cookieEnabled ) { 
      document.cookie = 'dump_cookie';
      cookieEnabled   = ( document.cookie.indexOf( 'dump_cookie' ) !== -1 );
  }

  return cookieEnabled;
}

/**
 * Capitalizes a string.
 */
export const capitalize = ( str: string ): string => {
  str = String( str );

  return str.charAt(0).toUpperCase() + str.substring( 1 );
};

/**
 * Truncates a string.
 */
export const truncateString = ( str: string, maxLength: number ) => {
  return ( str.length > maxLength ) ? ( str.slice( 0, maxLength - 1 ) + '...' ) : str;
};

/**
 * Converts string casing to camelCase.
 */
export const camelize = ( str: string ): string => {
  str = String( str );

  return str.toLowerCase()
            .replace( /[^a-z0-9]/gi, ' ' )
            .trim()
            .replace( /\s+/g, ' ' )
            .split( ' ' )
            .map(( word: string, idx: number ) => {
              if ( 0 === idx ) {
                return word;
              }

              return capitalize( word );
            })
            .join( '' );
};

/**
 * Converts string casing to title case.
 */
export const toTitleCase = ( str: string ): string => {
  const temp = String( str ).toLowerCase();

  return temp.replace( /\w\S*/g, ( word: string ) => (
    word.charAt( 0 ).toUpperCase() + word.substring( 1 )
  ));
};

/**
 * Checks whether or not a variable is an object.
 */
export const isObject = ( variable: any ) => {
  return (
      typeof variable === 'object' &&
      !Array.isArray( variable ) &&
      variable !== null
  );
};

/**
 * Checks whether or not an object is empty.
 */
export const isObjectEmpty = ( obj: any ) => {
  if ( !isObject( obj ) ) {
    return true;
  }

  return ( Object.keys( obj ).length === 0 );
};

/**
 * Returns a currency string formatted in accounting style.
 */
export const numberToCurrency = ( value: number | string ) => {
  const theValue  = Number( value );

  if ( Number.isNaN( theValue ) ) {
    return '';
  }

  const formatter = new Intl.NumberFormat( 'en-US', {
    style: 'currency',
    currency: getAppConfig( 'currency', 'USD' ),
    minimumFractionDigits: ( theValue % 1 !== 0 ? 2 : 0 ),
    maximumFractionDigits: 2
  });

  return formatter.format( theValue );
};

/**
 * Returns a formatted number.
 */
export const formatNumber = ( 
  value: number | string, 
  numDecimals = 2, 
  options: { alwaysShowDecimals: boolean; } = { alwaysShowDecimals: false }
) => {
  const theValue  = Number( value );

  if ( Number.isNaN( theValue ) ) {
    return '';
  }

  const formatter = new Intl.NumberFormat( getAppLocale(), {
    minimumFractionDigits: ( options.alwaysShowDecimals || theValue % 1 !== 0 ) ? numDecimals : 0,
    maximumFractionDigits: numDecimals
  });

  return formatter.format( theValue );
};

/**
 * Creates a map from an array of objects.
 */
export const normalizeData = ( data: Record<string, any>[], pivotKey: string, deletePivotEntry = false ) => {
  if ( !Array.isArray( data ) ) {
    return {};
  };

  return data.reduce(( _map: Record<string, any>, item: Record<string, any> ) => {
      const pivotValue = item[pivotKey];

      if ( pivotValue ) {
        if ( deletePivotEntry ) {
          delete item[pivotKey];
        }

        return {
          ..._map,
          [String( pivotValue )]: item
        };
      }
      
      return _map;
    }, {} );
};

/**
 * For a given 'idKeyName', generates 
 * a list of literals (comma separated by default) from an array of objects.
 */
export const objectsToIdsList = ( arrayOfObjects: Record<string, any>[], idKeyName: string, join = true ) => {
  const list = ( arrayOfObjects || [] ).map(( object: any ) => object[idKeyName] );
  
  if ( join ) {
    return list.join( ', ' );
  }

  return list;
}

export const uniqueId = () => {
	const dateString = Date.now().toString(36);
	const randomness = Math.random().toString(36).substr(2);
	return dateString + randomness;
};

export const getProccessedFraudProbabilityNotes = (
	fraudProbabilityNotes: claimModels.fraudProbabilityNote[]
) => {
	if (!Array.isArray(fraudProbabilityNotes)) {
		return undefined;
	}

	const appLanguage = getAppLanguage();

	return fraudProbabilityNotes.map(
		(note: claimModels.fraudProbabilityNote): DetailsPageModalDataItem => {
			let value = String(note.value || "");

			if (note.type === claimModels.noteTypeEnum.DATE) {
				value = moment(note.value)
					.format(getAppDateFormatWithAproximateTime());
			} else if (note.type === claimModels.noteTypeEnum.NUMBER) {
				const number = handleNumbersWithAnyType(note.value);

				if (number) {
					const keysWhichAreDistanceInMeters = [
						"avg_distance_from_claim",
						"min_distance_from_claim",
					];
					const isDistanceInMeters =
						keysWhichAreDistanceInMeters.includes(note.key) ||
						note.unit === unitModels.unitsEnum.METERS;

					value = isDistanceInMeters
						? numberToDistance(number)
						: formatNumber(number, 1);

					if (note.unit === unitModels.unitsEnum.KILOMETERS) {
						value += " " + unitModels.unitsEnum.KILOMETERS;
					}
				}
			}

			return {
				value,
				label:
					note.label[
						claimModels.isNoteLabelKey(appLanguage) ? appLanguage : "en"
					],
			};
		}
	);
};
