import axios from "axios";
import { getApiEndpoint } from "../config";
import { AuthService } from "./AuthService";

import { PeriodState } from "../store/slices/periodSlice";
import { periodToQueryParams } from "./utils";
import { isObjectEmpty, normalizeData } from "../utils";
import { policyModels } from "../models/policyModels";
import { appConfig } from "../config/app-config/app-config";


export const ProfilesService = {
  getProfiles: async ( queryParams: Record<string, any>, period: PeriodState ) => {
    try {
      const { data: responseData } = await axios.get( 
        getApiEndpoint( 'profiles' ), 
        { 
          params: {
            ...queryParams,
            ...periodToQueryParams( period )
          },
          ...AuthService.getAuthHeaders()
        }
      );

      const rawData    = responseData.data.data as policyModels.ListDataItem[];
      const parsedData = rawData.map( item => {
        if ( item.policy_type ) {
          try {
            item.policy_type = JSON.parse( item.policy_type );
          } 
          catch (error) {
            // Left empty on purpose.
          }
        }
        
        return item;
      });
      
      return Promise.resolve({
        data: parsedData,
        totalItems: ( 
          Number( responseData.data.pagination.total ) || 
          parsedData.length || 1 
        )
      } as policyModels.ListResponse);
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  },
  getProfilesForReport: async ( queryParams: Record<string, any> ) => {
		try {
			const { data: responseData } = await axios.get(getApiEndpoint( 'profilesForReport' ), {
				params: queryParams,
				...AuthService.getAuthHeaders(),
			});

      const rawData    = responseData.data as policyModels.CsvReportDataItem[];
      const parsedData = rawData.map( item => {
        if ( item.policy_type ) {
          try {
            item.policy_type = JSON.parse( item.policy_type );
          }
          catch (error) {
            // Left empty on purpose.
          }
        }
        
        return item as policyModels.CsvReportParsedDataItem;
      });

      return Promise.resolve( parsedData );
		}
    catch ( error: any ) {
			return AuthService.maybeForceLogout( error );
		}
	},
  getProfilesSummaryForReport: async () => {
		try {
			const { data: responseData } = await axios.get(
        getApiEndpoint( 'profilesSummaryForReport' ),
				AuthService.getAuthHeaders(),
      );

      return Promise.resolve( responseData.data as policyModels.SummaryReportData );
		}
    catch ( error: any ) {
			return AuthService.maybeForceLogout( error );
		}
	},
  getProfileDetails: async ( id_voucher: string | number ) => {
    try {
      const { data: responseData } = await axios.get(
        getApiEndpoint( 'singleProfile', { id_voucher } ),
        AuthService.getAuthHeaders()
      );

      const rawData = responseData.data as policyModels.Single;

      let policy_type           = {};
      let residence_area        = {};
      let residence_area_center = {};

			try {
        if ( rawData.policy_type ) {
          policy_type = JSON.parse( rawData.policy_type );
        }

        if ( rawData.residence_area ) {
          residence_area = JSON.parse( rawData.residence_area );
        }

        if ( rawData.residence_area_center ) {
          residence_area_center = JSON.parse( rawData.residence_area_center );
        }
			}
      catch (error) {
        // Left empty on purpose
      }

			return Promise.resolve({
				...rawData,
        policy_type,
        residence_area,
        residence_area_center
			} as policyModels.SingleProcessed );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  },
  getEventsDetail: async ( voucherIDsList: string, period: PeriodState ): Promise<any> => {
    try {
      const { data: responseData } = await axios.get(
        getApiEndpoint( 'profileEventsDetail' ),
        { 
          params: {
            voucher_ids: ( voucherIDsList || null ),
            ...periodToQueryParams( period )
          },
          ...AuthService.getAuthHeaders()
        }
      );

      const normalizedData = ( responseData.data.length ? normalizeData( responseData.data, 'id_voucher' ) : {} );
      
      return Promise.resolve( normalizedData );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  },
  getProfilesWithEventsDetail: async ( queryParams: Record<string, any>, period: PeriodState ): Promise<any> => {
    try {
      const [ profiles, events ] = await Promise.all([
        ProfilesService.getProfiles( queryParams, period ),
        ProfilesService.getEventsDetail( '', period )
      ]);

      if ( !isObjectEmpty( events ) ) {
        profiles.data = profiles.data.map(( item: any ) => {
          const key = String( item.id_voucher );
    
          if ( events[key] ) {
            return {
              ...item,
              ...events[key]
            };
          }
    
          return item;
        })
      }
      
      return Promise.resolve( profiles );
    }
    catch ( error: any ) {
      return AuthService.maybeForceLogout( error );
    }
  },
  getApproxNumOfInapplicableFraudProb: async ( queryParams: Record<string, any> ): Promise<any> => {
		try {
			const { data: responseData } = await axios.get(getApiEndpoint( 'profilesCountFraudProb' ), {
				params: queryParams,
				...AuthService.getAuthHeaders(),
			});

      let count  = 0;
      const data = responseData.data as policyModels.FraudProbabilityCounts;
      const inapplicableFraudID = appConfig.fraudProbabilityLevels.indexOf( 'inapplicable' );

      [ 'policy_type', 'residence' ].forEach( fraudProbKey => {
        const foundItem = ( data[fraudProbKey as keyof policyModels.FraudProbabilityCounts] || [] ).find( 
          item => item ? ( inapplicableFraudID === Number( item.fraud_id ) ) : false 
        );

        if ( foundItem ) {
          count = Math.max( count,  Number( foundItem.count ) || 0 );
        }
      });

      count = Math.ceil( count / 10 ) * 10;
      
      return Promise.resolve( count );
		}
    catch ( error: any ) {
			return AuthService.maybeForceLogout( error );
		}
	},
};
