import { tripModels } from "../models/tripModels";


export const getTripsData = ( data: any ): Record<string, number> => {
  if ( !data ) {
    return {};
  }

  const processedData: Record<string, number> = {
    score: 0,
    meters: 0,
    tot_duration: 0,
    speeding: 0,
    idling_time: 0,
    long_distance: 0,
    night_time: 0,
    long_duration: 0,
    trips_count: 0,
  };

  data.forEach(( item: any ) => {
    for ( const key in item ) {
      if ( key in processedData ) {
        processedData[key] += ( Number( item[key] ) || 0 );
      }
    }
    
    processedData.trips_count += 1;
  });

  const { score: score_sum, ...otherData } = processedData;

  return {
    ...otherData,
    km_count: ( processedData.meters / 1000 ),
    avg_score: Math.round( ( score_sum / data.length ) * 1000 ) / 1000
  };
};

export const isHeartbeat = ( trip: tripModels.TripData ) => {
  return ( trip.trip_type && ( 'HB' === trip.trip_type ) );
};

export const isTrip = ( trip: tripModels.TripData ) => {
  return ( trip.trip_type && ( 'TRIP' === trip.trip_type ) );
};

export const tripListHasTrips = ( trips: tripModels.TripData[] ) => {
  if ( !( Array.isArray( trips ) && trips.length ) ) {
    return false;
  }

  return Boolean( trips.find( item => isTrip( item ) ) );
};
