import classNames from "classnames";
import { GroundIcon } from "@greenvulcano/ground-react";


const CloseButton = ({
  color = 'secondary',
  className,
  ...rest
}: any ) => {
  return (
    <button
      type='button'
      className={classNames(
        'close-button',
        className
      )}
      {...rest}
    >
      <GroundIcon svgIcon='close' width='18' color={color} />
    </button>
  );
};

export default CloseButton;