import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./ManualClaimDetails.scss";
import {
  selectGoogleIsLoaded,
  addMarkersWithId,
  removeMarkersById,
  selectMarkerIdsByName,
  changeMarkerIconById,
  selectAllMarkerObjects,
  selectShapeIdsByName,
  removeShapesById,
  addShapesWithId,
} from "../../../../../store/slices/mapSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { mapModels } from "../../../../../models/mapModels";
import { useTranslation } from "react-i18next";
import {
	GroundButton,
	GroundButtonTypes,
	GroundButtonVariants,
	GroundToastType,
  GroundToastVariant,
	useGroundToast,
} from "@greenvulcano/ground-react";
import Select from "../../../../../shared-components/RHF-input-wrappers/Select/Select";
import TextInput from "../../../../../shared-components/RHF-input-wrappers/TextInput/TextInput";
import DatePicker from "../../../../../shared-components/DatePicker/DatePicker";
import { useMutation, useQueryClient, UseQueryResult } from "react-query";
import { ClaimsService } from "../../../../../services/ClaimsService";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { updatePeriod } from "../../../../../store/slices/periodSlice";
import moment from "moment";
import { getMarkerIcon } from "../../../../../utils/colorcoded-markers";
import { claimModels } from "../../../../../models/claimModels";
import { useConfirmationModal } from "../../../../../hooks";
import ClaimDetailsConfirmationModal from "../../../../../shared-components/confirmation-modals/ClaimDetailsConfirmationModal/ClaimDetailsConfirmationModal";
import { useNavigate } from "react-router-dom";



type ManualClaimDetailsFormValues = {
	idAnomalyType: string;
	claimAddress: string;
	claimZipCode: string;
	claimDateAndTime: Date;
	claimPosition: { lat: number; lng: number };
};

const getDefaultValues = ( queryData: Record<string, any> ) => {
  return {
    claimAddress: queryData?.location || '',
    idAnomalyType: queryData?.claim_type_id || '',
    claimZipCode: queryData?.zip_code || '',
    claimDateAndTime: (
      queryData?.claim_date && queryData?.sinister_hour ? 
      new Date(queryData.claim_date.slice(0, 10) + "T" + queryData.sinister_hour) : 
      new Date()
    ),
    claimPosition: {
      lat: queryData?.claim_position?.coordinates[1],
      lng: queryData?.claim_position?.coordinates[0],
    },
  }
};

const ManualClaimDetails = ({claimDetailsQuery}: {claimDetailsQuery : UseQueryResult<any, unknown>}) => {
	const appDispatch = useAppDispatch();
  const groundToast = useGroundToast();
  const navigate     = useNavigate();
  const queryClient  = useQueryClient();
  const confirmationModal = useConfirmationModal();
	const isLoaded = useAppSelector(selectGoogleIsLoaded);
	const markerIdsByName = useAppSelector(selectMarkerIdsByName);
	// const allMarkerOjbects = useAppSelector(selectAllMarkerObjects);
	const shapeIdsByName = useAppSelector(selectShapeIdsByName);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const markerObjectsById = useAppSelector(selectAllMarkerObjects);
	const [searchbarInputValue, setSearchbarInputValue] = useState( claimDetailsQuery.data?.location || '' );
  const [defaultValues, setDefaultValues] = useState( getDefaultValues( claimDetailsQuery.data ) );
	const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<
		google.maps.places.AutocompletePrediction[]
		>([]);
	const { id: claimID = "0" } = useParams();
	const claimId: string = claimID;
	const { t } = useTranslation("main");
	const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm<ManualClaimDetailsFormValues>({
    defaultValues: defaultValues,
  });
	const idAnomalyTypeWatch = watch("idAnomalyType")
  const claimDateAndTimeWatch = watch("claimDateAndTime");
  const globalWatch = watch();

  const onActionClaimDetailsModal = () => {
    navigate(`/admin/profiles`);
    confirmationModal.close();
  };

  const closeSuggestionsDropdown = () => {
    setAutocompleteSuggestions( [] );
  };

	const handleSearchbarInputChange = async (value: string) => {
    setSearchbarInputValue(value);

    if ( !value.trim() ) {
      closeSuggestionsDropdown();

      return;
    }

		try {
			if (isLoaded) {
				const predictions = await autocompleteAddress(value);

				setAutocompleteSuggestions(predictions);
			}
		} catch (error) {
			console.error("Error retrieving autocomplete predictions:", error);
		}
	};

	const onSubmit = () => {
		const { idAnomalyType, claimAddress,claimZipCode, claimDateAndTime, claimPosition } = getValues();

		updateClaimMutation.mutate({
			claimId,
			claimPosition,
			claimAddress,
      claimZipCode,
			idAnomalyType,
			claimDateAndTime,
		});
	};

	const calculateFraudMutation = useMutation({
		mutationFn: (claimId: string ) => {
		  return ClaimsService.calculateFraud(claimId);
		},
		onSuccess: () => {
      claimDetailsQuery.refetch()
      queryClient.invalidateQueries({ queryKey : [ 'trips' ] });

		  groundToast.show({
			type: GroundToastType.success,
			variant: GroundToastVariant.featured,
			message: `${t("toastMessages.savingSuccessful")} `,
		  });
			
		  
		},
		onError: (error: AxiosError) => {
      groundToast.show({
				type: GroundToastType.alert,
				variant: GroundToastVariant.featured,
				message: String(error),
			});
		},
	  });

	const updateClaimMutation = useMutation({
		mutationFn: ClaimsService.updateClaim,
		onSuccess: (claimId : string) => {
			queryClient.invalidateQueries({ queryKey: [ 'claimsList' ]});
			queryClient.invalidateQueries({ queryKey: [ 'csvClaims' ]});
			queryClient.invalidateQueries({ queryKey: [ 'summaryClaims' ]});
			calculateFraudMutation.mutate(claimId, claimDetailsQuery.data.id_voucher);
		},
		onError: (error: AxiosError) => {
			groundToast.show({
				type: GroundToastType.alert,
				variant: GroundToastVariant.featured,
				message: String(error),
			});
		},
	});

  const fraudProbabilityColor =
    claimModels.fraudProbabilityColorsMap[
    claimDetailsQuery.data
      .fraud_probability_id as keyof typeof claimModels.fraudProbabilityColorsMap
    ];
	
  const getMarkerIconForManuallyCreatedClaim = (color: string) => {
    return getMarkerIcon(
      color,
      claimModels.claimTypeMap[
      idAnomalyTypeWatch as keyof typeof claimModels.claimTypeMap
      ]
    );
  };

  useEffect(() => {
    const fraudProbabilityUnkown = claimDetailsQuery.data?.fraud_probability_id === null;
    const inputsHaveBeenFilled =
      !!globalWatch.idAnomalyType &&
      !!globalWatch.claimAddress &&
      !!globalWatch.claimZipCode &&
      !!globalWatch.claimPosition.lat &&
      !!globalWatch.claimPosition.lng &&
      !!globalWatch.claimDateAndTime;

    const inputsAreDiffrentFromDefaultValues =
      !moment(globalWatch.claimDateAndTime).isSame(
        moment(defaultValues.claimDateAndTime)
      ) ||
      globalWatch.claimPosition.lat !== defaultValues.claimPosition.lat ||
      globalWatch.claimPosition.lng !== defaultValues.claimPosition.lng ||
      globalWatch.claimAddress !== defaultValues.claimAddress ||
      globalWatch.claimZipCode !== defaultValues.claimZipCode ||
      globalWatch.idAnomalyType !== defaultValues.idAnomalyType;

    const searchBarInputValueIsSameAsClaimAddress =
      globalWatch.claimAddress === searchbarInputValue;

    if (
      (inputsAreDiffrentFromDefaultValues &&
      inputsHaveBeenFilled &&
      searchBarInputValueIsSameAsClaimAddress) || fraudProbabilityUnkown
    ) {
      setSaveButtonEnabled(true);
    } else {
      setSaveButtonEnabled(false);
    }
  }, [globalWatch, searchbarInputValue, defaultValues, claimDetailsQuery.data]);

  useEffect(() => {
	const currentDate = new Date().toISOString().slice(0, 10); 
	const date = claimDateAndTimeWatch?.toISOString().slice(0, 10);
	let start, end;
	if (date === currentDate) {
		const prevDate = new Date(date);
		prevDate.setDate(prevDate.getDate() - 1);
		start = prevDate.toISOString().slice(0, 10);
		end = date;
	} else {
		const prevDate = new Date(date);
		prevDate.setDate(prevDate.getDate() - 1);
		start = prevDate.toISOString().slice(0, 10);
		const nextDate = new Date(date);
		nextDate.setDate(nextDate.getDate() + 1);
		end = nextDate.toISOString().slice(0, 10);
	}
	appDispatch(
	  updatePeriod({ id: "claimDetail", period: { start, end } })
	);

    if (
      !moment(claimDateAndTimeWatch).isSame(
        moment(defaultValues.claimDateAndTime)
      )
    ) {
      setNeutralMarkerAndShape({
        lat: globalWatch.claimPosition.lat,
        lng: globalWatch.claimPosition.lng,
        address: globalWatch.claimAddress,
      });
    }
  }, [claimDateAndTimeWatch]);

  useEffect(() => {
    if (markerObjectsById[markerIdsByName.manualClaimMarker]) {
      appDispatch(
        changeMarkerIconById({
          icon: getMarkerIconForManuallyCreatedClaim(
            fraudProbabilityColor ?? "#0E2636"
          ),
          id: markerIdsByName.manualClaimMarker,
        })
      );
    } else if (
      claimDetailsQuery.data &&
      claimDetailsQuery.data.claim_position &&
      claimDetailsQuery.data.claim_position.coordinates &&
      claimDetailsQuery.data.location
    ) {
      createOrUpdateFraudProbabilityMarker({
        lat: claimDetailsQuery.data.claim_position.coordinates[1],
        lng: claimDetailsQuery.data.claim_position.coordinates[0],
        address: claimDetailsQuery.data.location,
      });
    }
  }, [
    appDispatch,
    getValues,
    idAnomalyTypeWatch,
    markerIdsByName.manualClaimMarker,
  ]); 

	useEffect(() => {
		return () => {
			appDispatch(removeMarkersById([markerIdsByName.manualClaimMarker]));
      appDispatch(removeShapesById([shapeIdsByName.fraudProbabilityRadius]));
		};
	}, []);

	useEffect(() => {
		if (
      claimDetailsQuery.data?.claim_position?.coordinates[1] &&
      claimDetailsQuery.data?.claim_position?.coordinates[0] &&
      claimDetailsQuery.data.fraud_probability_radius &&
      fraudProbabilityColor
    ) {
      createOrUpdateFraudProbabilityCircle({
        lat: claimDetailsQuery.data.claim_position.coordinates[1],
        lng: claimDetailsQuery.data.claim_position.coordinates[0],
        radius: parseInt(claimDetailsQuery.data.fraud_probability_radius),
        color: fraudProbabilityColor,
      });

      createOrUpdateFraudProbabilityMarker({
        lat: claimDetailsQuery.data.claim_position.coordinates[1],
        lng: claimDetailsQuery.data.claim_position.coordinates[0],
        address: claimDetailsQuery.data.location,
      });
    } else {
      appDispatch(removeShapesById([shapeIdsByName.fraudProbabilityRadius]));
    }

    if (claimDetailsQuery.data.fraud_probability_id === null) {
      appDispatch(
        changeMarkerIconById({
          icon: getMarkerIconForManuallyCreatedClaim(
            fraudProbabilityColor ?? "#0E2636"
          ),
          id: markerIdsByName.manualClaimMarker,
        })
      );
    }

    setDefaultValues( getDefaultValues( claimDetailsQuery.data ) );
  }, [claimDetailsQuery.data])

  const onClickCancel = () => {
    confirmationModal.open(
      <ClaimDetailsConfirmationModal onAction={onActionClaimDetailsModal}/>
    );
  };

  const createOrUpdateFraudProbabilityMarker = ({
    lat,
    lng,
    address,
    neutralFraudProbabilityColor,
  }: {
    lat: number;
    lng: number;
    address: string;
    neutralFraudProbabilityColor?: boolean;
  }) => {
    const marker: mapModels.MarkerObj = {
      key: "",
      position: { lat, lng },
      title: address,
      icon: getMarkerIconForManuallyCreatedClaim(
        !!neutralFraudProbabilityColor || !fraudProbabilityColor
          ? "#0E2636"
          : fraudProbabilityColor
      ),
      zIndex: 10,
      clickable: false,
    };
    appDispatch(
      addMarkersWithId([
        {
          markerObj: marker,
          id: markerIdsByName.manualClaimMarker,
        },
      ])
    );
  };

  const createOrUpdateFraudProbabilityCircle = ({
    lat,
    lng,
    radius,
    color,
  }: {
    lat: number;
    lng: number;
    radius: number;
    color: string;
  }) => {
    const shapeObj: mapModels.CircleShapeInterface = {
      center: {
        lat,
        lng,
      },
      radius,
      strokeColor: color,
      strokeOpacity: 0.3,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.35,
    };

    appDispatch(
      addShapesWithId([
        {
          shapeObj: shapeObj,
          id: shapeIdsByName.fraudProbabilityRadius,
        },
      ])
    );
  };

  const getZipCode = (decodedAddress: google.maps.GeocoderResult): string | null => {
    for (const component of decodedAddress.address_components) {
      if (component.types.includes("postal_code")) {
        return component.long_name;
      }
    };
    return null;
  };

  const setNeutralMarkerAndShape = ({
    lat,
    lng,
    address,
  }: {
    lat: number;
    lng: number;
    address: string;
  }) => {
    createOrUpdateFraudProbabilityMarker({
      lat,
      lng,
      address,
      neutralFraudProbabilityColor: true,
    });
    appDispatch(removeShapesById([shapeIdsByName.fraudProbabilityRadius]));
  };

	const handleAutocompleteSuggestionsSelection = async (address: string) => {
		setSearchbarInputValue( address );
    closeSuggestionsDropdown();

		try {
			const results = await geocodeAddress(address);
			if (results && results.length ) {
				const { lat, lng } = results[0].geometry.location;
        setNeutralMarkerAndShape({
          lat: lat(),
          lng: lng(),
          address,
        });
        const zipCode = getZipCode(results[0]);
        if (zipCode === null) {
          groundToast.show({
            type: GroundToastType.warning,
            variant: GroundToastVariant.featured,
            message: `${t("toastMessages.moreDetailedAddressRequired")} `,
          });
        }
        setValue("claimZipCode", zipCode || "");
				setValue("claimAddress", address);
				setValue("claimPosition", { lat: lat(), lng: lng() });
			}
		} catch (error) {
			console.error("Error retrieving location:", error);
		}
	};

	const autocompleteAddress = (value: string) => {
		return new Promise<google.maps.places.AutocompletePrediction[]>(
			(resolve, reject) => {
				const autocompleteService =
					new google.maps.places.AutocompleteService();
				autocompleteService.getPlacePredictions(
					{
						input: value,
						region: 'MX',
            componentRestrictions: {
              country: 'MX'
            }
					},
					(predictions, status) => {
						if (status === google.maps.places.PlacesServiceStatus.OK) {
							resolve(predictions || []);
						} else {
							reject(status);
						}
					}
				);
			}
		);
	};

	const geocodeAddress = (address: string) => {
		return new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
			const geocoder = new google.maps.Geocoder();
			geocoder.geocode({ address }, (results, status) => {
				if (status === google.maps.GeocoderStatus.OK) {
					resolve(results || []);
				} else {
					reject(status);
				}
			});
		});
	};


	return (
    <div className="manual-claims-container">
      <form
        className="form-manual-claim-details"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="places-container">
          <div className="input-searchbar">
            <span className="description-span">
              {t("claims.reportedClaimAddress")}
            </span>
            {isLoaded && (
              <TextInput
                fluid
                name="claimAddress"
                control={control}
                errors={errors}
                value={searchbarInputValue}
                rules={{ required: true }}
                className={searchbarInputValue.trim() !== '' ? 'has-text' : ''}
                placeholder={t("claims.enterAnAddress") as string}
                onChange={(event) => {
                  handleSearchbarInputChange( event.target.value );

                  return true;
                }}
              />
            )}
          </div>
          {autocompleteSuggestions.length > 0 && (
            <div className="autocomplete-results">
              {autocompleteSuggestions.map((prediction) => (
                <div
                  className="autocomplete-item"
                  key={prediction.place_id}
                  onClick={() => {
                    handleAutocompleteSuggestionsSelection(
                      prediction.description
                    );
                  }}
                >
                  {prediction.description}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="date-picker-container flex">
          <span className="description-span">
            {t("claims.claimDateAndTime")}
          </span>
          <DatePicker
            control={control}
            name="claimDateAndTime"
            defaultValue={new Date()}
          ></DatePicker>
        </div>
        <div className="anomaly-type-container">
          <span className="description-span">{t("common.anomalyType")}</span>
          <Select
            fluid
            name="idAnomalyType"
            height="34"
            rules={{ required: true }}
            placeholder={t("common.anomalyType") as string}
            options={[
              { value: "1", label: t("common.crash") },
              { value: "2", label: t("common.theft") },
            ]}
            control={control}
            errors={errors}
            className="select-anomaly"
          />
        </div>
        <div className="save-button-wrapper">
          <GroundButton
            color="secondary"
            height="medium"
            variant={GroundButtonVariants.ghost}
            className="cancel-button"
            type={GroundButtonTypes.button}
            text={t('common.cancel')}
            onClick={onClickCancel}
          />
          <GroundButton
            disabled={!saveButtonEnabled}
            color="secondary"
            height="medium"
            className="uppercase"
            type={GroundButtonTypes.submit}
						text={t("common.save")}
            isLoading={calculateFraudMutation.isLoading || updateClaimMutation.isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default ManualClaimDetails;
