import { createContext, ReactElement } from "react";
import { ConfirmationModalProviderOptions } from "../shared-components/ConfirmationModalProvider/ConfirmationModalProviderInterface";


export interface ConfirmationModalContextInterface {
  open: ( modalComponent: ReactElement, options?: ConfirmationModalProviderOptions ) => void;
  close: () => void;
}

export const ConfirmationModalContext = createContext<ConfirmationModalContextInterface>({
  open: () => null,
  close: () => null
});