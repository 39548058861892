import * as echarts from "echarts";
import { View, Image } from "@react-pdf/renderer"


const PdfChart = ({
  element
}: {
  element: HTMLDivElement
}) => {
  let dataUrl = '';
  let width   = 0;
  let height  = 0;

  const imageScale  = 2;
  const pxToPtRatio = 0.24;
  const options     = {
    type: 'png' as 'png',
    pixelRatio: Math.ceil( imageScale ),
    backgroundColor: '#fff'
  };

  const chartInstance = echarts.getInstanceByDom( element );
    
  if ( chartInstance ) {
    dataUrl = chartInstance.getDataURL( options );
    width = chartInstance.getWidth() * imageScale * pxToPtRatio;
    height = chartInstance.getHeight() * imageScale * pxToPtRatio;
  }

  return (
    <View>
      {dataUrl ? (
        <Image
          src={dataUrl}
          style={{
            height: height,
            width: width
          }}
        />
      ) : null}
    </View>
  );
};

export default PdfChart;