export const deepBlue                = '#004E85';
export const deepBlueLight           = '#0064ab';
export const deepBlueLightest        = '#F5F8FA';
export const deepBlueDark            = '#004373';
export const deepBlueDarker          = '#002B49';
export const deepBlueWashedOut       = '#5B7383';
export const deepBlueWashedOutDarker = '#41535f';
export const deepBlueWashedOutDark   = '#425A6A';
export const turquoise               = '#20A8BF';
export const turquoiseLight          = '#25b9d8';
export const turquoiseDark           = '#1a8293';
export const turquoiseWashedOut      = '#80bcc8';
export const turquoiseWashedOutDark  = '#5f8e97';
export const grey                    = '#98a0a6';
export const greyDarker              = '#383839';
export const greyDark                = '#425360';
export const greyLight               = '#687680';
export const greyLighter             = '#A6B0B6';
export const blueGrey                = '#B3CADB';
export const blueGreyLight           = '#E6EDF3';
export const blueGreyLighter         = '#99B8CE';
export const blueGreyLightest        = '#F5F8FA';
export const blueGreyDark            = '#80a2bc';
export const white                   = '#FFFFFF';
export const monochromeGrey          = '#DDDDDD';
export const monochromeGreyDark      = '#ACB8C1';
export const monochromeGreyDarker    = '#BDBDBD';
export const monochromeGreyLight     = '#E1E1E1';
export const monochromeGreyLighter   = '#EAECEF';
export const monochromeGreyLightest  = '#F9F9F9';
export const black                   = '#0E2636';
export const blackLight              = '#333333';
export const green                   = '#488C21';
export const greenLight              = '#47A03F';
export const orange                  = '#F46A00';
export const red                     = '#DD0546';
export const mustard                 = '#D99C2B';
export const cloudyBlue              = '#7EB3BF';
export const mud                     = '#8C8369';
export const grass                   = '#A7BF20';
export const brightBlue              = '#0571DD';
export const nightBlueWashedOut      = '#284050';
