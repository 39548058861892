import { ForwardedRef, forwardRef } from "react";

import { ChartLoaderForPdfInterface } from "./ChartLoaderForPdfInterface";
import Chart from "../Chart/Chart";


const ChartLoaderForPdf = forwardRef(({
  canLoad,
  width = '1150px',
  height = '700px',
  chartOption,
  onLoaded
}: ChartLoaderForPdfInterface, ref: ForwardedRef<HTMLDivElement> ) => {
  return ( canLoad ? (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <Chart
        ref={ref}
        onFinished={onLoaded}
        style={{ height, width, position: 'absolute' }}
        option={chartOption}
      />
    </div>
  ) : null );
});

export default ChartLoaderForPdf;