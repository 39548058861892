import classNames from "classnames";

import Card from "../Card/Card";
import RequestIndicator from "../RequestIndicator/RequestIndicator";

import { MainSidebarCardInterface } from "./MainSidebarCardInterface";
import "./MainSidebarCard.scss";


const MainSidebarCard = ({
  isSuccess,
  isLoading,
  hideError,
  errorMessage,
  spinnerOptions,
  className,
  innerClassName,
  placeholderHeight,
  children
}: MainSidebarCardInterface ) => {
  return (
    <Card className={classNames(
      'pdetails-main-sidebar-card flex flex-vcenter',
      className
    )}>
      <div
        className={classNames(
          'width-100',
          innerClassName,
          { hidden: !isSuccess }
        )}
        style={{ minHeight: ( placeholderHeight || 'auto' )}}
      >
        {isSuccess || !placeholderHeight ? children : null}
      </div>
      {isSuccess ? null : (
        <RequestIndicator
          hideError={hideError}
          isLoading={isLoading}
          errorMessage={errorMessage}
          spinnerOptions={{
            scale: 0.5,
            ...spinnerOptions
          }}
          className='indicator'
        />
      )}
    </Card>
  );
};

export default MainSidebarCard;