import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { applyErrorBoundary } from "../../utils/error-boundary";
import { setDocumentTitle } from "../../config";

import { useAppTheme } from "../../hooks";
import { GroundButton, GroundButtonTypes } from "@greenvulcano/ground-react";
import "./ErrorPage.scss";


const ErrorWrapper = styled.div`
  background-color: ${( props: any ) => props.theme.palettes.backgroundSecondary};
    & h1 {
      color: ${( props: any ) => props.theme.palettes.primaryDarkest};
    }
    & h2 {
      color: ${( props: any ) => props.theme.palettes.primaryDarkest};
    }
    & p {
      color: ${( props: any ) => props.theme.palettes.textLight};
    }
`;

const ErrorPage = () => {
  const theme = useAppTheme();
  const { t }    = useTranslation( 'main' );
  const navigate = useNavigate();

  useEffect(() => {
    setDocumentTitle( t( 'errorPage.title' ) as string );
  }, [] );
  
  return (
    <ErrorWrapper
      theme={theme}
      className='error-page flex flex-columns flex-center height-100v'
    >
      <div className='error-wrapper'>
        <h1>{t( 'errorPage.title' )}</h1>
        <h2>{t( 'errorPage.subtitle' )}</h2>
        <p>{t( 'errorPage.errorMessage' )}</p>
        <GroundButton
          text={t( 'errorPage.buttonText' )}
          type={GroundButtonTypes.button}
          onClick={() => navigate( '/' )}
        />
      </div>
    </ErrorWrapper>
  );
};

export default applyErrorBoundary( ErrorPage );