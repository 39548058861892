import {
  View,
  Text,
  Image,
  StyleSheet
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getAppConfig, getAppDateFormat } from "../../../config";
import { capitalize, normalizeData } from "../../../utils";

import PdfSvgIcon from "../PdfSvgIcon/PdfSvgIcon";
import { PdfTemplateInterface } from "./PdfTemplateInterface";
import { valueNotFoundSymbol } from "../../../utils/constants";


const typesOfFeedback = [
  {
    id: 'true',
    i18nKey: 'common.claimIsTrue',
    icon: 'noFraud',
    color: 'success'
  },
  {
    id: 'false',
    i18nKey: 'common.claimIsFalse',
    icon: 'fraud',
    color: 'danger'
  },
  {
    id: 'investigate',
    i18nKey: 'common.toInvestigateMore',
    icon: 'magnifyingGlass',
    color: 'warning'
  },
  {
    id: 'unknown',
    i18nKey: 'common.notAssessed',
    icon: '',
    color: 'disabled'
  }
];

export const getPdfTempalteStyles = ({
  theme,
  feedbackColor = ''
}: {
  theme: Record<string, any>;
  feedbackColor?: string;
}) => {
  return StyleSheet.create({
    bold: {
      fontFamily: 'Helvetica-Bold'
    },
    title: {
      alignItems: 'center',
      borderBottom: `0.5px solid ${theme.palettes.borderTertiaryDarker}`,
      flexDirection: 'row',
      fontFamily: 'Helvetica-Bold',
      fontSize: 14,
      gap: 4,
      marginBottom: 10,
      marginTop: 20,
      paddingBottom: 4,
      textTransform: "uppercase",
    },
    titleBigMargin: {
      marginTop: 40
    },
    body: {
      padding: 20,
      paddingBottom: 10,
      flexGrow: 1
    },
    mainContent: {
      color: theme.palettes.text,
      fontSize: 11,
      lineHeight: 1.4,
      flexGrow: 1
    },
    header: {
      height: 78,
      flexDirection: 'row'
    },
    logo: {
      alignItems: 'center',
      justifyContent: 'center',
      width: 170,
    },
    feedbackInfo: {
      alignItems: 'center',
      backgroundColor: theme.palettes[feedbackColor],
      color: (
        theme.palettes[`on${capitalize( feedbackColor )}`] ||
        theme.palettes.textWhite
      ),
      flexGrow: 1,
      flexDirection: 'row',
      gap: 7,
      padding: 20,
      paddingLeft: 15
    },
    dateBox: {
      alignItems: 'flex-end',
      fontSize: 9,
    },
    reportDate: {
      opacity: 0.8
    },
    footer: {
      alignItems: 'flex-end',
      fontSize: 11,
      paddingBottom: 5
    }
  });
};

const PdfTemplate = ({
  theme,
  claimStatus,
  docTitle,
  reportID,
  reportIdLabel,
  period,
  pageNum,
  children
}: PdfTemplateInterface ) => {
  const { t }  = useTranslation( 'main' );
  const logo   = getAppConfig( 'pdfLogo' );
  const feedbackTypesMap = normalizeData( typesOfFeedback, 'id' );
  const dateFormat       = getAppDateFormat() + ' - HH:mm:ss a';
  let feedback = feedbackTypesMap.unknown;

  if ( claimStatus ) {
    switch ( String( claimStatus.id_claim_status ) ) {
      case '1':
        feedback = feedbackTypesMap.true;
        break;
      case '2':
        feedback = feedbackTypesMap.false;
        break;
      case '3':
        feedback = feedbackTypesMap.investigate;
        break;
      default:
        break;
    }
  }

  const styles = getPdfTempalteStyles({ 
    theme, 
    feedbackColor: ( claimStatus ? feedback.color : 'primary' )
  });

  return (
		<>
			<View style={styles.header}>
				{logo ? (
					<View style={styles.logo}>
						<Image
							src={logo}
							style={{ height: getAppConfig("pdfLogoHeight", "auto") }}
						/>
					</View>
				) : null}
				<View style={styles.feedbackInfo}>
					{"unknown" === feedback.id ? null : (
						<PdfSvgIcon
							theme={theme}
							name={feedback.icon}
							color="textWhite"
							style={{ width: 37, height: 37 }}
						/>
					)}
					<View style={[styles.bold, { flexGrow: 1, fontSize: 13, gap: 5 }]}>
						{docTitle ? (
							<Text>{docTitle}</Text>
						) : (
							<>
								<Text>{t("common.analysis")}</Text>
								<Text>{t(feedback.i18nKey)}</Text>
							</>
						)}
					</View>
          {period ? (
            <View style={[ styles.dateBox, { gap: 5 } ]}>
              <Text style={styles.bold}>
                {t( 'common.period' )}
                {':'}
              </Text>
              <View style={[ styles.reportDate, { gap: 2 }]}>
                <Text>
                  {moment.utc( period.start ).format( dateFormat )}
                </Text>
                <Text>
                  {moment.utc( period.end ).format( dateFormat )}
                </Text>
              </View>
            </View>
          ): ( reportIdLabel ? (
            <View style={[ styles.dateBox, { gap: 7 } ]}>
              <Text style={styles.bold}>
                {reportIdLabel}
                {': '}
                {reportID || valueNotFoundSymbol}
              </Text>
              <Text style={styles.reportDate}>
                {moment().format( dateFormat )}
              </Text>
            </View>
          ) : (
            <View style={[ styles.dateBox, { gap: 5 } ]}>
              <Text style={styles.bold}>
                {t( 'common.downloadDate')}
                {':'}
              </Text>
              <Text style={styles.reportDate}>
                {moment().format( dateFormat )}
              </Text>
            </View>
          ))}
				</View>
			</View>
			<View style={styles.body}>
        <View style={styles.mainContent}>
          {children}
        </View>
        {pageNum ? (
          <View style={styles.footer}>
            <Text>{pageNum}</Text>
          </View>
        ): null}
      </View>
		</>
	);
};

export default PdfTemplate;
